import { Stack, Typography } from "@mui/material";
import React from "react";
// import { allImages } from "../../../../../../images";
import Media from "./Media";

const RecievedMsg = ({
  content,
  image,
  lastMessage,
  isMedia,
  media,
  time,
  showTime,
}) => {
  // this is the recieved message component
  return (
    <Stack>
      <Stack
        style={{
          flexDirection: "row",
          // ustifyContent: "end",
          gap: 8,
          alignItems: "start",
        }}
      >
        {
          <Stack
            style={{
              visibility: lastMessage ? "visible" : "hidden",
              width: "28px",
              height: "28px",
              borderRadius: "10px",
              overflow: "hidden",
              // background: "#D8D8D8",
            }}
          >
            {/* <img
              src={image || allImages.avatar}
              alt="image"
              style={{ objectFit: "cover" }}
            /> */}
          </Stack>
        }
        <Stack
          className="recievermsg"
          style={{
            padding: isMedia && "none",
            borderTopLeftRadius: lastMessage && 0,
          }}
        >
          {isMedia ? <Media media={media} /> : content}
        </Stack>
      </Stack>
      {showTime && (
        <Typography
          variant="subtitle1"
          className="chat__time"
          style={{ alignSelf: "start", paddingLeft: "40px" }}
        >
          {/* {new Date(time * 1000).toDateString()} */}
          {"" +
            " " +
            new Date(time * 1000).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })}
        </Typography>
      )}
    </Stack>
  );
};

export default RecievedMsg;
