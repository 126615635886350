import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  lighten,
  makeStyles,
  withStyles,
  fade,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from "@material-ui/icons/FilterList";
import Popover from "@material-ui/core/Popover";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import ObjectUtils from "../../utils/ObjectUtils";
import { Route } from "react-router-dom";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import LocalStorage from "../../config/LocalStorage";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Hidden } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StringUtils from "../../utils/StringUtils";
import XLSX from "xlsx";
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    // onRequestSort,
    selectAll,
    tableHeader,
    translator,
  } = props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        {tableHeader.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={
              headCell.dataType === "string" || headCell.dataType === "date"
                ? "left"
                : headCell.dataType === "boolean"
                ? "center"
                : headCell.dataType === "number"
                ? "right"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "default"}
            // sortDirection={orderBy === headCell.id ? order : false}
            style={{
              minWidth: headCell.width,
              display:
                headCell.visibility !== undefined &&
                headCell.visibility !== null &&
                headCell.visibility
                  ? ""
                  : "none",
            }}
          >
            {translator(headCell.label)}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    overflow: "auto",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },

  typographyQueryTitle: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    alignSelf: "center",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "22.5ch",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: { minHeight: 400, maxHeight: 400 },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
    overflow: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  typography: {
    padding: theme.spacing(2),
  },

  tableRow:
    theme.palette.type === "light"
      ? {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: theme.palette.info.light,
          },
        }
      : {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: theme.palette.info.dark,
          },
        },
}));
export const TableBodyContext = React.createContext({});
function TableBodyContextHandler({ children }) {
  const [value, setValue] = React.useState("-");
  const [isLoading, setIsLoading] = React.useState(true);
  // useEffect(() => {
  //   import("../../utils/" + children.props.cellDetails.className).then(
  //     (file) => {
  //       let value = file.default[children.props.cellDetails.functionName](
  //         children.props.value
  //       );
  //       setValue(value);
  //       setIsLoading(false);
  //     }
  //   );
  // }, [children]);

  return (
    <TableBodyContext.Provider value={{ value, isLoading }}>
      {children}
    </TableBodyContext.Provider>
  );
}
const TableBodyFunctionCall = () => {
  const { value, isLoading } = useContext(TableBodyContext);
  return <Route render={() => (!isLoading ? value : "-")} />;
};
const TableBodyGenarater = (props) => {
  let value = ObjectUtils.dataTableBodyGenarater(props.row, props.cellDetails);
  if (value !== undefined && value !== null && value !== "") {
    if (
      props.cellDetails === "boolean" ||
      value === "active" ||
      value === "inactive"
    ) {
      if (value && value === "active") {
        // return right icon;
      } else {
        // return cross icon;
      }
    } else if (
      props.cellDetails !== "boolean" &&
      props.cellDetails.className !== undefined &&
      props.cellDetails.className !== null &&
      props.cellDetails.className !== "" &&
      props.cellDetails.functionName !== undefined &&
      props.cellDetails.functionName !== null &&
      props.cellDetails.functionName !== ""
    ) {
      return (
        <TableBodyContextHandler>
          <TableBodyFunctionCall
            cellDetails={props.cellDetails}
            value={value}
          />
        </TableBodyContextHandler>
      );
    }
  } else {
    value = "-";
  }

  return value;
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd),&:nth-of-type(odd):hover": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.info.light
          : theme.palette.info.light,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function TableWithoutSelection(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [tableHeader, setTableHeader] = React.useState(props.tableHeader);
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  useEffect(() => {
    if (props.openForm !== undefined && props.openForm !== null) {
      if (!props.openForm.status) {
        setSelected([]);
      }
    }
  }, [props.openForm]);
  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property.id && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property.id);
  //   props.setQuery({
  //     ...props.query,
  //     sortField: property.accessor,
  //     sortOrder: isAsc ? 'true' : 'false',
  //   });
  // };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props?.tableData?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  let timer = 0;
  let delay = 200;
  let prevent = false;
  const handleClick = (event, name, rowDetails) => {
    timer = setTimeout(function () {
      if (!prevent) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (props.selectAll) {
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1)
            );
          }
        } else {
          if (selected[0] !== name) {
            newSelected[0] = name;
          }
          if (newSelected.length > 0) {
            props.tableCallBack(rowDetails);
          } else {
            props.tableCallBack(null);
          }
        }

        setSelected(newSelected);
      }
      prevent = false;
    }, delay);
  };

  const handleDBLClick = (event, name, rowDetails) => {
    props.tableDBLCallBack(rowDetails);
  };

  const isSelected = (name) =>
    selected !== undefined &&
    selected !== null &&
    selected.length > 0 &&
    selected.indexOf(name) !== -1;

  const emptyRows = props?.tableData;
  const updateTableHeader = (headerDetails) => {
    setTableHeader(headerDetails);
  };

  //console.log("props.table", props.tableData);
  return (
    <TableContainer className={classes.container}>
      <Table
        size={dense ? "small" : "medium"}
        aria-label="sticky table"
        stickyHeader
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          // onRequestSort={handleRequestSort}
          rowCount={props?.tableData !== null ? props?.tableData?.length : 0}
          selectAll={props.selectAll}
          tableHeader={tableHeader}
          translator={props.t}
        />
        <TableBody>
          {emptyRows === null ? (
            <TableRow>
              <TableCell colSpan={tableHeader.length + 1} align={"center"}>
                <CircularProgress></CircularProgress>
              </TableCell>
            </TableRow>
          ) : emptyRows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={tableHeader.length + 1} align={"center"}>
                No Record Found
              </TableCell>
            </TableRow>
          ) : (
            props?.tableData?.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                  className={classes.tableRow}
                >
                  {/* <TableCell padding='checkbox'>
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell> */}
                  {tableHeader.map((cellDetails, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      align={
                        cellDetails.dataType === "string" ||
                        cellDetails.dataType === "date"
                          ? "left"
                          : cellDetails.dataType === "boolean"
                          ? "center"
                          : cellDetails.dataType === "number"
                          ? "right"
                          : "left"
                      }
                      style={{
                        minWidth: cellDetails.width,
                        display:
                          cellDetails.visibility !== undefined &&
                          cellDetails.visibility !== null &&
                          cellDetails.visibility
                            ? ""
                            : "none",
                      }}
                    >
                      <TableBodyGenarater row={row} cellDetails={cellDetails} />
                    </TableCell>
                  ))}
                </StyledTableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default withTranslation("translations")(TableWithoutSelection);
