import React from "react";

const RoutingState = () => {
  const [route, setRoute] = React.useState(false);

  const handleRoute = () => {
    setRoute(true);
  };

  return {
    route,
    handleRoute,
    setRoute,
  };
};

export default RoutingState;
