import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Paper,
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Button,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import Autocomplete from "@material-ui/lab/Autocomplete";

import useAssignForm from "./hooks/useAssignForm";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));

function EditFormPage(props) {
  const classes = useStyles();

  const {
    appointmentCreateData,
    setAppointmentCreateData,
    listUsers,
    interviewSetToOptions,
    handleStartAtDateChange,
    getInterviewersList,
    listUserFunc,
    sendToServer,
  } = useAssignForm(props);

  // Handler for Autocomplete change event
  const handleAutocompleteChange = (event, data) => {
    setAppointmentCreateData({
      ...appointmentCreateData,
      userId: data?._id,
    });
  };

  useEffect(() => {
    // Check if actionTo contains "everyone"
    const everyoneSelected =
      appointmentCreateData.actionTo.includes("everyone");
    // If "everyone" is selected, select all checkboxes; otherwise, keep them as they are
    if (everyoneSelected) {
      // If "everyone" is selected, populate all checkboxes
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient", "caregiver", "careteam"],
      });
    }
  }, [appointmentCreateData.actionTo]);
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let updatedActionTo;
    if (name === "everyone" && checked) {
      updatedActionTo = ["patient", "caregiver", "careteam"];
    } else if (name === "everyone" && !checked) {
      updatedActionTo = [];
    } else {
      // If a specific checkbox other than "everyone" is toggled
      if (checked) {
        // Add the selected value to the array if checked
        updatedActionTo = [...appointmentCreateData.actionTo, name];
      } else {
        // Remove the deselected value from the array
        updatedActionTo = appointmentCreateData.actionTo.filter(
          (item) => item !== name
        );
      }
      // If "everyone" is currently selected, deselect it
      if (appointmentCreateData.actionTo.includes("everyone")) {
        updatedActionTo = updatedActionTo.filter((item) => item !== "everyone");
      }
    }
    setAppointmentCreateData({
      ...appointmentCreateData,
      actionTo: updatedActionTo,
    });
  };


  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Paper elevation={1} style={{ padding: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id="username"
                    options={listUsers}
                    getOptionLabel={(option) =>
                      `${option.fname} ${option.lname}`
                    }
                    // onClose={() => setListTemplate([])}
                    onInput={(event) => {
                      listUserFunc(event.target.value);
                    }}
                    // value={userName || ""}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="User"
                        variant="standard"
                        value={appointmentCreateData?.userId}
                      />
                    )}
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    id="title"
                    disabled
                    label="Title"
                    autoComplete="off"
                    multiline
                    fullWidth
                    value={appointmentCreateData.title}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        title: event.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <TextField
                    id="description"
                    disabled
                    label={props.t("advertisements.description")}
                    autoComplete="off"
                    multiline
                    fullWidth
                    value={appointmentCreateData.description}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        description: event.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id="userId"
                    multiple={true}
                    options={interviewSetToOptions}
                    getOptionLabel={(option) => {
                      return option.fname + " " + option.lname;
                    }}
                    onInputChange={getInterviewersList}
                    // onClose={() => setInterviewSetToOptions([])}
                    // value={appointmentCreateData?.consultToCareTeam}
                    onChange={(event, data) => {
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        consultToCareTeam: data.map((option) => option._id),
                      });
                    }}
                    // disabled={!appointmentCreateData.userId || !selectedRole}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={props.t("sessions.searchAppointmentTo")}
                        variant="standard"
                        value={appointmentCreateData.consultToCareTeam}
                      />
                    )}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                    <KeyboardDateTimePicker
                      animateYearScrolling={false}
                      variant="dialog"
                      required
                      fullWidth
                      autoOk={true}
                      margin="normal"
                      id="startDate"
                      label={props.t("advertisements.startat")}
                      value={new Date(appointmentCreateData.startDate * 1000)} // Convert Unix timestamp to milliseconds
                      onChange={(date) => {
                        handleStartAtDateChange(date);
                      }}
                      format="MMM do, yyyy hh:mm a"  // Set the desired date format
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    id="status"
                    label={props.t("sessions.status")}
                    disabled
                    select
                    required
                    fullWidth
                    defaultValue={appointmentCreateData.status}
                    value={appointmentCreateData.status}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        status: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={"upcoming"}>
                      Upcoming
                    </MenuItem>
                    <MenuItem key={0} value={"completed"}>
                      Completed
                    </MenuItem>
                    <MenuItem key={0} value={"cancelled"}>
                      Cancelled
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    id="siteLink"
                    disabled
                    label={props.t("sessions.meetlink")}
                    autoComplete="off"
                    required
                    fullWidth
                    value={appointmentCreateData.meetLink}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        meetLink: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "home"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "home"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "home"
                                  ),
                            })
                          }
                          name="home"
                        />
                      }
                      label={props.t("notification.home")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "app"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "app"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "app"
                                  ),
                            })
                          }
                          name="app"
                        />
                      }
                      label={props.t("notification.app")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "email"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "email"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "email"
                                  ),
                            })
                          }
                          name="email"
                        />
                      }
                      label={props.t("notification.email")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "sms"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "sms"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "sms"
                                  ),
                            })
                          }
                          name="sms"
                        />
                      }
                      label={props.t("notification.sms")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "smartDevice"
                          )}
                          disabled
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [
                                    ...appointmentCreateData.visibleOn,
                                    "smartDevice",
                                  ]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "smartDevice"
                                  ),
                            })
                          }
                          name="smartDevice"
                        />
                      }
                      label={props.t("notification.smartDevice")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  {/* <TextField
                    label={props.t("case.actionTo")}
                    select
                    required
                    fullWidth
                    value={appointmentCreateData.actionTo}
                    name="actionTo"
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        actionTo: event.target.value,
                      })
                    }
                    style={{ marginBottom: 10 }}
                  >
                    <MenuItem key={0} value={"both"}>
                      {props.t("notification.both")}
                    </MenuItem>
                    <MenuItem key={1} value={"patient"}>
                      {props.t("notification.patient")}
                    </MenuItem>
                    <MenuItem key={2} value={"caregiver"}>
                      {props.t("notification.caregiver")}
                    </MenuItem>
                    <MenuItem key={2} value={"Careteam"}>
                      Careteam
                    </MenuItem>
                    <MenuItem key={2} value={"both careteam and patient"}>
                      Both careteam and patient
                    </MenuItem>
                    <MenuItem key={2} value={"both careteam and caregiver"}>
                      Both careteam and caregiver
                    </MenuItem>
                    <MenuItem key={2} value={"everyone"}>
                      Everyone
                    </MenuItem>
                  </TextField> */}
                    <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: ".7rem" }}
                >
                  <label>Notification Sent To</label>
                  <FormGroup row={true}>
                    <FormControlLabel
                    // disabled
                      control={
                        <Checkbox
                          checked={appointmentCreateData.actionTo.includes(
                            "patient"
                          )}
                          onChange={handleCheckboxChange}
                          name="patient"
                        />
                      }
                      label="Patient"
                    />

                    <FormControlLabel
                      // disabled
                      control={
                        <Checkbox
                          checked={appointmentCreateData.actionTo.includes(
                            "caregiver"
                          )}
                          onChange={handleCheckboxChange}
                          name="caregiver"
                        />
                      }
                      label="Caregiver"
                    />

                    <FormControlLabel
                      // disabled
                      control={
                        <Checkbox
                          checked={appointmentCreateData.actionTo.includes(
                            "careteam"
                          )}
                          onChange={handleCheckboxChange}
                          name="careteam"
                        />
                      }
                      label="Careteam"
                    />
                  </FormGroup>
                </Grid>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  align="center"
                  style={{ marginTop: "20px" }}
                >
                  <Button color="primary" onClick={() => sendToServer()}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(EditFormPage)
);
