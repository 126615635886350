//parameter need to pass from other page
// 3>  fileName          => array of name with extension [Excel,CSV], used for download files
// 4>  uploadTableHeader => upload file table header
// 5>  uploadUrl         => upload file to server URL
// 6>  uploadHistoryUrl  => uploaded file history with process stage URL
// 6>  bucketName        => location of the cloud storage location
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { FaFileImport } from 'react-icons/fa';
import { MdRefresh, MdDeleteSweep } from 'react-icons/md';
import APIRequest from '../../utils/APIRequest';
import DateConversion from '../../utils/DateConversion';
import { withTranslation } from 'react-i18next';
import StringUtils from '../../utils/StringUtils';
import XLSX from 'xlsx';
import ConfigAPIURL from '../../config/ConfigAPIURL';
import { Grid, Link, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NormalMuiTable from '../tables/NormalMuiTable';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  backgroundLineColor: {
    background: theme.custom.background,
  },
}));

function ExcelCSVUploadHistroy(props) {
  const classes = useStyles();
  const [sendToServerBtn, setSendToServerBtn] = React.useState(false);
  const [masterTableHeader] = React.useState(props.uploadTableHeader);
  const [masterTableRecord, setMasterTableRecord] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [
    uploadHistoryTableHeader,
    setUploadHistoryTableHeader,
  ] = React.useState([
    'Track ID',
    'Upload File',
    'Processed File',
    'Error File',
    'Status',
    'Created',
    'Message',
  ]);
  const [
    uploadHistoryTableRecord,
    setUploadHistoryTableRecord,
  ] = React.useState([]);
  const handleFile = async (event) => {
    setSendToServerBtn(true);
    [...event.target.files].map(async (data) => {
      props.notification(props.t('toaster.fileReading'), 'info');
      setFile(data);
      let reader = new FileReader();
      if (data.name.split('.').pop() !== 'xlsx') {
        _deleteS3Files();
        props.notification(
          props.t('toaster.invalid_excel_file_format'),
          'error'
        );
        return;
      }
      if (data.size < 3000000) {
      } else {
        _deleteS3Files();
        props.notification(props.t('toaster.fileLarge'), 'error');
        return;
      }

      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        let masterTableRecord = [];
        data.map((value) => {
          let arrayRecord = [];
          masterTableHeader.map((headerData) => {
            if (
              headerData.includes('/') ||
              headerData.includes('DOB') ||
              headerData.includes('Date')
            ) {
              arrayRecord.push(
                DateConversion.excelDateToJSDate(
                  value[headerData.replace(/\s/g, '')]
                )
              );
            } else {
              arrayRecord.push(value[headerData.replace(/\s/g, '-')]);
            }
            return arrayRecord;
          });
          masterTableRecord.push(arrayRecord);
          return masterTableRecord;
        });
        setSendToServerBtn(false);
        setMasterTableRecord(masterTableRecord);
        return;
      };
      if (rABS) {
        reader.readAsBinaryString(data);
      } else {
        reader.readAsArrayBuffer(data);
      }
    });
  };
  const _deleteS3Files = () => {
    setMasterTableRecord([]);
    setFile(null);
    setSendToServerBtn(false);
  };
  const _sendToServerWithUpload = () => {
    if (file !== undefined && file !== null && file !== '') {
      setSendToServerBtn(true);
      let formData = new FormData();
      formData.append('attachment', file);
      formData.append('menuName', props.bucketName);
      formData.append('fileName', file.name);
      APIRequest.multipartForDataRequest(
        'POST',
        props.uploadUrl,
        formData
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100) {
            if (response.data.responseCode === 109) {
              props.notification(
                props.t('toaster.transaction_completed'),
                'sucess'
              );
              _deleteS3Files();
              _uploadHistory();
            } else if (response.data.responseCode === 108) {
              props.notification(props.t('toaster.invalid_session'), 'error');
            }
          } else {
            setSendToServerBtn(false);
            props.notification(props.t('toaster.serverError'), 'error');
          }
        } else {
          setSendToServerBtn(false);
          props.notification(props.t('toaster.serverError'), 'error');
        }
      });
    } else {
      props.notification(props.t('toaster.select_file'), 'error');
    }
  };
  const _uploadHistory = () => {
    setUploadHistoryTableRecord([]);
    APIRequest.request(
      'PUT',
      ConfigAPIURL.uploadHistory,
      JSON.stringify({
        menuName: props.menuName,
        operationType: props.operationType,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100) {
          if (response.data.responseCode === 109) {
            let uploadHistoryTableRecord = [];
            response.data.result
              .slice(0)
              .reverse()
              .map((data) => {
                let array = [];
                array.push(data.trackId);
                if (
                  data.uploadedFilePath !== undefined &&
                  data.uploadedFilePath !== null &&
                  data.uploadedFilePath !== ''
                ) {
                  array.push(
                    <Link
                      download
                      href={
                        data.uploadedFilePath.startsWith('https://') ||
                        data.uploadedFilePath.startsWith('http://')
                          ? data.uploadedFilePath
                          : 'https://' + data.uploadedFilePath
                      }
                      style={{
                        Gridor: '#fc6863',
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                      title={props.t('buttons.download')}
                    >
                      {props.t('buttons.download')}
                    </Link>
                  );
                } else {
                  array.push('NA');
                }
                if (
                  data.processedFilePath !== undefined &&
                  data.processedFilePath !== null &&
                  data.processedFilePath !== ''
                ) {
                  array.push(
                    <Link
                      download
                      href={
                        data.processedFilePath.startsWith('https://') ||
                        data.processedFilePath.startsWith('http://')
                          ? data.processedFilePath
                          : 'https://' + data.processedFilePath
                      }
                      style={{
                        Gridor: '#fc6863',
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                      title={props.t('buttons.download')}
                    >
                      {props.t('buttons.download')}
                    </Link>
                  );
                } else {
                  array.push('NA');
                }
                if (
                  data.errorFilePath !== undefined &&
                  data.errorFilePath !== null &&
                  data.errorFilePath !== ''
                ) {
                  array.push(
                    <Link
                      download
                      href={
                        data.errorFilePath.startsWith('https://') ||
                        data.errorFilePath.startsWith('http://')
                          ? data.errorFilePath
                          : 'https://' + data.errorFilePath
                      }
                      style={{
                        Gridor: '#fc6863',
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                      title={props.t('buttons.download')}
                    >
                      {props.t('buttons.download')}
                    </Link>
                  );
                } else {
                  array.push('NA');
                }
                array.push(StringUtils.firstCharacterUpperCase(data.status));
                array.push(DateConversion.unixToLocaleDateTime(data.createdAt));
                if (
                  data.processedDate !== undefined &&
                  data.processedDate !== null &&
                  data.processedDate !== 0
                ) {
                  array.push(
                    DateConversion.unixToLocaleDateTime(data.processedAt)
                  );
                } else {
                  array.push('NA');
                }

                uploadHistoryTableRecord.push(array);
              });
            setUploadHistoryTableRecord(
              [...uploadHistoryTableRecord],
              uploadHistoryTableRecord
            );
          } else if (response.data.responseCode === 108) {
            props.notification(props.t('toaster.108'), 'error');
          }
        }
      }
    });
  };
  useEffect(() => {
    _uploadHistory();
  }, []);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item lg={6} md={6} sm={12} xs={12} spacing={2}>
        <input
          style={{ display: 'none' }}
          // accept=".xlsx, .xlsb, .xlsm, .xls, .xml, .csv, .txt, .ods,application/vnd.ms-excel"
          accept='.xlsx'
          id={'contained-button-file'}
          type='file'
          onChange={(event) => handleFile(event)}
        />
        <label htmlFor={'contained-button-file'}>
          <Button
            style={{ marginTop: '10px', marginRight: '10px' }}
            variant='primary'
            component='span'
          >
            <FaFileImport
              style={{
                fontSize: 16,
                marginRight: '20px',
              }}
            />
            {props.t('buttons.uploadFile')}
          </Button>
        </label>

        <Button
          style={{ marginTop: '10px' }}
          variant='primary'
          component='span'
          onClick={() => _deleteS3Files()}
        >
          <MdDeleteSweep
            style={{
              fontSize: 16,
              marginRight: '20px',
            }}
          />
          {props.t('buttons.deleteFile')}
        </Button>
      </Grid>
      <Grid
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{ textAlign: 'end', alignSelf: 'center' }}
      >
        <span>
          {props.t('buttons.download_Template')}
          {' : '}
        </span>
        <Link
          download={
            props.fileName !== undefined &&
            props.fileName !== null &&
            props.fileName.length > 0 &&
            props.fileName[0] !== undefined &&
            props.fileName[0] !== null
              ? props.fileName[0]
              : ''
          }
          href={
            props.fileName !== undefined &&
            props.fileName !== null &&
            props.fileName.length > 0 &&
            props.fileName[0] !== undefined &&
            props.fileName[0] !== null
              ? require('../../assets/sampleFile/' + props.fileName[0])
              : ''
          }
          style={{
            Gridor: '#fc6863',
            fontWeight: '600',
            fontSize: '14px',
            padding: '5px',
            textDecoration: 'underline',
          }}
          title={'Download Excel Sample'}
        >
          Excel
        </Link>
        {/* {' , '} */}
        <Link
          download={
            props.fileName !== undefined &&
            props.fileName !== null &&
            props.fileName.length > 0 &&
            props.fileName[1] !== undefined &&
            props.fileName[1] !== null
              ? props.fileName[1]
              : ''
          }
          target='_blank'
          // href={
          // 	props.fileName !== undefined &&
          // 	props.fileName !== null &&
          // 	props.fileName.length > 0 &&
          // 	props.fileName[1] !== undefined &&
          // 	props.fileName[1] !== null
          // 		? require('../../assets/sampleFile/' + props.fileName[1])
          // 		: ''
          // }
          style={{
            Gridor: '#fc6863',
            fontWeight: '600',
            fontSize: '14px',
            padding: '5px',
            textDecoration: 'underline',
            display: 'none',
          }}
          title={'Download CSV Sample'}
        >
          CSV
        </Link>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12}>
        <NormalMuiTable
          tableHeader={masterTableHeader}
          tableRecord={masterTableRecord}
        />
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ textAlign: '-webkit-center' }}
      >
        <Button
          variant='primary'
          block
          disabled={sendToServerBtn}
          onClick={() => _sendToServerWithUpload()}
        >
          {props.t('buttons.upload')}
        </Button>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <h4
          style={{
            width: '100%',
            textAlign: 'center',
            borderBottom: '1px solid grey',
            lineHeight: '0.1em',
            margin: '10px 0 20px',
          }}
        >
          <span
            className={classes.backgroundLineColor}
            style={{
              padding: '0 10px',
            }}
          >
            {props.t('buttons.uploadHistory')}
          </span>
        </h4>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ textAlign: 'end' }}
      >
        <Button
          style={{ marginTop: '10px' }}
          variant='primary'
          block
          onClick={() => _uploadHistory()}
        >
          <MdRefresh
            style={{
              fontSize: 16,
              marginRight: '20px',
            }}
          />
          {props.t('buttons.refresh')}
        </Button>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <NormalMuiTable
          tableHeader={uploadHistoryTableHeader}
          tableRecord={uploadHistoryTableRecord}
        />
      </Grid>
    </Grid>
  );
}
export default withTranslation('translations')(ExcelCSVUploadHistroy);
