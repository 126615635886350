import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles, TextField, Button } from '@material-ui/core';
import APIRequest from '../utils/APIRequest';
import ConfigAPIURL from '../config/ConfigAPIURL';

import SnackbarUtils from '../utils/SnackbarUtils';
import { connect } from 'react-redux';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  heading: {
    flexBasis: '67.33%',
    flexShrink: 0,
  },
}));
function SendNotes(props) {
  const classes = useStyles();

  const [notes, setNotes] = useState('');

  const [sendToServerBtn, setSendToServerBtn] = useState(false);

  const handleButtons = (method) => {
    if (method === 'submit') {
      setSendToServerBtn(true);

      if (notes === '') {
        setSendToServerBtn(false);
        SnackbarUtils.error(props.t('user.addNote'), 'bottomCenter', 3000).then(
          (notification) => {
            props.publishNotification(notification);
          }
        );
        return;
      }

      APIRequest.request(
        'POST',
        ConfigAPIURL.createNote,
        JSON.stringify({ note: notes, userId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setSendToServerBtn(false);
            props.handleSlideInDialog();

            SnackbarUtils.sucess(
              props.t('snackbar.successfullyNotified'),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          } else {
            setSendToServerBtn(false);
          }
        } else {
          setSendToServerBtn(false);
        }
      });
    } else {
      setSendToServerBtn(false);
      props.handleSlideInDialog();
      // props.getNotificationTemplate();
      // setSelectedNotification({ title: '' });
      // setCustomMessage({
      //   title: '',
      //   body: '',
      //   visibleOn: {
      //     home: false,
      //     app: false,
      //     email: false,
      //     sms: false,
      //   },
      // });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: '20px' }}
        >
          <TextField
            id='customTitle'
            label={props.t('user.notesOrComments')}
            autoComplete='something-unsupported'
            autoComplete='off'
            required
            value={notes.notes}
            fullWidth
            onChange={(event) => {
              setNotes(event.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '20px', textAlign: 'right' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            disabled={sendToServerBtn}
            onClick={() => handleButtons('disagree')}
            color='primary'
          >
            cancel
          </Button>
          <Button
            disabled={sendToServerBtn}
            onClick={() => handleButtons('submit')}
            color='primary'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(SendNotes)
);
