import { useState, useEffect } from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";

const useAevForm = (props) => {
  const [doctorDetails, setDoctorDetails] = useState([]);

  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      // resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setDoctorDetails([]);
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.doctorEdit,
        JSON.stringify({ recordId: props.rowDetails._id })
      ).then((response) => {
        if (response?.code === 100) {
          let responseData = response?.data;
          setDoctorDetails(responseData);
        }
      });
    }
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.advertisementCreate;
    let method = "POST";

    if (props.formDialog.divType === "edit") {
      url = ConfigAPIURL.doctorEdit;
      doctorDetails["recordId"] = props.rowDetails._id;
      delete doctorDetails?._id;
      method = "POST";
    }

    APIRequest.request(method, url, JSON.stringify(doctorDetails)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response?.code === 100) {
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              userType: "all",
              sortOrder: "false",
              active: true,
            });
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  return {
    doctorDetails,
    setDoctorDetails,
    sendToServer
  };
};
export default useAevForm;
