import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Paper,
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DateConversion from "../../../../../../utils/DateConversion";

import EditForm from "./hooks/useEditForm";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));

function EditFormPage(props) {
  const classes = useStyles();

  const {
    appointmentCreateData,
    setAppointmentCreateData,
    listTemplates,
    interviewSetToOptions,
    handleStartAtDateChange,
    getInterviewersList,
    listTemplatesFunc,
  } = EditForm(props);

  // Handler for Autocomplete change event
  const handleAutocompleteChange = (event, data) => {
    setAppointmentCreateData({
      ...appointmentCreateData,
      title: data?.title || "",
      description: data?.description || "",
      meetLink: data?.meetLink || "",
      consultationId: data?._id || "",
    });
  };

  useEffect(() => {
    const everyoneSelected =
      appointmentCreateData.actionTo.includes("everyone");
    if (everyoneSelected) {
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient", "caregiver", "careteam"],
      });
    }
  }, [appointmentCreateData.actionTo]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let updatedActionTo;
    if (name === "everyone" && checked) {
      updatedActionTo = ["patient", "caregiver", "careteam"];
    } else if (name === "everyone" && !checked) {
      updatedActionTo = [];
    } else {
      if (checked) {
        updatedActionTo = [...appointmentCreateData.actionTo, name];
      } else {
        updatedActionTo = appointmentCreateData.actionTo.filter(
          (item) => item !== name
        );
      }
      if (appointmentCreateData.actionTo.includes("everyone")) {
        updatedActionTo = updatedActionTo.filter((item) => item !== "everyone");
      }
    }
    setAppointmentCreateData({
      ...appointmentCreateData,
      actionTo: updatedActionTo,
    });
  };
  console.log(appointmentCreateData, "from here");

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Paper elevation={1} style={{ padding: "20px" }}>
              <Grid container>
                <Grid xl={4} lg={4} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id="userId"
                    options={listTemplates}
                    getOptionLabel={(option) => option.title}
                    // onClose={() => setListTemplate([])}
                    onInput={(event) => {
                      listTemplatesFunc(event.target.value);
                    }}
                    value={
                      (appointmentCreateData?.title &&
                        listTemplates?.find(
                          (item) =>
                            item?.title.trim() ===
                            appointmentCreateData?.title.trim()
                        )) ||
                      appointmentCreateData?.title ||
                      ""
                    }
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Title"
                        variant="standard"
                        value={
                          (appointmentCreateData?.title &&
                            listTemplates?.find(
                              (item) =>
                                item?.title.trim() ===
                                appointmentCreateData?.title.trim()
                            )) ||
                          appointmentCreateData?.title ||
                          ""
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  xl={8}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "-2px" }}
                >
                  <TextField
                    id="description"
                    disabled
                    label={props.t("advertisements.description")}
                    autoComplete="off"
                    multiline
                    fullWidth
                    value={appointmentCreateData.description}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        description: event.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "1rem" }}
                >
                  <Autocomplete
                    id="userId"
                    multiple
                    options={interviewSetToOptions}
                    getOptionLabel={(option) =>
                      option.fname + " " + option.lname
                    }
                    onInputChange={getInterviewersList}
                    value={appointmentCreateData.consultToCareTeam || []}
                    onChange={(event, data) => {
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        consultToCareTeam: data,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={props.t("sessions.searchAppointmentTo")}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>

                <Grid xl={4} lg={4} md={6} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDateTimePicker
                        disabled
                        animateYearScrolling={false}
                        variant="dialog"
                        required
                        fullWidth
                        autoOk={true}
                        margin="normal"
                        id="startDate"
                        label={props.t("advertisements.startat")}
                        value={new Date(appointmentCreateData.startDate * 1000)} // Convert Unix timestamp to milliseconds
                        onChange={(date) => {
                          handleStartAtDateChange(date);
                        }}
                        format="MMM do, yyyy hh:mm a"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />

                      {/* <TextField
                        id="datetime-local"
                        label="Start Date and time"
                        value={new Date(appointmentCreateData.startDate * 1000)}
                        type="datetime-local"
                        defaultValue="2017-05-24T10:30"
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(date) => {
                          handleStartAtDateChange(date);
                        }}
                      /> */}
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <TextField
                    id="status"
                    label={props.t("sessions.status")}
                    select
                    required
                    fullWidth
                    defaultValue={appointmentCreateData.status}
                    value={appointmentCreateData.status}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        status: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={"upcoming"}>
                      Upcoming
                    </MenuItem>
                    <MenuItem key={0} value={"completed"}>
                      Completed
                    </MenuItem>
                    <MenuItem key={0} value={"cancelled"}>
                      Cancelled
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: ".7rem" }}
                >
                  <TextField
                    id="siteLink"
                    disabled
                    label={props.t("sessions.meetlink")}
                    autoComplete="off"
                    required
                    fullWidth
                    value={appointmentCreateData.meetLink}
                    onChange={(event) =>
                      setAppointmentCreateData({
                        ...appointmentCreateData,
                        meetLink: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: ".7rem" }}
                >
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "home"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "home"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "home"
                                  ),
                            })
                          }
                          name="home"
                        />
                      }
                      label={props.t("notification.home")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "app"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "app"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "app"
                                  ),
                            })
                          }
                          name="app"
                        />
                      }
                      label={props.t("notification.app")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "email"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "email"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "email"
                                  ),
                            })
                          }
                          name="email"
                        />
                      }
                      label={props.t("notification.email")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "sms"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [...appointmentCreateData.visibleOn, "sms"]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "sms"
                                  ),
                            })
                          }
                          name="sms"
                        />
                      }
                      label={props.t("notification.sms")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.visibleOn.includes(
                            "smartDevice"
                          )}
                          disabled
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              visibleOn: event.target.checked
                                ? [
                                    ...appointmentCreateData.visibleOn,
                                    "smartDevice",
                                  ]
                                : appointmentCreateData.visibleOn.filter(
                                    (item) => item !== "smartDevice"
                                  ),
                            })
                          }
                          name="smartDevice"
                        />
                      }
                      label={props.t("notification.smartDevice")}
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: ".7rem" }}
                >
                  <label>Notification Sent To</label>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            appointmentCreateData.actionTo.includes(
                              "patient"
                            ) || appointmentCreateData.actionTo.includes("both")
                          }
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              actionTo: event.target.checked
                                ? [...appointmentCreateData.actionTo, "patient"]
                                : appointmentCreateData.actionTo.filter(
                                    (item) => item !== "patient"
                                  ),
                            })
                          }
                          name="patient"
                        />
                      }
                      label="Patient"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            appointmentCreateData.actionTo.includes(
                              "caregiver"
                            ) || appointmentCreateData.actionTo.includes("both")
                          }
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              actionTo: event.target.checked
                                ? [
                                    ...appointmentCreateData.actionTo,
                                    "caregiver",
                                  ]
                                : appointmentCreateData.actionTo.filter(
                                    (item) => item !== "caregiver"
                                  ),
                            })
                          }
                          name="caregiver"
                        />
                      }
                      label="Caregiver"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            appointmentCreateData.actionTo.includes(
                              "careteam"
                            ) ||
                            appointmentCreateData.actionTo.includes("Careteam")
                          }
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              actionTo: event.target.checked
                                ? [
                                    ...appointmentCreateData.actionTo,
                                    "careteam",
                                  ]
                                : appointmentCreateData.actionTo.filter(
                                    (item) => item !== "careteam"
                                  ),
                            })
                          }
                          name="careteam"
                        />
                      }
                      label="Careteam"
                    />
                  </FormGroup>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="p"
                    align="left"
                    component="p"
                  >
                    When:
                    <span style={{ fontWeight: "400" }}>
                      {DateConversion.returnDate(
                        appointmentCreateData?.startDate
                      )}
                    </span>
                  </Typography>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="p"
                    align="left"
                    component="p"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Sent To :
                    <span style={{ fontWeight: "400" }}>
                      {`${appointmentCreateData?.fname} ${appointmentCreateData?.lname}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Sent By :
                    <span style={{ fontWeight: "400" }}>
                      {`${appointmentCreateData?.createdBy?.[0]?.fname} ${appointmentCreateData?.createdBy?.[0]?.lname}`}
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Received a copy of session on :
                  </Typography>
                  <span style={{ fontWeight: "400" }}>
                    Mail:{" "}
                    <span style={{ fontWeight: "400" }}>
                      {appointmentCreateData?.email === "" ||
                      appointmentCreateData?.email === null ||
                      appointmentCreateData?.email === undefined
                        ? "Not Available"
                        : appointmentCreateData?.email}
                    </span>
                  </span>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Received a copy of session on :
                  </Typography>
                  <span style={{ fontWeight: "400" }}>
                    SMS:{" "}
                    <span style={{ fontWeight: "400" }}>
                      {appointmentCreateData?.mobileNo === "" ||
                      appointmentCreateData?.mobileNo === null ||
                      appointmentCreateData?.mobileNo === undefined
                        ? "Not Available"
                        : appointmentCreateData?.mobileNo}
                    </span>
                  </span>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <InputLabel
                    id="sessionStatus-label"
                    style={{ fontSize: "14px", marginBottom: "5px" }}
                  >
                    How useful did you find the session?
                  </InputLabel>
                  <Rating
                    name="read-only"
                    value={
                      appointmentCreateData &&
                      appointmentCreateData.rating &&
                      appointmentCreateData.rating.useful
                        ? Math.round(appointmentCreateData.rating.useful * 2) /
                          2
                        : 0
                    }
                    precision={0.5}
                    readOnly
                  />
                  {console.log(
                    "priting rating",
                    appointmentCreateData?.rating?.useful
                  )}
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <InputLabel
                    id="sessionStatus-label"
                    style={{ fontSize: "14px", marginBottom: "5px" }}
                  >
                    How informative was the session for you?
                  </InputLabel>
                  <Rating
                    name="read-only"
                    value={
                      appointmentCreateData &&
                      appointmentCreateData.rating &&
                      appointmentCreateData.rating.informative
                        ? Math.round(
                            appointmentCreateData.rating.informative * 2
                          ) / 2
                        : 0
                    }
                    precision={0.5}
                    readOnly
                  />
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <InputLabel
                    id="sessionStatus-label"
                    style={{ fontSize: "14px", marginBottom: "5px" }}
                  >
                    How courteous was the cousular?
                  </InputLabel>
                  <Rating
                    name="read-only"
                    value={
                      appointmentCreateData &&
                      appointmentCreateData.rating &&
                      appointmentCreateData.rating.courteous
                        ? Math.round(
                            appointmentCreateData.rating.courteous * 2
                          ) / 2
                        : 0
                    }
                    precision={0.5}
                    readOnly
                  />
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(EditFormPage)
);
