//parameter need to pass from other page
// 3>  fileName          => array of name with extension [Excel,CSV], used for download files
// 4>  uploadTableHeader => upload file table header
// 5>  uploadUrl         => upload file to server URL
// 6>  uploadHistoryUrl  => uploaded file history with process stage URL
// 6>  bucketName        => location of the cloud storage location
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { FaFileImport } from 'react-icons/fa';
import { MdRefresh, MdDeleteSweep } from 'react-icons/md';
import APIRequest from '../../utils/APIRequest';
import DateConversion from '../../utils/DateConversion';
import { withTranslation } from 'react-i18next';
import StringUtils from '../../utils/StringUtils';
import XLSX from 'xlsx';
import ConfigAPIURL from '../../config/ConfigAPIURL';
import { Grid, Link, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NormalMuiTable from '../tables/NormalMuiTable';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  backgroundLineColor: {
    background: theme.custom.background,
  },
}));

function ImageUploadHistroy(props) {
  const classes = useStyles();
  const [sendToServerBtn, setSendToServerBtn] = React.useState(false);
  const [masterTableHeader] = React.useState(props.uploadTableHeader);
  const [masterTableRecord, setMasterTableRecord] = React.useState([]);
  const [file, setFile] = React.useState([]);
  const [uploadHistoryTableHeader] = React.useState([
    'Track ID',
    'Upload File',
    'Processed File',
    'Error File',
    'Status',
    'Created',
    'Message',
  ]);
  const [
    uploadHistoryTableRecord,
    setUploadHistoryTableRecord,
  ] = React.useState([]);
  const handleFile = async (event) => {
    setSendToServerBtn(true);
    props.notification(props.t('toaster.fileReading'), 'info');
    [...event.target.files].map(async (data) => {
      if (data !== undefined && data !== null) {
        let files = file;
        files.push(data);
        setFile([...file], files);
        setSendToServerBtn(false);
        let tempMasterTableRecord = masterTableRecord;
        tempMasterTableRecord.push([
          data.name,
          data.type.split('/').pop().toUpperCase(),
        ]);
        setMasterTableRecord([...masterTableRecord], tempMasterTableRecord);
      }
    });
  };
  const _deleteS3Files = () => {
    setMasterTableRecord([]);
    setFile(null);
    setSendToServerBtn(false);
  };
  const _sendToServerWithUpload = () => {
    if (file !== undefined && file !== null && file !== '') {
      setSendToServerBtn(true);
      let formData = new FormData();
      file.map((result, index) => {
        formData.append(`attachment${index}`, result);
      });
      formData.append('menuName', props.bucketName);
      formData.append('bucketName', props.bucketName);
      APIRequest.multipartForDataRequest('PUT', props.uploadUrl, formData).then(
        (response) => {
          if (response !== undefined && response !== null) {
            if (response.code === 100) {
              if (response.data.responseCode === 109) {
                props.notification(
                  props.t('toaster.transaction_completed'),
                  'sucess'
                );
                _deleteS3Files();
                _uploadHistory();
              } else if (response.data.responseCode === 108) {
                props.notification(props.t('toaster.invalid_session'), 'error');
              }
            } else {
              setSendToServerBtn(false);
              props.notification(props.t('toaster.serverError'), 'error');
            }
          } else {
            setSendToServerBtn(false);
            props.notification(props.t('toaster.serverError'), 'error');
          }
        }
      );
    } else {
      props.notification(props.t('toaster.select_file'), 'error');
    }
  };
  const _uploadHistory = () => {
    setUploadHistoryTableRecord([]);
    APIRequest.request(
      'PUT',
      ConfigAPIURL.uploadHistory,
      JSON.stringify({
        menuName: props.menuName,
        operationType: props.operationType,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100) {
          if (response.data.responseCode === 109) {
            let uploadHistoryTableRecord = [];
            response.data.result
              .slice(0)
              .reverse()
              .map((data) => {
                let array = [];
                array.push(data.trackId);
                if (
                  data.uploadedFilePath !== undefined &&
                  data.uploadedFilePath !== null &&
                  data.uploadedFilePath !== ''
                ) {
                  array.push(
                    <Link
                      download
                      href={
                        data.uploadedFilePath.startsWith('https://') ||
                        data.uploadedFilePath.startsWith('http://')
                          ? data.uploadedFilePath
                          : 'https://' + data.uploadedFilePath
                      }
                      style={{
                        Gridor: '#fc6863',
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                      title={props.t('buttons.download')}
                    >
                      {props.t('buttons.download')}
                    </Link>
                  );
                } else {
                  array.push('NA');
                }
                if (
                  data.processedFilePath !== undefined &&
                  data.processedFilePath !== null &&
                  data.processedFilePath !== ''
                ) {
                  array.push(
                    <Link
                      download
                      href={
                        data.processedFilePath.startsWith('https://') ||
                        data.processedFilePath.startsWith('http://')
                          ? data.processedFilePath
                          : 'https://' + data.processedFilePath
                      }
                      style={{
                        Gridor: '#fc6863',
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                      title={props.t('buttons.download')}
                    >
                      {props.t('buttons.download')}
                    </Link>
                  );
                } else {
                  array.push('NA');
                }
                if (
                  data.errorFilePath !== undefined &&
                  data.errorFilePath !== null &&
                  data.errorFilePath !== ''
                ) {
                  array.push(
                    <Link
                      download
                      href={
                        data.errorFilePath.startsWith('https://') ||
                        data.errorFilePath.startsWith('http://')
                          ? data.errorFilePath
                          : 'https://' + data.errorFilePath
                      }
                      style={{
                        Gridor: '#fc6863',
                        fontWeight: '600',
                        fontSize: '14px',
                      }}
                      title={props.t('buttons.download')}
                    >
                      {props.t('buttons.download')}
                    </Link>
                  );
                } else {
                  array.push('NA');
                }
                array.push(StringUtils.firstCharacterUpperCase(data.status));
                array.push(DateConversion.unixToLocaleDateTime(data.createdAt));
                if (
                  data.processedDate !== undefined &&
                  data.processedDate !== null &&
                  data.processedDate !== 0
                ) {
                  array.push(
                    DateConversion.unixToLocaleDateTime(data.processedAt)
                  );
                } else {
                  array.push('NA');
                }

                uploadHistoryTableRecord.push(array);
              });
            setUploadHistoryTableRecord(
              [...uploadHistoryTableRecord],
              uploadHistoryTableRecord
            );
          } else if (response.data.responseCode === 108) {
            props.notification(props.t('toaster.108'), 'error');
          }
        }
      }
    });
  };
  useEffect(() => {
    _uploadHistory();
  }, []);

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12} spacing={2}>
        <input
          multiple
          style={{ display: 'none' }}
          accept='image/x-png,image/jpeg'
          id={'contained-button-file'}
          type='file'
          onChange={(event) => handleFile(event)}
        />
        <label htmlFor={'contained-button-file'}>
          <Button
            style={{ marginTop: '10px', marginRight: '10px' }}
            variant='primary'
            component='span'
          >
            <FaFileImport
              style={{
                fontSize: 16,
                marginRight: '20px',
              }}
            />
            {props.t('buttons.uploadFile')}
          </Button>
        </label>

        <Button
          style={{ marginTop: '10px' }}
          variant='primary'
          component='span'
          onClick={() => _deleteS3Files()}
        >
          <MdDeleteSweep
            style={{
              fontSize: 16,
              marginRight: '20px',
            }}
          />
          {props.t('buttons.deleteFile')}
        </Button>
      </Grid>
      <Grid lg={12} md={12} sm={12} xs={12}>
        <NormalMuiTable
          tableHeader={masterTableHeader}
          tableRecord={masterTableRecord}
        />
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ textAlign: '-webkit-center' }}
      >
        <Button
          variant='primary'
          block
          disabled={sendToServerBtn}
          onClick={() => _sendToServerWithUpload()}
        >
          {props.t('buttons.upload')}
        </Button>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <h4
          style={{
            width: '100%',
            textAlign: 'center',
            borderBottom: '1px solid grey',
            lineHeight: '0.1em',
            margin: '10px 0 20px',
          }}
        >
          <span
            className={classes.backgroundLineColor}
            style={{
              padding: '0 10px',
            }}
          >
            {props.t('buttons.uploadHistory')}
          </span>
        </h4>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ textAlign: 'end' }}
      >
        <Button
          style={{ marginTop: '10px' }}
          variant='primary'
          block
          onClick={() => _uploadHistory()}
        >
          <MdRefresh
            style={{
              fontSize: 16,
              marginRight: '20px',
            }}
          />
          {props.t('buttons.refresh')}
        </Button>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <NormalMuiTable
          tableHeader={uploadHistoryTableHeader}
          tableRecord={uploadHistoryTableRecord}
        />
      </Grid>
    </Grid>
  );
}
export default withTranslation('translations')(ImageUploadHistroy);
