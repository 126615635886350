import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import TableQuery from './TableQuery';
import ProductsNonDeletableList from './ProductsNonDeletableList';
import TableHeader from './TableHeader';
// import DataTable from '../../../../templates/tables/DataTable';
import ProcessCaseMultiSelectTable from '../../../../templates/tables/ProcessCaseMultiSelectTable';
import DataTable from '../../../../templates/tables/DataTable';
import APIRequestDataTableQuery from '../../../../utils/APIRequestDataTableQuery';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import APIRequest from '../../../../utils/APIRequest';
import TableButtons from '../../../../templates/buttons/TableButtons';
import FormDialog from '../../../../templates/dialog/FormDialog';
import Upload from './upload';
import SlideInDialog from '../../../../templates/dialog/SlideInDialog';
import LocalStorage from '../../../../config/LocalStorage';
import { connect } from 'react-redux';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

function Products(props) {
  const classes = useStyles();
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  const [query, setQuery] = useState({
    keyword: '',
    page: 0,
    pageSize: 10,
    sortField: '',
    sortOrder: 'false',
    availableQuery: 'all',
    // active: true,
    isCompleted: 'all',
  });
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [slideInDialog, setSlideInDialog] = useState(false);
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  // stock options
  const [stockOptions, setStockOptions] = useState([]);
  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);

  const [nonDeletableProductList, setNonDeletableProductList] = useState([]);

  const [slideAssignDialog, setSlideAssignDialog] = useState({
    status: false,
    title: '',
    divType: '',
  });
  const [openForm, setOpenForm] = useState({
    status: false,
    title: '',
    divType: '',
    functionName: '',
  });
  // useful of on page load
  useEffect(() => {
    tableQuery(query); // calling table api on page load by default value
    getProductStock();
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  const getProductStock = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.productStock,
      JSON.stringify({ name: 'availableCount' })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          if (
            response.data.options[0] !== undefined &&
            response.data.options[0] !== null
          ) {
            setStockOptions(response.data.options[0]);
          }
        }
      }
    });
  };

  const handleSlideAssignDialog = () => {
    setRowDetails(null);
    setSlideInDialog(false);
    tableQuery(query);
    setOpenForm({ status: false, title: '', divType: '', functionName: '' });

    setSlideAssignDialog({
      status: false,
      title: '',
      divType: '',
    });
  };
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery({
      keyword: '',
      page: 0,
      pageSize: 10,
      sortField: '',
      availableQuery: 'all',
      sortOrder: 'false',
      // active: true,
      isCompleted: 'all',
    });
    tableQuery({
      keyword: '',
      availableQuery: 'all',
      page: 0,
      pageSize: 10,
      sortField: '',
      sortOrder: 'false',
      isCompleted: 'all',
      // active: true,
    });
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === 'query' ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        'POST',
        ConfigAPIURL.productTableURL,
        JSON.stringify(query)
      ).then((tableData) => {
        setTableData(tableData);
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };

  // table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
    // console.log("hello")
  };
  //table button call back function
  const tableAdd = () => {
    // console.log('tableAdd');
  };
  const tableEdit = () => {
    // console.log('tableEdit');
  };
  const tableView = () => {
    // console.log('tableView');
  };

  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      setSlideInDialog(true);
    } else {
      SnackbarUtils.error(
        props.t('snackbar.selectRow'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }

    // console.log('rowDetails', rowDetails);
  };
  const tableUpload = () => {
    setOpenForm({
      status: true,
      title: props.t('products.upload'),
      divType: 'upload',
      functionName: '',
    });
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    setSlideInDialog(false);
    tableQuery(query);
    setOpenForm({ status: false, title: '', divType: '', functionName: '' });
  };
  const handleDeleteRequest = (response) => {
    if (response === 'agree') {
      APIRequest.request(
        'DELETE',
        ConfigAPIURL.inventoryDelete,
        JSON.stringify({ records: rowDetails })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            if (response.data.recordsFound.length === 0) {
              SnackbarUtils.sucess(
                props.t('snackbar.deleteSuccess'),
                'bottomCenter',
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
            } else {
              setNonDeletableProductList(response.data.recordsFound);
              setSlideAssignDialog({
                status: true,
                title: props.t('products.recordsFound'),
                divType: 'foundAssignedMeds',
              });
            }
            handleFormDialog();
          }
        }
      });
    } else {
      handleFormDialog();
    }
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            page={'product'}
            searchLabel={props.t('products.searchLabel')}
            queryComponent={
              <TableQuery
                query={query}
                stockOptions={stockOptions}
                setQuery={handleQuery}
              />
            } //filter menu option
            buttonComponent={
              <TableButtons
                tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableCheckStatus={tableCheckStatus}
                buttonList={buttonList}
              />
            }
          />
        </Grid>
      </Grid>
      <SlideInDialog
        title={props.t('user.deleteRecord')}
        contentComponent={props.t('dialog.delete')}
        fullWidth={true}
        maxWidth={'sm'}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleFormDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
      <SlideInDialog
        title={props.t('products.nonDeletableProducts')}
        contentComponent={
          <ProductsNonDeletableList
            nonDeletableProductList={nonDeletableProductList}
            handleSlideAssignDialog={handleSlideAssignDialog}
          />
        }
        fullWidth={true}
        maxWidth={'md'}
        dialogActionsStatus={false}
        slideInDialog={slideAssignDialog.status}
        setSlideInDialog={handleSlideAssignDialog}
        // handleDeleteRequest={handleDeleteRequest}
      />
      <FormDialog
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        formComponent={<Upload />}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(mapStateToProps, mapDispachToProps)(Products)
);
