import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { DateConvert } from "../../../../../../utils/DataConvert";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";

let socket;
let currentValue = null;
const coversationPageLength = 25;
const chatPageLength = 1000;

const useChat = (userData, rowDetails) => {
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [sideChatList, setSideChatList] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [selectedChat, setSelectedChat] = useState(rowDetails);
  const [hasMoreConverstion, setHasMoreConversation] = useState(true);

  const handlReadMessage = async (selectedChat) => {
    const userId = rowDetails?._id;
    const adminId = userData?._id;
    await readMessages(userId, adminId);
  };

  const handleSelectedChat = async () => {
    if (!selectedChat) return;
    // resetting everything like clearing the converstion once we select another chat
    await new Promise((res, rej) => {
      res(setConversation([]), setHasMoreConversation(true)); //resetting conversation
    });
    // reset page
    fetchMessages(rowDetails?._id);
    await handlReadMessage(selectedChat);
    currentValue = rowDetails;
    fetchChatsList(userData?._id);
  };
  useEffect(() => {
    handleSelectedChat();
    //whenever the selected chat is selected then we need to get the whole chat of specific user
  }, [rowDetails]);

  // helps to diconnect from socket io
  const handleDisconnect = () => {
    socket.disconnect();
  };

  // helps to connect to socket io
  const handleConnect = async (userId) => {
    socket = io(
      // ConfigAPIURL.payDefinationBaseUrl, // comment this in production
      window.location.origin, // uncomment this in  production

      {
        autoConnect: true,
        query: { userId }, // make it dynamic from userData
      }
    );
  };

  // once the user gets an event on connect
  async function onConnect() {
    await new Promise((res, rej) => {
      res(setIsConnected(true));
    });
    fetchChatsList(userData?._id);
  }

  // once the user gets an event on disconnect
  function onDisconnect() {
    setIsConnected(false);
  }

  // event on chat list side bar
  function updateSideChatList(data) {
    setSideChatList(data?.result);
  }

  const convertMessages = (result) => {
    return (
      result &&
      result?.map((value) => {
        const isMessageSend = !value?.fromUser; // it means we recieved the message
        return {
          isMessageSend,
          msg: value?.message,
          time: value?.createdAt,
          isRead: value?.isRead,
          isMedia: value?.message ? false : true,
          media: value?.media || "",
          profileImage: !isMessageSend
            ? value?.userId?.profileImage
            : userData?.profileImage,
        };
      })
    );
  };

  // list of  the conversation with specifc user
  const readMessages = async (userId, adminId) => {
    // console.log(userId, adminId, "this is user and admin");
    return socket.emit("readMessages", {
      userId,
      adminId,
    });
  };

  // coverstion of specific user
  const getConverstions = (data) => {
    //console.log("this is the response", data);

    const response = convertMessages(data);
    handlReadMessage(currentValue);
    setConversation((p) => [...p, ...response]);
    if (data?.length === 0 || data?.length < coversationPageLength)
      return setHasMoreConversation(false);
  };

  // fetches incoming msg from event
  const fetchIncomingMsg = (data) => {
    // when the message is send we will get back the sended message as obj
    // firstly check the sender id is same  as the current value becuase sender we will be selected
    // if (
    //   currentValue?._id === data?.data?.userId?._id ||
    //   userData?._id === data?.data?.adminId?._id
    // ) {
    const response = convertMessages([data?.data]);
    // if its same then push it
    setConversation((p) => [...response, ...p]);
    handlReadMessage(currentValue);
  };

  //once we send the message update the side bar
  // fetchChatsList(userData?._id);
  // };

  // searchChat list fn
  const handleSearchList = (searchKey) => {
    fetchChatsList(userData?._id, searchKey);
  };

  useEffect(() => {
    (async () => {
      if (!isConnected) {
        await handleConnect(userData?._id);
        //once its connected then update the state
        socket.on("connect", onConnect);
      }
    })();
    // firstly connects to socket if its not connected

    if (isConnected) {
      socket.on("disconnect", onDisconnect);
      // getting converstions
      socket.on("messages", getConverstions);
      socket.on("fetchChats", updateSideChatList);
      socket.on("incomingMessage", fetchIncomingMsg);

      // fetch the side bar chat list on loading of screen
      fetchChatsList(userData?._id);
    }

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("fetchChats", updateSideChatList);
      socket.off("messages", getConverstions);
      socket.off("incomingMessage", fetchIncomingMsg);
    };
  }, [isConnected]);

  // creates an event for the chat list getting
  const fetchChatsList = (
    userId,
    searchKey = "",
    pageNo = 0,
    pageSize = chatPageLength
  ) => {
    if (!socket) {
      handleConnect(userData?._id);
    }
    socket.emit("fetchChats", {
      userId: userId,
      pageNo: pageNo,
      pageSize: pageSize,
      searchKey: searchKey,
    });
  };
  // hepls to send the message to a specific person
  const sendMessage = (receiverId, msg, media) => {
    const result = {
      adminId: userData?._id,
      userId: receiverId,
      message: msg || "",
      media,
    };
    socket.emit("createMessage", result);
  };
  // helps to fetch specific conversation with a user
  const fetchMessages = (
    receiverId,
    pageNo = 0,
    pageSize = coversationPageLength
  ) => {
    socket.emit("fetchMessages", {
      userId: receiverId,
      pageNo: pageNo,
      pageSize: pageSize,
    });
  };
  // calculate minutes,secs for displaying
  const calculateMinutes = (data) => {
    let duration = new Date() - new Date(data * 1000);
    duration /= 1000;

    // get seconds
    var seconds = Math.round(duration);
    var minutes = Math.floor(seconds / 60);
    if (minutes === 0) {
      return `now`;
    }
    if (minutes < 60) {
      return `${minutes}m`;
    } else if (minutes > 60 && minutes < 1440) {
      function display(a) {
        var hours = Math.trunc(a / 60);
        var minutes = a % 60;
        return hours + "h";
      }
      return display(minutes);
    } else if (minutes > 1440) {
      const value =
        DateConvert.toDaysMinutesSeconds(duration).slice(0, 1) + "d";
      return value;
    }
  };

  //console.log(conversation,"from here")
  return {
    handleConnect,
    sideChatList,
    handleDisconnect,
    sendMessage,
    fetchMessages,
    conversation,
    calculateMinutes,
    selectedChat,
    setSelectedChat,
    hasMoreConverstion,
    handleSearchList,
  };
};

export default useChat;
