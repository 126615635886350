import React from "react";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import Role from "./role";
import User from "./user";
import Dashboard from "./dashboard";
import Advertisement from "./advertisement";
import ProcessCase from "./processCase";
import Enquiry from "./enquiry";
import Products from "./products/main";
import Profile from "./profile";
import Setting from "./setting";
import Faq from "./faq";
import Sessions from "./sessions";
import Doctor from "./Doctor/index"
import SupportChats from "./SupportChats/Index"


// import RoleFunctionMaintenance from './roleFunction/RoleFunctionMaintenance';
function AdminContentRouting(props) {
  return (
    <Switch>
      <Route
        exact
        path="/admin"
        render={() => <Redirect to="/admin/users" />}
      />
      <Route exact path={["/admin/security/role"]} component={Role} />
      <Route exact path={["/admin/dashboard"]} component={Dashboard} />
      <Route exact path={["/admin/advertisement"]} component={Advertisement} />
      <Route exact path={["/admin/sessions"]} component={Sessions} />{" "}
      {/* Added new Route for sessions/Appointments */}
      <Route exact path={["/admin/cases"]} component={ProcessCase} />
      <Route exact path={["/admin/doctor"]} component={Doctor} />
      <Route exact path={["/admin/products"]} component={Products} />
      <Route exact path={["/admin/faq"]} component={Faq} />
      <Route exact path={["/admin/enquiry"]} component={Enquiry} />
      <Route exact path={["/admin/users"]} component={User} />
      <Route exact path={["/admin/customer/support"]} component={SupportChats} />
      <Route exact path={["/admin/profile"]} component={Profile} />
      <Route exact path={["/admin/setting"]} component={Setting} />
      {/* <Route exact path={['/admin/roles']} component={RoleFunctionMaintenance} /> */}
      <Route render={() => <h3>Coming soon.</h3>} />
    </Switch>
  );
}
export default withTranslation("translations")(AdminContentRouting);
