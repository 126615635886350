import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
  Typography,
  CardContent,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DateConversion from '../../utils/DateConversion';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import APIRequest from '../../utils/APIRequest';
import ConfigAPIURL from '../../config/ConfigAPIURL';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
  },
  chatReceiverMessages: {
    width: '80%',
    margin: '15px 0px',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  chatSenderMessages: {
    width: '80%',
    margin: '15px 0px',
    backgroundColor: theme.palette.primary.light,
  },
}));

function Notification(props) {
  const classes = useStyles();

  const [notificationMessages, setNotificationMessages] = useState([]);

  const [openNotifications, setOpenNotifications] = useState(false);

  const [loading, setLoading] = useState(false);
  const handleMessageTitles = (selected) => {
    setOpenNotifications(true);
    setNotificationMessages(selected);
  };

  const handleNotificationStatus = (read, notificationId) => {
    setLoading(true);
    APIRequest.request(
      'POST',
      ConfigAPIURL.retireCase,
      JSON.stringify({ recordId: notificationId, read: read })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setOpenNotifications(false);
          setLoading(false);
          props.getNotifications();
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div style={{ position: 'absolute', bottom: '20%', right: '25%' }}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <></>
      )}
      <Grid container style={{ height: '100%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardHeader
              avatar={
                openNotifications ? (
                  <Tooltip title={props.t('buttons.back')}>
                    <IconButton
                      aria-label='settings'
                      onClick={() => setOpenNotifications(false)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )
              }
              title={
                <Typography
                  align={openNotifications ? 'right' : 'center'}
                  variant='p'
                  style={{ fontWeight: 'bold' }}
                  component='p'
                >
                  {props.t('user.notifications')}
                </Typography>
              }
            />
            <Divider />
            <div>
              {openNotifications === false ? (
                <CardContent style={{ height: '450px', overflow: 'auto' }}>
                  {props.notificationLists.length === 0 ? (
                    <Card
                      style={{
                        marginTop: '15px',
                        paddingTop: '15px',
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              align='center'
                              variant='p'
                              component='p'
                            >
                              No Record Found
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    props.notificationLists.map((notifications, index) => (
                      <div style={{ marginTop: '15px' }} key={index}>
                        <Typography
                          align='center'
                          style={{ fontWeight: 'bold' }}
                          variant='p'
                          component='p'
                        >
                          {DateConversion.todayOrReturnDate(
                            notifications.updatedAt
                          )}
                        </Typography>
                        {notifications.notificationHistory.map(
                          (notificationhistory, idx) => (
                            <Card
                              key={idx}
                              style={{
                                marginTop: '15px',
                                paddingTop: '15px',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleMessageTitles(notificationhistory)
                              }
                            >
                              <CardContent>
                                <Grid container>
                                  <Grid xl={10} lg={10} md={10} sm={12} xs={12}>
                                    <Typography
                                      align='center'
                                      variant='p'
                                      component='p'
                                    >
                                      {notificationhistory.label +
                                        ' ' +
                                        DateConversion.unixToLocalDate12HourTime(
                                          notificationhistory.updatedAt
                                        )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    xl={2}
                                    lg={2}
                                    md={2}
                                    sm={12}
                                    xs={12}
                                    style={{ marginTop: '-12px' }}
                                  >
                                    <Tooltip title={props.t('buttons.open')}>
                                      <IconButton aria-label='view'>
                                        <ArrowForwardIosIcon variant='contained' />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </div>
                    ))
                  )}
                </CardContent>
              ) : (
                <CardContent style={{ height: '450px', overflow: 'auto' }}>
                  <div style={{ marginTop: '15px' }}>
                    <TextField
                      label={props.t('query.activeStatus')}
                      select
                      defaultValue={!notificationMessages.read}
                      value={!notificationMessages.read}
                      onChange={() =>
                        handleNotificationStatus(
                          !notificationMessages.read,
                          notificationMessages._id
                        )
                      }
                      style={{
                        float: 'right',
                        width: 'auto',
                        minWidth: '40%',
                      }}
                    >
                      <MenuItem key={0} value={true}>
                        {props.t('query.active')}
                      </MenuItem>
                      <MenuItem key={1} value={false}>
                        {props.t('query.statusRetire')}
                      </MenuItem>
                    </TextField>

                    <Typography
                      style={{ fontWeight: 'bold' }}
                      variant='p'
                      align='left'
                      component='p'
                    >
                      {props.t('notification.sentBy')}
                    </Typography>

                    <Typography variant='p' align='left' component='p'>
                      {notificationMessages.senderfname !== undefined &&
                      notificationMessages.senderfname !== null
                        ? notificationMessages.senderfname
                        : 'System'}{' '}
                      {notificationMessages.senderlname !== undefined &&
                      notificationMessages.senderlname !== null
                        ? notificationMessages.senderlname
                        : ''}
                    </Typography>
                    <Typography
                      style={{ fontWeight: 'bold', marginTop: '15px' }}
                      variant='p'
                      align='left'
                      component='p'
                    >
                      {notificationMessages.title}
                    </Typography>
                    <Typography
                      variant='p'
                      component='p'
                      align='left'
                      style={{ marginTop: '15px' }}
                    >
                      {notificationMessages.body}
                    </Typography>

                    <Box align='left'>
                      <Typography
                        style={{ fontWeight: 'bold', marginTop: '15px' }}
                        variant='p'
                        component='p'
                      >
                        {props.t('notification.sentOn')} :{' '}
                      </Typography>

                      {notificationMessages.visibleOn.map((visible, idx2) =>
                        visible === 'home' ? (
                          <Tooltip title={props.t('notification.home')}>
                            <IconButton aria-label='home'>
                              <HomeIcon fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        ) : visible === 'app' ? (
                          <Tooltip title={props.t('notification.app')}>
                            <IconButton aria-label='app'>
                              <PhoneIphoneIcon fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        ) : visible === 'sms' ? (
                          <Tooltip title={props.t('notification.sms')}>
                            <IconButton aria-label='sms'>
                              <SmsIcon fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        ) : visible === 'email' ? (
                          <Tooltip title={props.t('notification.email')}>
                            <IconButton aria-label='email'>
                              <EmailIcon fontSize='large' />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      )}
                    </Box>
                    <Typography
                      align='right'
                      style={{ fontWeight: 'bold', marginTop: '15px' }}
                      variant='p'
                      component='p'
                    >
                      {DateConversion.todayOrReturnDate(
                        notificationMessages.updatedAt
                      )}
                    </Typography>
                  </div>
                </CardContent>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation('translations')(Notification);
