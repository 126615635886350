import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  RadioGroup,
  FormControl,
  Checkbox,
  FormGroup,
  Card,
  CardContent,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  Radio,
  AccordionDetails,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import APIRequest from "../utils/APIRequest";
import ConfigAPIURL from "../config/ConfigAPIURL";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SlideInDialog from "./dialog/SlideInDialog";
import SnackbarUtils from "../utils/SnackbarUtils";
import { connect } from "react-redux";
import "react-dual-listbox/lib/react-dual-listbox.css";
import NotificationMacroDisplay from "./NotificationMacroDisplay";
import NotificationDeleteForm from "../components/privateRouting/admin/user/NotificationDeleteForm";

const userPageMacros = [
  // {
  //   label: "macros.fname",
  //   value: "<%fname%>",
  // },

  // {
  //   label: "macros.lname",
  //   value: "<%lname%>",
  // },
  // {
  //   label: "macros.userTag",
  //   value: "<%userTag%>",
  // },

  {
    label: "macros.email",
    value: "<%email%>",
  },

  {
    label: "macros.mobileNo",
    value: "<%mobileNo%>",
  },

  {
    label: "macros.gender",
    value: "<%gender%>",
  },

  {
    label: "macros.bloodGroup",
    value: "<%bloodGroup%>",
  },

  {
    label: "macros.height",
    value: "<%height%>",
  },

  {
    label: "macros.weight",
    value: "<%weight%>",
  },

  {
    label: "macros.age",
    value: "<%age%>",
  },

  {
    label: "macros.surgeries",
    value: "<%surgeries%>",
  },

  {
    label: "macros.chronicConditions",
    value: "<%chronicConditions%>",
  },

  {
    label: "macros.allergies",
    value: "<%allergies%>",
  },

  {
    label: "macros.familyHistory",
    value: "<%familyHistory%>",
  },

  {
    label: "macros.alcoholIntake",
    value: "<%alcoholIntake%>",
  },

  {
    label: "macros.city",
    value: "<%city%>",
  },

  {
    label: "macros.state",
    value: "<%state%>",
  },

  {
    label: "macros.country",
    value: "<%country%>",
  },
  {
    label: "macros.pin",
    value: "<%pin%>",
  },
  {
    label: "macros.patientId",
    value: "<%patientId%>",
  },

  {
    label: "macros.address1",
    value: "<%address1%>",
  },

  {
    label: "macros.address2",
    value: "<%address2%>",
  },
];

const casePageMacros = [
  // {
  //   label: "macros.caseType",
  //   value: "<%caseType%>",
  // },
  {
    label: "macros.patientFirstName",
    value: "<%fname%>",
  },
  {
    label: "macros.patientLastName",
    value: "<%lname%>",
  },
  // {
  //   label: "macros.mobileNo",
  //   value: "<%mobileNo%>",
  // },
  // {
  //   label: "macros.email",
  //   value: "<%email%>",
  // },
  // {
  //   label: "macros.weight",
  //   value: "<%weight%>",
  // },
  {
    label: "macros.caseId",
    value: "<%caseId%>",
  },
  {
    label: "link",
    value: "<%link%>",
  },
  // {
  //   label: "macros.patientId",
  //   value: "<%patientId%>",
  // },
  // {
  //   label: "macros.diagnosis",
  //   value: "<%diagnosis%>",
  // },
  // {
  //   label: "macros.status",
  //   value: "<%status%>",
  // },

  // {
  //   label: "macros.doctorInstructions",
  //   value: "<%doctorInstructions%>",
  // },

  // {
  //   label: "macros.pharmaInstructions",
  //   value: "<%pharmaInstructions%>",
  // },
  // {
  //   label: "macros.remark",
  //   value: "<%remark%>",
  // },
  // {
  //   label: "macros.patientNotes",
  //   value: "<%patientNotes%>",
  // },
];

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  // secondaryHeading: {
  //   flexBasis: '67.33%',
  //   flexShrink: 0,
  // },
  heading: {
    flexBasis: "50.33%",
    flexShrink: 0,
  },
}));
function SelectSendNotification(props) {
  const classes = useStyles();

  console.log(props,"from send notification")

  const [customMessage, setCustomMessage] = useState({
    title: "",
    body: "",
    visibleOn: {
      home: false,
      app: false,
      email: false,
      sms: false,
      smartDevice: false,
    }, // made changes
    actionTo:["patient"]
  });

  const [sendToServerBtn, setSendToServerBtn] = useState(false);

  const [selectedNotification, setSelectedNotification] = useState([]);

  const [actionType, setActionType] = useState("");
  const [actionTo, setActionTo] = useState("");
  const [actionTypeOptions, setActionTypeOptions] = useState([]);

  const [openMacroForm, setOpenMacroForm] = useState(false);
  const [userMacros, setUserMacros] = useState([]); //List of all Macros

  // console.log("customMessage", customMessage);

  const handleCheckbox = (notification) => {
    // console.log("this is notiii", notification);
    const foundIndex = selectedNotification.findIndex(
      (el) => el.title === notification.title
    );
    if (foundIndex === -1) {
      selectedNotification.push(notification);
      setSelectedNotification([...selectedNotification]);
    } else {
      selectedNotification.splice(foundIndex);
      setSelectedNotification([...selectedNotification]);
    }
  };

  const handleCustomNotificationMessage = (event, type) => {
    setCustomMessage({ ...customMessage, [type]: event.target.value });
  };

  const handleVisibleCheckChange = (event, index) => {
    // console.log(event.target.name);
    if (event.target.name !== "actionTo") {
      props.notificationList[index].visibleOn[event.target.name] =
        event.target.checked;
    } else {
      props.notificationList[index].actionTo = event.target.value;
    }

    props.setNotificationList([...props.notificationList]);
  };

  // const handleCustomVisibleCheckChange = (event) => {
  //   let messages = customMessage;
  //   messages.visibleOn[event.target.name] = event.target.checked;

  //   setCustomMessage({ ...messages });
  // };

  const handleCustomVisibleCheckChange = (event) => {
    setCustomMessage((prevMessage) => ({
      ...prevMessage,
      visibleOn: {
        ...prevMessage.visibleOn,
        [event.target.name]: !prevMessage.visibleOn[event.target.name],
      },
    }));
  };
  

  const handleMacroForm = () => {
    setOpenMacroForm(false);
  };
  const handleOpenMacroForm = () => {
    // getAllmacros();
    setOpenMacroForm(true);
  };

  const [notificationCheck,setNotificationCheck] = useState({
    actionTo:["patient"]
  })

  const handleButtons = (method) => {
    if (method === "submit") {
      setSendToServerBtn(true);

      // console.log("selectedNotification", selectedNotification);

      let sendToServer = true;
      let notificationList = selectedNotification;

      if (
        notificationList.length !== 0 ||
        customMessage.title !== "" ||
        customMessage.body !== ""
      ) {
        let visibleOnVal;
        selectedNotification.map((notifications, idx) => {
          if (
            notifications.visibleOn.app === false &&
            notifications.visibleOn.email === false &&
            notifications.visibleOn.home === false &&
            notifications.visibleOn.sms === false
          ) {
            SnackbarUtils.error(
              props.t("snackbar.selectWhereItshouldVisible"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            sendToServer = false;
            setSendToServerBtn(false);
            return;
          }

          visibleOnVal = Object.keys(notifications.visibleOn).filter(function (
            el
          ) {
            return notifications.visibleOn[el] === true;
          });

          notifications["visibleOn"] = visibleOnVal;
          notifications["title"] = notifications.title;
          notifications["templateId"] = notifications._id;
          notifications["body"] = notifications.content;
          notifications["category"] = "cases";
          notifications["actionType"] = notifications.actionType;
          notifications["actionTo"] = notificationCheck.actionTo;
          notifications["actionIds"] = props.rowDetails;

          delete notifications.updatedAt;
          delete notifications._id;
        });

        if (customMessage.title !== "" || customMessage.body !== "") {
          if (customMessage.title === "") {
            SnackbarUtils.error(
              props.t("snackbar.titleCannotBeEmpty"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            setSendToServerBtn(false);
            return;
          }
          if (customMessage.body === "") {
            SnackbarUtils.error(
              props.t("snackbar.bodyCannotBeEmpty"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            setSendToServerBtn(false);
            return;
          }
          if (
            customMessage.visibleOn.app === false &&
            customMessage.visibleOn.email === false &&
            customMessage.visibleOn.home === false &&
            customMessage.visibleOn.sms === false
          ) {
            SnackbarUtils.error(
              props.t("snackbar.selectCustomWhereItshouldVisible"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            setSendToServerBtn(false);
            return;
          }

          if (actionType === "") {
            SnackbarUtils.error(
              props.t("snackbar.notificationAccessLinkMandatory"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            setSendToServerBtn(false);
            return;
          }
          // if (actionTo === "") {
          //   SnackbarUtils.error(
          //     props.t("snackbar.notificationActionToMandatory"),
          //     "bottomCenter",
          //     3000
          //   ).then((notification) => {
          //     props.publishNotification(notification);
          //   });

          //   setSendToServerBtn(false);
          //   return;
          // }

          sendToServer = true;
          let customVisibleOnVal;
          customVisibleOnVal = Object.keys(customMessage.visibleOn).filter(
            function (el) {
              return customMessage.visibleOn[el] === true;
            }
          );
          notificationList.push({
            visibleOn: customVisibleOnVal,
            title: customMessage.title,
            body: Buffer.from(customMessage.body, "utf8").toString("base64"),
            category: "cases",
            actionType: actionType,
            actionTo: notificationCheck.actionTo,
            actionIds: props.rowDetails,
          });
        }
        if (sendToServer === true) {
          APIRequest.request(
            "POST",
            ConfigAPIURL.createNotifications,
            JSON.stringify({ notificationList: notificationList })
          ).then((response) => {
            if (response !== undefined && response !== null) {
              if (response.code === 100 && response.data.responseCode === 109) {
                setSendToServerBtn(false);
                props.handleSlideInDialog();
                props.getNotificationTemplate();
                // setActionType('');
                // setActionTo('');
                setNotificationCheck({
                  actionTo:""
                })

                setSelectedNotification([]);
                if (props.page === "caseMedicine") {
                  props.getCaseBasedNotifications();
                }

                setCustomMessage({
                  title: "",
                  body: "",
                  visibleOn: {
                    home: false,
                    app: false,
                    email: false,
                    sms: false,
                  },
                });
                setActionType("notification");
                setActionTo("both");
                SnackbarUtils.sucess(
                  props.t("snackbar.successfullyNotified"),
                  "bottomCenter",
                  3000
                ).then((notification) => {
                  props.publishNotification(notification);
                });
              } else {
                setSendToServerBtn(false);
              }
            } else {
              setSendToServerBtn(false);
            }
          });
        }
      } else {
        setSendToServerBtn(false);
        SnackbarUtils.error(
          props.t("snackbar.emptyNotification"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      setSendToServerBtn(false);
      props.handleSlideInDialog();
      // setActionType('');
      // setActionTo('');
      setNotificationCheck({
        actionTo:""
      })
      setActionType("notification");
      // setActionTo("both");
      props.getNotificationTemplate();
      setSelectedNotification([]);
      setCustomMessage({
        title: "",
        body: "",
        visibleOn: {
          home: false,
          app: false,
          email: false,
          sms: false,
        },
      });
    }
  };

  const handleUserNotifications = (response) => {
    if (response === "submit") {
      let result = {};
      setSendToServerBtn(true);
      if (customMessage.title !== "" || customMessage.body !== "") {
        if (customMessage.title === "") {
          SnackbarUtils.error(
            props.t("snackbar.titleCannotBeEmpty"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
          setSendToServerBtn(false);
          return;
        }
        if (customMessage.body === "") {
          SnackbarUtils.error(
            props.t("snackbar.bodyCannotBeEmpty"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          setSendToServerBtn(false);
          return;
        }

        if (
          customMessage.visibleOn.app === false &&
          customMessage.visibleOn.email === false &&
          customMessage.visibleOn.home === false &&
          customMessage.visibleOn.sms === false
        ) {
          SnackbarUtils.error(
            props.t("snackbar.selectCustomWhereItshouldVisible"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          setSendToServerBtn(false);
          return;
        }
        if (actionType === "") {
          SnackbarUtils.error(
            props.t("snackbar.notificationAccessLinkMandatory"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          setSendToServerBtn(false);
          return;
        }
        // if (actionTo === "") {
        //   SnackbarUtils.error(
        //     props.t("snackbar.notificationActionToMandatory"),
        //     "bottomCenter",
        //     3000
        //   ).then((notification) => {
        //     props.publishNotification(notification);
        //   });

        //   setSendToServerBtn(false);
        //   return;
        // }

        let customVisibleOnVal;

        customVisibleOnVal = Object.keys(customMessage.visibleOn).filter(
          function (el) {
            return customMessage.visibleOn[el] === true;
          }
        );

        result["visibleOn"] = customVisibleOnVal;
        result["title"] = customMessage.title;
        result["body"] = Buffer.from(customMessage.body, "utf8").toString(
          "base64"
        );
        result["category"] = "cases";
        result["actionType"] = actionType;
        result["actionTo"] =customMessage?.actionTo;
        result["data"] = props.rowDetails;
        console.log(result,"priritng payload")

        APIRequest.request(
          "POST",
          ConfigAPIURL.createUserNotifications,
          JSON.stringify(result)
        ).then((response) => {
          if (response !== undefined && response !== null) {
            if (response.code === 100 && response.data.responseCode === 109) {
              setSendToServerBtn(false);
              props.handleSlideInDialog();
              setSelectedNotification([]);
              // setActionType('');
              // setActionTo('');
              setActionType("notification");
              // setActionTo("both");
              setCustomMessage({
                title: "",
                body: "",
                visibleOn: {
                  home: false,
                  app: false,
                  email: false,
                  sms: false,
                },
              });
              SnackbarUtils.sucess(
                props.t("snackbar.successfullyNotified"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
            } else {
              setSendToServerBtn(false);
            }
          } else {
            setSendToServerBtn(false);
          }
        });
      } else {
        setSendToServerBtn(false);
        SnackbarUtils.error(
          props.t("snackbar.emptyNotification"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      setSendToServerBtn(false);
      props.handleSlideInDialog();
      // setActionType('');
      // setActionTo('');
      setActionType("notification");
      setActionTo("both");
      setSelectedNotification([]);
      setCustomMessage({
        title: "",
        body: "",
        visibleOn: {
          home: false,
          app: false,
          email: false,
          sms: false,
        },
      });
    }
  };

  useEffect(() => {
    getOptions();
    setActionType("notification");
    setActionTo("both");
  }, []);

  // console.log("customMessage", customMessage);

  const getAllmacros = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getAllmacros,
      JSON.stringify({ userId: props?.rowDetails?._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            // Transform API response into desired format
            const transformedMacros = response?.data?.macros?.map((macro) => ({
              label: `macros.${macro}`,
              value: `<%${macro}%>`,
            }));

            // Setting state with transformed data
            setUserMacros(transformedMacros);
          }
        }
      }
    });
  };

  // console.log("new macros", userMacros);
  const getOptions = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "actionType" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let serverResponse = response.data.options[0].options;

            if (props.page === "user") {
              serverResponse = serverResponse.filter(
                (item) => item.display !== "Case"
              );
            }

            setActionTypeOptions(serverResponse);
          }
        }
      }
    });
  };

  const handleCreateNotificationsTemplate = () => {
    // Modify customMessage object
    const modifiedMessage = {
      ...customMessage,
      content: customMessage.body, // Change body to content
      notificationType: "notice", // Add notificationType
      actionType: actionType,
    };

    // Validate modifiedMessage fields
    const validationErrors = [];
    if (!modifiedMessage.content) {
      validationErrors.push("Notification Message is required.");
    }
    if (!modifiedMessage.notificationType) {
      validationErrors.push("Notification Access Link is required.");
    }
    if (!modifiedMessage.actionType) {
      validationErrors.push("Notification Send to is required.");
    }
    if (!modifiedMessage.title) {
      validationErrors.push("Notification Title is required.");
    }

    // If there are validation errors, display snackbar error
    if (validationErrors.length > 0) {
      SnackbarUtils.error(
        validationErrors.join(" "),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return; // Stop further execution

      // SnackbarUtils.error(
      //   props.t("snackbar.selectWhereItshouldVisible"),
      //   "bottomCenter",
      //   3000
      // ).then((notification) => {
      //   props.publishNotification(notification);
      // });
      // // sendToServer = false;
      // setSendToServerBtn(false);
      // return;
    }

    APIRequest.request(
      "POST",
      ConfigAPIURL.createReminderTemplate,
      JSON.stringify({ ...modifiedMessage })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            // Transform API response into desired format
            // props.setSlideInDialog();
            props.getNotificationTemplate();
          }
        }
      }
    });
  };

  const [openNotificationDeleteForm, setOpenNotificationDeleteForm] =
    useState(false);
  const [templateId, setTemplateId] = useState("");
  const handleNotificationDeleteForm = (notifications) => {
    // Open the delete modal with the template object
    // You can use this template object within the ReminderDeleteForm component
    // For example, you can access notifications._id to extract the _id
    setOpenNotificationDeleteForm(true);
    setTemplateId(notifications?._id); // Save the template object to state
  };
  const handleDeleteForm = () => {
    setOpenNotificationDeleteForm(false);
  };

  console.log(customMessage, "selectedNotification");

  return (
    <div className={classes.root}>
      <Grid container>
        {props.page === "case" || props.page === "caseMedicine" ? (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {props.notificationList.map((notifications, index) => (
              <Accordion
                onChange={() => handleCheckbox(notifications)}
                expanded={selectedNotification.includes(notifications)}
                style={{ marginBottom: "12px" }}
              >
                {console.log(notifications,"from ehehhehhehe from mapping")}
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedNotification.includes(notifications)}
                          // checked={selectedNotification.includes(notifications)}
                          name={notifications.title}
                        />
                      }
                      label={notifications.title}
                    />
                  </FormGroup>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormGroup row style={{ float: "right" }}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={notifications.visibleOn.home}
                                  onChange={(event) =>
                                    handleVisibleCheckChange(event, index)
                                  }
                                  name="home"
                                />
                              }
                              label={props.t("notification.home")}
                            />
                          </FormGroup>
                        </FormControl>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={notifications.visibleOn.app}
                                  onChange={(event) =>
                                    handleVisibleCheckChange(event, index)
                                  }
                                  name="app"
                                />
                              }
                              label={props.t("notification.app")}
                            />
                          </FormGroup>
                        </FormControl>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={notifications.visibleOn.sms}
                                  onChange={(event) =>
                                    handleVisibleCheckChange(event, index)
                                  }
                                  name="sms"
                                />
                              }
                              label={props.t("notification.sms")}
                            />
                          </FormGroup>
                        </FormControl>

                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={notifications.visibleOn.email}
                                  onChange={(event) =>
                                    handleVisibleCheckChange(event, index)
                                  }
                                  name="email"
                                />
                              }
                              label={props.t("notification.email")}
                            />
                          </FormGroup>
                          
                        </FormControl>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            <FormControlLabel
                            disabled
                              control={
                                <Checkbox
                                  checked={notifications.visibleOn.smartDevice}
                                  onChange={(event) =>
                                    handleVisibleCheckChange(event, index)
                                  }
                                  name="smartDevice"
                                />
                              }
                              label="Smart Device"
                            />
                          </FormGroup>
                          
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      {notifications.content}
                    </Grid>

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "15px" }}
                    >
                      <Grid container>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <TextField
                            label={props.t("case.notificationAccessLink")}
                            select
                            required
                            disabled
                            defaultValue={notifications.actionType}
                            value={notifications.actionType}
                            fullWidth
                            onChange={(event) =>
                              setActionType(event.target.value)
                            }
                            style={{ marginBottom: 10 }}
                          >
                            {actionTypeOptions.map((option) => (
                              <MenuItem key={0} value={option.value}>
                                {option.display}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          {/* <TextField
                            label={props.t("case.actionTo")}
                            select
                            required
                            defaultValue={notifications.actionTo}
                            value={notifications.actionTo}
                            fullWidth
                            name="actionTo"
                            onChange={(event) =>
                              handleVisibleCheckChange(event, index)
                            }
                            style={{ marginBottom: 10 }}
                          >
                            <MenuItem key={0} value={"both"}>
                              {props.t("notification.both")}
                            </MenuItem>
                            <MenuItem key={1} value={"patient"}>
                              {props.t("notification.patient")}
                            </MenuItem>
                            <MenuItem key={2} value={"caregiver"}>
                              {props.t("notification.caregiver")}
                            </MenuItem>
                          </TextField> */}

<Grid item xl={8} lg={8} md={8} sm={12} xs={12}
                  // style={{ marginTop: ".7rem" }}
                >
                  <label>Notification Sent To</label>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificationCheck.actionTo.includes(
                            "patient"
                          )}
                          onChange={(event) =>
                            setNotificationCheck({
                              ...notificationCheck,
                              actionTo: event.target.checked
                                ? [...customMessage.actionTo, "patient"]
                                : customMessage.actionTo.filter(
                                    (item) => item !== "patient"
                                  ),
                            })
                          }
                          name="patient"
                        />
                      }
                      label="Patient"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificationCheck.actionTo.includes(
                            "caregiver"
                          )}
                          onChange={(event) =>
                            setNotificationCheck({
                              ...notificationCheck,
                              actionTo: event.target.checked
                                ? [
                                    ...notificationCheck.actionTo,
                                    "caregiver",
                                  ]
                                : notificationCheck.actionTo.filter(
                                    (item) => item !== "caregiver"
                                  ),
                            })
                          }
                          name="caregiver"
                        />
                      }
                      label="Caregiver"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificationCheck.actionTo.includes(
                            "careteam"
                          )}
                          onChange={(event) =>
                            setNotificationCheck({
                              ...notificationCheck,
                              actionTo: event.target.checked
                                ? [
                                    ...notificationCheck.actionTo,
                                    "careteam",
                                  ]
                                : notificationCheck.actionTo.filter(
                                    (item) => item !== "careteam"
                                  ),
                            })
                          }
                          name="careteam"
                        />
                      }
                      label="Careteam"
                    />
                  </FormGroup>
                </Grid>
                        </Grid>
                      </Grid>

                      {props.page === "case" ||
                      props.page === "caseMedicine" ? (
                        <Grid
                          container
                          style={{ marginTop: "20px", textAlign: "right" }}
                        >
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Button
                              onClick={() =>
                                handleNotificationDeleteForm(notifications)
                              }
                              color="primary"
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        ) : (
          <></>
        )}
        <Grid container>
          <Grid
            style={{
              display: "flex",
              alignItems: "flex-end",
              width: "100%",
              justifyContent: "flex-end",
              margin: "0px",
            }}
          >
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button
                fullWidth
                onClick={handleOpenMacroForm}
                // onClick={() => {
                //   setOpenMacroForm(true);
                // }}
              >
                {props.t("patientAccess.viewMacros")}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: "20px" }}
          >
            <Typography
              variant="p"
              component="p"
              style={{ fontWeight: "bold" }}
            >
              {props.t("case.customMessages")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                {/* <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "20px" }}
                >
                  <FormGroup row style={{ float: "right" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customMessage.visibleOn.home}
                          onChange={(event) =>
                            handleCustomVisibleCheckChange(event)
                          }
                          name="home"
                        />
                      }
                      label={props.t("notification.home")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customMessage.visibleOn.app}
                          onChange={(event) =>
                            handleCustomVisibleCheckChange(event)
                          }
                          name="app"
                        />
                      }
                      label={props.t("notification.app")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customMessage.visibleOn.email}
                          onChange={(event) =>
                            handleCustomVisibleCheckChange(event)
                          }
                          name="email"
                        />
                      }
                      label={props.t("notification.email")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={customMessage.visibleOn.sms}
                          onChange={(event) =>
                            handleCustomVisibleCheckChange(event)
                          }
                          name="sms"
                        />
                      }
                      label={props.t("notification.sms")}
                    />
                  </FormGroup>
                </Grid> */}
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "20px" }}
                >
                  <TextField
                    id="customTitle"
                    label={props.t("case.customMessageSubject")}
                    autoComplete="something-unsupported"
                    autoComplete="off"
                    value={customMessage.title}
                    fullWidth
                    onChange={(event) => {
                      handleCustomNotificationMessage(event, "title");
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "20px" }}
                >
                  <TextField
                    id="customBody"
                    label={props.t("case.customMessageBody")}
                    autoComplete="something-unsupported"
                    autoComplete="off"
                    multiline
                    value={customMessage.body}
                    fullWidth
                    onChange={(event) => {
                      handleCustomNotificationMessage(event, "body");
                    }}
                  />
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <Grid container>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                      <TextField
                        label={props.t("case.notificationAccessLink")}
                        select
                        required={
                          customMessage.title !== "" &&
                          customMessage.body !== ""
                            ? true
                            : false
                        }
                        defaultValue={actionType}
                        value={actionType}
                        fullWidth
                        onChange={(event) => setActionType(event.target.value)}
                        style={{ marginBottom: 10 }}
                      >
                        {actionTypeOptions.map((option) => (
                          <MenuItem key={0} value={option.value}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {props.page==="user" && (
                      <>
                    {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                      <TextField
                        label={props.t("case.actionTo")}
                        select
                        required
                        required={
                          customMessage.title !== "" &&
                          customMessage.body !== ""
                            ? true
                            : false
                        }
                        defaultValue={actionTo}
                        value={actionTo}
                        fullWidth
                        onChange={(event) => setActionTo(event.target.value)}
                        style={{ marginBottom: 10 }}
                      >
                        <MenuItem key={0} value={"both"}>
                          {props.t("notification.both")}
                        </MenuItem>
                        <MenuItem key={1} value={"patient"}>
                          {props.t("notification.patient")}
                        </MenuItem>
                        <MenuItem key={2} value={"caregiver"}>
                          {props.t("notification.caregiver")}
                        </MenuItem>
                      </TextField>
                    </Grid> */}

<Grid item xl={4} lg={4} md={6} sm={12} xs={12}
                  style={{ marginTop: ".7rem" }}
                >
                  <label>Notification Sent To</label>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customMessage.actionTo.includes(
                            "patient"
                          )}
                          onChange={(event) =>
                            setCustomMessage({
                              ...customMessage,
                              actionTo: event.target.checked
                                ? [...customMessage.actionTo, "patient"]
                                : customMessage.actionTo.filter(
                                    (item) => item !== "patient"
                                  ),
                            })
                          }
                          name="patient"
                        />
                      }
                      label="Patient"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customMessage.actionTo.includes(
                            "caregiver"
                          )}
                          onChange={(event) =>
                            setCustomMessage({
                              ...customMessage,
                              actionTo: event.target.checked
                                ? [
                                    ...customMessage.actionTo,
                                    "caregiver",
                                  ]
                                : customMessage.actionTo.filter(
                                    (item) => item !== "caregiver"
                                  ),
                            })
                          }
                          name="caregiver"
                        />
                      }
                      label="Caregiver"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customMessage.actionTo.includes(
                            "careteam"
                          )}
                          onChange={(event) =>
                            setCustomMessage({
                              ...customMessage,
                              actionTo: event.target.checked
                                ? [
                                    ...customMessage.actionTo,
                                    "careteam",
                                  ]
                                : customMessage.actionTo.filter(
                                    (item) => item !== "careteam"
                                  ),
                            })
                          }
                          name="careteam"
                        />
                      }
                      label="Careteam"
                    />
                  </FormGroup>
                </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{
                        marginTop: "0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid>
                        <FormGroup row style={{ float: "right" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={customMessage.visibleOn.home}
                                onChange={(event) =>
                                  handleCustomVisibleCheckChange(event)
                                }
                                name="home"
                              />
                            }
                            label={props.t("notification.home")}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={customMessage.visibleOn.app}
                                onChange={(event) =>
                                  handleCustomVisibleCheckChange(event)
                                }
                                name="app"
                              />
                            }
                            label={props.t("notification.app")}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={customMessage.visibleOn.email}
                                onChange={(event) =>
                                  handleCustomVisibleCheckChange(event)
                                }
                                name="email"
                              />
                            }
                            label={props.t("notification.email")}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item>
                        <FormGroup row style={{ float: "right" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                // disabled
                                checked={customMessage.visibleOn.sms}
                                onChange={(event) =>
                                  handleCustomVisibleCheckChange(event)
                                }
                                name="sms"
                              />
                            }
                            label={props.t("notification.sms")}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={customMessage.visibleOn.smartDevice}
                                onChange={(event) =>
                                  handleCustomVisibleCheckChange(event)
                                }
                                name="smartDevice"
                              />
                            }
                            label={props.t("notification.smartDevice")}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid> 
                    </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {props.page === "case" || props.page === "caseMedicine" ? (
                <Grid
                  container
                  style={{ marginTop: "20px", textAlign: "right" }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      // disabled={sendToServerBtn}
                      onClick={() => handleCreateNotificationsTemplate()}
                      color="primary"
                    >
                      Save as Template
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "20px", textAlign: "right" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            disabled={sendToServerBtn}
            onClick={
              props.page === "user"
                ? () => handleUserNotifications("disagree")
                : () => handleButtons("disagree")
            }
            color="primary"
          >
            cancel
          </Button>
          <Button
            disabled={sendToServerBtn}
            onClick={
              props.page === "user"
                ? () => handleUserNotifications("submit")
                : () => handleButtons("submit")
            }
            color="primary"
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <SlideInDialog
        title={props.t("patientAccess.openMacros")}
        contentComponent={
          <NotificationMacroDisplay
            setSlideInDialog={handleMacroForm}
            macro={props.page === "user" ? userMacros : casePageMacros}
          />
        }
        fullWidth={true}
        maxWidth={"md"}
        dialogActionsStatus={false}
        slideInDialog={openMacroForm}
        setSlideInDialog={handleMacroForm}
        // handleDeleteRequest={handleDeleteRequest}
      />

      {/* -----------------------Delete Modal------------------------ */}
      <SlideInDialog
        title={props.t("patientAccess.DeleteNotificationTemplate")}
        contentComponent={
          <NotificationDeleteForm
            setSlideInDialog={handleDeleteForm}
            rowDetails={templateId}
            getNotifications={props.getNotificationTemplate}
          />
        }
        fullWidth={true}
        maxWidth={"md"}
        dialogActionsStatus={false}
        slideInDialog={openNotificationDeleteForm}
        setSlideInDialog={handleDeleteForm}
        // handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(SelectSendNotification)
);
