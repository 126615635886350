import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import SecurityIcon from "@material-ui/icons/Security";
import FilterListIcon from "@material-ui/icons/FilterList";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ChatIcon from "@mui/icons-material/Chat";
class SideBarMenu {
  static menu = [
    // {
    //   display: 'sideNavMenu.adminUser',
    //   link: '/admin/adminUser',
    //   linkPage: false,
    //   onclick: null,
    //   urlEndWith: 'adminUser',
    //   className: '',
    //   permissionLevel: 'adminUser',
    //   icon: <PersonIcon />,
    //   eventKey: 'adminUser',
    //   subMenu: [],
    // },
    {
      display: "sideNavMenu.dashboard",
      link: "/admin/dashboard",
      linkPage: false,
      onclick: null,
      urlEndWith: "dashboard",
      className: "",
      permissionLevel: "Dashboard",
      icon: <DashboardIcon />,
      eventKey: "dashboard",
      subMenu: [],
    },
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "Users",
      icon: <PeopleIcon />,
      eventKey: "users",
      subMenu: [],
    },
    {
      display: "sideNavMenu.cases",
      link: "/admin/cases",
      linkPage: false,
      onclick: null,
      urlEndWith: "cases",
      className: "",
      permissionLevel: "Cases",
      icon: <ListAltIcon />,
      eventKey: "cases",
      subMenu: [],
    },
    {
      display: "sideNavMenu.products",
      link: "/admin/products",
      linkPage: false,
      onclick: null,
      urlEndWith: "products",
      className: "",
      permissionLevel: "Products",
      icon: <LocalGroceryStoreIcon />,
      eventKey: "products",
      subMenu: [],
    },
    {
      display: "Doctor",
      link: "/admin/doctor",
      linkPage: false,
      onclick: null,
      urlEndWith: "doctor",
      className: "",
      permissionLevel: "Doctor",
      icon: <LocalHospitalIcon />,
      eventKey: "doctor",
      subMenu: [],
    },
    {
      display: "sideNavMenu.sessions",
      link: "/admin/sessions",
      linkPage: false,
      onclick: null,
      urlEndWith: "sessions",
      className: "",
      permissionLevel: "Sessions",
      icon: <MeetingRoomIcon />,
      eventKey: "sessions",
      subMenu: [],
    },
    {
      display: "Support Chats",
      link: "/admin/customer/support",
      linkPage: false,
      onclick: null,
      urlEndWith: "support",
      className: "",
      permissionLevel: "support",
      icon: <ChatIcon />,
      eventKey: "support",
      subMenu: [],
    },

    {
      display: "sideNavMenu.advertisement",
      link: "/admin/advertisement",
      linkPage: false,
      onclick: null,
      urlEndWith: "advertisement",
      className: "",
      permissionLevel: "Advertisement",
      icon: <PostAddIcon />,
      eventKey: "advertisement",
      subMenu: [],
    },
    // {
    // 	display: 'sideNavMenu.enquiry',
    // 	link: '/admin/enquiry',
    // 	linkPage: false,
    // 	onclick: null,
    // 	urlEndWith: 'enquiry',
    // 	className: '',
    // 	permissionLevel: 'Enquiry',
    // 	icon: <LiveHelpIcon />,
    // 	eventKey: 'enquiry',
    // 	subMenu: [],
    // },
    {
      display: "sideNavMenu.faq",
      link: "/admin/faq",
      linkPage: false,
      onclick: null,
      urlEndWith: "faq",
      className: "",
      permissionLevel: "faq",
      icon: <PostAddIcon />,
      eventKey: "faq",
      subMenu: [],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.security",
      link: "/admin/security",
      linkPage: false,
      onclick: null,
      urlEndWith: "security",
      className: "",
      permissionLevel: "",
      icon: <SecurityIcon />,
      eventKey: "security",
      subMenu: [
        {
          display: "sideNavMenu.role_function",
          link: "/admin/security/role",
          linkPage: false,
          onclick: null,
          urlEndWith: "role",
          className: "",
          permissionLevel: "Role",
          icon: <FilterListIcon />,
          eventKey: "role",
        },
      ],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.setting",
      link: "/admin/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      icon: <SettingsIcon />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
}
export default SideBarMenu;
