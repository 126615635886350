class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/
  static tableHeader = [
    {
      id: 'productSku',
      accessor: 'productSku',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'products.productSku',
      className: '',
      functionName: '',
    },
    {
      id: 'title',
      accessor: 'title',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'products.title',
      className: '',
      functionName: '',
    },
    {
      id: 'genericName',
      accessor: 'genericName',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.genericName',
      className: '',
      functionName: '',
    },
    {
      id: 'unitPackSize',
      accessor: 'unitPackSize',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.unitPackSize',
      className: '',
      functionName: '',
    },
    {
      id: 'marketedBy',
      accessor: 'marketedBy',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.marketedBy',
      className: '',
      functionName: '',
    },
    {
      id: 'distributedBy',
      accessor: 'distributedBy',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.distributedBy',
      className: '',
      functionName: '',
    },
    {
      id: 'drcNo',
      accessor: 'drcNo',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.drcNo',
      className: '',
      functionName: '',
    },
    {
      id: 'form',
      accessor: 'form',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.form',
      className: '',
      functionName: '',
    },
    {
      id: 'dosageForm',
      accessor: 'dosageForm',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.dosageForm',
      className: '',
      functionName: '',
    },
    {
      id: 'barCodeId',
      accessor: 'barCodeId',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.barCodeId',
      className: '',
      functionName: '',
    },

    {
      id: 'substitutes',
      accessor: 'substitutes',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.substitutes',
      className: '',
      functionName: '',
    },
    {
      id: 'concerns',
      accessor: 'concerns',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.concerns',
      className: '',
      functionName: '',
    },
    {
      id: 'composition',
      accessor: 'composition',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.composition',
      className: '',
      functionName: '',
    },
    {
      id: 'images',
      accessor: 'images',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'products.images',
      className: 'StringUtils',
      functionName: 'image',
    },

    {
      id: 'available',
      accessor: 'available',
      dataType: 'number',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 20,
      label: 'products.available',
      className: '',
      functionName: '',
    },
    {
      id: 'price',
      accessor: 'price',
      dataType: 'number',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.price',
      className: '',
      functionName: '',
    },
    {
      id: 'discount',
      accessor: 'discount',
      dataType: 'number',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.discount',
      className: '',
      functionName: '',
    },
    {
      id: 'category',
      accessor: 'category',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'products.category',
      className: '',
      functionName: '',
    },
    // {
    //   id: 'subCategory',
    //   accessor: 'subCategory',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 100,
    //   label: 'products.subCategory',
    //   className: '',
    //   functionName: '',
    // },
    {
      id: 'itemType',
      accessor: 'itemType',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 100,
      label: 'products.itemType',
      className: '',
      functionName: '',
    },
    {
      id: 'brand',
      accessor: 'brand',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'products.brand',
      className: '',
      functionName: '',
    },
    {
      id: 'tax',
      accessor: 'tax',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: 'products.tax',
      className: '',
      functionName: '',
    },
    {
      id: 'deliveryDays',
      accessor: 'deliveryDays',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.deliveryDays',
      className: '',
      functionName: '',
    },
    {
      id: 'drugClass',
      accessor: 'drugClass',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.drugClass',
      className: '',
      functionName: '',
    },
    {
      id: 'offLabel',
      accessor: 'offLabel',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.offLabel',
      className: '',
      functionName: '',
    },
    {
      id: 'preg',
      accessor: 'preg',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.preg',
      className: '',
      functionName: '',
    },
    {
      id: 'csa',
      accessor: 'csa',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.csa',
      className: '',
      functionName: '',
    },
    {
      id: 'alcohol',
      accessor: 'alcohol',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.alcohol',
      className: '',
      functionName: '',
    },
    {
      id: 'rating',
      accessor: 'rating',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.rating',
      className: '',
      functionName: '',
    },
    {
      id: 'popularity',
      accessor: 'popularity',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'products.popularity',
      className: '',
      functionName: '',
    },
    // {
    //   id: 'relatedTreatements',
    //   accessor: 'additionalInfo.relatedTreatements',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 80,
    //   label: 'products.relatedTreatements',
    //   className: '',
    //   functionName: '',
    // },
    // {
    //   id: 'relatedDrugs',
    //   accessor: 'additionalInfo.relatedDrugs',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 80,
    //   label: 'products.relatedDrugs',
    //   className: '',
    //   functionName: '',
    // },

    {
      id: 'manufacturer',
      accessor: 'manufacturer',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'products.manufacturer',
      className: '',
      functionName: '',
    },
    {
      id: 'overview',
      accessor: 'overview',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'products.overview',
      className: '',
      functionName: '',
    },
  ];
}
export default TableHeader;
