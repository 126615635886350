import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles, TextField, Button } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import LocalStorage from "../../../../../config/LocalStorage";
import APIRequest from "../../../../../utils/APIRequest";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
import AEVForm from "../AEVForm";
import FormDialog from "../../../../../templates/dialog/FormDialog";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));

const filter = createFilterOptions();

function CreatePatient(props) {
  const classes = useStyles();

  const [userList, setUserList] = useState([]);

  const [patient, setpatient] = useState("");
  const [roles,setRoles] = useState([])

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });

  useEffect(() => {
    getRoleTitles();
  }, []);

  const handleAssignDialog = (response) => {
    props.handleAddPatientsDialog();
    if (response === "submit") {
      if (props.page === "patient" && patient === "") {
        return SnackbarUtils.error(
          props.t("snackbar.addPatient"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
      if (props.page === "caregiver" && patient === "") {
        return SnackbarUtils.error(
          props.t("snackbar.addCaregiver"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
      if (props.page === "careTeam" && patient === "") {
        return SnackbarUtils.error(
          "Please Select Careteam",
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }

      let url = "";
      let body = {};
      if (props.page === "caregiver") {
        url = ConfigAPIURL.addCaregiver;
        body = { userId: props.rowDetails._id, caregiverId: patient._id };
      }

      if (props.page === "patient") {
        url = ConfigAPIURL.addPatient;
        body = { userId: props.rowDetails._id, patientId: patient._id };
      }
      if (props.page === "careTeam") {
        url = ConfigAPIURL.addCareTeam;
        body = { userId: props.rowDetails._id, careTeamId: patient._id };
      }
      APIRequest.request("POST", url, JSON.stringify(body)).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 100) {
            props.handleRefreshData();
            setpatient("");
            // getUserList();
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      });
    }
  };

  const getUserList = (event, value) => {
    let APIURL =
      props.page === "careTeam"
        ? ConfigAPIURL.searchCareTeam
        : ConfigAPIURL.searchPatientOrCaregiver;

    if (value !== undefined && value !== null) {
      if (value === "") {
        setUserList([]);
      } else if (value.length > LocalStorage.searchableStringLimit) {
        //based on page we are setting the URL

        APIRequest.request(
          "POST",
          APIURL,
          JSON.stringify({ userId: props.rowDetails._id, keyword: value })
        ).then((response) => {
          if (response !== undefined && response !== null) {
            if (response.code === 100 && response.data.responseCode === 109) {
              let serverResponse = response.data.result;

              serverResponse.map((results) => {
                results["label"] =
                  results.fname + " " + results.lname + " " + results.mobileNo;
              });

              setUserList(serverResponse);
            }
          }
        });
      } else {
        setUserList([]);
      }
    } else {
      setUserList([]);
    }
  };

  const getRoleTitles = () => {
    APIRequest.request('GET', ConfigAPIURL.roleTitles, '').then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setRoles(response.data.roles);
        }
      }
    });
  };

  const handleUserList = (event, value) => {
    if (value !== undefined && value !== null) {
      if (value.inputValue !== undefined) {
        props.handleAddPatientsDialog();
        setOpenForm({
          status: true,
          title: props.t("user.addUser"),
          divType: "new",
          functionName: "",
        });
      } else {
        setpatient(value);
      }
    }
  };

  const handleFormDialog = () => {
    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
  };

  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };

  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            value={patient}
            onClose={() => setUserList([])}
            onChange={handleUserList}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (
                params.inputValue !== "" &&
                params.inputValue.length > LocalStorage.searchableStringLimit
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `${props.t(
                    "patientAccess.searchUserNotFoundFirst"
                  )} "${params.inputValue}" ${props.t(
                    "patientAccess.searchUserNotFoundSecond"
                  )}`,
                  // label: `${props.t('patientAccess.searchUserNotFound')}  `,
                });
              }
              return filtered;
            }}
            selectOnFocus
            noOptionsText={props.t("user.userOptionNotFound")}
            clearOnBlur
            handleHomeEndKeys
            onInputChange={getUserList}
            id="usersList"
            options={userList}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            renderOption={(option) => option.label}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={props.label}
                variant="standard"
              />
            )}
          />

          {/* <Autocomplete
            id='combo-box-demo'
            options={userList}
            getOptionLabel={(option) => option.label}
            fullWidth
            noOptionsText={props.t('user.userOptionNotFound')}
            onClose={() => setUserList([])}
            required
            onInputChange={getUserList}
            value={patient}
            onChange={handleUserList}
            renderInput={(params) => (
              <TextField {...params} label={props.label} variant='standard' />
            )}
          /> */}
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "20px", textAlign: "right" }}
        >
          <Button
            onClick={() => handleAssignDialog("disagree")}
            color="primary"
          >
            {props.t("buttons.cancel")}
          </Button>
          <Button onClick={() => handleAssignDialog("submit")} color="primary">
            {props.t("buttons.submit")}
          </Button>
        </Grid>
      </Grid>

      <FormDialog
        // label={
        //   props.rowDetails !== undefined && props.rowDetails !== null
        //     ? props.rowDetails.fname + ' - ' + props.rowDetails.patientId
        //     : ''
        // }
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          <AEVForm
            page={props.page === "careTeam" ? "careTeam" : "createPatientPage"}
            marginTop={60}
            roles={roles}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={props.rowDetails}
            // tableQuery={tableQuery}

          />
        }
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(CreatePatient)
);
