import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableBody,
  CardActions,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";

import ChipInput from "material-ui-chip-input";
import MedicationTableWithPagination from "../../../../templates/tables/MedicationTableWithPagination";

import FormValidation from "../../../../utils/FormValidation";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";

import SelectSendNotification from "../../../../templates/SelectSendNotification";
import SendNotes from "../../../../templates/SendNotes";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import Notification from "../../../../templates/patientConnect/Notification";
import CreateReminderTemplates from "./CreateReminderTemplates";
import ReminderDeleteForm from "./ReminderDeleteForm";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "80px",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

function ReminderNotification(props) {
  const classes = useStyles();

  const [notificationTemplates, setNotificationTemplates] = useState([]);
  const [userNotificationData, setUserNotificationData] = useState([]);
  const [actionTypeOptions, setActionTypeOptions] = useState([]);
  const [openReminderCreationForm, setOpenReminderCreationForm] =
    useState(false);
  const [openReminderEditForm, setOpenReminderEditForm] = useState(false);
  const [openReminderDeleteForm, setOpenReminderDeleteForm] = useState(false);
  //state for template id
  const [templateId, setTemplateId] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    content: "",
    notificationType: "reminder",
    userId: props?.rowDetails?._id,
  });

  // console.log("datadadadadad", formData);
  const handleOpenReminderCreationForm = () => {
    setFormData({
      title: "",
      content: "",
      notificationType: "reminder",
      userId: props?.rowDetails?._id,
    });
    setOpenReminderCreationForm(true);
  };
  const handleReminderForm = () => {
    setOpenReminderCreationForm(false);
  };
  const handleReminderDeleteForm = (template) => {
    // Open the delete modal with the template object
    // You can use this template object within the ReminderDeleteForm component
    // For example, you can access template._id to extract the _id
    setOpenReminderDeleteForm(true);
    setTemplateId(template?._id); // Save the template object to state
  };
  const handleDeleteForm = () => {
    setOpenReminderDeleteForm(false);
  };

  // console.log("userNotificationDatauserNotificationData", userNotificationData);

  const getReminderDetails = (templateId) => {
    const url = `${ConfigAPIURL.getReminderTemplateDetails}?_id=${templateId}`;
    APIRequest.request("GET", url).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response?.data?.responseCode !== undefined &&
          response?.data?.responseCode !== null
        ) {
          if (response?.data?.responseCode === 109) {
            // Extract only the required fields from the response
            const { title, content, notificationType, _id } =
              response.data.result;
            // Update the state with the extracted fields
            setFormData({
              title: title,
              content: content,
              notificationType: notificationType,
              _id: _id,
            });
          }
        }
      }
    });
  };

  const handleReminderEditForm = (template) => {
    // Open the delete modal with the template object
    // You can use this template object within the ReminderDeleteForm component
    // For example, you can access template._id to extract the _id
    getReminderDetails(template?._id);
    setOpenReminderEditForm(true);
    setTemplateId(template?._id); // Save the template object to state
  };
  const handleEditForm = () => {
    setOpenReminderEditForm(false);
  };

  useEffect(() => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog !== undefined &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getNotifications();
      getOptions();
    }
  }, [props.formDialog]);

  useEffect(() => {
    getUserNotifications();
  }, [notificationTemplates]);

  const getOptions = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "actionType" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let serverResponse = response.data.options[0].options;

            // if (props.page === 'user') {
            //   serverResponse = serverResponse.filter(
            //     (item) => item.display !== 'Case'
            //   );
            // }

            setActionTypeOptions(serverResponse);
          }
        }
      }
    });
  };

  const getNotifications = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.getNotificationTemplate + "?notificationType=reminder",
      ""
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          let serverResponse = response.data.result;

          serverResponse.map((results) => {
            results["visibleOn"] = ["app", "home", "sms", "email"];

            // results['visibleOn'] = {
            //   app: false,
            //   home: false,
            //   sms: false,
            //   whatsApp: false,
            //   email: false,
            // };
            // results['timings'] = {
            //   Morning: false,
            //   Afternoon: false,
            //   Evening: false,
            //   Night: false,
            // };
            // results['actionType'] = '';
            // results['actionTo'] = '';
          });

          setNotificationTemplates(serverResponse);
        }
      }
    });
  };
  const getUserNotifications = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.userDetails,
      JSON.stringify({ userId: props.rowDetails._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          let serverResponse = response.data.user.reminder;
          setUserNotificationData(serverResponse);
          //   if (serverResponse.length === 0) {
          //     let arr = new Array(notificationTemplates.length).fill({
          //       notificationTemplateId: '',
          //       timings: [],
          //       visibleOn: [],
          //       actionType: '',
          //       actionTo: '',
          //     });
          //     setUserNotificationData([...arr]);
          //   } else {

          //   }
        }
      }
    });
  };

  const handleSelection = (event, index) => {
    let findIndex = userNotificationData.findIndex(
      (el) => el.notificationTemplateId === event.target.value
    );

    if (event.target.checked === true && findIndex === -1) {
      let data = userNotificationData;
      data.push({
        notificationTemplateId: event.target.value,
        // timings: {
        //   Morning: false,
        //   Afternoon: false,
        //   Evening: false,
        //   Night: false,
        // }, //Morning, Afternoon, Evening, Night
        visibleOn: [],
        // {
        //   app: false,
        //   home: false,
        //   sms: false,
        //   whatsApp: false,
        //   email: false,
        // }, //where this notification has to be triggered
        actionType: "home", //where it has to open
        actionTo: "patient", //to whom this notification has to get triggered
      });

      setUserNotificationData([...data]);
    } else if (event.target.checked === false && findIndex > -1) {
      let data = userNotificationData;
      delete data.splice(findIndex, 1);

      setUserNotificationData([...data]);
    }
  };

  const handleVisibleCheckChange = (event, index, templateId) => {
    let findIndex = userNotificationData.findIndex(
      (el) => el.notificationTemplateId === templateId
    );

    let findVisibleOnIndex = userNotificationData[
      findIndex
    ].visibleOn.findIndex((val) => val === event.target.name);

    let userData = userNotificationData;
    if (event.target.checked === false && findVisibleOnIndex !== -1) {
      userData[findIndex].visibleOn.splice(findVisibleOnIndex, 1);
    } else {
      userData[findIndex].visibleOn.push(event.target.name);
    }
    setUserNotificationData([...userData]);
  };
  const handleActionToChange = (event, index, templateId) => {
    const { name, value } = event.target;

    setUserNotificationData((prevData) => {
      return prevData.map((item) => {
        if (item.notificationTemplateId === templateId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
    });
  };

  // const handleActionToChange = (event, index, templateId) => {
  //   // console.log('eventValue', event.target.value);
  //   // console.log('eventName', event.target.name);
  //   // console.log('templateId', templateId);
  //   let findIndex = userNotificationData.findIndex(
  //     (el) => el.notificationTemplateId === templateId
  //   );
  //   userNotificationData[findIndex][event.target.name] = event.target.value;
  //   setUserNotificationData([...userNotificationData]);
  // };
  const getValueForActionToField = (templateId, templates) => {
    const userNotification = userNotificationData.find(
      (el) => el.notificationTemplateId === templateId && el.actionTo !== ""
    );

    return userNotification ? userNotification.actionTo : templates.actionTo;
  };

  const sendToserver = () => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.userUpdateReminder,
      JSON.stringify({
        userId: props.rowDetails._id,
        reminder: userNotificationData,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          getNotifications();
          SnackbarUtils.sucess(
            props.t("snackbar.transComplete"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          align="right"
          style={{ marginBottom: "15px" }}
        >
          <Button onClick={handleOpenReminderCreationForm}>Create</Button>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            {notificationTemplates?.map((templates, index) => (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "10px" }}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}> */}
                      <Checkbox
                        checked={
                          !!userNotificationData.find(
                            (el) => el.notificationTemplateId === templates._id
                          )
                        }
                        value={templates._id}
                        onChange={(event) => handleSelection(event, index)}
                        name="Select"
                      />
                      {/* </Grid>

                      <Grid
                        item
                        xl={11}
                        lg={11}
                        md={9}
                        sm={8}
                        xs={8}
                        style={{ marginTop: "10px" }}
                      > */}
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "10px",
                        }}
                      >
                        {templates.subject}
                      </Typography>
                      {/* </Grid> */}
                    </Grid>

                    <Typography
                      variant="body2"
                      component="p"
                      style={{ marginTop: "15px" }}
                    >
                      {templates.content}
                    </Typography>

                    {/* ----------------------This Is Bottom Dropdown----------------------------------         */}

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "15px" }}
                    >
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            label={props.t("case.notificationAccessLink")}
                            select
                            disabled={true}
                            required
                            defaultValue={templates.actionType}
                            value={templates.actionType}
                            fullWidth
                            // onChange={(event) => setActionType(event.target.value)}
                            style={{ marginBottom: 10 }}
                          >
                            {actionTypeOptions.map((option) => (
                              <MenuItem key={0} value={option.value}>
                                {option.display}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <TextField
                            label={props.t("case.actionTo")}
                            select
                            required
                            disabled={
                              !!!userNotificationData.find(
                                (el) =>
                                  el.notificationTemplateId === templates._id
                              )
                            }
                            // defaultValue={userNotificationData.map(function (
                            //   el
                            // ) {
                            //   if (
                            //     el.notificationTemplateId === templates._id &&
                            //     el.actionTo !== ""
                            //   ) {
                            //     return el.actionTo;
                            //   } else {
                            //     return templates.actionTo;
                            //   }
                            // })}
                            // value={userNotificationData?.map(function (el) {
                            //   if (
                            //     el.notificationTemplateId === templates._id &&
                            //     el.actionTo !== ""
                            //   ) {
                            //     return el.actionTo;
                            //   } else {
                            //     return templates.actionTo;
                            //   }
                            // })}
                            value={getValueForActionToField(
                              templates._id,
                              templates
                            )}
                            fullWidth
                            name="actionTo"
                            onChange={(event) =>
                              handleActionToChange(event, index, templates._id)
                            }
                            style={{ marginBottom: 10 }}
                          >
                            <MenuItem key={0} value={"both"}>
                              {props.t("notification.both")}
                            </MenuItem>
                            <MenuItem key={1} value={"patient"}>
                              {props.t("notification.patient")}
                            </MenuItem>
                            <MenuItem key={2} value={"caregiver"}>
                              {props.t("notification.caregiver")}
                            </MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* ------------------------------------------------------------------------------------- */}

                    {/* These Are selection button on Reminder to where to show that Reminder */}
                    <Grid container>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormGroup
                          row={true}
                          // style={{ justifyContent: "flex-end" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                        templates._id &&
                                      el.visibleOn.includes("home")
                                  )
                                }
                                disabled={
                                  !!!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                      templates._id
                                  )
                                }
                                onChange={(event) =>
                                  handleVisibleCheckChange(
                                    event,
                                    index,
                                    templates._id
                                  )
                                }
                                name="home"
                              />
                            }
                            label={props.t("notification.home")}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                        templates._id &&
                                      el.visibleOn.includes("app")
                                  )
                                }
                                disabled={
                                  !!!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                      templates._id
                                  )
                                }
                                onChange={(event) =>
                                  handleVisibleCheckChange(
                                    event,
                                    index,
                                    templates._id
                                  )
                                }
                                name="app"
                              />
                            }
                            label={props.t("notification.app")}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                        templates._id &&
                                      el.visibleOn.includes("email")
                                  )
                                }
                                disabled={
                                  !!!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                      templates._id
                                  )
                                }
                                onChange={(event) =>
                                  handleVisibleCheckChange(
                                    event,
                                    index,
                                    templates._id
                                  )
                                }
                                name="email"
                              />
                            }
                            label={props.t("notification.email")}
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                        templates._id &&
                                      el.visibleOn.includes("sms")
                                  )
                                }
                                disabled={
                                  !!!userNotificationData.find(
                                    (el) =>
                                      el.notificationTemplateId ===
                                      templates._id
                                  )
                                }
                                onChange={(event) =>
                                  handleVisibleCheckChange(
                                    event,
                                    index,
                                    templates._id
                                  )
                                }
                                name="sms"
                              />
                            }
                            label={props.t("notification.sms")}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                // checked={customMessage.visibleOn.smartDevice}
                                // onChange={(event) =>
                                //   handleCustomVisibleCheckChange(event)
                                // }
                                name="smartDevice"
                              />
                            }
                            label={props.t("notification.smartDevice")}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        justify="space-evenly"
                      >
                        <Button
                          onClick={() => handleReminderDeleteForm(templates)}
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => handleReminderEditForm(templates)}
                        >
                          Edit
                        </Button>
                        <Button onClick={() => sendToserver()}>Save</Button>
                      </Grid>
                    </Grid>
                    {/* ------------------------------------------- */}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <SlideInDialog
          title={props.t("patientAccess.createReminder")}
          contentComponent={
            <CreateReminderTemplates
              setSlideInDialog={handleReminderForm}
              rowDetails={props.rowDetails}
              getNotifications={getNotifications}
              isEditing={false}
              formData={formData}
              setFormData={setFormData}
            />
          }
          fullWidth={true}
          maxWidth={"md"}
          dialogActionsStatus={false}
          slideInDialog={openReminderCreationForm}
          setSlideInDialog={handleReminderForm}
          // handleDeleteRequest={handleDeleteRequest}
        />
        {/* -----------------------Delete Modal------------------------ */}
        <SlideInDialog
          title={props.t("patientAccess.DeleteReminderTemplate")}
          contentComponent={
            <ReminderDeleteForm
              setSlideInDialog={handleDeleteForm}
              rowDetails={templateId}
              getNotifications={getNotifications}
            />
          }
          fullWidth={true}
          maxWidth={"md"}
          dialogActionsStatus={false}
          slideInDialog={openReminderDeleteForm}
          setSlideInDialog={handleDeleteForm}
          // handleDeleteRequest={handleDeleteRequest}
        />
        {/* -----------------------Edit Modal------------------------ */}
        <SlideInDialog
          title={props.t("patientAccess.editReminderTemplate")}
          contentComponent={
            <CreateReminderTemplates
              setSlideInDialog={handleEditForm}
              rowDetails={props.rowDetails}
              templateId={templateId}
              getNotifications={getNotifications}
              isEditing={true}
              formData={formData}
              setFormData={setFormData}
            />
          }
          fullWidth={true}
          maxWidth={"md"}
          dialogActionsStatus={false}
          slideInDialog={openReminderEditForm}
          setSlideInDialog={handleEditForm}
        />
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(ReminderNotification)
);
