import React from 'react';
import {
  Grid,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Divider,
  Typography,
  Select,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  Avatar,
  Button,
  Box,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  dialogContext: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.info.light,
    padding: '20px',
  },
  notesLabel: {
    fontSize: 18,
  },
  notesData: {
    marginLeft: 25,
    fontSize: 16,
  },
}));

function Notes(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        scroll={'paper'}
        open={props.open}
        onClose={props.handleSlideInDialog}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id='max-width-dialog-title'>
          {props.notes.caseId + ' ' + props.t('case.notes')}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {props.notes.diagnosis !== '' ? (
            <DialogContentText
              className={classes.dialogContext}
              style={{
                overflow: 'auto',
              }}
            >
              <Typography
                variant='p'
                className={classes.notesLabel}
                component='h6'
              >
                {props.t('case.diagnosis')} :
              </Typography>
              <Typography
                variant='p'
                className={classes.notesData}
                component='p'
              >
                {props.notes.diagnosis}
              </Typography>
            </DialogContentText>
          ) : (
            <></>
          )}
          {props.notes.doctorInstructions !== '' ? (
            <DialogContentText className={classes.dialogContext}>
              <Typography
                variant='p'
                className={classes.notesLabel}
                component='h6'
              >
                {props.t('case.doctorInstructions')} :
              </Typography>
              <Typography
                variant='p'
                className={classes.notesData}
                component='p'
              >
                {props.notes.doctorInstructions}
              </Typography>
            </DialogContentText>
          ) : (
            <></>
          )}
          {props.notes.pharmaInstructions !== '' ? (
            <DialogContentText className={classes.dialogContext}>
              <Typography
                variant='p'
                className={classes.notesLabel}
                component='h6'
              >
                {props.t('case.pharmaInstructions')} :
              </Typography>
              <Typography
                variant='p'
                className={classes.notesData}
                component='p'
              >
                {props.notes.pharmaInstructions}
              </Typography>
            </DialogContentText>
          ) : (
            <></>
          )}
          {props.notes.remark !== '' ? (
            <DialogContentText className={classes.dialogContext}>
              <Typography
                variant='p'
                className={classes.notesLabel}
                component='h6'
              >
                {props.t('case.remark')} :
              </Typography>
              <Typography
                variant='p'
                className={classes.notesData}
                component='p'
              >
                {props.notes.remark}
              </Typography>
            </DialogContentText>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleSlideInDialog} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withTranslation('translations')(Notes);
