import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
  FormGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  InputLabel,
  Snackbar,
} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import LocalStorage from "../../../../../config/LocalStorage";
import { withTranslation } from "react-i18next";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { Label } from "@material-ui/icons";

const ScheduleSessionModal = (props) => {
  //state for selectedRole
  const [selectedRole, setSelectedRole] = useState("");
  const [listTemplates, setListTemplate] = useState([]);
  const [interviewSetToOptions, setInterviewSetToOptions] = useState([]); //Interviewer option will contain all interviewers for selected users
  const [dateAndTime, setDateAndTime] = useState({
    time: "",
    date: "",
  });
  const [appointmentCreateData, setAppointmentCreateData] = useState({
    userId: props?.rowDetails?._id,
    description: "",
    title: "",
    meetLink: "",
    startDate: Math.floor(new Date().getTime() / 1000),
    endDate: "",
    status: "upcoming",
    consultToCareTeam: [],
    consultationId: "",
    visibleOn: [],
    actionType: "consultation",
    actionTo: ["patient"],
    active: "true",
  });

  //below is the api integration for listing the template

  useEffect(() => {
    listTemplatesFunc();
  }, []);

  const listTemplatesFunc = async (keyword) => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getAllAppointments,
      JSON.stringify({
        keyword: keyword,
        sortOrder: "true",
        active: true,
        page: 0,
        pageSize: 20,
      })
    );
    if (response?.data?.responseCode === 109) {
      setListTemplate(response?.data?.rows);
    }
  };

  function formatEpochTime(epochTime) {
    const date = new Date(epochTime * 1000);
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const options = { day: "numeric", month: "short", year: "numeric" };
    const dateFormat = new Intl.DateTimeFormat("en-US", options);
    const dateString = dateFormat.format(date);
    return {
      time,
      date: dateString,
    };
  }

  useEffect(() => {
    const epochTime = appointmentCreateData?.startDate;
    const formattedDateTime = formatEpochTime(epochTime);
    setDateAndTime({
      ...dateAndTime,
      time: formattedDateTime.time,
      date: formattedDateTime.date,
    });
  }, [appointmentCreateData?.startDate]);

  const getInterviewersList = (event, data) => {
    if (!appointmentCreateData?.userId) {
      return;
    }

    const URL = ConfigAPIURL.getCareTeamLists;
    APIRequest.request(
      "POST",
      URL,
      JSON.stringify({
        userId: appointmentCreateData?.userId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setInterviewSetToOptions(response?.data?.result?.careTeam);
        }
      }
    });
  };

  const handleStartAtDateChange = (date) => {
    const unixTimestamp = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
    setAppointmentCreateData({
      ...appointmentCreateData,
      startDate: unixTimestamp,
    });
  };

  useEffect(() => {
    getInterviewersList();
  }, [appointmentCreateData?.userId]);

  const sendToServer = () => {
    let url = ConfigAPIURL.createSession;
    let method = "POST";

    const payload = {
      ...appointmentCreateData,
      ...dateAndTime,
    };

    if (appointmentCreateData?.consultToCareTeam?.length === 0) {
      return SnackbarUtils.error(
        "Please select atleast one careteam member",
        "bottomCenter"
      ).then((notification) => {
        props.publishNotifications(notification);
      });
    }

    // Removed form validation here
    APIRequest.request(method, url, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotifications(notification);
            });

            props.getSessions();
            props.handleSlideInDialog();
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotifications(notification);
            });
          }
          if (response.code === 100 && response.data.responseCode === 125) {
            SnackbarUtils.error(
              response?.data?.message,
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotifications(notification);
            });
          }
        }
      }
    );
  };

  const handleAutocompleteChange = (event, data) => {
    setAppointmentCreateData({
      ...appointmentCreateData,
      title: data?.title || "",
      description: data?.description || "",
      meetLink: data?.meetLink || "",
      consultationId: data?._id || "",
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper elevation={1} style={{ padding: "20px" }}>
            <Grid container>
              <Grid xl={4} lg={4} md={12} sm={12} xs={12}>
                <Autocomplete
                  id="userId"
                  options={listTemplates}
                  getOptionLabel={(option) => option.title}
                  // onClose={() => setListTemplate([])}
                  onInput={(event) => {
                    listTemplatesFunc(event.target.value);
                  }}
                  value={
                    (appointmentCreateData.title &&
                      listTemplates.find(
                        (item) => item.title === appointmentCreateData.title
                      )) ||
                    null
                  }
                  onChange={handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Title"
                      variant="standard"
                      value={appointmentCreateData.title}
                    />
                  )}
                />
              </Grid>

              <Grid
                xl={8}
                lg={8}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "-2px" }}
              >
                <TextField
                  disabled
                  id="description"
                  label={props.t("advertisements.description")}
                  autoComplete="off"
                  multiline
                  fullWidth
                  value={appointmentCreateData.description}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      description: event.target.value,
                    })
                  }
                />
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "1rem" }}
              >
                <Autocomplete
                  id="userId"
                  multiple={true}
                  options={interviewSetToOptions}
                  getOptionLabel={(option) => {
                    return option.fname + " " + option.lname;
                  }}
                  onInputChange={getInterviewersList}
                  onClose={() => setInterviewSetToOptions([])}
                  // value={appointmentCreateData?.consultToName}
                  onChange={(event, data) => {
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      consultToCareTeam: data?.[0]?._id,
                    });
                  }}
                  // disabled={!appointmentCreateData.userId || !selectedRole}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.t("sessions.searchAppointmentTo")}
                      variant="standard"
                      value={appointmentCreateData.consultToCareTeam}
                    />
                  )}
                />
              </Grid>

              <Grid xl={4} lg={4} md={6} sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDateTimePicker
                      animateYearScrolling={false}
                      variant="dialog"
                      required
                      fullWidth
                      autoOk={true}
                      margin="normal"
                      id="startDate"
                      label={props.t("advertisements.startat")}
                      value={new Date(appointmentCreateData.startDate * 1000)} // Convert Unix timestamp to milliseconds
                      onChange={(date) => {
                        handleStartAtDateChange(date);
                      }}
                      format="MMM do, yyyy hh:mm a" // Set the desired date format
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={6}
                sm={6}
                xs={12}
                style={{ marginTop: "15px" }}
              >
                <TextField
                  id="status"
                  label={props.t("sessions.status")}
                  select
                  required
                  disabled
                  fullWidth
                  defaultValue={appointmentCreateData.status}
                  value={appointmentCreateData.status}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      status: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"upcoming"}>
                    Upcoming
                  </MenuItem>
                  <MenuItem key={0} value={"completed"}>
                    Completed
                  </MenuItem>
                  <MenuItem key={0} value={"cancelled"}>
                    Cancelled
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                // style={{ marginTop: "3rem" }}
              >
                <TextField
                  disabled
                  id="siteLink"
                  label={props.t("sessions.meetlink")}
                  autoComplete="off"
                  required
                  fullWidth
                  value={appointmentCreateData.meetLink}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      meetLink: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
                <FormGroup row={true}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appointmentCreateData.visibleOn.includes(
                          "home"
                        )}
                        onChange={(event) =>
                          setAppointmentCreateData({
                            ...appointmentCreateData,
                            visibleOn: event.target.checked
                              ? [...appointmentCreateData.visibleOn, "home"]
                              : appointmentCreateData.visibleOn.filter(
                                  (item) => item !== "home"
                                ),
                          })
                        }
                        name="home"
                      />
                    }
                    label={props.t("notification.home")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appointmentCreateData.visibleOn.includes(
                          "app"
                        )}
                        onChange={(event) =>
                          setAppointmentCreateData({
                            ...appointmentCreateData,
                            visibleOn: event.target.checked
                              ? [...appointmentCreateData.visibleOn, "app"]
                              : appointmentCreateData.visibleOn.filter(
                                  (item) => item !== "app"
                                ),
                          })
                        }
                        name="app"
                      />
                    }
                    label={props.t("notification.app")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appointmentCreateData.visibleOn.includes(
                          "email"
                        )}
                        onChange={(event) =>
                          setAppointmentCreateData({
                            ...appointmentCreateData,
                            visibleOn: event.target.checked
                              ? [...appointmentCreateData.visibleOn, "email"]
                              : appointmentCreateData.visibleOn.filter(
                                  (item) => item !== "email"
                                ),
                          })
                        }
                        name="email"
                      />
                    }
                    label={props.t("notification.email")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appointmentCreateData.visibleOn.includes(
                          "sms"
                        )}
                        onChange={(event) =>
                          setAppointmentCreateData({
                            ...appointmentCreateData,
                            visibleOn: event.target.checked
                              ? [...appointmentCreateData.visibleOn, "sms"]
                              : appointmentCreateData.visibleOn.filter(
                                  (item) => item !== "sms"
                                ),
                          })
                        }
                        name="sms"
                      />
                    }
                    label={props.t("notification.sms")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appointmentCreateData.visibleOn.includes(
                          "smartDevice"
                        )}
                        onChange={(event) =>
                          setAppointmentCreateData({
                            ...appointmentCreateData,
                            visibleOn: event.target.checked
                              ? [
                                  ...appointmentCreateData.visibleOn,
                                  "smartDevice",
                                ]
                              : appointmentCreateData.visibleOn.filter(
                                  (item) => item !== "smartDevice"
                                ),
                          })
                        }
                        disabled
                        name="smartDevice"
                      />
                    }
                    label={props.t("notification.smartDevice")}
                  />
                </FormGroup>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {/* <TextField
                  label={props.t("case.actionTo")}
                  select
                  required
                  fullWidth
                  value={appointmentCreateData.actionTo}
                  name="actionTo"
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      actionTo: event.target.value,
                    })
                  }
                  style={{ marginBottom: 10 }}
                >
                  <MenuItem key={0} value={"both"}>
                    {props.t("notification.both")}
                  </MenuItem>
                  <MenuItem key={1} value={"patient"}>
                    {props.t("notification.patient")}
                  </MenuItem>
                  <MenuItem key={2} value={"caregiver"}>
                    {props.t("notification.caregiver")}
                  </MenuItem>
                  <MenuItem key={2} value={"Careteam"}>
                    Careteam
                  </MenuItem>
                  <MenuItem key={2} value={"both careteam and patient"}>
                    Both careteam and patient
                  </MenuItem>
                  <MenuItem key={2} value={"both careteam and caregiver"}>
                    Both careteam and caregiver
                  </MenuItem>
                  <MenuItem key={2} value={"everyone"}>
                    Everyone
                  </MenuItem>
                </TextField> */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.actionTo.includes(
                            "patient"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              actionTo: event.target.checked
                                ? [...appointmentCreateData.actionTo, "patient"]
                                : appointmentCreateData.actionTo.filter(
                                    (item) => item !== "patient"
                                  ),
                            })
                          }
                          name="patient"
                        />
                      }
                      label="Patient"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.actionTo.includes(
                            "caregiver"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              actionTo: event.target.checked
                                ? [
                                    ...appointmentCreateData.actionTo,
                                    "caregiver",
                                  ]
                                : appointmentCreateData.actionTo.filter(
                                    (item) => item !== "caregiver"
                                  ),
                            })
                          }
                          name="caregiver"
                        />
                      }
                      label="Caregiver"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointmentCreateData.actionTo.includes(
                            "careteam"
                          )}
                          onChange={(event) =>
                            setAppointmentCreateData({
                              ...appointmentCreateData,
                              actionTo: event.target.checked
                                ? [
                                    ...appointmentCreateData.actionTo,
                                    "careteam",
                                  ]
                                : appointmentCreateData.actionTo.filter(
                                    (item) => item !== "careteam"
                                  ),
                            })
                          }
                          name="careteam"
                        />
                      }
                      label="Careteam"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              {/* ----------------------------------------------------------- */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button onClick={props.handleSlideInDialog}>
                  {props.t("buttons.close")}
                </Button>
                <Button onClick={sendToServer}>
                  {props.t("buttons.create")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default withTranslation("translations")(ScheduleSessionModal);
