import React, { useEffect, useState } from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";

const EditForm = (props) => {
  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      updateSessionEditDetails();
    }
  }, [props.formDialog]);

  const getEditable = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getConsultationTemplateDetails,
      JSON.stringify({
        consultationId: props.rowDetails?._id,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response?.code === 100 && response?.data?.responseCode === 109) {
          let userResult = response?.data?.result;
          console.log(userResult);

          setAppointmentCreateData({
            ...appointmentCreateData,
            title: userResult?.title,
            description: userResult?.description,
            meetLink: userResult?.meetLink,
            active: userResult?.active,
            consultationId: props.rowDetails?._id,
          });
        }
      }
    });
  };

  const [listUsers, setListUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [interviewSetToOptions, setInterviewSetToOptions] = useState([]);
  const [dateAndTime, setDateAndTime] = useState({
    time: "",
    date: "",
  });
  const [appointmentCreateData, setAppointmentCreateData] = React.useState({
    userId: "",
    description: "",
    title: "",
    meetLink: "",
    startDate: Math.floor(new Date().getTime() / 1000),
    endDate: "",
    status: "upcoming",
    consultToCareTeam: [],
    consultationId: "",
    visibleOn: [],
    actionType: "consultation",
    actionTo: "",
    active: "true",
  });

  useEffect(() => {
    listUserFunc();
    getEditable();
  }, []);

  function formatEpochTime(epochTime) {
    const date = new Date(epochTime * 1000);
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const options = { day: "numeric", month: "short", year: "numeric" };
    const dateFormat = new Intl.DateTimeFormat("en-US", options);
    const dateString = dateFormat.format(date);
    return {
      time,
      date: dateString,
    };
  }

  useEffect(() => {
    const epochTime = appointmentCreateData?.startDate;
    const formattedDateTime = formatEpochTime(epochTime);
    setDateAndTime({
      ...dateAndTime,
      time: formattedDateTime.time,
      date: formattedDateTime.date,
    });
  }, [appointmentCreateData?.startDate]);

  const listUserFunc = async (keyword) => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.userTableURL,
      JSON.stringify({
        keyword: keyword,
        sortOrder: "true",
        active: true,
        page: 0,
        pageSize: 20,
        userType: "user",
      })
    );
    if (response?.data?.responseCode === 109) {
      setListUsers(response?.data?.rows);
    }
  };

  const getInterviewersList = (event, data) => {
    if (!appointmentCreateData?.userId) {
      return;
    }

    const URL = ConfigAPIURL.getCareTeamLists;
    APIRequest.request(
      "POST",
      URL,
      JSON.stringify({
        userId: appointmentCreateData?.userId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setInterviewSetToOptions(response?.data?.result?.careTeam);
        }
      }
    });
  };

  const handleStartAtDateChange = (date) => {
    const unixTimestamp = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
    setAppointmentCreateData({
      ...appointmentCreateData,
      startDate: unixTimestamp,
    });
  };

  useEffect(() => {
    getInterviewersList();
  }, [appointmentCreateData?.userId]);

  // const getsessionEditPage = () => {
  //   setAppointmentCreateData((prev) => {
  //     return {
  //       ...prev,
  //       title: props?.rowDetails?.consultation?.consultationId?.title,
  //       description:
  //         props?.rowDetails?.consultation?.consultationId?.description,
  //       meetLink: props?.rowDetails?.consultation?.consultationId?.meetLink,
  //       consultationId: props?.rowDetails?.consultation?.consultationId?._id,
  //     };
  //   });
  // };

  const updateSessionEditDetails = () => {
    delete appointmentCreateData?.rating;
    const URL = ConfigAPIURL.editSessionbyid;
    APIRequest.request("POST", URL, JSON.stringify(appointmentCreateData)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              "Session Updated Successfully",
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              // userType: "all",
              sortOrder: "false",
              active: "true",
            });
          }
        }
      }
    );
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.createSession;
    let method = "POST";

    const payload = {
      ...appointmentCreateData,
      ...dateAndTime,
    };

    if (appointmentCreateData?.consultToCareTeam?.length === 0) {
      return SnackbarUtils.error(
        "Please select atleast one careteam member",
        "bottomCenter"
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }

    // Removed form validation here
    APIRequest.request(method, url, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              // userType: "all",
              sortOrder: "false",
              active: "true",
            });
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            // props.getSessions();
            props.handleFormDialog();
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
          if (response.code === 100 && response.data.responseCode === 125) {
            SnackbarUtils.error(
              response?.data?.message,
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  console.log(props, "from assiugn funtion page");

  return {
    appointmentCreateData,
    setAppointmentCreateData,
    listUsers,
    interviewSetToOptions,
    handleStartAtDateChange,
    setListUsers,
    getInterviewersList,
    setInterviewSetToOptions,
    updateSessionEditDetails,
    listUserFunc,
    userName,
    setUserName,
    sendToServer,
  };
};

export default EditForm;
