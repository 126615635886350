import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Article from "./SEO/Article";
import Event from "./SEO/Event";
import FAQPage from "./SEO/FAQPage";
import WebSite from "./SEO/WebSite";
function SiteDataWithSEO(props) {
  const _SEO = (seoType, seoDetails) => {
    switch (seoType) {
      case "Artical":
        return JSON.stringify(Article.seoFormatter(seoDetails));
      case "Event":
        return JSON.stringify(Event.seoFormatter(seoDetails));
      case "FAQPage":
        return JSON.stringify(FAQPage.seoFormatter(seoDetails));
      case "WebSite":
        return JSON.stringify(WebSite.seoFormatter(seoDetails));
      default:
        return "";
    }
  };
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta name="title" content={props.title} />
      <meta property="og:title" content={props.title} />
      <meta name="description" content={props.description} />
      <meta name="og:description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta property="og:site_name" content={props.site_name} />
      <meta property="og:url" content={props.url} />
      <meta property="twitter:card" content={props.twitterCard} />
      <meta property="twitter:url" content={props.url} />
      <meta property="twitter:title" content={props.title} />
      <meta property="twitter:description" content={props.description} />
      <meta property="og:image" content={props.imageURL} />
      <meta property="og:image:secure_url" content={props.imageURL} />
      <link rel="image_src" href={props.imageURL} />
      <meta property="og:type" content={props.keywords} />
      <link rel="canonical" href={props.canonicalURL} />
      {props.seoRequired ? (
        <script type="application/ld+json">{`${_SEO(
          props.seoType,
          props.seoDetails
        )}`}</script>
      ) : (
        <script />
      )}
    </Helmet>
  );
}
SiteDataWithSEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  site_name: PropTypes.string,
  url: PropTypes.string.isRequired,
  twitterCard: PropTypes.oneOf(["summary_large_image", "summary"]),
  imageURL: PropTypes.string.isRequired,
  canonicalURL: PropTypes.string,
  seoRequired: PropTypes.bool.isRequired,
  seoType: PropTypes.oneOf(["Artical", "Event", "FAQPage", "WebSite"]),
  seoDetails: PropTypes.object,
};
SiteDataWithSEO.defaultProps = {
  title: "WiseRx",
  description: "WiseRx eHealth Application.",
  keywords: "WiseRx Chatbot",
  site_name: "WiseRx",
  url: "http://www.wiserx.co/",
  twitterCard: "summary_large_image",
  imageURL:
    "https://davaguide.s3.ap-south-1.amazonaws.com/wiserx.jpg",
  canonicalURL: "",
  seoRequired: false,
  seoType: "",
  seoDetails: {},
};

export default SiteDataWithSEO;
