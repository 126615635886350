import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Card,
  Button,
  CardHeader,
  Divider,
  Tooltip,
  Link,
  IconButton,
  Box,
  Typography,
  CardContent,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DateConversion from '../../../../../utils/DateConversion';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
  },
  chatReceiverMessages: {
    width: '80%',
    margin: '15px 0px',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  chatSenderMessages: {
    width: '80%',
    margin: '15px 0px',
    backgroundColor: theme.palette.primary.light,
  },
}));

function Notes(props) {
  const classes = useStyles();

  const [notificationMessages, setNotificationMessages] = useState([]);

  const [openNotifications, setOpenNotifications] = useState(false);

  const handleMessageTitles = (selected) => {
    setOpenNotifications(true);
    setNotificationMessages(selected);
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardHeader
              avatar={
                openNotifications ? (
                  <Tooltip title={props.t('buttons.back')}>
                    <IconButton
                      aria-label='settings'
                      onClick={() => setOpenNotifications(false)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )
              }
              title={
                <Typography
                  align={openNotifications ? 'right' : 'center'}
                  variant='p'
                  style={{ fontWeight: 'bold' }}
                  component='p'
                >
                  {props.t('user.notesOrComments')}
                </Typography>
              }
            />
            <Divider />
            <div>
              {openNotifications === false ? (
                <CardContent style={{ height: '450px', overflow: 'auto' }}>
                  {props.notesList.length === 0 ? (
                    <Card
                      style={{
                        marginTop: '15px',
                        paddingTop: '15px',
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              align='center'
                              variant='p'
                              component='p'
                            >
                              No Record Found
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    props.notesList.map((notifications, index) => (
                      <div style={{ marginTop: '15px' }} key={index}>
                        <Typography
                          align='center'
                          style={{ fontWeight: 'bold' }}
                          variant='p'
                          component='p'
                        >
                          {DateConversion.todayOrReturnDate(
                            notifications.updatedAt
                          )}
                        </Typography>
                        {notifications.userNotes.map((notes, idx) => (
                          <Card
                            key={idx}
                            style={{
                              marginTop: '15px',
                              paddingTop: '15px',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleMessageTitles(notes)}
                          >
                            <CardContent>
                              <Grid container>
                                <Grid xl={10} lg={10} md={10} sm={12} xs={12}>
                                  <Typography
                                    align='center'
                                    variant='p'
                                    component='p'
                                  >
                                    {notes.label +
                                      ' ' +
                                      DateConversion.unixToLocalDate12HourTime(
                                        notes.updatedAt
                                      )}
                                  </Typography>
                                </Grid>
                                <Grid
                                  xl={2}
                                  lg={2}
                                  md={2}
                                  sm={12}
                                  xs={12}
                                  style={{ marginTop: '-12px' }}
                                >
                                  <Tooltip title={props.t('buttons.open')}>
                                    <IconButton aria-label='view'>
                                      <ArrowForwardIosIcon variant='contained' />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    ))
                  )}
                </CardContent>
              ) : (
                <CardContent style={{ height: '450px', overflow: 'auto' }}>
                  <div style={{ marginTop: '15px' }}>
                    <Typography
                      style={{ fontWeight: 'bold' }}
                      variant='p'
                      align='left'
                      component='p'
                    >
                      {props.t('notification.enteredBy')}
                    </Typography>

                    <Typography variant='p' component='p' align='left'>
                      {notificationMessages.senderfname}{' '}
                      {notificationMessages.senderlname}
                    </Typography>
                    <Typography
                      style={{ fontWeight: 'bold', marginTop: '15px' }}
                      variant='p'
                      align='left'
                      component='p'
                    >
                      {props.t('user.notesOrComments')}
                    </Typography>
                    <Typography
                      style={{ marginTop: '15px' }}
                      variant='p'
                      align='left'
                      component='p'
                    >
                      {notificationMessages.note}
                    </Typography>

                    <Typography
                      align='right'
                      style={{ fontWeight: 'bold', marginTop: '15px' }}
                      variant='p'
                      component='p'
                    >
                      {DateConversion.todayOrReturnDate(
                        notificationMessages.updatedAt
                      )}
                    </Typography>
                  </div>
                </CardContent>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation('translations')(Notes);
