import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ExcelCSVUploadHistroy from '../../../../../templates/excelcsvuploadhistroy/ExcelCSVUploadHistroy'
import ConfigAPIURL from '../../../../../config/ConfigAPIURL'
import SnackbarUtils from '../../../../../utils/SnackbarUtils'
const UploadStockHeader = ['title', 'productSku', 'available']
function UploadStock(props) {
  const [bucketName] = React.useState('inventory')
  const [uploadTableHeader] = React.useState(UploadStockHeader)
  const [fileName] = React.useState([
    'Inventory_Stock_Template.xlsx',
    'Inventory_Stock_Template.csv',
  ])
  const [uploadUrl] = React.useState(ConfigAPIURL.inventoryUploadStock)
  const [uploadHistoryUrl] = React.useState('')
  const notification = (message, type) => {
    SnackbarUtils[type](message, 'bottomCenter', 3000).then((notification) => {
      props.publishNotification(notification)
    })
  }
  return (
    <ExcelCSVUploadHistroy
      fileName={fileName}
      uploadTableHeader={uploadTableHeader}
      uploadUrl={uploadUrl}
      uploadHistoryUrl={uploadHistoryUrl}
      bucketName={bucketName}
      notification={notification}
      menuName={'inventory'}
      operationType={'uploadInventoryStock'}
    />
  )
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  }
}
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(UploadStock),
)
