import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import DataTable from "../../../../templates/tables/DataTable";
import AssignSession from "./AssignSession";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
  FormGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  InputLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import TableHeader from "./TableRelated/TableHeader";
import { connect } from "react-redux";
import { MdDeleteSweep, MdCloudUpload } from "react-icons/md";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalStorage from "../../../../config/LocalStorage";
import FileUploadTableCheckBox from "../../../../templates/FileUploadTableCheckBox";
import FileUpload from "../../../../templates/FileUpload";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import FormValidation from "../../../../utils/FormValidation";
import Toaster from "../../../../utils/Toaster";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormLabel from "@material-ui/core/FormLabel";
import imageCompression from "browser-image-compression";
import Rating from "@material-ui/lab/Rating";
import AevForm from "./hooks/useAevForm";

import { IoIosInformationCircle } from "react-icons/io";
import TemplateTable from "../../../../templates/tables/TemplateTable";
import TableButtons from "../../../../templates/buttons/TableButtons";
import FormDialog from "../../../../templates/dialog/FormDialog";
import EditFormTemplate from "./EditFormTemplate";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import TableQuery from "./TableRelated/TableQuery";
import MacrosDisplay from "./MacrosDisplay";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));

function AEVForm(props) {
  const classes = useStyles();
  const [rowDetails, setRowDetails] = useState(null);
  const [buttonList, setButtonDetails] = useState([]);
  const [slideInDialog, setSlideInDialog] = useState(false);
  const [openMacroForm, setOpenMacroForm] = useState(false);

  const handleMacroForm = () => {
    setOpenMacroForm(false);
  };
  const handleOpenMacroForm = () => {
    setOpenMacroForm(true);
  };

 

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });

  const {
    addForm,
    appointmentCreateData,
    setAppointmentCreateData,
    tableData,
    query,
    setQuery,
    resetQuery,
    handleQuery,
    getTableData,
    sendToServer,
    setTableData,
    getEditable,
  } = AevForm(props);

  useEffect(() => {
    getTableData(query);
  }, []);
  useEffect(() => {
    getTableData(query);
  }, [query]);

  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
  };
  // useful of on page load
  useEffect(() => {
    // tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);
  const handleFormDialog = () => {
    setRowDetails(null);
    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    getTableData(query);
  };

  //console.log("this is button", LocalStorage.adminButtonPermission);

  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      //console.log("i am inside edit func");
      // getEditable(rowDetails);
      setOpenForm({
        status: true,
        title: props.t("sessions.editSession"),
        divType: "edit",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === "query" ? true : false;
      })
    ) {
      const url = `${ConfigAPIURL.listSessionTemplate}`;
      APIRequest.request("POST", url, JSON.stringify({ ...query })).then(
        (tableData) => {
          setTableData(tableData?.data);
        }
      );
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };

  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (query.active === true) {
        setSlideInDialog(true);
      } else {
        SnackbarUtils.error(
          props.t("snackbar.recordAlreadyDeleted"),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    getTableData(query);
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "DELETE",
        ConfigAPIURL.deleteConsultationTemplate,
        JSON.stringify({ consultationId: rowDetails[0] })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.deleteSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
    }
  };

  const tableAssign = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: true,
        title: "Assign Session",
        divType: "assign",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  //console.log(props,"from here")
  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="title"
                  label={props.t("advertisements.title")}
                  autoComplete="off"
                  required
                  value={appointmentCreateData.title}
                  fullWidth
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      title: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid
                xl={8}
                lg={8}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "-2px" }}
                className={classes.formGrid}
              >
                <TextField
                  id="description"
                  label={props.t("advertisements.description")}
                  autoComplete="off"
                  multiline
                  fullWidth
                  value={appointmentCreateData.description}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      description: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>

              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                style={{ marginTop: "15px" }}
              >
                <TextField
                  id="status"
                  label="Active"
                  select
                  required
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={appointmentCreateData.active}
                  value={appointmentCreateData.active}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    Active
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    Inactive
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
                style={{ marginTop: "15px" }}
              >
                <TextField
                  id="siteLink"
                  label={props.t("sessions.meetlink")}
                  autoComplete="off"
                  required
                  fullWidth
                  value={appointmentCreateData.meetLink}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      meetLink: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button fullWidth onClick={handleOpenMacroForm}>
                {props.t("patientAccess.viewMacros")}
              </Button>
            </Grid>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button color="primary" onClick={sendToServer}>
                Create
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid container style={{ height: "100%" }}>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: "10px" }}
          >
            <TemplateTable
              tableHeader={TableHeader.tableHeader} // table column header
              tableData={tableData} // pass table record
              query={query} // pass query condition
              setQuery={setQuery} // update query fields
              resetQuery={resetQuery} // reset filter menu values
              selectAll={false} //select all check box props
              tableCallBack={tableCallBack}
              tableDBLCallBack={()=>{
                console.log("double click")
              }}
              searchLabel={"Search by title"}
              buttonComponent={
                <TableButtons
                  tableEdit={tableEdit}
                  tableDelete={tableDelete}
                  tableAssign={tableAssign}
                  buttonList={buttonList?.filter(
                    (button) => button.button !== "add"
                  )}
                />
              }
              queryComponent={<TableQuery query={query} setQuery={setQuery} />}
            />
          </Grid>
        </Grid>
      </Grid>
      {openForm?.divType === "edit" && (
        <FormDialog
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <EditFormTemplate
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails}
              tableQuery={tableQuery}
            />
          }
        />
      )}
      {openForm?.divType === "assign" && (
        <FormDialog
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          showUpdateLabel={false}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <AssignSession
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails}
              tableQuery={tableQuery}
              publishNotification={props.publishNotification}
            />
          }
        />
      )}
      <SlideInDialog
        title={props.t("sessions.deleteSessionTemplate")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
      <SlideInDialog
        title="Macros to personalize session"
        contentComponent={
          <MacrosDisplay
            setSlideInDialog={handleMacroForm}
          />
        }
        fullWidth={true}
        maxWidth={"md"}
        dialogActionsStatus={false}
        slideInDialog={openMacroForm}
        setSlideInDialog={handleMacroForm}
        // handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
