import React from "react";
import { Grid, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";

const NotificationDeleteForm = (props) => {
  const deleteRemindertemplates = () => {
    APIRequest.request(
      "DELETE",
      ConfigAPIURL.deleteReminderTemplate,
      JSON.stringify({ _id: props.rowDetails })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response?.data?.responseCode !== undefined &&
          response?.data?.responseCode !== null
        ) {
          if (response?.data?.responseCode === 109) {
            // Transform API response into desired format
            props.setSlideInDialog();
            props.getNotifications();
          }
        }
      }
    });
  };
  return (
    <div>
      <Typography
        color="textSecondary"
        gutterBottom
        style={{
          fontWeight: "600",
          fontSize: "18px",
        }}
      >
        Are you sure you want to Delete this Template?
      </Typography>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={props.setSlideInDialog}>
          {props.t("buttons.close")}
        </Button>
        <Button onClick={deleteRemindertemplates}>
          {props.t("buttons.delete")}
        </Button>
      </Grid>
    </div>
  );
};
export default withTranslation("translations")(NotificationDeleteForm);
