import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Surface,
  Symbols,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Card, Grid, CardContent } from "@material-ui/core";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card className="custom-tooltip">
        <CardContent>
          <p className="label">{`${label}`}</p>

          <p className="label" style={{ color: "#f56f42" }}>
            {payload[0]?.payload?.new !== undefined ? (
              <div> Open: {`${payload[0]?.payload.new}`}</div>
            ) : (
              ""
            )}
          </p>

          <p className="label" style={{ color: "#82ca9d" }}>
            {payload[0]?.payload?.underReview !== undefined ? (
              <div> Assigned: {`${payload[0]?.payload.underReview}`}</div>
            ) : (
              ""
            )}
          </p>

          <p className="label" style={{ color: "#8884d8" }}>
            {payload[0]?.payload?.processed !== undefined ? (
              <div> Closed: {`${payload[0]?.payload.processed}`}</div>
            ) : (
              ""
            )}
          </p>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default class Example extends PureComponent {
  renderCusomizedLegend = (props) => {
    const { payload } = props;
    return (
      <Grid
        container
        style={{
          // display: 'flex',
          // justifyContent: 'space-between',
          marginLeft: "60px",
        }}
      >
        {payload.map((entry) => {
          const { dataKey, color } = entry;

          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              key={`overlay-${dataKey}`}
              trigger={["hover", "focus"]}
              placement="top"
              // overlay={this.renderPopoverTop(dataKey)}
            >
              <span className="legend-item">
                <Surface width={10} height={10} viewBox="0 0 10 10">
                  <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                </Surface>
                <span>
                  {dataKey === "new"
                    ? "Open"
                    : dataKey === "underReview"
                    ? "Assigned"
                    : dataKey === "processed"
                    ? "Closed"
                    : ""}
                </span>
              </span>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  render() {
    return (
      <ResponsiveContainer width={"100%"} height={350}>
        <LineChart
          width={"100%"}
          height={350}
          data={this.props.webAdminGraph}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray='3 3' /> */}
          <XAxis dataKey="_id" height={60} tick={<CustomizedAxisTick />} />
          <YAxis />
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Legend content={this.renderCusomizedLegend} />

          <Line type="monotone" connectNulls dataKey="new" stroke="#f56f42" />

          <Line
            type="monotone"
            connectNulls
            dataKey="underReview"
            stroke="#82ca9d"
          />
          <Line
            connectNulls
            type="monotone"
            dataKey="processed"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
