import React from "react";
import ChatProfile from "./chatProfile";
import { DataContext } from "../../Index";
import SendMessage from "./SendMessage";
import { Stack, Typography } from "@mui/material";
import MainChat from "./MainChatArea";
import { allImages } from "../../../../../../images";

const Index = () => {
  const {
    selectedChat,
    sendMessage,
    conversation,
    fetchMessages,
    hasMoreConverstion,
  } = React.useContext(DataContext);
  console.log(conversation);
  return (
    <>
      {selectedChat ? (
        <Stack
          justifyContent="space-between"
          className="background_image"
          style={{ height: "100%" }}
        >
          <ChatProfile selectedChat={selectedChat} />
          <MainChat
            conversation={conversation}
            selectedChat={selectedChat}
            fetchMessages={fetchMessages}
            hasMoreConverstion={hasMoreConverstion}
          />
          <Stack style={{ padding: "1rem 2rem"}}>
            <SendMessage
              sendMessage={sendMessage}
              selectedChat={selectedChat}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack
          justifyContent="space-between"
          className="background_image"
          style={{
            height: "100%",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "420px",
            opacity: "0.8",

            backgroundImage: `url(${allImages.chatBg})`,
          }}
        >
          <Stack justifyContent="end" height="100%" mb={4}>
            <Typography
              textAlign="center"
              variant="subtitle1"
              style={{ fontSize: "20px", fontWeight: 600 }}
            >
              Break the silence ignite the conversation
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Index;
