import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  CardActions,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";

import TableHeader from "./DoctorTableHeader";
import TableQuery from "./DoctorTableQuery";

import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import MedicationTableWithPagination from "../../../../templates/tables/MedicationTableWithPagination";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import CustomerViewForm from "./CustomerViewForm";
import TableWithoutSelection from "../../../../templates/tables/TableWithoutSelection";
import TableButtons from "../../../../templates/buttons/TableButtons";
import ProcessCaseMultiSelectTable from "../../../../templates/tables/ProcessCaseMultiSelectTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    marginTop: "80px",
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

// const tableHeader = [
//   {
//     id: 'itemName',
//     accessor: 'itemName',
//     dataType: 'string',
//     sortable: true,
//     disablePadding: false,
//     visibility: true,
//     width: 200,
//     label: 'user.itemName',
//     className: '',
//     functionName: '',
//   },
// ];

function DoctorDetails(props) {
  const classes = useStyles();
  const [medicationRecords, setMedicationRecords] = useState([]);
  const [filterRecords, setFilterRecords] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState({
    page: 0,
    pageSize: 100,
    sortField: "",
    sortOrder: "false",
    active: "all",
    keyword: "",
  });
  const [rowDetails, setRowDetails] = useState(null);

  const [openCustomerViewForm, setOpenCustomerViewForm] = useState(false);

  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  //console.log("query", query);

  useEffect(() => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog !== undefined &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getMedicalInfo(query);
    }
  }, [props.formDialog]);

  //   console.log("userID", props);

  const getMedicalInfo = (query) => {
    const url = `${ConfigAPIURL.getAllUserDoctors}?userId=${props.rowDetails._id}&pageNo=${query.page}&activeStatus=${query.active}&keyword=${query.keyword}`;
    APIRequest.request("GET", url).then((response) => {
      let serverResponse = response.data.result;
      setTableData({
        ...tableData,
        rows: serverResponse, // Save result data in rows property
      });
      //   setTableData(serverResponse);
    });
  };
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
    // if (rowDetails !== undefined && rowDetails !== null) {
    // }
  };

  const handleQuery = (query) => {
    setQuery(query);
    getMedicalInfo(query);
  };

  const handleTableData = () => {
    getMedicalInfo(query);
  };

  const resetQuery = () => {
    setQuery({
      page: 0,
      pageSize: 100,
      sortField: "",
      sortOrder: "false",
      active: "all",
    });
    getMedicalInfo({
      page: 0,
      pageSize: 100,
      sortField: "",
      sortOrder: "false",
      active: "all",
    });
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "10px" }}
        >
          {tableData.rows && (
            // <TableWithoutSelection
            //   tableHeader={TableHeader.tableHeader}
            //   tableData={tableData}
            // ></TableWithoutSelection>
            <ProcessCaseMultiSelectTable
              tableHeader={TableHeader.tableHeader} // table column header
              tableData={tableData} // pass table record
              query={query} // pass query condition
              setQuery={handleQuery} // update query fields
              resetQuery={resetQuery} // reset filter menu values
              selectAll={false} //select all check box props
              // openForm={openForm} //un select all check box props
              // handleNotes={handleNotes}
              tableCallBack={tableCallBack}
              // tableDBLCallBack={tableDBLCallBack}
              searchLabel={props.t("case.searchLabel")}
              //   page={"processCase"}
              queryComponent={
                <TableQuery
                  query={query}
                  setQuery={handleQuery}
                  pageName={
                    props.pageName !== undefined && props.pageName !== null
                      ? props.pageName
                      : null
                  }
                />
              } //filter menu option
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation("translations")(DoctorDetails);
