import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link,
  List,
  ListItem,
  Divider,
  Container,
  Typography,
  ListItemText,
} from "@material-ui/core";
function Copyright() {
  return (
    <div>
      <Typography variant="button" display="block" gutterBottom align="center">
        Users may contact Us by email at{" "}
        <Link
          class="mailto"
          href="mailto:support@wiserx.co"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
          color="primary"
        >
          support@wiserx.co
        </Link>{" "}
        for any queries
      </Typography>
      <Typography variant="button" display="block" gutterBottom align="center">
        {"Copyright © "}
        WiseRx {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  root: {
    backgroundRepeat: "no-repeat",
    backgroundColor: "#709fe3",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  list: { backgroundColor: theme.palette.background.paper },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="lg" className="dummyPage">
            <List className={classes.list}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Privacy Policy
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WiseRx, a platform for patients and caregivers to manage
                        their doctor prescribed medicines or to seek doctors'
                        opinions, advice, and treatment plans for medical
                        conditions, is owned and operated by WiseRx Private
                        Limited. WiseRx Private Limited (“us”, “we”, or WiseRx,
                        which also includes its affiliates) is the author and
                        publisher of the internet resource www.wiserx.co
                        (“Website”) on the world wide web as well as the
                        software and applications provided by WiseRx and the
                        mobile application ‘WiseRx’, (together with the Website,
                        referred to as the “Services”). WiseRx respects the
                        privacy of its users and guests (“User” or “you” and
                        their connotations) and has developed this Privacy
                        Policy to demonstrate its commitment to protect your
                        privacy.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        This Privacy Policy informs you of our policies and
                        practices regarding the collection and use of
                        information you submit to us or which we collect through
                        our platform. By using our Website or Services, you are
                        accepting the Privacy Policy and the Terms of Service
                        and you expressly consent to our use and disclosure of
                        your information in accordance with this Privacy Policy.
                        Please read this Privacy Policy and our Terms of Use
                        carefully when using our mobile application or website
                        (together “Website”) and / or when using our medical
                        consultation services through the Website or through any
                        other medium (“Services”), and if you do not agree to
                        any of these terms, please do not use our Website or
                        Services. Wherever you reside or from whichever country
                        you submit your information, you consent to our use of
                        your information, consistent with this Privacy Policy,
                        in the territories where WiseRx provides its Services.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom m={5}>
                      DISCLAIMER & IMPORTANT INFORMATION FOR CUSTOMERS /
                      PATIENTS
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WiseRx is a service designed for non-urgent medical
                        conditions. If you think you have something that
                        requires urgent attention, please see a doctor as soon
                        as possible.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Please note that WiseRx is an electronic platform to
                        manage information provided by doctors whom you may seek
                        medical advice from. WiseRx does not endorse the medical
                        advice given by the doctors and shall not be liable for
                        the quality or consequences of advice given.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Should WiseRx offer virtual consultation services by
                        doctors as a Service, such doctors may prescribe
                        medications and products based on their assessment as
                        per information provided by you, neither the doctor nor
                        WiseRx is responsible or liable for such medical advice
                        followed by the User /patient. Any risk associated with
                        the medical advice shall remain the responsibility of
                        the User / patient at all times.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      INFORMATION WE COLLECT
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        “Personal Information” is defined under the Information
                        Technology (Reasonable Security Practices and Procedures
                        and Sensitive Personal Information) Rules, 2011 (“SPI
                        Rules”) to mean any information that relates to a
                        natural person, which, either directly or indirectly, in
                        combination with other information available or likely
                        to be available to a body corporate, is capable of
                        identifying such person.
                      </Typography>
                      <Container maxWidth="lg">
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          1. WiseRx collects information that may be used to
                          identify you including, but not limited to, (i) name,
                          mobile number, e-mail ID, photographs, location and
                          other contact information (ii) age, gender and other
                          demographics (iii) details regarding your physical /
                          mental condition, and any infirmities or illness you
                          suffer, and all the information in relation thereto
                          (you may choose to submit only the information which
                          is mandatorily required).
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          2. WiseRx also collects certain information either
                          when you provide it through the use of the Website,
                          Application or Services and / or automatically as you
                          navigate through the Services (which may include usage
                          details, IP address, device ID and type, your browser
                          type and language, the operating system used by your
                          device, access times, and information collected
                          through cookies, web beacons, GPS and other tracking
                          technologies). You agree that we may use such
                          information to do internal research on our Users'
                          demographics and medical history to better understand,
                          protect and serve our Users.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          3. We also collect information from your user ID
                          associated with any social media account (such as your
                          Facebook and Google account) that users use to sign
                          into the Website or connect with or use of our
                          Services. When you sign in with your social media
                          account information, or otherwise connect to your
                          social media account with our Website, you consent to
                          our collection, storage, and use, in accordance with
                          this Privacy Policy, of the information that you make
                          available to us through the social media interface.
                          This includes, without limitation, any information
                          that you have made public through your social media
                          account, information that the social media service
                          shares with us or information that is disclosed during
                          the sign-up and sign-in processes.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          4. If another User allows us to collect information
                          from his or her device phonebook—and you are one of
                          those User’s contacts—we may combine the information
                          we collect from that User’s phonebook with other
                          information we have collected about you. We may also
                          obtain information from other sources and combine that
                          with the information we collect through WiseRx.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                        >
                          5. We use data collection devices such as "cookies" on
                          certain pages of the Website to help analyze our web
                          page flow, measure promotional effectiveness, and
                          promote trust and safety. Cookies are small files
                          placed on your hard drive that assist us in providing
                          our Services. We offer certain features that are only
                          available through the use of a cookie. Cookies can
                          also help us provide information that is targeted to
                          your interests. You may be able to change the
                          preferences on your browser or mobile device to
                          prevent or limit your computer or device's acceptance
                          of cookies, but this may prevent you from taking
                          advantage of our Mobile App's best features.{" "}
                        </Typography>
                      </Container>

                      <Typography variant="subtitle2" gutterBottom align="left">
                        In order to provide Services, WiseRx requires certain
                        user information and may obtain the same through user
                        provided data or automatic data collection.
                      </Typography>

                      <Typography variant="subtitle2" gutterBottom align="left">
                        The information collected from you by WiseRx may
                        constitute ‘Sensitive Personal Information’ under the
                        SPI Rules which is defined as personal information about
                        that person relating to:
                      </Typography>
                      <Container maxWidth="lg">
                        <ul
                          style={{
                            listStyleType: "disc",
                            fontSize: "10px",
                          }}
                        >
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Passwords;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Financial information such as bank accounts, credit
                            and debit card details or other payment instrument
                            details;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Physical, physiological and mental health condition;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Sexual orientation;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Medical records and history;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Biometric information;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Information received by body corporate under lawful
                            contract or otherwise;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            visitor details as provided at the time of
                            registration or thereafter; and
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            call or chat data records.
                          </Typography>
                        </ul>
                      </Container>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Collection of information which has been designated as
                        Sensitive Personal Information under the SPI Rules
                        requires your express consent. By affirming your assent
                        to this Privacy Policy, you provide your consent to such
                        collection as required under applicable law.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        The reference to the term ‘Personal Information’ in this
                        Privacy Policy may also, depending on the context,
                        include within its ambit Sensitive Personal Information.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      EXTERNAL LINKS ON THE APPLICATION
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        The Application may include offers from our vendors,
                        advertisements, hyperlinks to other websites,
                        applications, content or resources. You acknowledge and
                        agree that WiseRx is not responsible for any collection
                        or disclosure of your Personal Information by any
                        external sites, applications, companies or persons, nor
                        do We endorse any advertising, products or other
                        materials on or available from such external
                        application, websites or resources.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        You further acknowledge and agree that We are not liable
                        for any loss or damage which may be incurred by You as a
                        result of the collection and/or disclosure of Your
                        Personal Information by external applications, sites or
                        resources, or as a result of any reliance placed by You
                        on the completeness, accuracy or existence of any
                        advertising, products or other materials on, or
                        available from such applications, websites or resources.
                        These external applications, websites and resource
                        providers may have their own privacy policies governing
                        the collection, storage, retention and disclosure of
                        Your Personal Information that You may be subject to. We
                        recommend that You enter the external application or
                        website and review their privacy policy.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      USE OF INFORMATION
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WiseRx collects and stores information provided by you
                        from time to time. This allows us to provide services
                        and features that most likely meet your needs, and:
                      </Typography>

                      <Container maxWidth="lg">
                        <ul
                          style={{
                            listStyleType: "disc",
                            fontSize: "10px",
                          }}
                        >
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To enable you to avail our Services and ensure
                            efficient customer care experience and develop new
                            features;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To maintain the operation of the Website including
                            but not limited to facilitating payments, sending
                            receipts, enabling communication of patients with
                            the doctors and other medical professionals;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To notify the necessary information related to the
                            Website and Services and your user account on the
                            Website from time to time;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To comply with applicable laws, rules and
                            regulations;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To provide you with information and offers on
                            Services, updates, promotions, related, affiliated
                            or associated service providers and partners that we
                            believe would be of interest to you;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To maintain records and provide you with an
                            efficient, safe and customized experience while
                            using the Website;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To analyze overall trends to helps us improve our
                            Website, Application and Services;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Allow you to participate in interactive features
                            offered through the Website and Application;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Provide personalized content and information to you
                            and others, which could include online ads or other
                            forms of marketing;
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Verify your identity and prevent fraud or other
                            unauthorized or illegal activity; and
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            In any other way consistent with this Privacy Policy
                            and our Terms of Use.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Additionally, WiseRx reserves the right to use or
                            disclose your Personal Information in response to
                            subpoenas, court orders, warrants, or legal process,
                            or to otherwise establish or exercise our legal
                            rights or defend against legal claims or in the
                            event you violate or breach an agreement with us. We
                            will use and disclose your Personal Information if
                            we believe you will harm the property or rights of
                            WiseRx, its owners, or those of WiseRx’s other
                            users. Finally, we will use or disclose your
                            Personal Information if we believe it is necessary
                            to share information in order to investigate,
                            prevent, or take action regarding illegal
                            activities, suspected fraud, situations involving
                            potential threats to the physical safety of any
                            person or property, violations of WiseRx’s other
                            policies, or as otherwise required by law when
                            responding to subpoenas, court orders and other
                            legal processes.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            If we believe that disclosure is necessary or
                            appropriate to protect our rights, property, or
                            safety, our Users or others, we may in good faith
                            share information with other companies and
                            organizations for the purposes of fraud protection
                            and credit risk reduction.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            You agree that we may use Personal Information about
                            you to improve our marketing and promotional
                            efforts, to analyze site usage, improve the
                            Website's content and service offerings, and
                            customize the Website's content, layout, and
                            services. These uses improve the Website and better
                            tailor it to meet your needs, so as to provide you
                            with an efficient, safe and customized experience
                            while using the Website.
                          </Typography>
                        </ul>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      REVIEW & ALTERATION OF INFORMATION
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WiseRx strives to accurately record all information
                        provided on our platform from time to time.
                      </Typography>

                      <Container maxWidth="lg">
                        <ul
                          style={{
                            listStyleType: "disc",
                            fontSize: "10px",
                          }}
                        >
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            WiseRx encourages you to review, correct, update,
                            and/or change your account information or Personal
                            Information at any time.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            You have a right to review, update, correct, and
                            delete your information; subject to our records
                            retention policies and applicable law, including any
                            statutory retention requirements.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            If you want to have us delete your information at
                            any time please send the request to{" "}
                            <Link
                              class="mailto"
                              href="mailto:support@wiserx.co"
                              variant="subtitle2"
                              style={{ fontWeight: "bold" }}
                              color="primary"
                            >
                              support@wiserx.co
                            </Link>
                            . However, note that we may retain certain
                            information as required by law or for reasonable
                            business purposes.
                          </Typography>
                        </ul>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      THIRD PARTY SERVICES
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        The information we obtain from or about you may be
                        processed and stored in your local country and our other
                        various servers located across the globe, which may
                        provide for different data protection rules than the
                        country in which you reside. We comply with generally
                        accepted industry standard regulations regarding the
                        collection, use, and retention of data. By using the
                        Website and / or Services, you consent to the
                        collection, transfer, use, storage and disclosure of
                        your information as described in this Privacy Policy,
                        including to the transfer of your information outside of
                        your country of residence.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom m={5}>
                      HOW WE PROTECT YOUR INFORMATION
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WiseRx has implemented appropriate physical, electronic,
                        and managerial procedures to safeguard and help prevent
                        unauthorized access to the User information and to
                        maintain data security. These safeguards take into
                        account the sensitivity of the information that WiseRx
                        collects, processes and stores and the current state of
                        technology. WiseRx follows generally accepted industry
                        standards to protect the Personal Information submitted
                        to WiseRx, both during transmission and once received,
                        including the use of vault and tokenization services
                        from third party service providers. While WiseRx strives
                        to use commercially acceptable means to protect your
                        Personal Information, we cannot guarantee its absolute
                        security and thereby usage in a manner that is
                        inconsistent with this Privacy Policy.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WiseRx neither accepts nor assumes any liability or
                        responsibility for disclosure of your information due to
                        errors in transmission, unauthorized third party access,
                        or other causes beyond WiseRx’s control. We do not,
                        under any circumstances, represent that our platform is
                        completely void of malware including but not limited to
                        viruses, spywares, cancel bots, Trojans, time bombs and
                        any such application, which may be placed on our
                        platform without our knowledge. However we shall take
                        all the necessary and reasonable steps to ensure that
                        our platform is free from the above-mentioned malware.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        The User also plays an important role in keeping their
                        Personal Information secure. Using unsecured Wi-Fi or
                        other unprotected networks to submit messages through
                        the Website and/or Application is never recommended. You
                        should not share your user name, password, or other
                        security information for your WiseRx account or the
                        device through which you access our Website and / or
                        Services (as the case may be) with anyone. If WiseRx
                        receives any instructions through any account or device
                        or any other medium bearing your Personal Information
                        (e.g.: through your contact details, account details or
                        your WhatsApp account, etc.), we will consider that you
                        have authorized the instructions.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      UPDATES
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        From time to time, the Application may automatically
                        check the version of the Application installed on the
                        Device and, if applicable, provide updates for the
                        Application (hereinafter referred to as “Updates”).
                        Updates may contain, without limitation, bug fixes,
                        patches, enhanced functionality, plug-ins and new
                        versions of the Application. By installing the
                        Application, You authorize the automatic download and
                        installation of Updates and agree to download and
                        install Updates manually if necessary. Your use of the
                        Application and Updates shall be governed by this
                        Agreement (as amended by any terms and conditions that
                        may be provided with Updates).
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom m={5}>
                      IN THE EVENT OF CHANGE OF CONTROL
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        In the event of a change of control of WiseRx due to the
                        merger with third party, acquisition, bankruptcy or
                        other change of control, we reserve the right to
                        transfer or assign the information we have collected
                        from our users as part of such merger, acquisition,
                        sale, or other change of control. In the unlikely event
                        of our bankruptcy, insolvency, reorganization,
                        receivership, or assignment for the benefit of
                        creditors, or the application of laws or equitable
                        principles affecting creditors' rights generally, we may
                        not be able to control how your Personal Information is
                        treated, transferred, or used.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom m={5}>
                      CHANGES TO THIS PRIVACY POLICY
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        We reserve the right to update, change or modify this
                        Privacy Policy at any time to reflect any (including but
                        not limited to) changes in the law, the data collection
                        and use practices, the features of the Website,
                        Application or Services, or advances in technology. The
                        amendment to this Privacy Policy shall come to effect
                        from the time of such update, change or modification and
                        the same will be published on this Website.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Users are requested to review the Privacy Policy from
                        time to time. Use of information collected is subject to
                        the Privacy Policy in effect at the time such
                        information is used. Any material changes made to this
                        Privacy Policy, shall be posted here. You are requested
                        to review the changes carefully. The changes to this
                        Privacy Policy shall be treated as read, recognized,
                        understood, consented and accepted if the User continues
                        to use the Website or Services provided by WiseRx post
                        such changes.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Container>
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        ></Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  );
}
