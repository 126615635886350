import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Accordion,
  Tooltip,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { connect } from 'react-redux';
import { MdDeleteSweep, MdSend, MdCloudUpload } from 'react-icons/md';
import DateFnsUtils from '@date-io/date-fns';
import ChipInput from 'material-ui-chip-input';
import APIRequest from '../../../../utils/APIRequest';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import imageCompression from 'browser-image-compression';
import LocalStorage from '../../../../config/LocalStorage';
import FileUploadTableCheckBox from '../../../../templates/FileUploadTableCheckBox';
import FileUpload from '../../../../templates/FileUpload';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
  },
}));
const addFormDetails = {
  name: '',
  permission: [],
  active: false,
};
function AEVForm(props) {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(addFormDetails);

  const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'submit'
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'reset'
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== '' &&
      props.formDialog.divType !== 'new' &&
      props.formDialog.functionName === ''
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setAddForm(addFormDetails);
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== 'new'
    ) {
      APIRequest.request(
        'POST',
        ConfigAPIURL.roleDetails,
        JSON.stringify({ roleId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setAddForm(response.data.role);
          }
        }
      });
    }
  };

  const sendToServer = () => {
    APIRequest.request(
      'PUT',
      ConfigAPIURL.roleUpdate,
      JSON.stringify({
        roleId: props.rowDetails._id,
        name: addForm.name,
        active: addForm.active,
        permission: addForm.permission,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          SnackbarUtils.sucess(
            props.t('snackbar.transComplete'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
          props.handleFormDialog();
          // props.tableQuery({
          //   keyword: '',
          //   page: 0,
          //   pageSize: 10,
          //   sortField: '',
          //   userType: 'all',
          //   sortOrder: 'false',
          //   active: true,
          // });
          setAddForm(addFormDetails);
        }
        if (response.code === 100 && response.data.responseCode === 114) {
          SnackbarUtils.error(
            props.t('snackbar.dupliRecords'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  const handlePermissionCheckChange = (event, labelIndex, index) => {
    let form = addForm;
    form.permission[labelIndex].buttons[index].enable = event.target.checked;
    setAddForm({ ...addForm }, form);
  };

  const handlePermissionChange = (event, labelIndex) => {
    let form = addForm;
    form.permission[labelIndex].enable = event.target.checked;
    setAddForm({ ...addForm }, form);
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id='name'
                  label={props.t('role.roleName')}
                  autoComplete='off'
                  required
                  value={addForm.name}
                  fullWidth
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      name: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                />
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id='active'
                  label={props.t('role.active')}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={addForm.active}
                  value={addForm.active}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t('advertisements.active')}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t('advertisements.inactive')}
                  </MenuItem>
                </TextField>
              </Grid>

              {addForm.permission.map((permission, index) => (
                <Grid
                  key={index}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: '20px' }}
                  className={classes.formGrid}
                >
                  <Accordion>
                    <AccordionSummary
                      aria-controls='panel3bh-content'
                      id='panel3bh-header'
                    >
                      <Grid container>
                        <Grid
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className={classes.heading}
                        >
                          <Typography>
                            {permission.label}{' '}
                            <Checkbox
                              style={{ float: 'right' }}
                              checked={permission.enable}
                              onChange={(event) =>
                                handlePermissionChange(event, index)
                              }
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className={classes.secondaryHeading}
                        >
                          <Grid container>
                            {permission.buttons.map((buttons, idx) => (
                              <Grid
                                key={idx}
                                xl={4}
                                lg={4}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <Checkbox
                                  checked={buttons.enable}
                                  onChange={(event) =>
                                    handlePermissionCheckChange(
                                      event,
                                      index,
                                      idx
                                    )
                                  }
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                                {buttons.label}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(AEVForm)
);
