/* eslint-disable no-useless-escape */
const Validation = {
	emailValidation: async function (email) {
		// eslint-disable-next-line no-useless-escape
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		}
		return false;
	},
	urlValidation: async function (url) {
		// eslint-disable-next-line no-useless-escape
		if (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(url)) {
			return true;
		}
		return false;
	},
};
export default Validation;
