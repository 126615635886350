import { Stack } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useUpload from "../../hooks/useUpload";
import EmojiPicker from "emoji-picker-react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import CloseIcon from "@mui/icons-material/Close";

const SendMessage = ({ sendMessage, selectedChat }) => {
  const [textMessage, setTextMessage] = useState("");
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);
  const handleSendMessage = () => {
    if (!textMessage) return;
    sendMessage(selectedChat?._id, textMessage);
    setTextMessage("");
    if (isEmojiVisible) setIsEmojiVisible(false);
    return;
  };
  const handleMedia = (media) => {
    sendMessage(selectedChat?._id, "", media);
  };
  const { fileUpload } = useUpload(handleMedia);
  const Component = !isEmojiVisible ? InsertEmoticonIcon : CloseIcon;
  return (
    <Stack style={{ flexDirection: "row", alignItems: "center", gap: 3 }}>
      {/* <label htmlFor="input">
        <AttachFileIcon
          style={{ transform: "rotate(45deg)", cursor: "pointer" }}
        />
      </label>
      <input
        type="file"
        style={{ display: "none", wordBreak: "break-word" }}
        id="input"
        multiple
        onChange={(e) => fileUpload(e, handleMedia)}
      /> */}
      <Stack
        style={{
          width: "100%",
          minHeight: "40px", 
          maxHeight: "200px", 
          overflowY: "auto",
          overflow: "auto",
          // border: "1.69px solid #efefef",
          borderRadius: "12px",
          flexDirection: "row",
          alignItems: "center",
          padding: "8px .5rem ",
          background: "#F3F6F6",
          justifyContent: "center",
          position: "relative",
          //   width: "90%",
          //   margin: "auto",
        }}
      >
        {" "}
        <Stack style={{ position: "absolute", width: "100%", bottom: "40px" }}>
          {isEmojiVisible && (
            <EmojiPicker
              size={28}
              width="100%"
              lazyLoadEmojis={true}
              height="300px"
              onEmojiClick={(e) => setTextMessage((p) => p.concat(e.emoji))}
            />
          )}
        </Stack>
        <Component
          onClick={() => setIsEmojiVisible(!isEmojiVisible)}
          style={{ cursor: "pointer", marginRight: ".3rem" }}
        />
        <textarea
          className="scroll"
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          autoFocus
          style={{
            resize: "none", // Disable resizing by the user
            outline: "none",
            border: "none",
            flexGrow: 2,
            backgroundColor: "#F3F6F6",
            fontFamily: "Poppins",
            minHeight: "40px", 
            maxHeight: "200px", 
            overflowY: "auto", 
          }}
          placeholder="Message"
          value={textMessage}
          onChange={(e) => {
            const inputValue = e.target.value.replace(
              /[^\w\s,.?!:;'"\(\)-]/gi,
              ""
            ); // Include additional punctuation marks
            setTextMessage(inputValue);
          }}
          onInput={(e) => {
            e.target.style.height = "auto"; // Reset height to auto
            e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
          }}
        />
        {
          <button
            disabled={textMessage ? false : true}
            style={{
              outline: "none",
              border: "none",
              background: "none",
            }}
          >
            <SendIcon
              style={{ cursor: "pointer", color: "#8BABD8" }}
              onClick={handleSendMessage}
            />
          </button>
        }
      </Stack>
    </Stack>
  );
};

export default SendMessage;
