import React from "react";

const CustomerViewForm = (props) => {
  // Function to get the next 7 days from today
  const getNextSevenDays = () => {
    const days = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      days.push(date.toLocaleDateString("en-US", { weekday: "long" }));
    }
    return days;
  };

  // Function to filter out retired medications and format data for each day
  const formatMedicationData = () => {
    const sevenDays = getNextSevenDays();

    const formattedData = {};

    sevenDays.forEach((day, index) => {
      const date = new Date("February 8, 2024"); // Specific date February 8, 2024
      date.setDate(date.getDate() + index);
      const formattedDate = date.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      formattedData[day] = [];
      console.log(props?.medicationData, "form pritiing main data");
      if (props?.medicationData) {
        props.medicationData.forEach((medication) => {
          if (!medication.retire) {
            formattedData[day].push({
              intakeData: medication.intakeData,
              intake: medication.intake,
              intakeQuantity: medication.intakeQuantity,
              itemName: medication.itemName,
              itemId: medication.itemId,
              doctor: medication.doctor,
              day: day,
              formattedDate: formattedDate,
              sos: medication.sos,
            });
          }
        });
      }
    });

    return formattedData;
  };

  // Output
  const formattedMedicationData = formatMedicationData();
  //   console.log("formattedMedicationData", formattedMedicationData);

  // Function to render medications for each time of day
  const renderMedications = (dayData) => {
    const times = [
      "Morning",
      "Afternoon",
      "Evening",
      "Night",
      "OtherTime",
      "sosQuantity",
    ];

    const schedules = ["After","Before","with"] 
    const medications = formattedMedicationData[dayData] || [];
    return (
      <div key={dayData.day}>
        {/* <h3>{dayData.day}</h3> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "350px",
            padding: "8px",
          }}
        >
          {times.map((time) => {
            const medicationsForTime = medications.filter(
              (medication) => medication.intakeQuantity[time]
            );

            return (
              <div
                key={`${dayData.day}-${time}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    color: time === "sosQuantity" ? "red" : "#74c1de",
                    alignSelf: "center",
                  }}
                >
                  {time === "sosQuantity"
                    ? "SOS (Emergency Medication)"
                    : time === "OtherTime"
                    ? "Other Time"
                    : time}
                </h3>

                {medicationsForTime?.map((medication, index) => (
                  <div key={`${dayData.day}-${time}-${index}`}>
                    <div
                      style={{
                        border: "1px solid #e5e5e5",
                        borderRadius: "10px",
                        padding: "15px",
                        marginBottom: "12px",
                      }}
                    >
                      <p
                        style={{
                          color: "#74c1de",
                          fontWeight: "500",
                          marginBottom: "0",
                        }}
                      >
                        {console.log(medication.intake[time])}
                        {/* added medication here */}
                        {schedules.includes(medication.intake[time])
                          ? ""
                          : medication.intake.Other}{" "}
                        {medication.intake[time]} Meal :
                      </p>
                      <p style={{ fontWeight: "500", marginBottom: "0" }}>
                        {medication.itemName}
                      </p>
                      <p style={{ fontWeight: "500", marginBottom: "0" }}>
                        Take {medication.intakeQuantity[time]} |{" "}
                        {medication.itemId.dosageForm} |{" "}
                        {medication.itemId.form}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // const renderMedications = (dayData) => {
  //   const times = ["Morning", "Afternoon", "Evening", "Night","OtherTime"]; // "OtherTime"
  //   const medications = formattedMedicationData[dayData] || [];

  //   console.log(medications,"from hererrererer")

  //   return (
  //     <div key={dayData.day}>
  //       {/* <h3>{dayData.day}</h3> */}
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           width: "350px",
  //           padding: "8px",
  //         }}
  //       >
  //         {times?.map((time) => {
  //           const medicationsForTime = medications?.filter(
  //             (medication) => medication.intakeQuantity[time]
  //           );

  //           // Group medications by intake type
  //           const groupedMedications = {};
  //           medicationsForTime.forEach((medication) => {
  //             const intakeType = medication.intake[time];
  //             if (!groupedMedications[intakeType]) {
  //               groupedMedications[intakeType] = [];
  //             }
  //             groupedMedications[intakeType].push(medication);
  //           });

  //           return (
  //             <div
  //               key={`${dayData.day}-${time}`}
  //               style={{
  //                 display: "flex",
  //                 flexDirection: "column",
  //               }}
  //             >
  //               <h3 style={{ color: "#74c1de", alignSelf: "center" }}>
  //                 {time}
  //                 {console.log(time)}
  //               </h3>

  //               {/* Render medications for each intake type */}
  //               {Object.entries(groupedMedications)?.map(
  //                 ([intakeType, meds], index) => (
  //                   <div key={`${dayData.day}-${time}-${intakeType}-${index}`}>
  //                     {/* Render block for each intake type */}
  //                   {  console.log("1",intakeType)}
  //                    { console.log("2",meds)}
  //                     <div
  //                       style={{
  //                         border: "1px solid #e5e5e5",
  //                         borderRadius: "10px",
  //                         padding: "15px",
  //                         marginBottom: "12px",
  //                       }}
  //                     >
  //                       <h4
  //                         style={{
  //                           color: "#74c1de",
  //                           marginBottom: "2px",
  //                           fontSize: "20px",
  //                           marginTop: "0",
  //                         }}
  //                       >
  //                         {intakeType} Meal :

  //                       </h4>
  //                       {/* Render medications for this intake type */}
  //                       {meds?.map((medication, index) => (
  //                         <div
  //                           key={`${dayData.day}-${time}-${intakeType}-${index}`}
  //                         >
  //                           <p
  //                             style={{
  //                               fontWeight: "700",
  //                               marginBottom: "0",
  //                               fontSize: "18px",
  //                               color: "#000",
  //                             }}
  //                           >
  //                             {medication.itemName}
  //                           </p>
  //                           <p
  //                             style={{
  //                               fontWeight: "500",
  //                               marginBottom: "0",
  //                               marginTop: "0",
  //                             }}
  //                           >
  //                             Take {medication.intakeQuantity[time]} |{" "}
  //                             {medication.itemId.dosageForm} |{" "}
  //                             {medication.itemId.form}
  //                           </p>
  //                         </div>
  //                       ))}
  //                     </div>
  //                   </div>
  //                 )
  //               )}
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {Object.keys(formattedMedicationData).map((day) => (
        <div key={day} style={{ margin: "10px" }}>
          {/* {console.log("datetete", day)} */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f1f1f1",
            }}
          >
            <h3
              style={{
                color: "#ECB159",
                margin: "auto",

                padding: "12px",
                alignSelf: "center",
              }}
            >
              {day}
            </h3>
          </div>
          <div>{renderMedications(day)}</div>
        </div>
      ))}
    </div>
  );
};

export default CustomerViewForm;
