/* eslint-disable array-callback-return */
import DateConversion from './DateConversion';

const ObjectUtils = {
  objectCreateByString: function (stringLocation) {
    // e.g stringLocation="accounting.creditAccount"
    let result = {};
    try {
      let locationArray = stringLocation.split('.');
      locationArray
        .slice(0)
        .reverse()
        .map((data, index) => {
          if (index === 0) {
            result[data] = true;
          } else {
            result[data] = {};
          }
        });
      let tempObject;
      Object.keys(result).map((data, index) => {
        if (index === 0) {
          tempObject = data;
        } else {
          result[data][tempObject] = result[tempObject];
          delete result[tempObject];
          tempObject = data;
        }
      });
    } catch (err) {
      console.error(err);
    }
    return result;
  },
  mergeRecursive: function (mainObject, mergeObject) {
    try {
      if (mainObject !== undefined && mainObject !== null) {
        for (let p in mergeObject) {
          try {
            if (mergeObject[p].constructor === Object) {
              mainObject[p] = this.mergeRecursive(
                mainObject[p],
                mergeObject[p]
              );
            } else {
              mainObject[p] = mergeObject[p];
            }
          } catch (e) {
            mainObject[p] = mergeObject[p];
          }
        }
      } else {
        return mergeObject;
      }
    } catch (err) {
      console.error(err);
    }
    return mainObject;
  },
  compareObject: async function (mainObject, compareObject) {
    let tempMainObject = {};
    let tempCompareObject = {};
    if (
      mainObject !== undefined &&
      mainObject !== null &&
      compareObject !== undefined &&
      compareObject !== null
    ) {
      Object.keys(mainObject).map((data) => {
        if (
          typeof mainObject[data] === 'string' ||
          typeof mainObject[data] === 'number' ||
          typeof mainObject[data] === 'boolean'
        ) {
          if (mainObject[data] !== compareObject[data]) {
            tempMainObject[data] = mainObject[data];
            tempCompareObject[data] = compareObject[data];
          }
        } else if (
          typeof mainObject[data] === 'object' &&
          !Array.isArray(mainObject[data])
        ) {
          //A Recursive call to verify unmatch objects fields
          this.compareObject(mainObject[data], compareObject[data]).then(
            (result) => {
              if (
                Object.keys(result.mainObject).length > 0 ||
                Object.keys(result.compareObject).length > 0
              ) {
                tempMainObject[data] = result.mainObject;
                tempCompareObject[data] = result.compareObject;
              }
            }
          );
        } else if (
          typeof mainObject[data] === 'object' &&
          Array.isArray(mainObject[data])
        ) {
          if (mainObject[data].length > 0 || compareObject[data].length > 0) {
            if (
              (typeof mainObject[data][0] !== undefined &&
                (typeof mainObject[data][0] === 'string' ||
                  typeof mainObject[data][0] === 'number' ||
                  typeof mainObject[data][0] === 'boolean')) ||
              (typeof compareObject[data][0] !== undefined &&
                (typeof compareObject[data][0] === 'string' ||
                  typeof compareObject[data][0] === 'number' ||
                  typeof compareObject[data][0] === 'boolean'))
            ) {
              this.arrayPadding(mainObject[data], compareObject[data]).then(
                (paddingResult) => {
                  this.duplicateArrayRemoveFormBoth(
                    paddingResult.mainObject,
                    paddingResult.compareObject
                  ).then((duplicateRemoveResult) => {
                    this.arrayPadding(
                      duplicateRemoveResult.mainObject,
                      duplicateRemoveResult.compareObject
                    ).then((finalPaddingResult) => {
                      if (
                        finalPaddingResult.mainObject.length > 0 &&
                        finalPaddingResult.compareObject.length > 0
                      ) {
                        tempMainObject[data] = finalPaddingResult.mainObject;
                        tempCompareObject[data] =
                          finalPaddingResult.compareObject;
                      }
                    });
                  });
                }
              );
            } else {
              if (mainObject[data].length > compareObject[data].length) {
                mainObject[data].map((mainObjectArray, index) => {
                  compareObject[data].map((compareObjectArray, index) => {
                    Object.keys(compareObjectArray).map((key) => {
                      if (compareObjectArray[key] === mainObjectArray[key]) {
                        delete mainObjectArray[key];
                        delete compareObjectArray[key];
                      }
                    });
                  });
                  tempMainObject[data] = mainObject[data];
                  tempCompareObject[data] = compareObject[data];
                });
              } else {
                compareObject[data].map((compareObjectArray, index) => {
                  mainObject[data].map((mainObjectArray, index) => {
                    Object.keys(mainObjectArray).map((key) => {
                      if (
                        typeof compareObjectArray[key] === 'object' ||
                        typeof mainObjectArray[key] === 'object'
                      ) {
                        //A Recursive call to verify unmatch objects fields
                        this.compareObject(
                          mainObjectArray[key],
                          compareObjectArray[key]
                        ).then((result) => {
                          if (
                            Object.keys(result.mainObject).length > 0 ||
                            Object.keys(result.compareObject).length > 0
                          ) {
                            if (Object.keys(result.mainObject).length === 0) {
                              delete mainObjectArray[key];
                            }
                            if (
                              Object.keys(result.compareObject).length === 0
                            ) {
                              delete compareObjectArray[key];
                            } else if (
                              Object.keys(result.mainObject).length > 0 &&
                              Object.keys(result.compareObject).length > 0
                            ) {
                              mainObjectArray[key] = result.mainObject;
                              compareObjectArray[key] = result.compareObject;
                            }
                          } else {
                            delete compareObjectArray[key];
                            delete mainObjectArray[key];
                          }
                        });
                      } else {
                        if (compareObjectArray[key] === mainObjectArray[key]) {
                          delete compareObjectArray[key];
                          delete mainObjectArray[key];
                        }
                      }
                    });
                  });
                  tempMainObject[data] = mainObject[data];
                  tempCompareObject[data] = compareObject[data];
                });
              }
            }
          }
        }
      });
      tempMainObject = await this.arrangeInOrder(tempMainObject);
      tempCompareObject = await this.arrangeInOrder(tempCompareObject);
      return { mainObject: tempMainObject, compareObject: tempCompareObject };
    } else {
      return {
        mainObject: {},
        compareObject: await this.arrangeInOrder(compareObject),
      };
    }
  },
  arrangeInOrder: async function (object) {
    // 1st String /Number
    // 2nd Array
    // 3rd Object
    let normalValuesMainObject = {};
    let arrayListMainObject = {};
    let objectListMainObject = {};

    if (object !== undefined && object !== null) {
      Object.keys(object).map((data) => {
        if (
          typeof object[data] !== undefined &&
          (typeof object[data] === 'string' ||
            typeof object[data] === 'number' ||
            typeof object[data] === 'boolean')
        ) {
          if (data.includes('dob') || data.includes('date')) {
            normalValuesMainObject[data] = DateConversion.unixToLocaleDate(
              object[data] / 1000
            );
          } else {
            normalValuesMainObject[data] = object[data];
          }
        }
        if (
          typeof object[data] !== undefined &&
          typeof object[data] === 'object' &&
          Array.isArray(object[data])
        ) {
          arrayListMainObject[data] = object[data];
        }
        if (
          typeof object[data] !== undefined &&
          typeof object[data] === 'object' &&
          !Array.isArray(object[data])
        ) {
          objectListMainObject[data] = object[data];
        }
      });
    }
    return {
      ...normalValuesMainObject,
      ...arrayListMainObject,
      ...objectListMainObject,
    };
  },
  arrayPadding: async function (mainObject, compareObject) {
    if (mainObject.length > compareObject.length) {
      let diff = mainObject.length - compareObject.length;
      for (let i = 0; i < diff; i++) {
        compareObject[compareObject.length + i] = '';
      }
    } else {
      let diff = compareObject.length - mainObject.length;
      for (let i = 0; i < diff; i++) {
        mainObject[mainObject.length + i] = '';
      }
    }
    return { mainObject, compareObject };
  },
  duplicateArrayRemoveFormBoth: async function (mainObject, compareObject) {
    let tempCompareObjectRemover = [];
    mainObject = mainObject.filter(function (val) {
      if (compareObject.indexOf(val.toString()) > -1) {
        tempCompareObjectRemover.push(val.toString());
      }
      return compareObject.indexOf(val.toString()) === -1;
    });
    compareObject = compareObject.filter(function (val) {
      return tempCompareObjectRemover.indexOf(val.toString()) === -1;
    });
    return { mainObject, compareObject };
  },
  dataTableBodyGenarater: function (tableDataObject, tableHeaderObject) {
    let path =
      tableHeaderObject.accessor !== undefined &&
      tableHeaderObject.accessor !== null
        ? tableHeaderObject.accessor
        : '';
    if (!path || path === '') {
      return '-';
    }
    else if(path==="sos"){
      return "Emergency Medication"
    }
    var pathObj = path.split('.');
    var val = void 0;
    try {
      val = pathObj.reduce(function (current, pathPart) {
        if (current !== undefined && current !== null) {
          if (pathPart !== undefined && pathPart !== null) {
            return current[pathPart];
          }
        }
      }, tableDataObject);
    } catch (e) {
      // console.log(e);
    }
    return typeof val !== 'undefined' ? val : '-';
  },
};
export default ObjectUtils;
