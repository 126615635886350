import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link,
  Button,
  TextField,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import FormValidation from "../../utils/FormValidation";
import APIRequest from "../../utils/APIRequest";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
function Copyright() {
  return (
    <div>
      <Typography variant="button" display="block" gutterBottom align="center">
        Users may contact Us by email at{" "}
        <Link
          class="mailto"
          // href="mailto:support@davaguide.com"
          href="mailto:support@wiserx.co"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
          color="primary"
        >
          support@wiserx.co
        </Link>{" "}
        for any queries
      </Typography>
      <Typography variant="button" display="block" gutterBottom align="center">
        {"Copyright © "}
        WiseRx {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  formGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundRepeat: "no-repeat",
    backgroundColor: "#709fe3",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  heroContent: {
    padding: theme.spacing(10, 0, 6),
  },
  list: { backgroundColor: theme.palette.background.paper },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    padding: theme.spacing(6),
  },
}));

const contactFormvalues = {
  mobileNo: "",
  fname: "",
  lname: "",
  email: "",
  message: "",
};

export default function ContactUs(props) {
  const classes = useStyles();

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: "", message: "" });
  };
  const [contactForm, setContactForm] = useState(contactFormvalues);

  const sendToServer = () => {
    let fieldValidation = ["fname", "lname", "mobileNo", "email", "message"];
    FormValidation.validation(fieldValidation, contactForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(
            "POST",
            ConfigAPIURL.contactAdmin,
            JSON.stringify(contactForm)
          ).then((response) => {
            if (response !== undefined && response !== null) {
              if (response.code === 100 && response.data.responseCode === 109) {
                setSnakbarValues({
                  status: true,
                  severity: "success",
                  message:
                    "Message sent sucessfully. Thank you for contacting Us",
                });
                setContactForm(contactFormvalues);
              }
            }
          });
        }
      }
    );
  };

  return (
    <div className={classes.root}>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container
            maxWidth="md"
            className="dummyPage"
            className={classes.list}
          >
            <Grid container>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  style={{ margin: "10px 0px" }}
                >
                  Contact Us
                </Typography>
              </Grid>

              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="fname"
                  label="First Name"
                  autoComplete="something-unsupported"
                  autoComplete="off"
                  required
                  style={{ width: "98%" }}
                  value={contactForm.fname}
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  onChange={(event) =>
                    setContactForm({
                      ...contactForm,
                      fname: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="lname"
                  autoComplete="something-unsupported"
                  autoComplete="off"
                  label="Last Name"
                  required
                  style={{ width: "98%" }}
                  value={contactForm.lname}
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  onChange={(event) =>
                    setContactForm({
                      ...contactForm,
                      lname: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="mobileNo"
                  autoComplete="something-unsupported"
                  autoComplete="off"
                  label="Mobile Number"
                  required
                  style={{ width: "98%" }}
                  inputProps={{ maxLength: 10 }}
                  value={contactForm.mobileNo}
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  onChange={(event) =>
                    setContactForm({
                      ...contactForm,
                      mobileNo: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="email"
                  autoComplete="something-unsupported"
                  autoComplete="off"
                  label="Email"
                  style={{ width: "98%" }}
                  required
                  value={contactForm.email}
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      (event.charCode >= 48 && event.charCode <= 57) ||
                      event.charCode === 64 ||
                      event.charCode === 46
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  onChange={(event) =>
                    setContactForm({
                      ...contactForm,
                      email: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="message"
                  autoComplete="something-unsupported"
                  autoComplete="off"
                  label="Message"
                  multiline
                  required
                  rows={4}
                  value={contactForm.message}
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  onChange={(event) =>
                    setContactForm({
                      ...contactForm,
                      message: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
                align="center"
              >
                <Button
                  // variant='contained'
                  color="primary"
                  onClick={() => sendToServer()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        ></Typography>
        <Copyright />
      </footer>
      {/* End footer */}
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </div>
  );
}
