import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  select: {
    borderBottom: "none", // Remove the bottom border
    "&:before": {
      borderBottom: "none", // Remove the bottom border when not focused
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none", // Remove the bottom border on hover
    },
  },

  toggleButtonGroup: {
    width: "100%", // Set the width to 100% to make it full-width
  },
  toggleButton: {
    height: "30px", // Set the desired height
    color: "#585858",
    "&.Mui-selected": {
      backgroundColor: "#f7f7f7", // Change the active button background color
      color: "#000", // Change the text color of the active button
    },
  },
}));
const userFormDetails = {
  userType: "",
  active: true,
  isSuperAdmin: false,
  permission: "",
  fname: "",
  lname: "",
  mobileNo: "",
  email: "",
  address1: "",
  address2: "",
  bloodPressure: { high: "", low: "" },
  city: "",
  state: "",
  country: "",
  pin: "",
  age: "",
  dob: null,
  gender: null,
  weight: 0,
  bloodGroup: "",
  height: 0,
  isSmoker: null,
  alcoholIntake: "",
  allergies: [],
  chronicConditions: [],
  familyHistory: [],
  surgeries: [],
  weightUnit: "lbs",
  heightUnit: "inch",
  isSubstance: null,
  password: "",
};
function AEVForm(props) {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [userForm, setUserForm] = useState(userFormDetails);
  const [smokerOptions, setSmokerOptions] = useState([]);
  const [substanceOptions, setSubstanceOptions] = useState([
    {
      value: "true",
      display: "Yes",
    },
    {
      value: "false",
      display: "No",
    },
  ]);
  // console.log("smokerOptions", smokerOptions);
  const [alcoholIntakeOptions, setAlcoholIntakeOptions] = useState([]);

  useEffect(() => {
    getSmokerOptions();

    getAlcoholIntakeOptions();
  }, []);
  useEffect(() => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    }
    if (props?.page === "careTeam") {
      setUserForm({ ...userFormDetails, userType: "admin" });
    }
  }, [props.page]);

  useEffect(() => {
    // setUserForm(userFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    if (
      props.page !== undefined &&
      props.page !== null &&
      props.page === "createPatientPage"
    ) {
      setUserForm({ ...userFormDetails, userType: "user" });
    } else {
      setUserForm(userFormDetails);
    }
  };

  const getSmokerOptions = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "smoker" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setSmokerOptions(response?.data?.options?.[0]?.options);
          }
        }
      }
    });
  };

  const getAlcoholIntakeOptions = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "alcoholIntake" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setAlcoholIntakeOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.userDetails,
        JSON.stringify({ userId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response.data.user;
            userResult.dob = new Date(userResult.dob * 1000);
            userResult.allergies =
              userResult.allergies !== ""
                ? userResult.allergies.split(",")
                : [];
            userResult.chronicConditions =
              userResult.chronicConditions !== ""
                ? userResult.chronicConditions.split(",")
                : [];
            userResult.familyHistory =
              userResult.familyHistory !== ""
                ? userResult.familyHistory.split(",")
                : [];
            userResult.surgeries =
              userResult.surgeries !== ""
                ? userResult.surgeries.split(",")
                : [];

            let permissions = {};
            if (props.roles !== undefined && props.roles !== null) {
              props.roles.map((role) => {
                if (role._id === userResult.permission) {
                  permissions = role;
                }
              });
            }

            setUserForm({
              ...userForm,
              userType: userResult.userType,
              active: userResult.active,
              isSuperAdmin: userResult.isSuperAdmin,
              permission: permissions,
              fname: userResult.fname,
              lname: userResult.lname,
              mobileNo: userResult.mobileNo,
              email: userResult.email,
              address1: userResult.address1,
              address2: userResult.address2,
              city: userResult.city,
              state: userResult.state,
              country: userResult.country,
              pin: userResult.pin,
              // age: userResult.age,
              dob: userResult.dob,
              gender: userResult.gender,
              weight: userResult.weight,
              weightUnit: userResult.weightUnit, //New field added
              heightUnit: userResult.heightUnit, //New field added
              bloodGroup: userResult.bloodGroup,
              height: userResult.height,
              bloodPressure:
                userResult.bloodPressure !== undefined &&
                userResult.bloodPressure !== null
                  ? userResult.bloodPressure
                  : "",
              isSmoker: userResult.isSmoker,
              isSubstance: userResult.isSubstance,
              alcoholIntake: userResult.alcoholIntake,
              allergies: userResult.allergies,
              chronicConditions: userResult.chronicConditions,
              familyHistory: userResult.familyHistory,
              surgeries: userResult.surgeries,
              password: userResult.password,
            });

            setSelectedRowId(userResult._id);
          }
        }
      });
    }
  };
  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "save":
        sendToServer();
        break;
      case "reset":
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.userCreate;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.userUpdate;
      userForm["userId"] = selectedRowId;
      method = "PUT";
    }
    let fieldValidation =
      userForm.userType !== "admin"
        ? ["fname", "lname", "dob", "userType", "mobileNo", "gender"]
        : [
            "fname",
            "lname",
            "dob",
            "userType",
            "mobileNo",
            "email",
            "permission",
          ];
    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          userForm.permission = userForm.permission._id;
          if (props.formDialog.divType === "new") {
            delete userForm?.password;
          }
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t("snackbar.transComplete"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  // props.tableQuery({
                  //   keyword: '',
                  //   page: 0,
                  //   pageSize: 10,
                  //   sortField: '',
                  //   userType: 'all',
                  //   sortOrder: 'false',
                  //   active: true,
                  // });
                  setUserForm(userFormDetails);
                  setSelectedRowId("");
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  // console.log("props.userForm", userForm);
  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xl={
            userForm?.userType === "" || userForm?.userType === "admin" ? 12 : 6
          }
          lg={
            userForm?.userType === "" || userForm?.userType === "admin" ? 12 : 6
          }
          md={12}
          sm={12}
          xs={12}
        >
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: "center" }}
              >
                <h2>{props.t("user.personalInformation")}</h2>
              </Grid>
              <Grid
                xl={userForm?.userType === "admin" ? 4 : 6}
                lg={userForm?.userType === "admin" ? 4 : 6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="userType"
                  label={props.t("user.userType")}
                  className={classes.userTypeMenuItem}
                  select
                  required
                  fullWidth
                  defaultValue={userForm.userType}
                  disabled={
                    props.page !== undefined &&
                    props.page !== null &&
                    props.page === "createPatientPage"
                      ? true
                      : props?.page === "careTeam"
                      ? true
                      : false
                  }
                  value={userForm.userType}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      userType: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"user"}>
                    {props.t("user.user")}
                  </MenuItem>
                  <MenuItem key={1} value={"admin"}>
                    {props.t("user.admin")}
                  </MenuItem>
                  {/* <MenuItem key={1} value={"careTeam"}>
                    CareTeam
                  </MenuItem> */}
                </TextField>
              </Grid>
              <Grid
                xl={userForm?.userType === "admin" ? 4 : 6}
                lg={userForm?.userType === "admin" ? 4 : 6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="active"
                  label={props.t("user.active")}
                  className={classes.userTypeMenuItem}
                  select
                  required
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("user.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("user.inactive")}
                  </MenuItem>
                </TextField>
              </Grid>
              {userForm?.userType === "admin" ? (
                <Grid
                  xl={userForm?.userType === "admin" ? 4 : 6}
                  lg={userForm?.userType === "admin" ? 4 : 6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="isSuperAdmin"
                    className={classes.userTypeMenuItem}
                    label={props.t("user.isSuperAdmin")}
                    select
                    required
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.isSuperAdmin}
                    value={userForm.isSuperAdmin}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        isSuperAdmin: event.target.value,
                      })
                    }
                  >
                    <MenuItem key={0} value={true}>
                      {props.t("user.yes")}
                    </MenuItem>
                    <MenuItem key={1} value={false}>
                      {props.t("user.no")}
                    </MenuItem>
                  </TextField>
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="fname"
                  label={props.t("user.firstName")}
                  autoComplete="something-unsupported"
                  required
                  value={userForm.fname}
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      fname: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="lname"
                  label={props.t("user.lastName")}
                  autoComplete="something-unsupported"
                  required
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  fullWidth
                  value={userForm.lname}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      lname: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                />
              </Grid>
              <Grid
                xl={userForm?.userType === "admin" ? 4 : 6}
                lg={userForm?.userType === "admin" ? 4 : 6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="mobileNo"
                  label={props.t("user.mobileNumber")}
                  autoComplete="something-unsupported"
                  required
                  fullWidth
                  value={userForm.mobileNo}
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  inputProps={{ maxLength: 10 }}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      mobileNo: event.target.value,
                    })
                  }
                  // disabled={props.formDialog.divType === "view" ? true : false}
                />
              </Grid>
              <Grid
                xl={userForm?.userType === "admin" ? 4 : 6}
                lg={userForm?.userType === "admin" ? 4 : 6}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="email"
                  label={props.t("user.emailAddress")}
                  autoComplete="something-unsupported"
                  required={userForm?.userType === "admin" ? true : false}
                  fullWidth
                  value={userForm.email}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      email: event.target.value,
                    })
                  }
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      (event.charCode >= 48 && event.charCode <= 57) ||
                      event.charCode === 64 ||
                      event.charCode === 46
                      ? event
                      : event.preventDefault();
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>

              {userForm?.userType === "admin" ? (
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <Autocomplete
                    id="permission"
                    options={props?.roles}
                    value={userForm?.permission}
                    getOptionLabel={(option) => option?.name}
                    // onInputChange={getInventorList}
                    onChange={(event, value) =>
                      setUserForm({
                        ...userForm,
                        permission: value,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        label={props.t("user.permission")}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                mt={5}
                className={classes.formGrid}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="dob"
                    autoOk={true}
                    autoComplete="something-unsupported"
                    // autoComplete="off"
                    fullWidth
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    disableFuture={true}
                    label={props.t("user.dob")}
                    // format="dd/MM/yyyy"
                    format="MMM do, yyyy"
                    value={userForm.dob}
                    required
                    style={{ marginTop: "-0px" }}
                    onChange={(date) => {
                      // console.log(date);
                      if (date !== null) {
                        // var birthdate = new Date(date);
                        // var cur = new Date();
                        // var diff = cur - birthdate;
                        // var age = Math.floor(diff / 31536000000);
                        setUserForm({
                          ...userForm,
                          dob: date,
                          // age: age,
                        });
                      } else {
                        setUserForm({
                          ...userForm,
                          // age: '',
                        });
                      }
                    }}
                    // InputProps={{
                    //   disabled: true,
                    // }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="age"
                  label={props.t("user.age")}
                  autoComplete="something-unsupported"
                  fullWidth
                  disabled={true}
                  value={
                    userForm.dob !== null
                      ? Math.floor((new Date() - userForm.dob) / 31536000000)
                      : "-"
                  }
                  // onChange={(event) =>
                  //   setUserForm({
                  //     ...userForm,
                  //     age: event.target.value,
                  //   })
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="gender"
                  label={props.t("user.gender")}
                  select
                  required
                  autoComplete="something-unsupported"
                  InputLabelProps={{
                    shrink: userForm.gender !== "" ? true : false,
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                  fullWidth
                  defaultValue={userForm.gender}
                  value={userForm.gender}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      gender: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"male"}>
                    {props.t("user.male")}
                  </MenuItem>
                  <MenuItem key={1} value={"female"}>
                    {props.t("user.female")}
                  </MenuItem>
                  <MenuItem key={1} value={"other"}>
                    {props.t("user.other")}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid
                xl={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 6
                    : 12
                }
                lg={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 6
                    : 12
                }
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="address1"
                  label={props.t("user.address1")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm.address1}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address1: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              <Grid
                xl={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 6
                    : 12
                }
                lg={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 6
                    : 12
                }
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="address2"
                  label={props.t("user.address2")}
                  autoComplete="something-unsupported"
                  fullWidth
                  value={userForm.address2}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      address2: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              <Grid
                xl={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="city"
                  label={props.t("user.city")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  value={userForm.city}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      city: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              {userForm?.userType === "admin" && (
                <>
                  {props.formDialog.divType === "edit" ||
                  props.formDialog.divType === "view" ? (
                    <Grid
                      xl={
                        userForm.userType === "" ||
                        userForm?.userType === "admin"
                          ? 3
                          : 6
                      }
                      lg={
                        userForm.userType === "" ||
                        userForm?.userType === "admin"
                          ? 3
                          : 6
                      }
                      md={6}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id="password"
                        label="Password"
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={userForm.password}
                        onChange={(event) =>
                          setUserForm({
                            ...userForm,
                            password: event.target.value,
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <Grid
                xl={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="state"
                  label={props.t("user.state")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  value={userForm.state}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      state: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>

              <Grid
                xl={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="country"
                  label={props.t("user.country")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return (event.charCode >= 65 && event.charCode <= 90) ||
                      (event.charCode >= 97 && event.charCode <= 122) ||
                      event.charCode === 32
                      ? event
                      : event.preventDefault();
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  value={userForm.country}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      country: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
              <Grid
                xl={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                lg={
                  userForm.userType === "" || userForm?.userType === "admin"
                    ? 3
                    : 6
                }
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="pin"
                  label={props.t("user.pin")}
                  autoComplete="something-unsupported"
                  fullWidth
                  onKeyPress={(event) => {
                    return event.charCode >= 48 && event.charCode <= 57
                      ? event
                      : event.preventDefault();
                  }}
                  // disabled={
                  //   props.formDialog.divType === 'view' &&
                  //   props.rowDetails !== null &&
                  //   props.rowDetails.userType === 'admin'
                  //     ? true
                  //     : false
                  // }
                  inputProps={{ maxLength: 6 }}
                  value={userForm.pin}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      pin: event.target.value,
                    })
                  }
                  // disabled={
                  //   props.formDialog.divType === 'new' ||
                  //   props.formDialog.divType === 'edit'
                  //     ? false
                  //     : true
                  // }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {userForm?.userType !== "" && userForm?.userType !== "admin" ? (
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Paper className={classes.paper} elevation={1}>
              <Grid container>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  <h2>{props.t("user.medicalInformation")}</h2>
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "-10px" }}
                >
                  {props.t("user.bloodPressure")}
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className={classes.formGrid}
                >
                  <TextField
                    label={props.t("user.high")}
                    id="high"
                    fullWidth
                    autoComplete="something-unsupported"
                    value={
                      userForm.bloodPressure.high !== undefined &&
                      userForm.bloodPressure.high !== null
                        ? userForm.bloodPressure.high
                        : ""
                    }
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        bloodPressure: {
                          ...userForm.bloodPressure,
                          high: event.target.value,
                        },
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }

                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position='end'>
                    //       {props.t('user.Kg')}
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className={classes.formGrid}
                >
                  <TextField
                    label={props.t("user.low")}
                    id="low"
                    fullWidth
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    value={
                      userForm.bloodPressure.low !== undefined &&
                      userForm.bloodPressure.low !== null
                        ? userForm.bloodPressure.low
                        : ""
                    }
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        bloodPressure: {
                          ...userForm.bloodPressure,
                          low: event.target.value,
                        },
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    autoComplete="something-unsupported"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position='end'>
                    //       {props.t('user.Kg')}
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    label={props.t("user.bloodGroup")}
                    id="bloodGroup"
                    fullWidth
                    value={userForm.bloodGroup}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        bloodGroup: event.target.value,
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    autoComplete="something-unsupported"
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    label={props.t("user.weight")}
                    id="weight"
                    fullWidth
                    value={userForm.weight}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        weight: event.target.value,
                      })
                    }
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    autoComplete="something-unsupported"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* <Select
                            value={userForm.weightUnit || "kg"} // Set a default value or use the state value if available
                            onChange={(event) =>
                              setUserForm({
                                ...userForm,
                                weightUnit: event.target.value,
                              })
                            }
                            className={classes.select}
                          >
                            <MenuItem value="kg">{props.t("user.Kg")}</MenuItem>
                            <MenuItem value="lbs">
                              {props.t("user.lbs")}
                            </MenuItem>
                          </Select> */}
                          <ToggleButtonGroup
                            value={userForm.weightUnit || "lbs"}
                            exclusive
                            onChange={(event, newValue) =>
                              newValue !== null &&
                              setUserForm({
                                ...userForm,
                                weightUnit: newValue,
                              })
                            }
                            className={classes.toggleButtonGroup}
                          >
                            <ToggleButton
                              value="kg"
                              aria-label={props.t("user.Kg")}
                              className={classes.toggleButton}
                            >
                              {props.t("user.Kg")}
                            </ToggleButton>
                            <ToggleButton
                              value="lbs"
                              aria-label={props.t("user.lbs")}
                              className={classes.toggleButton}
                            >
                              {props.t("user.lbs")}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <TextField
                    label={props.t("user.weight")}
                    id="weight"
                    fullWidth
                    value={userForm.weight}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        weight: event.target.value,
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    autoComplete="something-unsupported"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.t("user.Kg")}
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    label={props.t("user.height")}
                    id="height"
                    fullWidth
                    value={userForm.height}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        height: event.target.value,
                      })
                    }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    autoComplete="something-unsupported"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* <Select
                            value={userForm.heightUnit || "inch"} // Set a default value or use the state value if available
                            onChange={(event) =>
                              setUserForm({
                                ...userForm,
                                heightUnit: event.target.value,
                              })
                            }
                            className={classes.select}
                          >
                            <MenuItem value="inch">
                              {props.t("user.Inch")}
                            </MenuItem>
                            <MenuItem value="cm">{props.t("user.cm")}</MenuItem>
                          </Select> */}
                          <ToggleButtonGroup
                            value={userForm.heightUnit || "inch"}
                            exclusive
                            onChange={(event, newValue) =>
                              newValue !== null &&
                              setUserForm({
                                ...userForm,
                                heightUnit: newValue,
                              })
                            }
                            className={classes.toggleButtonGroup}
                          >
                            <ToggleButton
                              value="inch"
                              aria-label={props.t("user.Inch")}
                              className={classes.toggleButton}
                            >
                              {props.t("user.Inch")}
                            </ToggleButton>
                            <ToggleButton
                              value="cm"
                              aria-label={props.t("user.cm")}
                              className={classes.toggleButton}
                            >
                              {props.t("user.cm")}
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="isSmoker"
                    label={props.t("user.isSmoker")}
                    select
                    fullWidth
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    autoComplete="something-unsupported"
                    defaultValue={userForm.isSmoker}
                    value={userForm.isSmoker}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        isSmoker: event.target.value,
                      })
                    }
                    style={{ marginTop: "-4px" }}
                  >
                    {smokerOptions.map((smokeOptions, index) => (
                      <MenuItem key={index} value={smokeOptions.value}>
                        {props.t(`user.smoker.${smokeOptions.display}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="alcoholIntake"
                    label={props.t("user.alcoholIntake")}
                    select
                    autoComplete="something-unsupported"
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.alcoholIntake}
                    value={userForm.alcoholIntake}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        alcoholIntake: event.target.value,
                      })
                    }
                    style={{ marginTop: "-4px" }}
                  >
                    {alcoholIntakeOptions.map((alcoholOptions, index) => (
                      <MenuItem key={0} value={alcoholOptions.value}>
                        {props.t(
                          `user.alcoholintake.${alcoholOptions.display}`
                        )}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* New field added for substances/drugs */}
                <Grid
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <TextField
                    id="substance"
                    label={props.t("user.substance")}
                    select
                    fullWidth
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    autoComplete="something-unsupported"
                    defaultValue={userForm.isSubstance}
                    value={userForm.isSubstance}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        isSubstance: event.target.value,
                      })
                    }
                    style={{ marginTop: "-4px" }}
                  >
                    {substanceOptions?.map((substanceOption, index) => (
                      <MenuItem key={index} value={substanceOption.value}>
                        {props.t(`${substanceOption.display}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <ChipInput
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.allergies}
                    onChange={(chip) =>
                      setUserForm({
                        ...userForm,
                        allergies: chip,
                      })
                    }
                    allowDuplicates={false}
                    placeholder={props.t("user.enterAllergies")}
                    label={props.t("user.allergies")}
                  />
                </Grid>

                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  // style={{ marginTop: '-10px' }}
                  className={classes.formGrid}
                >
                  <ChipInput
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.chronicConditions}
                    onChange={(chip) =>
                      setUserForm({
                        ...userForm,
                        chronicConditions: chip,
                      })
                    }
                    allowDuplicates={false}
                    placeholder={props.t("user.enterChronicConditions")}
                    label={props.t("user.chronicConditions")}
                  />
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <ChipInput
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.familyHistory}
                    onChange={(chip) =>
                      setUserForm({
                        ...userForm,
                        familyHistory: chip,
                      })
                    }
                    allowDuplicates={false}
                    placeholder={props.t("user.familyHistory")}
                    label={props.t("user.familyHistory")}
                  />
                  {/* <TextField
                    label={props.t('user.familyHistory')}
                    id='familyHistory'
                    fullWidth
                    value={userForm.familyHistory}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        familyHistory: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === 'new' ||
                      props.formDialog.divType === 'edit'
                        ? false
                        : true
                    }
                    autoComplete='off'
                  /> */}
                </Grid>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <ChipInput
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    // disabled={
                    //   props.formDialog.divType === 'view' &&
                    //   props.rowDetails !== null &&
                    //   props.rowDetails.userType === 'admin'
                    //     ? true
                    //     : false
                    // }
                    fullWidth
                    defaultValue={userForm.surgeries}
                    onChange={(chip) =>
                      setUserForm({
                        ...userForm,
                        surgeries: chip,
                      })
                    }
                    allowDuplicates={false}
                    placeholder={props.t("user.surgeries")}
                    label={props.t("user.surgeries")}
                  />
                  {/* <TextField
                    label={props.t('user.surgeries')}
                    id='surgeries'
                    fullWidth
                    value={userForm.surgeries}
                    onChange={(event) =>
                      setUserForm({
                        ...userForm,
                        surgeries: event.target.value,
                      })
                    }
                    disabled={
                      props.formDialog.divType === 'new' ||
                      props.formDialog.divType === 'edit'
                        ? false
                        : true
                    }
                    autoComplete='off'
                  /> */}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          // <div />
          <></>
        )}

        {props.formDialog.divType === "view" ? (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="center"
            style={{ marginTop: "20px" }}
          >
            <Button color="primary" onClick={() => sendToServer()}>
              {props.t("buttons.submit")}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
