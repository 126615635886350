import React, { useEffect, useRef } from "react";
import RecievedMsg from "./RecievedMsg";
import { CircularProgress, Stack } from "@mui/material";
import SenderMsg from "./SenderMsg";
// import { allImages } from "../../../../../../images";
// import { useBottomScrollListener } from "react-bottom-scroll-listener";
// import { BottomScrollListener } from "react-bottom-scroll-listener";

const MainChatArea = ({
  conversation,
  fetchMessages,
  selectedChat,
  hasMoreConverstion,
}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // this is for the pagination in chats
    const element = scrollRef.current;
    let currentPage = 0; // resets the current page whenever the selected chat changes
    const handleScroll = (e) => {
      const totalScrollHeight = element.scrollHeight - 750; // this is scroll total height of the div
      if (Math.abs(element.scrollTop) > totalScrollHeight) {
        // if the user has reached 800px height away from top the loading starts
        currentPage++;
        fetchMessages(selectedChat?._id, currentPage);
      }
    };
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [selectedChat]);

  return (
    <Stack
      className="scrollsmall"
      style={{
        // backgroundImage: `url(${allImages?.doodles})`,
        padding: "1rem 1rem",
        flexGrow: "2",
        flexDirection: "column-reverse",
        gap: 2,
        overflowY: "scroll",
        height: "400px",
      }}
      ref={scrollRef}
    >
      {conversation &&
        conversation?.length > 0 &&
        conversation?.map((value, ind) => {
          let showProfile = true;
          let showTime = true;
          if (conversation[ind + 1]?.isMessageSend === value?.isMessageSend) {
            // whether to showprofile if the next message from sender or reciever
            showProfile = false;
          }
          // checks the next index time is same
          const nextTime = new Date(
            conversation[ind - 1]?.time * 1000
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });
          // checks the current index time is same

          const currentTime = new Date(value?.time * 1000).toLocaleTimeString(
            "en-US",
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          );
          if (nextTime === currentTime) {
            // if a message is sent at same time its not required to display
            showTime = false;
          }
          let date;

          if (
            ind !== 0 &&
            new Date(
              conversation[ind + 1]?.time * 1000
            ).toLocaleDateString() !==
              new Date(value?.time * 1000).toLocaleDateString()
          ) {
            date = new Date(value?.time * 1000).toLocaleDateString("en-IN");
          }

          return (
            <React.Fragment key={ind}>
              {value?.isMessageSend ? (
                <SenderMsg
                  content={value?.msg}
                  image={value?.profileImage}
                  lastMessage={showProfile}
                  isMedia={value?.isMedia}
                  media={value?.media}
                  time={value?.time}
                  showTime={showTime}
                />
              ) : (
                <RecievedMsg
                  content={value?.msg}
                  image={value?.profileImage}
                  lastMessage={showProfile}
                  isMedia={value?.isMedia}
                  media={value?.media}
                  time={value?.time}
                  showTime={showTime}
                />
              )}
              {date && <Stack className="date__time--order">{date}</Stack>}
            </React.Fragment>
          );
        })}
      <Stack alignItems="center">
        {hasMoreConverstion && <CircularProgress />}
      </Stack>
    </Stack>
  );
};

export default MainChatArea;
