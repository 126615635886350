class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
      
          id:- Use for uniquely identify each column in data table,
          accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                      Example:- 
                                  1> name, is from parent, ( {name:'name'} )
                                  2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
          dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                      1> string & date - left (handle in DataTable.js)
                      2> boolean- center (handle in DataTable.js)
                      3> number-  right (handle in DataTable.js)
          sortable:- Use to enable & disable sorting for perticuler column.
          disablePadding:- Use to enable padding.
          visibility:- Use to toggle column visibility.
          width:- Use to set individual column min-width in px. It's should be number format.
          label:- Refer the values from translations file, (./src/translations/**.js).
          className:- It's basically the js file name & all the file should be available in utils folder.
          functionName:-  It's basically the function name, which should be available inside above className 
          
      ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static tableHeader = [
    {
      id: 'status',
      accessor: 'retire',
      dataType: 'boolean',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'case.caseretireStatus',
      className: '',
      functionName: '',
    },
    {
      id: 'itemName',
      accessor: 'itemName',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: 'case.itemName',
      className: '',
      functionName: '',
    },
    {
      id: 'strength',
      accessor: 'itemId.dosageForm',
      dataType: 'string',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 100,
      label: 'case.strength',
      className: '',
      functionName: '',
    },
    {
      id: 'form',
      accessor: 'itemId.form',
      dataType: 'string',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'user.form',
      className: '',
      functionName: '',
    },
    {
      id: 'doctorName',
      accessor: 'doctor.name',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: 'user.doctor',
      className: 'StringUtils',
      functionName: 'doctor',
    },

    {
      id: 'startAt',
      accessor: 'startDate',
      dataType: 'date',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'user.startDate',
      className: 'DateConversion',
      functionName: 'unixToLocaleDate',
    },
    {
      id: 'endDate',
      accessor: 'endDate',
      dataType: 'date',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: 'user.endDate',
      className: 'DateConversion',
      functionName: 'unixToLocaleDate',
    },

    // {
    //   id: 'intake',
    //   accessor: 'intake',
    //   dataType: 'string',
    //   sortable: false,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 170,
    //   label: 'case.whenToTake',
    //   className: 'StringUtils',
    //   functionName: 'objectIntake',
    // },
    {
      id: 'intake',
      accessor: 'intakeData',
      dataType: 'string',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 195,
      label: 'user.intake',
      className: 'StringUtils',
      functionName: 'arrayToString',
    },
    // {
    //   id: 'needed',
    //   accessor: 'sos',
    //   dataType: 'string',
    //   sortable: false,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 195,
    //   label: 'InCase',
    //   className: '',
    //   functionName: '',
    // },
    {
      id: 'frequency',
      accessor: 'frequency',
      dataType: 'string',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.frequency',
      className: '',
      functionName: '',
    },
    {
      id: 'forMedicalConditions',
      accessor: 'forMedicalConditions',
      dataType: 'string',
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 180,
      label: 'user.forMedicalConditions',
      className: '',
      functionName: '',
    },
    // {
    //   id: 'caseId',
    //   accessor: 'caseId',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 100,
    //   label: 'case.caseId',
    //   className: 'StringUtils',
    //   functionName: 'url',
    // },
  ];
}
export default TableHeader;
