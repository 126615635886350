import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Paper,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ChipInput from 'material-ui-chip-input';
import APIRequest from '../../../../utils/APIRequest';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import { connect } from 'react-redux';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));
function AssignRole(props) {
  const classes = useStyles();
  const [assigned, setAssigned] = useState([]);
  const [unAssigned, setUnAssigned] = useState([]);
  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'submit'
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'reset'
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== '' &&
      props.formDialog.divType !== 'new' &&
      props.formDialog.functionName === ''
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const handleDualchange = (selected, value) => {
    setAssigned(selected);
  };

  const resetForm = () => {
    setAssigned([]);
    setUnAssigned([]);
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== 'new'
    ) {
      APIRequest.request(
        'POST',
        ConfigAPIURL.roleUnassigned,
        JSON.stringify({ roleId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setAssigned(response.data.admins);

            let employeeList = [];
            response.data.employees.map((employees) => {
              employeeList.push({
                label: employees.fname + ' ' + employees.lname,
                value: employees._id,
              });
            });
            setUnAssigned(employeeList);
          }
        }
      });
    }
  };

  const sendToServer = () => {
    APIRequest.request(
      'PUT',
      ConfigAPIURL.roleGrant,
      JSON.stringify({ roleId: props.rowDetails._id, assign: assigned })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          SnackbarUtils.sucess(
            props.t('snackbar.transComplete'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
          props.handleFormDialog();
          // props.tableQuery({
          //   keyword: '',
          //   page: 0,
          //   pageSize: 10,
          //   sortField: '',
          //   userType: 'all',
          //   sortOrder: 'false',
          //   active: true,
          // });
          setAssigned([]);
        }
        if (response.code === 100 && response.data.responseCode === 114) {
          SnackbarUtils.error(
            props.t('snackbar.dupliRecords'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };
  return (
    <div className={classes.root}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: 'center' }}
              >
                <h2>{props.t('role.assignRole')}</h2>
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <DualListBox
                  options={unAssigned}
                  selected={assigned}
                  onChange={handleDualchange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(AssignRole)
);
