import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Link,
  ListItemText,
  Button,
} from '@material-ui/core';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import APIRequest from '../../../../utils/APIRequest';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import StarIcon from '@material-ui/icons/Star';
import { connect } from 'react-redux';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import FormDialog from '../../../../templates/dialog/FormDialog';
import AEVForm from '../processCase/AEVForm';
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  heading: {
    flexBasis: '67.33%',
    flexShrink: 0,
  },
}));

function NonDeletableProducts(props) {
  const classes = useStyles();
  const [notificationList, setNotificationList] = useState([]);

  const [rowDetails, setRowDetails] = useState(null);
  const [slideAssignDialog, setSlideAssignDialog] = useState({
    status: false,
    title: '',
    divType: '',
  });

  const [medicineName, setMedicineName] = React.useState('');
  const handleFormDialog = () => {
    setRowDetails(null);
    props.handleSlideAssignDialog();
    setOpenForm({ status: false, title: '', divType: '', functionName: '' });
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: 'submit' });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: 'reset' });
  };
  const [openForm, setOpenForm] = useState({
    status: false,
    title: '',
    divType: '',
    functionName: '',
  });

  const handleSlideInDialog = () => {
    props.handleSlideAssignDialog();
    setSlideAssignDialog({ status: false, title: '', divType: '' });
  };

  const getNotificationTemplate = () => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.getNotificationTemplate + '?notificationType=notice',
      ''
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          let serverResponse = response.data.result;

          serverResponse.map((results) => {
            results['visibleOn'] = {
              app: false,
              home: false,
              sms: false,
              whatsApp: false,
              email: false,
            };
          });

          setNotificationList(serverResponse);

          // let array = new Array(response.data.result.length).fill(false);

          // setNotificationRadio(array);
        }
      }
    });
  };

  const getMedicationData = (caseId, medicationId) => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.adminCaseMongoId,
      JSON.stringify({
        caseId: caseId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          props.handleSlideAssignDialog();
          setRowDetails([response.data.result._id]);
          setMedicineName(medicationId);
          setOpenForm({
            status: true,
            title: props.t('case.editProcessCase'),
            divType: 'edit',
            functionName: '',
          });
        } else {
          SnackbarUtils.error(
            props.t('snackbar.recordNotFound'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      } else {
        SnackbarUtils.error(
          props.t('snackbar.recordNotFound'),
          'bottomCenter',
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant='p' component='p'>
            {props.t('products.NonDeletableProductsAvailable')}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            {props.nonDeletableProductList.map((products, index) => (
              <Grid item xl={4} lg={4} md={4} sm={6} xs={6} key={index}>
                <Typography variant='p' component='p'>
                  {products.itemName}
                </Typography>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() => {
                    getMedicationData(products.caseId, products.itemName);
                  }}
                >
                  {products.caseId}
                </Link>
                {/* <List className={classes.root} aria-label='products'>
                  <ListItem>
                    <ListItemText
                      primary={products.itemName + '-' + products.caseId}
                    />
                  </ListItem>
                </List> */}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant='p' component='p'>
            {props.t('products.deleteThereToDeleteHere')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '20px', textAlign: 'right' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            onClick={() => props.handleSlideAssignDialog()}
            color='primary'
          >
            {props.t('buttons.close')}
          </Button>
          {/* <Button
            onClick={() => props.handleProcessedCaseConfirmDialog('proceed')}
            color='primary'
          >
            {props.t('buttons.proceed')}
          </Button> */}
        </Grid>
      </Grid>

      <FormDialog
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          <AEVForm
            medicineName={medicineName}
            notificationList={notificationList}
            slideAssignDialog={slideAssignDialog}
            setNotificationList={setNotificationList}
            handleSlideInDialog={handleSlideInDialog}
            getNotificationTemplate={getNotificationTemplate}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={props.handleTableData}
          />
        }
      />
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(NonDeletableProducts)
);
