import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './Login';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';
import PasswordGenaration from './PasswordGenaration';
export default class Content extends Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path='/' render={() => <Redirect to='/login' />} /> */}

        <Route exact path={['/login']} component={Login} />

        <Route
          exact
          path={['/user/password/generate']}
          component={PasswordGenaration}
        />

        <Route exact path={['/contactus']} component={ContactUs} />
        <Route exact path={['/privacypolicy']} component={PrivacyPolicy} />
        <Route exact path={['/termsofservice']} component={TermsOfService} />
        <Route render={() => <h3>Coming soon.</h3>} />
      </Switch>
    );
  }
}
