import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t('query.activeStatus')}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t('query.active')}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t('query.inactive')}
              </MenuItem>
            </TextField>
          </Grid> */}
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='customLabelWidth'
          >
            <TextField
              label={props.t('query.isUpdatedInventory')}
              select
              defaultValue={props.query.isCompleted}
              value={props.query.isCompleted}
              onChange={(event) =>
                props.setQuery({
                  ...props.query,
                  isCompleted: event.target.value,
                })
              }
              fullWidth
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={'all'}>
                {props.t('query.all')}
              </MenuItem>
              <MenuItem key={0} value={true}>
                {props.t('query.isCompleted')}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t('query.isNotCompleted')}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t('query.stock')}
              select
              defaultValue={props.query.availableQuery}
              value={props.query.availableQuery}
              onChange={(event) =>
                props.setQuery({
                  ...props.query,
                  availableQuery: event.target.value,
                })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={'all'}>
                {props.t('query.all')}
              </MenuItem>
              {props.stockOptions.options.map((stockOptions, index) => (
                <MenuItem key={index + 1} value={stockOptions.value}>
                  {stockOptions.display}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation('translations')(TableQuery);
