import PropTypes from 'prop-types';
const WebSite = {
	seoFormatter: function (details) {
		let potentialAction = details.potentialActionRequired
			? {
					'@type': 'SearchAction',
					target: details.target, // url with get request, e.g:- https://example.com/search?q=
					'query-input': 'required name=search_term_string',
			  }
			: '';
		return {
			'@context': 'http://schema.org',
			'@type': 'WebSite',
			about: details.about,
			copyrightYear: details.copyrightYear,
			image: {
				'@type': 'ImageObject',
				caption: details.caption,
				representativeOfPage: 'true',
				contentUrl: details.contentUrl,
			},
			mainEntityOfPage: details.mainEntityOfPage,
			name: details.name,
			description: details.description,
			sameAs: details.socialLinks,
			potentialAction,
		};
	},
};
WebSite.propTypes = {
	about: PropTypes.string.isRequired,
	copyrightYear: PropTypes.string.isRequired,
	caption: PropTypes.string.isRequired,
	contentUrl: PropTypes.string,
	mainEntityOfPage: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	socialLinks: PropTypes.array,
	potentialActionRequired: PropTypes.boolean,
	target: PropTypes.string,
};
export default WebSite;
