import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ReminderNotification from "./ReminderNotification";
import AEVForm from "./AEVForm";
import MedicationDetails from "./MedicationDetails";
import PatientConnect from "./patientConnect/index";
import AccountAccess from "./accountAccess/index";
import ProcessCase from "../processCase";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import DoctorDetails from "./DoctorDetails";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* //{' '}
          <Box p={3} style={{ marginTop: '120px' }}>
            // <Typography>{children}</Typography>
            //{' '}
          </Box> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  if (index !== undefined && index !== null) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 60,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ViewUser(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tabLabels, setTabLabels] = React.useState([
    "patient Profile",
    "account Access",
    "patient Connect",
    "reminders",
    "doctors",
    "medications",
    "prescriptions",
    "lab Results",
    "Counseling"
  ]);
  const [tabs, setTabs] = React.useState([
    <AEVForm
      marginTop={0}
      roles={props.roles}
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
      handleFormDialog={props.handleFormDialog}
    />,
    <AccountAccess
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
    />,
    <PatientConnect
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
      publishNotifications={props.publishNotification}
    />,
    <ReminderNotification
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
    ></ReminderNotification>,
    <DoctorDetails
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
    ></DoctorDetails>,

    <MedicationDetails
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
    />,
    <ProcessCase
      pageName={"Prescription"}
      selectedRowId={
        props.rowDetails !== undefined && props.rowDetails !== null
          ? props.rowDetails._id
          : null
      }
    />,
    <ProcessCase
      pageName={"Lab Result"}
      selectedRowId={
        props.rowDetails !== undefined && props.rowDetails !== null
          ? props.rowDetails._id
          : null
      }
    />,
    <ProcessCase
      pageName={"counselling"}
      selectedRowId={
        props.rowDetails !== undefined && props.rowDetails !== null
          ? props.rowDetails._id
          : null
      }
    />,
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (
            response.code === 100 &&
            response.data !== undefined &&
            response.data.responseCode === 109
          ) {
            let tabLabelIndex = [];
            response.data.user.permission.permission.map((data) => {
              if (data.label === "Users") {
                data.buttons.map((button) => {
                  tabLabels.map((tabs) => {
                    if (button.label === tabs && button.enable === false) {
                      tabLabelIndex.push(tabLabels.indexOf(button.label));
                    }
                  });
                });
              }
            });

            let tabLabel = tabLabels;
            let tab = tabs;
            for (var i = tabLabelIndex.length - 1; i >= 0; i--) {
              tabLabel.splice(tabLabelIndex[i], 1);
              tab.splice(tabLabelIndex[i], 1);
            }
            setTabLabels([...tabLabel]);
            setTabs([...tab]);
          }
        }
      }
    );
  }, []);

  return (
    <div className={classes.root}>
      <Hidden only={["xl", "lg", "md"]}>
        <AppBar position="fixed">
          <Tabs
            value={value}
            className={!props.sideDrawerData ? "userTableViewTabs" : ""}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabLabels.map((labels, index) => (
              <Tab label={props.t(labels)} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <AppBar position="fixed" style={{ marginTop: "70px" }}>
          <Tabs
            value={value}
            className={!props.sideDrawerData ? "userTableViewTabs" : ""}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabLabels.map((labels, index) => (
              <Tab label={props.t(labels)} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </Hidden>

      {tabs.map((tab, idx) => (
        <TabPanel value={value} index={idx}>
          {tab}
        </TabPanel>
      ))}
      {/* <TabPanel
        value={value}
        index={
          LocalStorage.adminButtonPermission.find(function (element) {
            return element.button === 'notes' ? true : false;
          })
            ? 5
            : null
        }
      >
        {props.t('user.notes')}
      </TabPanel> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(ViewUser)
);

// export default withTranslation('translations')(ViewUser);
