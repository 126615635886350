import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';

import StarIcon from '@material-ui/icons/Star';
import { connect } from 'react-redux';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  heading: {
    flexBasis: '67.33%',
    flexShrink: 0,
  },
}));
function ProcessedCases(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {props.t('case.processedCaseAvailable')}
        </Grid>
        {props.processedCaseData.map((cases, index) => (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={index}>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                <List className={classes.root} aria-label='cases'>
                  <ListItem button>
                    <ListItemIcon>
                      <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary={cases.caseId} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container style={{ marginTop: '20px', textAlign: 'right' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            onClick={() => props.handleProcessedCaseConfirmDialog('cancel')}
            color='primary'
          >
            {props.t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => props.handleProcessedCaseConfirmDialog('proceed')}
            color='primary'
          >
            {props.t('buttons.proceed')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(ProcessedCases)
);
