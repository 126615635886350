import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import DataTable from "../../../../templates/tables/DataTable";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
  FormGroup,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  InputLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useAevForm from "./hooks/useAevForm";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));

function EditFormTemplate(props) {
  const classes = useStyles();
  const {
    appointmentCreateData,
    setAppointmentCreateData,
    tableData,
    query,
    resetQuery,
    handleQuery,
    getTableData,
    sendToServer,
    setTableData,
  } = useAevForm(props);



  console.log("appointmentCreateData",appointmentCreateData);

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Paper elevation={1} style={{ padding: "20px" }}>
            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id="title"
                  label={props.t("advertisements.title")}
                  autoComplete="off"
                  required
                  value={appointmentCreateData?.title}
                  fullWidth
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      title: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid
                xl={8}
                lg={8}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "-2px" }}
                className={classes.formGrid}
              >
                <TextField
                  id="description"
                  label={props.t("advertisements.description")}
                  autoComplete="off"
                  multiline
                  fullWidth
                  value={appointmentCreateData?.description}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      description: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>

              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={6}
                xs={12}
                style={{ marginTop: "15px" }}
              >
                <TextField
                  id="status"
                  label={props.t("sessions.status")}
                  select
                  required
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={appointmentCreateData?.active}
                  value={appointmentCreateData.active}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("sessions.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                   InActive
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={3}
                lg={3}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
                style={{ marginTop: "15px" }}
              >
                <TextField
                  id="siteLink"
                  label={props.t("sessions.meetlink")}
                  autoComplete="off"
                  required
                  fullWidth
                  value={appointmentCreateData?.meetLink}
                  onChange={(event) =>
                    setAppointmentCreateData({
                      ...appointmentCreateData,
                      meetLink: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>
            </Grid>

            {/* <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button color="primary" onClick={sendToServer}>
                Create
              </Button>
            </Grid> */}
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(EditFormTemplate)
);
