/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withTranslation } from "react-i18next";
import {makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import "./style.css";
import LocalStorage from "../../../../../config/LocalStorage";
import ChatArea from "./chatArea/Index";
import { connect } from "react-redux";
import useChat from "./hooks/useChat";
import Container from "@material-ui/core/Container";

export const DataContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

function CustomerService(props) {
  const { rowDetails } = props;
  const userDetails = LocalStorage.userDetails;
  const userData = { ...userDetails };

  //here we are using custom hook functions for chat

  const {
    handleConnect,
    sideChatList,
    sendMessage,
    fetchMessages,
    conversation,
    calculateMinutes,
    selectedChat,
    hasMoreConverstion,
    handleSearchList,
    setSelectedChat,
    markAsResolve,
  } = useChat(userData, rowDetails, props);
  // useEffect(() => {
  //   // if any value found state location then that is the reviever
  //   if (!location?.state) return;
  //   setRowDetails({
  //     ...location?.state,
  //   });
  // }, []);
  const classes = useStyles();

  const location = useLocation();

  //console.log(rowDetails, "from details printing");

  return (
    <DataContext.Provider
      value={{
        handleConnect,
        sideChatList,
        selectedChat,
        userData,
        sendMessage,
        fetchMessages,
        conversation,
        calculateMinutes,
        hasMoreConverstion,
        handleSearchList,
        rowDetails,
      }}
    >
      <div className={classes.root}>
        <Container style={{ padding: 0 }}>
          <ChatArea />
        </Container>
      </div>
    </DataContext.Provider>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(CustomerService)
);
