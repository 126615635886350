import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { withTranslation } from "react-i18next";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import APIRequest from "../../../../../utils/APIRequest";
import CreatePatient from "./CreatePatient";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function AssignedCareTeam(props) {
  const classes = useStyles();

  const [openAddForm, setOpenAddForm] = useState(false);

  const handleAddPatients = () => {
    setOpenAddForm(true);
  };
  const handleAddPatientsDialog = () => {
    setOpenAddForm(false);
  };

  // const handleUserList = (event, value) => {
  //   setpatient(value);
  // };

  const handledelete = (selected) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.removeCareTeam,
      JSON.stringify({
        userId: props.rowDetails._id,
        careTeamId: selected._id,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response?.code === 100) {
          props.handleRefreshData();
          // setpatient('');
          // props.getUserList();
          SnackbarUtils.sucess(
            props.t("snackbar.transComplete"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        } else if (response?.data?.responseCode === 125) {
          SnackbarUtils.error(
            "Something went wrong!",
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };
  return (
    <div>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.root}>
            <CardHeader
              action={
                <Tooltip title={props.t("dataTable.add")}>
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      handleAddPatients();
                    }}
                  >
                    <AddCircleIcon variant="contained" />
                  </IconButton>
                </Tooltip>
              }
              title={
                <Typography
                  variant="p"
                  component="p"
                  style={{ fontWeight: "bold" }}
                >
                  {props.t("patientAccess.assignedCareTeam")}
                </Typography>
              }
            />
            <CardContent>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {props.careTeamData.length === 0 ? (
                    <Card
                      style={{
                        marginTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              variant="p"
                              component="p"
                            >
                              No Records Found
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    props.careTeamData.map((patients, index) => (
                      <Card key={index}>
                        <CardContent>
                          <Tooltip
                            title={props.t("dataTable.delete")}
                            style={{ float: "right" }}
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => handledelete(patients)}
                            >
                              <DeleteIcon variant="contained" />
                            </IconButton>
                          </Tooltip>

                          <Typography
                            variant="p"
                            component="p"
                            style={{ fontWeight: "bold" }}
                          >
                            {props.t("patientAccess.name")}
                          </Typography>
                          <Typography variant="p" component="p">
                            {patients.fname + " " + patients.lname}
                          </Typography>
                          {/* <Grid container> */}
                          <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
                            <Typography
                              variant="p"
                              component="p"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.t("patientAccess.mobileNo")}
                            </Typography>
                            <Typography variant="p" component="p">
                              {patients.mobileNo}
                            </Typography>
                          </Grid>
                          <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
                            <Typography
                              variant="p"
                              component="p"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.t("patientAccess.email")}
                            </Typography>
                            <Typography variant="p" component="p">
                              {patients.email}
                            </Typography>
                          </Grid>
                          {/* </Grid> */}
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SlideInDialog
        title={props.t("patientAccess.addCareTeam")}
        contentComponent={
          <CreatePatient
            page={"careTeam"}
            // getUserLists={props.getUserList}
            label={props.t("patientAccess.searchCareTeam")}
            // userList={props.userList}
            // patient={patient}
            handleAddPatientsDialog={handleAddPatientsDialog}
            rowDetails={props.rowDetails}
            handleRefreshData={props.handleRefreshData}
            // handleUserList={handleUserList}
            // handleAssignDialog={handleAssignDialog}
          ></CreatePatient>
        }
        fullWidth={true}
        maxWidth={"sm"}
        slideInDialog={openAddForm}
        dialogActionsStatus={false}
      />
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AssignedCareTeam)
);
