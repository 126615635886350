import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  CardActions,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";

import TableHeader from "./MedicationTableHeader";
import MedicationTableQuery from "./MedicationTableQuery";

import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import MedicationTableWithPagination from "../../../../templates/tables/MedicationTableWithPagination";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import CustomerViewForm from "./CustomerViewForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    marginTop: "80px",
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

// const tableHeader = [
//   {
//     id: 'itemName',
//     accessor: 'itemName',
//     dataType: 'string',
//     sortable: true,
//     disablePadding: false,
//     visibility: true,
//     width: 200,
//     label: 'user.itemName',
//     className: '',
//     functionName: '',
//   },
// ];

function AEVForm(props) {
  const classes = useStyles();
  const [medicationRecords, setMedicationRecords] = useState([]);
  const [filterRecords, setFilterRecords] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [query, setQuery] = useState({
    page: 0,
    pageSize: 100,
    sortField: "",
    sortOrder: "false",
    active: "all",
  });

  const [openCustomerViewForm, setOpenCustomerViewForm] = useState(false);

  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  useEffect(() => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog !== undefined &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getMedicalInfo(query);
    }
  }, [props.formDialog]);

  const getMedicalInfo = (query) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getMedicationList,
      JSON.stringify({ ...query, userId: props.rowDetails._id })
    ).then((response) => {
      let serverResponse = response.data;
      serverResponse.rows.map((results) => {
        results["intakeData"] = [];
        results.intakeData.push(
          results.whenToTake.includes("Morning")
            ? `${results.intakeQuantity.Morning} Morning ${results.intake.Morning} Meal`
            : "",
          results.whenToTake.includes("Afternoon")
            ? `${results.intakeQuantity.Afternoon} Afternoon ${results.intake.Afternoon} Meal`
            : "",
          results.whenToTake.includes("Evening")
            ? `${results.intakeQuantity.Evening} Evening ${results.intake.Evening} Meal`
            : "",
          results.whenToTake.includes("Night")
            ? `${results.intakeQuantity.Night} Night ${results.intake.Night} Meal`
            : "",
          results.whenToTake.includes("OtherTime")
            ? `${results.intakeQuantity.OtherTime} ${results.intake.Other} Meal at ${results.intake.OtherTime}`
            : "",
          results.whenToTake.includes("sos") ? "Emergency Medication (SOS)" : ""
        );
        
      });

      // serverResponse.map((responseServer) => {
      //   responseServer.intakeData.map((intakes) => {
      //     if (intakes !== '') {
      //       console.log('intakesintakes', intakes);
      //     }
      //   });
      // });

      setTableData(serverResponse);
    });
  };

  console.log(tableData,"from mediatonsnsnsn")

  const handleQuery = (query) => {
    setQuery(query);
    getMedicalInfo(query);
  };

  const handleTableData = () => {
    getMedicalInfo(query);
  };

  const resetQuery = () => {
    setQuery({
      page: 0,
      pageSize: 100,
      sortField: "",
      sortOrder: "false",
      active: "all",
    });
    getMedicalInfo({
      page: 0,
      pageSize: 100,
      sortField: "",
      sortOrder: "false",
      active: "all",
    });
  };

  const handleOpenCustomerViewForm = () => {
    setOpenCustomerViewForm(true);
  };
  const handleCustomerForm = () => {
    setOpenCustomerViewForm(false);
  };

  console.log("tableDatatableData", tableData);

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "10px" }}
        >
          <MedicationTableWithPagination
            tableHeader={TableHeader.tableHeader}
            tableData={tableData}
            query={query}
            setQuery={handleQuery}
            resetQuery={resetQuery}
            handleTableData={handleTableData}
            selectAll={false}
            queryComponent={
              <MedicationTableQuery query={query} setQuery={handleQuery} />
            } //filter menu option
            handleOpenCustomerViewForm={handleOpenCustomerViewForm}

            // pageNo={pageNo}
            // getMedicalInfo={getMedicalInfo}
            // // tableHeader={tableHeader}
            // tableRecord={medicationRecords}
            // filterRecords={filterRecords}
          />
        </Grid>
      </Grid>
      {/* -------------------------------Customer View Form---------------------------------- */}
      <SlideInDialog
        title={props.t("patientAccess.customerView")}
        contentComponent={
          <CustomerViewForm
            setSlideInDialog={handleCustomerForm}
            rowDetails={props.rowDetails}
            medicationData={tableData?.rows}
            // getNotifications={getNotifications}
          />
        }
        fullWidth={true}
        maxWidth={"xl"}
        dialogActionsStatus={false}
        slideInDialog={openCustomerViewForm}
        setSlideInDialog={handleCustomerForm}
        onCloseButton={handleCustomerForm}
        // handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}
export default withTranslation("translations")(AEVForm);
