import { Stack, Typography } from "@mui/material";
import React from "react";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { FileIcon, defaultStyles } from "react-file-icon";
const ext = ["jpeg", "jpg", "png", "gif", "tiff", "svg"];
const Media = ({ media }) => {
  return (
    <>
      {ext?.includes(media?.type?.toLowerCase()) ? (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img
          onClick={() => window.open(media?.url)}
          src={media?.url}
          style={{ width: "100%", borderRadius: "12px", cursor: "pointer" }}
          alt="media image"
        />
      ) : (
        <Stack className="document">
          <Stack className="document__ext">
            <FileIcon extension={media?.type} {...defaultStyles[media?.type]} />
          </Stack>
          <Stack>
            <Typography variant="subtitle1">{media?.name}</Typography>
          </Stack>
          <ArrowCircleDownIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              var link = document.createElement("a");
              link.href = media?.url;
              link.download = media?.url.substr(
                media?.url.lastIndexOf("/") + 1
              );
              link.click();
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default Media;
