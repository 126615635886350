import { createMuiTheme } from '@material-ui/core';
import './font.css';

const font = 'Poppins';

export const LIGHT = createMuiTheme({
  name: 'LIGHT',
  overrides: {
    //.MuiPopover-paper

    MuiPopover: {
      paper: {
        minWidth: '200px',
      },
    },
    MuiDialog: {
      root: {
        // background: 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
      container: {
        // background: 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
      paperFullScreen: {
        background:
          'linear-gradient(30deg, #6da5de  0%,#6da5de  15%, #7DE3DC 100%)',
        // 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        background:
          'linear-gradient(30deg, #6da5de  0%,#6da5de  15%, #7DE3DC 100%)',
        // 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
    },
    MuiGrid: {
      root: {
        paddingRight: '3px',
        fontFamily: font,
      },
    },

    MuiCheckbox: {
      colorSecondary: {
        Mui: {
          checked: {
            color: '#fff',
          },
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        fontFamily: font,
      },
    },

    MuiTypography: {
      root: {
        fontFamily: font,
        fontSize: 16,
        // color: '#666c73',
      },
      body1: {
        fontFamily: font,
        fontSize: 16,
        // color: '#666c73',
      },
      body2: {
        fontFamily: font,
        fontSize: 16,
      },
    },

    // MuiTabScrollButton-root

    MuiTab: {
      wrapper: {
        fontFamily: font,
        fontSize: '16px',
        fontWeight: 'bold',
      },
      textColorInherit: {
        opacity: 'unset',
      },
      // scrollButtons: {
      //   backgroundColor: 'black',
      //   color: 'black',
      // },
    },

    MuiTableCell: {
      stickyHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: font,
        color: '#666c73',
      },
      head: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: font,
        color: '#666c73',
      },

      body: {
        fontFamily: font,
      },
    },
    // MuiFormLabel: {
    // 	root: {
    // 		color: '#42A5F5',
    // 		'&$focused': {
    // 			color: '#42A5F5',
    // 		},
    // 	},
    // 	asterisk: {
    // 		color: 'red',
    // 	},
    // },

    MuiInputLabel: {
      formControl: {
        fontSize: 16,
        width: 'max-content',
        fontFamily: font,
        lineHeight: '0',
      },
      asterisk: {
        color: 'red',
      },
    },

    MuiInputBase: {
      input: {
        fontSize: 14,
        fontFamily: font,
      },
    },
    // MuiInput: {
    //   underline: {
    //     '&:hover:not($disabled):after': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:hover:not($disabled):before': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:focus:not($disabled):after': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:focus:not($disabled):before': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:after': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:before': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     borderBottom: '1px solid #42A5F5',
    //   },
    // },

    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#fff',
      },
    },

    MuiButton: {
      root: {
        // background: '#fff',
        // '&:hover': {
        //   backgroundColor: '#fff',
        // },
      },
      textPrimary: {
        // color: '#fff',
        // '&:hover': {
        //   backgroundColor: '#fff',
        // },
      },
      label: {
        backgroundColor: '#fff',
        border: '2px solid #669fba',
        padding: '7px 20px',
        borderRadius: 15,
        color: '#669fba',
        fontWeight: 'bold',
      },
    },
    MuiTableRow: {
      selected: {
        backgroundColor: '#669fba',
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        // height: 'unset',
        height: '125px',
        // backgroundColor: 'unset',
      },
    },

    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: '#000',
      },
      toolbarTxt: { fontWeight: 'bold', color: '#669fba' },
    },

    MuiPickerDTToolbar: {
      separator: {
        color: '#fff',
      },
    },

    MuiPickersModal: {
      dialog: {
        overflow: 'hidden',
      },
    },

    // MuiPickersModal: {
    //   dialog: {
    //     overflow: 'initial',
    //   },
    // },

    MuiPickersToolbarButton: {
      toolbarBtn: {
        textTransform: 'math-auto',
      },
    },
  },

  palette: {
    type: 'light',
    primary: {
      main: '#419fab',
    },
    secondary: {
      main: '#419fab',
    },
    info: {
      main: '#669fba',
    },
    background: {
      default: '#fafafa',
    },
  },
  custom: {
    background: '#fff',
    containerColor:
      'linear-gradient(30deg, #6da5de  0%,#6da5de  15%, #7DE3DC 100%)',
    // containerColor:
    //   'linear-gradient(-45deg, #2196F3 0%, #2196F3 33%, #00BFA5 100%)',
    topHeader: {
      toolbarColor:
        'linear-gradient(30deg, #6da5de  0%,#6da5de  15%, #7DE3DC 100%)',
      // toolbarColor:
      //   'linear-gradient(-45deg, #2196F3 0%, #2196F3 33%, #00BFA5 100%)',
      toolBarLogout: '#fff',
      toolBarLanguage: '#fff',
    },
    sideDrawer: {
      boxShadow: '0 10px 15px -5px rgba(62, 57, 107, .07)',
      backdropFilter: 'saturate(180%) blur(20px)',
      backgroundColor: 'rgba(225, 225, 225, 0.9)',
      companyName: 'rgba(0, 0, 0, 0.87)',
      userName: '#000',
      menuColor: '#000',
    },
  },
});
