import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  CardActions,
  Link,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest";
import FormValidation from "../../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import LocalStorage from "../../../../../config/LocalStorage";
import MedicationTableWithPagination from "../../../../../templates/tables/MedicationTableWithPagination";
import Notes from "./Notes";
import SelectSendNotification from "../../../../../templates/SelectSendNotification";
import SendNotes from "../../../../../templates/SendNotes";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog";
import Notification from "../../../../../templates/patientConnect/Notification";
import ScheduleSession from "./ScheduleSession";
import ScheduleSessionModal from "./ScheduleSessionModal";
import CustomerService from "../Chats/index";
import UseSession from "./sessionEditHook/SessionEdit";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "80px",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

function PatientConnect(props) {
  const classes = useStyles();
  const { sessionsList, getSessions } = UseSession(props);
  const [patientChats, setPatientChats] = useState([]);
  const [notificationLists, setNotificationList] = useState([]);
  const [notesList, setNotesList] = useState([]);
  //State for SessionsList array
  // const [sessionsList, setSessionsList] = useState([]);

  const [slideAssignDialog, setSlideAssignDialog] = useState({
    status: false,
    title: "",
    divType: "",
  });

  const handleNotification = () => {
    setSlideAssignDialog({
      status: true,
      title: props.t("case.sendNotification"),
      divType: "notification",
    });
  };

  const handleAddNewNote = () => {
    setSlideAssignDialog({
      status: true,
      title: props.t("user.addNewNote"),
      divType: "notes",
    });
  };
  const handleScheduleSession = () => {
    setSlideAssignDialog({
      status: true,
      title: props.t("user.scheduleSession"),
      divType: "scheduleSession",
    });
  };
  const handleAddChat = () => {
    setSlideAssignDialog({
      status: true,
      title: props.t("user.chat"),
      divType: "chat",
    });
  };

  useEffect(() => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog !== undefined &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getChats();
      getNotifications();
      getNotes();
      getSessions();
    }
  }, [props.formDialog]);

  const getChats = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getAllChats,
      JSON.stringify({ userId: props.rowDetails._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setPatientChats(response.data.result);
        }
      }
    });
  };
  const getNotes = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.userNotesList,
      JSON.stringify({ userId: props.rowDetails._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setNotesList(response.data.notes);
        }
      }
    });
  };

  const handleSlideInDialog = () => {
    getNotifications();
    getNotes();
    setSlideAssignDialog({
      status: false,
      title: "",
      divType: "",
    });
  };

  const getNotifications = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getUserBasedNotifications,
      JSON.stringify({ userId: props.rowDetails._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setNotificationList(response.data.result);
        }
      }
    });
  };
  // const getSessions = () => {
  //   const URL = `${ConfigAPIURL.getConsultationDetails}?userId=${props.rowDetails._id}`;
  //   APIRequest.request("GET", URL).then((response) => {
  //     if (response !== undefined && response !== null) {
  //       if (response.code === 100 && response.data.responseCode === 109) {
  //         const data = response?.data?.filteredConsultation?.reverse();
  //         setSessionsList(data);
  //       }
  //     }
  //   });
  // };

  return (
    <div className={classes.root}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
        <Button onClick={handleAddChat}>{props.t("user.startChat")}</Button>
      </Grid>
      {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <Chat patientChats={patientChats}></Chat>
            </Grid> */}
      <Grid
        // container
        style={{
          // height: "100%",
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* --------------------------------Schedule Sessions----------------------------------------------- */}
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <Button onClick={handleScheduleSession}>
                {props.t("sessions.scheduleSessions")}
              </Button>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <ScheduleSession
                rowDetails={props.rowDetails}
                sessionsList={sessionsList}
                getNotifications={getNotifications}
                getSessions={getSessions}
              ></ScheduleSession>
            </Grid>
          </Grid>
        </Grid>
        {/* ------------------------------------------------------------------------------- */}
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <Button onClick={handleNotification}>
                {props.t("user.sendNotification")}
              </Button>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <Notification
                notificationLists={notificationLists}
                getNotifications={getNotifications}
              ></Notification>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <Button onClick={handleAddNewNote}>
                {props.t("user.addNewNote")}
              </Button>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
              <Notes notesList={notesList}></Notes>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SlideInDialog
        title={slideAssignDialog.title}
        onCloseButton={handleSlideInDialog}
        contentComponent={
          slideAssignDialog.divType === "notification" ? (
            <SelectSendNotification
              page={"user"}
              rowDetails={props.rowDetails}
              handleSlideInDialog={handleSlideInDialog}
            ></SelectSendNotification>
          ) : slideAssignDialog.divType === "notes" ? (
            <SendNotes
              rowDetails={props.rowDetails}
              handleSlideInDialog={handleSlideInDialog}
            ></SendNotes>
          ) : slideAssignDialog.divType === "scheduleSession" ? (
            <ScheduleSessionModal
              rowDetails={props.rowDetails}
              handleSlideInDialog={handleSlideInDialog}
              getSessions={getSessions}
              publishNotifications={props.publishNotifications}
            />
          ) : slideAssignDialog.divType === "chat" ? (
            <>
              <CustomerService
                rowDetails={props.rowDetails}
                handleSlideInDialog={handleSlideInDialog}
              />
            </>
          ) : (
            <></>
          )
        }
        fullWidth={true}
        maxWidth={slideAssignDialog.divType === "assign" ? "sm" : "md"}
        dialogActionsStatus={false}
        slideInDialog={slideAssignDialog.status}
        setSlideInDialog={handleSlideInDialog}
      />
    </div>
  );
}
export default withTranslation("translations")(PatientConnect);
