import { Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../../../../images";
// import { dateConvert } from "../../../../../../utils/DatesConvert";

const LeftChat = (props) => {
  const {
    recieverDetails,
    lastMessage,
    setSelectedChat,
    selectedChat,
    time,
    unreadCount,
    calculateMinutes,
    isSender,
  } = props;
  return (
    <Stack
      onClick={() =>
        setSelectedChat((p) => {
          if (
            JSON.stringify(p) !==
            JSON.stringify({ ...recieverDetails, isSender })
          ) {
            return { ...recieverDetails, isSender };
          }
          return p;
        })
      }
      className="chat__fullcontainer"
      justifyContent="space-between"
      sx={{
        cursor: "pointer",
        backgroundColor:
          recieverDetails?._id === selectedChat?._id &&
          "rgba(97, 94, 240, 0.06)",
      }}
    >
      <Stack className="chat__singlecontainer">
        <Stack
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={recieverDetails?.profileImage || allImages?.avatar}
            alt="image"
            style={{ objectFit: "cover" }}
          />
        </Stack>
        <Stack gap={0} className="sidebar__details">
          <Typography
            variant="subtitle1"
            className="heading truncate"
            sx={{
              fontWeight: 600,
              lineHeight: "21px",
              textTransform: "capitalize",
            }}
          >
            {recieverDetails?.fname}
          </Typography>
          <Typography
            className="font truncate"
            variant="subtitle1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "250px",
              fontWeight: 600,
              fontSize: "12px",
              color: "rgba(0, 0, 0, 0.4)",
            }}
          >
            {lastMessage}
          </Typography>
        </Stack>
      </Stack>
      <Stack style={{ justifyContent: "space-between" }}>
        <Typography
          className="font"
          variant="subtitle1"
          sx={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 600,
            color: "rgba(0, 0, 0, 0.4)",
          }}
        >
          {calculateMinutes(time)}
        </Typography>
        {recieverDetails?._id !== selectedChat?._id &&
          unreadCount !== 0 &&
          unreadCount && (
            <Stack
              style={{
                fontSize: "12px",
                alignSelf: "end",
                color: "white",
                width: "17px",
                height: "17px",
                background: "#615ef0",
                borderRadius: "100%",
                marginTop: ".3rem",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                {unreadCount}
              </Typography>
            </Stack>
          )}
      </Stack>
    </Stack>
  );
};

export default LeftChat;
