import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../../../../images";

const ChatProfile = ({ selectedChat }) => {
  return (
    <Stack>
      <Stack
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          margin: "1rem 2rem",
        }}
      >
        <Stack
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "12px",
            overflow: "hidden",
            // background: "#D8D8D8",
          }}
        >
          <img
            src={selectedChat?.profileImage || allImages.avatar}
            alt="profile"
            style={{ objectFit: "cover" }}
          />
        </Stack>
        <Typography
          variant="subtitle1"
          className="heading"
          style={{
            fontWeight: 600,
            lineHeight: "21px",
            textTransform: "capitalize",
          }}
        >
          {selectedChat?.fname}
        </Typography>
      </Stack>
      <Divider variant="fullWidth" />
    </Stack>
  );
};

export default ChatProfile;
