import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  CardActions,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";

import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../../utils/APIRequest";
import FormValidation from "../../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import MedicationTableWithPagination from "../../../../../templates/tables/MedicationTableWithPagination";
import Patient from "./Patient";
import Caregiver from "./Caregiver";
import AssignedCareTeam from "./CareTeam";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "80px",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

function AccountAccess(props) {
  const classes = useStyles();

  const [data, setData] = useState({
    userId: props.rowDetails._id,
    keyword: "",
    searchType: "",
  });

  const [patientsData, setPatientsData] = useState([]);
  const [careGiverData, setCareGiverData] = useState([]);
  const [careTeamData, setCareTeamData] = useState([]);

  useEffect(() => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog !== undefined &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getPatient(data);
      getCareTeamData(data);
    }
  }, [props.formDialog, data]);

  // useEffect(() => {
  //   getUserList();
  // }, []);

  const getPatient = (data) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.patientCaregiverList,
      JSON.stringify(data)
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setCareGiverData(response.data.user.caregiver);
          setPatientsData(response.data.user.patient);
        }
      }
    });
  };
  //care team list

  const getCareTeamData = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getCareTeamLists,
      JSON.stringify(data)
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response?.code === 100 && response?.data?.responseCode === 109) {
          setCareTeamData(response?.data?.result?.careTeam);
        }
      }
    });
  };

  const handleRefreshData = () => {
    getPatient(data);
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Patient
            data={data}
            // userList={userList}
            setData={setData}
            rowDetails={props.rowDetails}
            patientsData={patientsData}
            handleRefreshData={handleRefreshData}
          ></Patient>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Caregiver
            data={data}
            // userList={userList}
            setData={setData}
            rowDetails={props.rowDetails}
            careGiverData={careGiverData}
            handleRefreshData={handleRefreshData}
          ></Caregiver>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}> 
          <AssignedCareTeam
            data={data}
            // userList={userList}
            setData={setData}
            rowDetails={props.rowDetails}
            careTeamData={careTeamData}
            handleRefreshData={getCareTeamData}
          ></AssignedCareTeam>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation("translations")(AccountAccess);
