class ConfigAPIURL {
  //Base URL For Live & Staging
  static payDefinationBaseUrl =
    process.env.REACT_APP_PayDefination_BaseUrl ||
    process.env.REACT_APP_Dev_PayDefination_BaseUrl;

  // static intercomLink = 'https://app.intercom.com/a/apps';
  static intercomLink = process.env.REACT_APP_IntercomLink;

  //List of Pay Defination APIs and request method type
  static sessionValidation = this.payDefinationBaseUrl + "/user/islogin"; //get
  static verifyUser = this.payDefinationBaseUrl + "/user/verifyUser";
  static accountLogin = this.payDefinationBaseUrl + "/user/accountLogin";
  static mobileLogin = this.payDefinationBaseUrl + "/user/mobileno/login";
  static verifyOtp = this.payDefinationBaseUrl + "/user/verify/otp";
  static resendOtp = this.payDefinationBaseUrl + "/user/admin/resend/otp";
  static getOptions = this.payDefinationBaseUrl + "/user/custom/dropdown";
  static adminLogout = this.payDefinationBaseUrl + "/user/logout";
  static searchUser = this.payDefinationBaseUrl + "/admin/search/user";
  static searchAdmin = this.payDefinationBaseUrl + "/admin/search";
  static profileInfo = this.payDefinationBaseUrl + "/user/profile";
  static adminCaseMongoId = this.payDefinationBaseUrl + "/admin/case/id";

  static getWebStats = this.payDefinationBaseUrl + "/admin/dashboard/webstats";
  static contactAdmin = this.payDefinationBaseUrl + "/user/contactus";
  static getCaseStats =
    this.payDefinationBaseUrl + "/admin/dashboard/casestats";

  static getAllChats = this.payDefinationBaseUrl + "/admin/chat/all";
  static getUserBasedNotifications =
    this.payDefinationBaseUrl + "/admin/notification/details";

  static getCaseBasedNotifications =
    this.payDefinationBaseUrl + "/admin/notification/case/details";

  static getNotificationTemplate =
    this.payDefinationBaseUrl + "/admin/notification/templates";

  static adminGeneratePassword =
    this.payDefinationBaseUrl + "/user/password/generate";

  static forgotPassword = this.payDefinationBaseUrl + "/user/forgot/password";
  static resetPassword = this.payDefinationBaseUrl + "/admin/reset/password";

  static uploadHistory =
    this.payDefinationBaseUrl + "/admin/list/all/upload/file";
  static searchDoctors =
    this.payDefinationBaseUrl + "/admin/doctor/list/search";
  static getDoctorData = this.payDefinationBaseUrl + "/admin/doctor/data";

  static doctorCreate = this.payDefinationBaseUrl + "/admin/doctor/add";
  // Options API
  static options = this.payDefinationBaseUrl + "/admin/enquiry/options";
  static medicalOptions = this.payDefinationBaseUrl + "/admin/medical/options";
  static productStock =
    this.payDefinationBaseUrl + "/admin/inventory/availablecount";

  static inventoryProductList =
    this.payDefinationBaseUrl + "/admin/Advertisement/search"; //get

  static inventorySearch =
    this.payDefinationBaseUrl + "/admin/inventory/search"; //get

  //Admin APIS
  static roleTableURL = this.payDefinationBaseUrl + "/admin/roles/all"; //post
  static roleTitles = this.payDefinationBaseUrl + "/admin/roles/titles"; //post
  static userTableURL = this.payDefinationBaseUrl + "/admin/user/all"; //post
  static roleDetails = this.payDefinationBaseUrl + "/admin/role/details"; //post
  static roleCreate = this.payDefinationBaseUrl + "/admin/role/create"; //post
  static roleUpdate =
    this.payDefinationBaseUrl + "/admin/role/permission/update"; //post
  static roleUnassigned =
    this.payDefinationBaseUrl + "/admin/role/permission/unassigned"; //post
  static roleGrant = this.payDefinationBaseUrl + "/admin/role/permission/grant"; //post

  static caseTableURL = this.payDefinationBaseUrl + "/admin/case/all"; //post
  static caseDetails = this.payDefinationBaseUrl + "/admin/case/details";
  static caseCreate = this.payDefinationBaseUrl + "/admin/case/create";
  static caseUpdate = this.payDefinationBaseUrl + "/admin/case/update";
  static caseDelete = this.payDefinationBaseUrl + "/admin/case/delete";
  static updatePrescriptionCase =
    this.payDefinationBaseUrl + "/admin/case/update/prescription";
  static assignCase = this.payDefinationBaseUrl + "/admin/case/assign";
  static getCaseStatus = this.payDefinationBaseUrl + "/admin/case/getstatus";
  static deleteMedications =
    this.payDefinationBaseUrl + "/admin/medication/delete";
  static updateMedications =
    this.payDefinationBaseUrl + "/admin/medication/update";

  static getAllUserDoctors = this.payDefinationBaseUrl + "/user/my/doctor/list"; //Get Added New
  static getAllmacros = this.payDefinationBaseUrl + "/admin/user/macros"; //post Added New
  static getAllRoles = this.payDefinationBaseUrl + "/admin//roles/all"; //post Added New
  static updateReminders =
    this.payDefinationBaseUrl + "/admin/notification/templates/update"; //Patch Added New
  static getReminderTemplateDetails =
    this.payDefinationBaseUrl + "/admin/notification/template"; //Get Added New
  static deleteReminderTemplate =
    this.payDefinationBaseUrl + "/admin/notification/templates/delete"; //DELETE Added New
  static createReminderTemplate =
    this.payDefinationBaseUrl + "/admin/notification/templates/create"; //post Added New

  static userCreate = this.payDefinationBaseUrl + "/admin/user/add"; //post
  static userUpdate = this.payDefinationBaseUrl + "/admin/user/update"; //post
  static userUpdateReminder =
    this.payDefinationBaseUrl + "/admin/user/update/reminder"; //post
  static userDelete = this.payDefinationBaseUrl + "/admin/user/delete"; //post
  static userDetails = this.payDefinationBaseUrl + "/admin/user/details"; //post
  static enquiryDetails = this.payDefinationBaseUrl + "/admin/enquiry/details"; //post

  static resetPasswordAttempt =
    this.payDefinationBaseUrl + "/admin/user/resetpasswordattempt";

  static userMedicationDetails =
    this.payDefinationBaseUrl + "/user/my/medication/group/list";

  static getMedicationList =
    this.payDefinationBaseUrl + "/admin/medication/list";

  static enquiryTableURL = this.payDefinationBaseUrl + "/admin/enquiry/all"; //post
  static enquiryUpdate = this.payDefinationBaseUrl + "/admin/enquiry/update"; //post
  static advertisementTableURL =
    this.payDefinationBaseUrl + "/admin/advertisement/all"; //post
  static advertisementCreate =
    this.payDefinationBaseUrl + "/admin/advertisement/create"; //post
  static advertisementUpdate =
    this.payDefinationBaseUrl + "/admin/advertisement/update"; //post
  static advertisementDetails =
    this.payDefinationBaseUrl + "/admin/advertisement/details"; //post
  static advertisementDelete =
    this.payDefinationBaseUrl + "/admin/advertisement/delete"; //post

  static createNotifications =
    this.payDefinationBaseUrl + "/admin/case/notification/create";
  st;

  static createUserNotifications =
    this.payDefinationBaseUrl + "/admin/user/notification/create";

  // product

  static searchPatientOrCaregiver =
    this.payDefinationBaseUrl + "/admin/search/patientCaregiver";

  static addPatient = this.payDefinationBaseUrl + "/admin/profile/patient/add";
  static addCaregiver =
    this.payDefinationBaseUrl + "/admin/profile/caregiver/add";
  static removePatient =
    this.payDefinationBaseUrl + "/admin/profile/patient/remove";
  static removeCaregiver =
    this.payDefinationBaseUrl + "/admin/profile/caregiver/remove";

  static productTableURL = this.payDefinationBaseUrl + "/admin/inventory/all"; //post
  static inventoryDelete =
    this.payDefinationBaseUrl + "/admin/inventory/delete"; //post
  static inventoryUploadItems =
    this.payDefinationBaseUrl + "/admin/inventory/upload/items"; //post
  static inventoryUploadStock =
    this.payDefinationBaseUrl + "/admin/inventory/upload/stock"; //post
  static inventoryUploadPrice =
    this.payDefinationBaseUrl + "/admin/inventory/upload/price"; //post
  static uploadInventoryImages =
    this.payDefinationBaseUrl + "/admin/inventory/upload/image";
  static createInventory = this.payDefinationBaseUrl + "/admin/inventory/add";

  static fileDownloader = this.payDefinationBaseUrl + "/admin/fileDownloader";

  static patientCaregiverList =
    this.payDefinationBaseUrl + "/admin/patientCaregiverList";
  static addMedicationAdd =
    this.payDefinationBaseUrl + "/admin/medicationoption/add";

  static createNote = this.payDefinationBaseUrl + "/admin/note/create";
  static retireCase =
    this.payDefinationBaseUrl + "/admin/notification/update/status";
  static userNotesList = this.payDefinationBaseUrl + "/admin/user/notes";

  //Sessions Page Api's
  // static getAllAppointments = this.payDefinationBaseUrl + "/admin/consultation"; //Get Added New
  static getAllAppointments =
    this.payDefinationBaseUrl + "/admin/consultation/template"; //Get Added New
  // static createNewAppointment =
  //   this.payDefinationBaseUrl + "/admin/consultation/create"; //Post Added New
  static createNewAppointment =
    this.payDefinationBaseUrl + "/admin/consultation/template/create"; //Post Added New
  static searchAllUsers = this.payDefinationBaseUrl + "/admin/user/all"; //Post Added New
  static getCareGiversList =
    this.payDefinationBaseUrl + "/admin/patientCaregiverList"; //Post Added New
  static getDoctorsList =
    this.payDefinationBaseUrl + "/admin/doctor/list/search"; //Post Added New
  static getAllusersList = this.payDefinationBaseUrl + "/admin/user/role"; //Get Added New
  // static getConsultationDetails =
  //   this.payDefinationBaseUrl + "/admin/consultation"; //Get Added New
  static getConsultationDetails =
    this.payDefinationBaseUrl + "/admin/consultation/userDetail"; //Get Added New
  // static getConsultationDetailsById =
  //   this.payDefinationBaseUrl + "/admin/consultation/details"; //POST Added New
  static getConsultationDetailsById =
    this.payDefinationBaseUrl + "/admin/consultation/template/details"; //POST Added New
  // static updateConsultationDetails =
  //   this.payDefinationBaseUrl + "/admin/consultation/update"; //Get Added New
  static updateConsultationDetails =
    this.payDefinationBaseUrl + "/admin/consultation/template/update"; //Get Added New
  // static deleteConsultation =
  //   this.payDefinationBaseUrl + "/admin/consultation/delete"; //DELETE Added New
  static deleteConsultation =
    this.payDefinationBaseUrl + "/admin/consultation/template/delete"; //DELETE Added New

  //careTeam apis
  static getCareTeamLists =
    this.payDefinationBaseUrl + "/admin/profile/careteam/list"; //POST
  static searchCareTeam =
    this.payDefinationBaseUrl + "/admin/search/patientCareTeam"; //POST
  static addCareTeam =
    this.payDefinationBaseUrl + "/admin/profile/careteam/add"; //POST

  //creating part in user for session

  static createSession =
    this.payDefinationBaseUrl + "/admin/consultation/create"; //POST

  //apis related to session page
  static listSession = this.payDefinationBaseUrl + "/admin/consultation"; //POST
  static sessionDetails =
    this.payDefinationBaseUrl + "/admin/consultation/details"; //POST

  //listing table data while creating session

    //apis related to session page
    static listSession = this.payDefinationBaseUrl + "/admin/consultation" //POST
    static sessionDetails = this.payDefinationBaseUrl + "/admin/consultation/details" //POST


  //listing table data while creating session

    static listSessionTemplate = this.payDefinationBaseUrl + "/admin/consultation/template" //POST


    //apis related to session page edit page

    static getSessionbyid = this.payDefinationBaseUrl + "/admin/consultation/details" //POST
    static editSessionbyid = this.payDefinationBaseUrl + "/admin/consultation/update" //POST
  static listSessionTemplate =
    this.payDefinationBaseUrl + "/admin/consultation/template"; //POST
  static createConsultationTemplate =
    this.payDefinationBaseUrl + "/admin/consultation/template/create"; //POST
  static getConsultationTemplateDetails =
    this.payDefinationBaseUrl + "/admin/consultation/template/details"; //POST
  static updateConsultationTemplate =
    this.payDefinationBaseUrl + "/admin/consultation/template/update"; //PATCH
  static deleteConsultationTemplate =
    this.payDefinationBaseUrl + "/admin/consultation/template/delete"; //DELETE

    //doctor page apis
    static doctorTableURL = this.payDefinationBaseUrl + "/admin/doctor/all" //POST
    static doctorPatientListURL = this.payDefinationBaseUrl + "/admin/doctor/patients" //POST
    static doctorCasesListURL = this.payDefinationBaseUrl + "/admin/doctor/cases" //POST
    static doctorEdit=this.payDefinationBaseUrl + "/admin/doctor/edit" //POST

    //for deleting particular session
    static deleteSession = this.payDefinationBaseUrl + "/admin/consultation/delete"; //DELETE


    //removed caretime api
    static removeCareTeam = this.payDefinationBaseUrl + "/admin/profile/careteam/remove"; //POST
}

export default ConfigAPIURL;
