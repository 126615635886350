import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  MenuItem,
  Tooltip,
  Button,
  makeStyles,
} from '@material-ui/core';
import APIRequest from '../../../../utils/APIRequest';
import Toaster from '../../../../utils/Toaster';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import { connect } from 'react-redux';
import FormValidation from '../../../../utils/FormValidation';
import imageCompression from 'browser-image-compression';
import LocalStorage from '../../../../config/LocalStorage';
import FileUpload from '../../../../templates/FileUpload';

import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles((theme) => ({
  actionButton: {
    textAlign: 'end',
  },
}));
function AddCaseForm(props) {
  const classes = useStyles();

  const [aws, setAws] = useState(LocalStorage.aws);

  const [postAttachmentFile, setPostAttachmentFile] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [s3FileURLPostAttachment, setS3FileURLPostAttachment] = useState([]);
  const postAttachmentFileUpload = useRef();
  const [postAttachmentFileBase64, setPostAttachmentFileBase64] = useState('');

  const [userId, setUserId] = useState('');
  const [pageName, setPageName] = useState('');
  const [prescription, setPrescription] = useState([]);

  const [userOptions, setUserOptions] = useState([]);
  const resetRoleObject = () => {
    if (props.pageName === undefined || props.pageName === null) {
      setUserId('');
    }
    setPrescription([]);
    props.setSlideInDialog(false);
  };

  useEffect(() => {
    if (props.pageName !== undefined && props.pageName !== null) {
      setPageName(props.pageName);
      setPrescription([]);
      userOptions.map((users) => {
        if (users._id === props.selectedRowId) {
          setUserId(users);
        }
      });
    }
  }, [props.pageName, userOptions]);

  useEffect(() => {
    if (props.pageName !== undefined && props.pageName !== null) {
      getUsersFromUserPage();
    }
  }, []);

  const getUsersFromUserPage = () => {
    APIRequest.request('GET', ConfigAPIURL.searchUser, '').then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setUserOptions(response.data.result);
        }
      }
    });
  };

  const getUsers = (event, data) => {
    if (data.length > LocalStorage.searchableStringLimit) {
      APIRequest.request(
        'GET',
        ConfigAPIURL.searchUser + '?keyword=' + data,
        ''
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setUserOptions(response.data.result);
          }
        }
      });
    } else {
      setUserOptions([]);
    }
  };

  const handlePrescriptions = async (event) => {
    let file = event.target.files[0];
    if (file !== undefined && file !== null) {
      if (file.type.endsWith('png') || file.type.endsWith('jpeg')) {
        if (file !== undefined && file !== null) {
          let reader = new FileReader();
          let url = '';
          if (
            event.target.files[0].type.startsWith('image') &&
            event.target.files[0].size > 1000000
          ) {
            SnackbarUtils.info(
              props.t('snackbar.compressFile'),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            // Toaster.info(
            //   'Compressing file. Please wait for a while.',
            //   'topRight'
            // );
            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const compressedFile = await imageCompression(
              event.target.files[0],
              options
            );
            url = reader.readAsDataURL(compressedFile);
          } else if (
            event.target.files[0].type.startsWith('image') &&
            event.target.files[0].size < 3000000
          ) {
            url = reader.readAsDataURL(event.target.files[0]);
          } else if (event.target.files[0].type.startsWith('image')) {
            SnackbarUtils.error(
              props.t('snackbar.fileLarge'),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            // Toaster.error(
            //   'File size is too large, Max. file size is 3mb.',
            //   'topRight'
            // );
            return;
          } // Toaster.info('Ready to upload image into storage', 'topRight');

          SnackbarUtils.sucess(
            props.t('snackbar.uploadReady'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          reader.onloadend = function (err) {
            // if (err) Toaster.error("Error uploading file", "topRight");

            setPostAttachmentFileBase64(reader.result.split('base64,')[1]);
            setPostAttachmentFile(file);
            setFileUploadStatus(true);
            setS3FileURLPostAttachment('');
            postAttachmentFileUpload.current._s3Upload();
          }.bind(this);
        }
      } else {
        SnackbarUtils.error(
          props.t('snackbar.onlyImage'),
          'bottomCenter',
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    }
  };

  const _s3ImageUrlPostAttachment = (url) => {
    prescription.push(url);
    setFileUploadStatus(false);
    setPostAttachmentFileBase64('');
    setS3FileURLPostAttachment(url);
    setPrescription(prescription);
    setPostAttachmentFile('');
  };
  const addRole = () => {
    if (userId._id === undefined) {
      Toaster.error(props.t('case.selectUser'), 'topRight');
      return;
    }

    if (prescription.length === 0) {
      Toaster.error(props.t('case.addPrescription'), 'topRight');
      return;
    }
    let body = { userId: userId._id, prescription: prescription };

    if (
      props.pageName !== undefined &&
      props.pageName !== null &&
      props.pageName !== ''
    ) {
      body['caseType'] = pageName;
    }
    APIRequest.request(
      'POST',
      ConfigAPIURL.caseCreate,
      JSON.stringify(body)
    ).then((response) => {
      if (response.code === 100) {
        if (response.data !== undefined && response.data !== null) {
          if (response.data.responseCode === 109) {
            resetRoleObject();
            SnackbarUtils.sucess(
              props.t('toaster.transaction_completed'),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          } else {
            SnackbarUtils.error(
              props.t('toaster.' + response.data.responseCode),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        } else {
          SnackbarUtils.error(
            props.t('toaster.serverError'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      } else {
        SnackbarUtils.error(
          props.t('toaster.serverError'),
          'bottomCenter',
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <Autocomplete
            id='userId'
            options={userOptions}
            getOptionLabel={(option) =>
              option.fname !== undefined
                ? option.fname +
                  ' ( Mobile ' +
                  option.mobileNo +
                  ', patient id ' +
                  option.patientId +
                  ' )'
                : ''
            }
            onInputChange={getUsers}
            onClose={() => setUserOptions([])}
            value={userId}
            onChange={(event, data) => {
              setUserId(data);
            }}
            disabled={
              props.pageName !== undefined &&
              props.pageName !== null &&
              props.pageName !== ''
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.t('case.searchPatient')}
                variant='standard'
              />
            )}
          />
          {/* <TextField
            autoFocus
            margin='dense'
            required
            id='name'
            autoComplete='off'
            label={props.t('role.roleName')}
            type='text'
            fullWidth
            value={addRoleObject.name}
            onChange={(event) => {
              setAddRoleObject({ ...addRoleObject, name: event.target.value });
            }}
          /> */}
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <input
            accept='image/*'
            // className={classes.input}
            style={{ display: 'none' }}
            id='contained-button-file'
            onChange={handlePrescriptions}
            multiple
            type='file'
          />
          <label htmlFor='contained-button-file'>
            <Button variant='contained' color='primary' component='span'>
              {props.t('case.uploadPrescriptions')}
            </Button>
          </label>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {prescription.map((images, index) => (
            <img
              src={images}
              style={{ margin: '0px 5px' }}
              key={index}
              width={100}
              height={100}
            ></img>
          ))}
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.actionButton}
        >
          <Button
            onClick={() => {
              resetRoleObject();
            }}
            color='primary'
            style={{ margin: 2 }}
          >
            {props.t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => addRole()}
            color='primary'
            style={{ margin: 2 }}
          >
            {props.t('buttons.submit')}
          </Button>
        </Grid>
      </Grid>

      <FileUpload
        ref={postAttachmentFileUpload}
        aws={aws}
        file={postAttachmentFile}
        base64={postAttachmentFileBase64}
        bucket={aws.prescriptionBucket}
        callback={_s3ImageUrlPostAttachment}
      />
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(AddCaseForm)
);
