import { Stack, Typography } from "@mui/material";
import React from "react";
import { allImages } from "../../../../../../images";
import Media from "./Media";

const SenderMsg = ({
  content,
  image,
  lastMessage,
  isMedia,
  media,
  time,
  showTime,
}) => {
  // this is the sended message component
  
  return (
    <Stack>
      <Stack
        style={{
          flexDirection: "row",
          justifyContent: "end",
          gap: 8,
          alignItems: "start",
        }}
      >
        <Stack
          className="recievermsg sendermsg"
          style={{
            flexDirection: "row",
            position: "relative",
            padding: isMedia && "4px 4px",
            borderTopRightRadius: lastMessage && 0,
          }}
        >
          {isMedia ? <Media media={media} /> : content}

          {/* {content} */}
          {/* <span
          style={{
            fontSize: "9px",
            alignSelf: "end",
            position: "absolute",
            bottom: 0,
            right: 10,
          }}
        >
          12:30pm
        </span>{" "} */}
        </Stack>
        {
          <Stack
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "12px",
              overflow: "hidden",
              visibility: lastMessage ? "visible" : "hidden",
              // background: "#D8D8D8",
            }}
          >
            <img
              src={image || allImages.avatar}
              alt="image"
              style={{ objectFit: "cover" }}
            />
          </Stack>
        }
      </Stack>
      {showTime && (
        <Typography variant="subtitle1" className="chat__time">
          {/* {new Date(time * 1000).toDateString()} */}
          {"" +
            " " +
            new Date(time * 1000).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })}
        </Typography>
      )}
    </Stack>
  );
};

export default SenderMsg;
