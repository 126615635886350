import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import TableQuery from "./TableQuery";
import TableHeader from "./TableHeader";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
import APIRequest from "../../../../utils/APIRequest";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import ProcessCaseMultiSelectTable from "../../../../templates/tables/ProcessCaseMultiSelectTable";
import DataTable from "../../../../templates/tables/DataTable";
import APIRequestDataTableQuery from "../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import TableButtons from "../../../../templates/buttons/TableButtons";
import AssignCase from "./AssignCase";
import SelectSendNotification from "../../../../templates/SelectSendNotification";
import FormDialog from "../../../../templates/dialog/FormDialog";
import AEVForm from "./AEVForm";
import Notes from "./Notes";
import AddCaseForm from "./AddCaseForm";
import ProcessedCases from "./ProcessedCases";
import RoutingState from "../hook/routingState";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

function Advertisement(props) {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); 
  const openCasesPage = queryParams.get("addcase");

  useEffect(() => {
    if(openCasesPage === "true"){
      tableAdd();
    }
  },[openCasesPage])

  const [notes, setNotes] = useState([]);
  const [openNotesCard, setNotesCard] = useState(false);

  const [addSlideForm, setAddSlideForm] = useState(false);
  const [slideInDialog, setSlideInDialog] = useState(false);
  const [slideAssignDialog, setSlideAssignDialog] = useState({
    status: false,
    title: "",
    divType: "",
  });

  // const [medicineName, setMedicineName] = useState(null);

  const [processedCaseData, setProcessedCaseData] = useState([]);

  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/

  const { route, setRoute } = RoutingState();
  const [query, setQuery] = useState(
    props.pageName !== undefined &&
      props.pageName !== null &&
      props.pageName !== ""
      ? {
          keyword: "",
          page: 0,
          pageSize: 10,
          status: "all",
          sortField: "",
          sortOrder: "false",
          active: true,
          caseType: props.pageName,
          userId: props.selectedRowId,
        }
      : {
          keyword: "",
          page: 0,
          pageSize: 10,
          status: "all",
          sortField: "",
          sortOrder: "false",
          active: true,
          caseType: "all",
        }
  );
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [notificationList, setNotificationList] = useState([]);

  const [selectedNotification, setSelectedNotification] = useState({
    title: "",
  });
  const [customMessage, setCustomMessage] = useState({ title: "", body: "" });

  // const [notificationRadio, setNotificationRadio] = useState([]);

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    setAddSlideForm(false);
    // setMedicineName(null);
    setNotesCard(false);
    setSlideAssignDialog({ status: false, title: "", divType: "" });
    tableQuery(query);
    // tableQuery(
    //   props.pageName !== undefined &&
    //     props.pageName !== null &&
    //     props.pageName !== ''
    //     ? {
    //         keyword: '',
    //         page: 0,
    //         pageSize: 10,
    //         status: 'all',
    //         sortField: '',
    //         sortOrder: 'false',
    //         active: true,
    //         caseType: props.pageName,
    //         userId: props.selectedRowId,
    //       }
    //     : {
    //         keyword: '',
    //         page: 0,
    //         pageSize: 10,
    //         status: 'all',
    //         sortField: '',
    //         sortOrder: 'false',
    //         active: true,
    //         caseType: 'all',
    //       }
    // );
    handleFormDialog();
  };

  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);

  // useful of on page load
  useEffect(() => {
    if (
      props.pageName !== undefined &&
      props.pageName !== null &&
      props.pageName !== ""
    ) {
      tableQuery({
        ...query,
        caseType: props.pageName,
        userId: props.selectedRowId,
      });
    } else {
      tableQuery(query); // calling table api on page load by default value
    }
  }, [props.pageName, buttonList]);

  useEffect(() => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (dataTableDBClick === "dataTableDBClick") {
        {
          buttonList.find(function (element) {
            return element.button === "edit" ? true : false;
          })
            ? tableEdit()
            : SnackbarUtils.error(
                props.t("toaster.permissionNotAvailable"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
        }
      }
    }
  }, [rowDetails]);

  // Handle Case based on user

  const getButtonPermissions = () => {
    APIRequest.request("GET", ConfigAPIURL.sessionValidation, "").then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (
            response.code === 100 &&
            response.data !== undefined &&
            response.data.responseCode === 109
          ) {
            response.data.user.permission.permission.map((data) => {
              if (data.label === "Cases") {
                let prevStateButtonDetails = [];
                data.buttons.map((buttonData) => {
                  if (buttonData.enable) {
                    let tempButtonObject = {
                      button: buttonData.label,
                      disable: false,
                    };
                    prevStateButtonDetails.push(tempButtonObject);
                  }
                });
                setButtonDetails(prevStateButtonDetails);
              }
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    getNotificationTemplate();
    getButtonPermissions();
    // setButtonDetails(LocalStorage.adminButtonPermission);
  }, []);

  // table query body value handler
  const handleQuery = (query) => {
    if (
      props.pageName !== undefined &&
      props.pageName !== null &&
      props.pageName !== ""
    ) {
      setQuery({
        ...query,
        caseType: props.pageName,
        userId: props.selectedRowId,
      });
    } else {
      setQuery(query); // calling table api on page load by default value
    }

    tableQuery(query);
  };

  const handleNotes = (row) => {
    setNotesCard(true);
    setNotes(row);
  };

  // useEffect(() => {
  //   if (
  //     props.location !== undefined &&
  //     props.location.search !== undefined &&
  //     props.location.search !== '' &&
  //     props.location.search.split('=')[1] !== undefined &&
  //     props.location.search.split('=')[1] !== null &&
  //     props.location.search.split('=')[1].split('&')[0] !== undefined &&
  //     props.location.search.split('=')[1].split('&')[0] !== null
  //   ) {
  //     let recordId = [];
  //     APIRequest.request(
  //       'POST',
  //       ConfigAPIURL.adminCaseMongoId,
  //       JSON.stringify({
  //         caseId: props.location.search.split('=')[1].split('&')[0],
  //       })
  //     ).then((response) => {
  //       if (response !== undefined && response !== null) {
  //         if (response.code === 100 && response.data.responseCode === 109) {
  //           setRowDetails([response.data.result._id]);
  //           setOpenForm({
  //             status: true,
  //             title: props.t('case.editProcessCase'),
  //             divType: 'edit',
  //             functionName: '',
  //           });
  //         } else {
  //           SnackbarUtils.error(
  //             props.t('snackbar.recordNotFound'),
  //             'bottomCenter',
  //             3000
  //           ).then((notification) => {
  //             props.publishNotification(notification);
  //           });
  //         }
  //       } else {
  //         SnackbarUtils.error(
  //           props.t('snackbar.recordNotFound'),
  //           'bottomCenter',
  //           3000
  //         ).then((notification) => {
  //           props.publishNotification(notification);
  //         });
  //       }
  //     });
  //   }
  //   if (
  //     props.location !== undefined &&
  //     props.location.search !== undefined &&
  //     props.location.search !== '' &&
  //     props.location.search.split('=')[2] !== undefined &&
  //     props.location.search.split('=')[2] !== null
  //   ) {
  //     setMedicineName(props.location.search.split('=')[2]);
  //   }
  // }, [buttonList]);

  const getNotificationTemplate = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.getNotificationTemplate + "?notificationType=notice",
      ""
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          let serverResponse = response.data.result;

          serverResponse.map((results) => {
            results["visibleOn"] = {
              app: false,
              home: false,
              sms: false,
              whatsApp: false,
              email: false,
            };
          });

          setNotificationList(serverResponse);

          // let array = new Array(response.data.result.length).fill(false);

          // setNotificationRadio(array);
        }
      }
    });
  };

  // table query body value reset handler
  const resetQuery = () => {
    if (
      props.pageName !== undefined &&
      props.pageName !== null &&
      props.pageName !== ""
    ) {
      setQuery({
        keyword: "",
        page: 0,
        pageSize: 10,
        status: "all",
        sortField: "",
        sortOrder: "false",
        active: true,
        caseType: props.pageName,
        userId: props.selectedRowId,
      });
      tableQuery({
        keyword: "",
        page: 0,
        pageSize: 10,
        status: "all",
        sortField: "",
        sortOrder: "false",
        active: true,

        caseType: props.pageName,
        userId: props.selectedRowId,
      });
    } else {
      setQuery({
        keyword: "",
        page: 0,
        pageSize: 10,
        sortField: "",
        status: "all",
        sortOrder: "false",
        active: true,
        caseType: "all",
      });
      tableQuery({
        keyword: "",
        page: 0,
        pageSize: 10,
        status: "all",
        sortField: "",
        sortOrder: "false",
        active: true,
        caseType: "all",
      });
    }
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      buttonList.find(function (element) {
        return element.button === "query" ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        "POST",
        ConfigAPIURL.caseTableURL,
        JSON.stringify(query)
      ).then((tableData) => {
        setTableData(tableData);
      });
    }
    //  else {
    //   // notification(props.t('toaster.tableRecordPermission'), 'error');
    // }
  };
  const notification = (message, type) => {
    SnackbarUtils[type](message, "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  // table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
    // if (rowDetails !== undefined && rowDetails !== null) {
    // }
  };

  const [dataTableDBClick, setDataTableDBClick] = useState(null);

  const tableDBLCallBack = (dbClickrowDetails) => {
    setRowDetails(dbClickrowDetails);
    setOpenForm({
      status: true,
      title: props.t("case.editProcessCase"),
      divType: "edit",
      functionName: "",
    });
    // setDataTableDBClick('dataTableDBClick');
  };
  //table button call back function
  const tableAdd = () => {
    setAddSlideForm(true);
  };

  // useEffect(() => {
  //   setAddSlideForm(true);
  //   setRoute(false)
  // }, []);

  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.length === 1) {
        setOpenForm({
          status: true,
          title: props.t("case.editProcessCase"),
          divType: "edit",
          functionName: "",
        });
      } else {
        SnackbarUtils.error(
          props.t("snackbar.selectOneRow"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const handleProcessedCaseConfirmDialog = (response) => {
    // console.log(response);
    if (response === "proceed") {
      setSlideAssignDialog({
        status: true,
        title: props.t("user.assignAdmin"),
        divType: "assign",
      });
      // setAssignee({ fname: '', lname: '' });
    } else {
      setSlideAssignDialog({
        status: false,
        title: "",
        divType: "",
      });
    }
  };

  const tableAssign = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getCaseStatus,
        JSON.stringify(rowDetails)
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            if (response.data.result.length > 0) {
              setProcessedCaseData(response.data.result);
              setSlideAssignDialog({
                status: true,
                title: props.t("case.confirmProcessedCases"),
                divType: "confirmDialog",
              });
            } else {
              setSlideAssignDialog({
                status: true,
                title: props.t("user.assignAdmin"),
                divType: "assign",
              });
              // setAssignee({ fname: '', lname: '' });
            }
          }
        }
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const tableSendNotification = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setSlideAssignDialog({
        status: true,
        title: props.t("case.sendNotification"),
        divType: "notification",
      });
      // setAssignee({ fname: '', lname: '' });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const tableView = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.length === 1) {
        setOpenForm({
          status: true,
          title: props.t("case.viewProcessCase"),
          divType: "view",
          functionName: "",
        });
      } else {
        SnackbarUtils.error(
          props.t("snackbar.selectOneRow"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (query.active === true) {
        setSlideInDialog(true);
      } else {
        SnackbarUtils.error(
          props.t("snackbar.recordAlreadyDeleted"),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
      // if (rowDetails.length === 1) {
      // } else {
      //   SnackbarUtils.error(
      //     props.t('snackbar.selectOneRow'),
      //     'bottomCenter',
      //     3000
      //   ).then((notification) => {
      //     props.publishNotification(notification);
      //   });
      // }
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    if (
      props.pageName !== undefined &&
      props.pageName !== null &&
      props.pageName !== ""
    ) {
      tableQuery({
        ...query,
        caseType: props.pageName,
        userId: props.selectedRowId,
      });
      // } else if (
      //   props.location !== undefined &&
      //   props.location.search !== undefined &&
      //   props.location.search !== '' &&
      //   props.location.search.split('=')[1] !== undefined &&
      //   props.location.search.split('=')[1] !== null &&
      //   props.location.search.split('=')[1].split('&')[0] !== undefined &&
      //   props.location.search.split('=')[1].split('&')[0] !== null
      // ) {
      //   tableQuery(query);
      // }
    } else {
      tableQuery(query);
    }
    setDataTableDBClick(null);
    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "DELETE",
        ConfigAPIURL.caseDelete,
        JSON.stringify({ recordId: rowDetails })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.deleteSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
    }
  };

  return (
    <div
      className={classes.root}
      style={
        props.pageName !== undefined &&
        props.pageName !== null &&
        props.pageName !== ""
          ? { marginTop: "80px" }
          : {}
      }
    >
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <ProcessCaseMultiSelectTable
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            handleNotes={handleNotes}
            tableCallBack={tableCallBack}
            tableDBLCallBack={tableDBLCallBack}
            searchLabel={props.t("case.searchLabel")}
            page={"processCase"}
            queryComponent={
              <TableQuery
                query={query}
                setQuery={handleQuery}
                pageName={
                  props.pageName !== undefined && props.pageName !== null
                    ? props.pageName
                    : null
                }
              />
            } //filter menu option
            buttonComponent={
              <TableButtons
                tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableAssign={tableAssign}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableCheckStatus={tableCheckStatus}
                tableSendNotification={tableSendNotification}
                buttonList={buttonList}
              />
            }
          />
        </Grid>
      </Grid>
      {rowDetails !== undefined &&
      rowDetails !== null &&
      rowDetails[0] !== undefined &&
      rowDetails[0] !== null ? (
        <FormDialog
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <AEVForm
              // medicineName={medicineName}
              notificationList={notificationList}
              slideAssignDialog={slideAssignDialog}
              setNotificationList={setNotificationList}
              handleSlideInDialog={handleSlideInDialog}
              getNotificationTemplate={getNotificationTemplate}
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails}
              tableQuery={tableQuery}
            />
          }
        />
      ) : (
        <></>
      )}

      <SlideInDialog
        title={props.t("case.addProcessCase")}
        contentComponent={
          <AddCaseForm
            pageName={
              props.pageName !== undefined && props.pageName !== null
                ? props.pageName
                : null
            }
            selectedRowId={
              props.selectedRowId !== undefined && props.selectedRowId !== null
                ? props.selectedRowId
                : null
            }
            setSlideInDialog={handleSlideInDialog}
            // selectedRowId={props.selectedRowId}
          />
        }
        fullWidth={true}
        maxWidth={"md"}
        slideInDialog={addSlideForm}
        setSlideInDialog={handleSlideInDialog}
      />

      <SlideInDialog
        title={slideAssignDialog.title}
        contentComponent={
          slideAssignDialog.divType === "assign" ? (
            <AssignCase
              rowDetails={rowDetails}
              // adminList={adminList}
              // handleAdminList={handleAdminList}
              handleSlideInDialog={handleSlideInDialog}
              // handleAssignDialog={handleAssignDialog}
              // assignee={assignee}
            />
          ) : slideAssignDialog.divType === "notification" ? (
            <SelectSendNotification
              page={"case"}
              notificationList={notificationList}
              setNotificationList={setNotificationList}
              rowDetails={rowDetails}
              handleSlideInDialog={handleSlideInDialog}
              getNotificationTemplate={getNotificationTemplate}
            ></SelectSendNotification>
          ) : slideAssignDialog.divType === "confirmDialog" ? (
            <ProcessedCases
              processedCaseData={processedCaseData}
              handleProcessedCaseConfirmDialog={
                handleProcessedCaseConfirmDialog
              }
            ></ProcessedCases>
          ) : (
            <></>
          )
        }
        fullWidth={true}
        maxWidth={slideAssignDialog.divType === "assign" ? "sm" : "md"}
        dialogActionsStatus={false}
        slideInDialog={slideAssignDialog.status}
        setSlideInDialog={handleSlideInDialog}
      />
      <SlideInDialog
        title={props.t("user.deleteRecord")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
      <Notes
        open={openNotesCard}
        notes={notes}
        handleSlideInDialog={handleSlideInDialog}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Advertisement)
);
