import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import TableQuery from './TableQuery';
import TableHeader from './TableHeader';
import DataTable from '../../../../templates/tables/DataTable';
import APIRequestDataTableQuery from '../../../../utils/APIRequestDataTableQuery';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import TableButtons from '../../../../templates/buttons/TableButtons';
import LocalStorage from '../../../../config/LocalStorage';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import { connect } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

function Faq(props) {
  const classes = useStyles();
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  const [query, setQuery] = useState({
    keyword: '',
    page: 0,
    pageSize: 5,
    sortField: '',
    sortOrder: 'false',
    active: true,
  });
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([
    {
      button: 'add',
      disable: false,
    },
    {
      button: 'edit',
      disable: false,
    },
    {
      button: 'view',
      disable: false,
    },
    {
      button: 'delete',
      disable: false,
    },
  ]);
  // useful of on page load
  useEffect(() => {
    tableQuery(query); // calling table api on page load by default value
  }, []);
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery({
      keyword: '',
      page: 0,
      pageSize: 5,
      sortField: '',
      sortOrder: 'false',
      active: true,
    });
    tableQuery({
      keyword: '',
      page: 0,
      pageSize: 5,
      sortField: '',
      sortOrder: 'false',
      active: true,
    });
  };
  const [openForm, setOpenForm] = useState({
    status: false,
    title: '',
    divType: '',
    functionName: '',
  });
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === 'query' ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        'POST',
        ConfigAPIURL.roleTableURL,
        JSON.stringify(query)
      ).then((tableData) => {
        setTableData(tableData);
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };
  const notification = (message, type) => {
    SnackbarUtils[type](message, 'bottomCenter', 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  // table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
  };
  //table button call back function
  const tableAdd = () => {
    // console.log('tableAdd');
  };
  const tableEdit = () => {
    // console.log('tableEdit');
  };
  const tableView = () => {
    // console.log('tableView');
  };
  const tableDelete = () => {
    // console.log('tablDelete');
  };
  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              <TableButtons
                tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableCheckStatus={tableCheckStatus}
                buttonList={buttonList}
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(Faq)
);
