import { TableCell, Popover } from "@material-ui/core";
import React from "react";

class TableHeader {
  static truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // static renderDescriptionCell = (description) => {
  //   console.log("i am inside desc", description);
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  //   const handlePopoverOpen = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handlePopoverClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const open = Boolean(anchorEl);

  //   return (
  //     <TableCell
  //       id="description"
  //       align="left"
  //       padding="default"
  //       className="YourCustomClass"
  //       onMouseEnter={handlePopoverOpen}
  //       onMouseLeave={handlePopoverClose}
  //     >
  //       {this.truncateText(description, 200)}
  //       <Popover
  //         id="popover"
  //         open={open}
  //         anchorEl={anchorEl}
  //         onClose={handlePopoverClose}
  //         anchorOrigin={{
  //           vertical: "bottom",
  //           horizontal: "left",
  //         }}
  //         transformOrigin={{
  //           vertical: "top",
  //           horizontal: "left",
  //         }}
  //       >
  //         <div>{description}</div>
  //       </Popover>
  //     </TableCell>
  //   );
  // };
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
	  
		  id:- Use for uniquely identify each column in data table,
		  accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
					  Example:- 
								  1> name, is from parent, ( {name:'name'} )
								  2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
		  dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
					  1> string & date - left (handle in DataTable.js)
					  2> boolean- center (handle in DataTable.js)
					  3> number-  right (handle in DataTable.js)
		  sortable:- Use to enable & disable sorting for perticuler column.
		  disablePadding:- Use to enable padding.
		  width:- Use to set individual column min-width in px. It's should be number format.
		  label:- Refer the values from translations file, (./src/translations/**.js).
		  className:- It's basically the js file name & all the file should be available in utils folder.
		  functionName:-  It's basically the function name, which should be available inside above className 
		  
	  ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static tableHeader =
    //  [
    //   {
    //     id: "title",
    //     accessor: "title",
    //     dataType: "string",
    //     sortable: true,

    //     disablePadding: false,
    //     visibility: true,
    //     width: 200,
    //     label: "advertisements.title",
    //     className: "",
    //     functionName: "",
    //   },
    //   // {
    //   //   id: "description",
    //   //   accessor: "description",
    //   //   dataType: "string",
    //   //   sortable: true,

    //   //   disablePadding: false,
    //   //   visibility: true,
    //   //   width: 200,
    //   //   label: "sessions.description",
    //   //   className: "",
    //   //   functionName: "",
    //   // },
    //   {
    //     id: "description",
    //     accessor: "description",
    //     dataType: "string",
    //     sortable: true,

    //     disablePadding: false,
    //     visibility: true,
    //     width: 200,
    //     label: "sessions.description",
    //     className: "DateConversion",
    //     functionName: "renderDescriptionCell", // Assigning the function to functionName
    //   },
    //   {
    //     id: "meetLink",
    //     accessor: "meetLink",
    //     dataType: "string",
    //     sortable: true,

    //     disablePadding: false,
    //     visibility: true,
    //     width: 200,
    //     label: "sessions.meetLink",
    //     className: "",
    //     functionName: "",
    //   },
    //   // {
    //   //   id: 'typeOfLink',
    //   //   accessor: 'typeOfLink',
    //   //   dataType: 'string',
    //   //   sortable: true,

    //   //   disablePadding: false,
    //   //   visibility: true,
    //   //   width: 200,
    //   //   label: 'advertisements.typeOfLink',
    //   //   className: '',
    //   //   functionName: '',
    //   // },
    //   // {
    //   //   id: "name",
    //   //   accessor: "userId.fname",
    //   //   dataType: "string",
    //   //   sortable: true,

    //   //   disablePadding: false,
    //   //   visibility: true,
    //   //   width: 200,
    //   //   label: "sessions.patientName",
    //   //   className: "",
    //   //   functionName: "",
    //   // },
    //   // {
    //   //   id: "startDate",
    //   //   accessor: "startDate",
    //   //   dataType: "date",
    //   //   sortable: true,

    //   //   disablePadding: false,
    //   //   visibility: true,
    //   //   width: 200,
    //   //   label: "sessions.startDate",
    //   //   className: "DateConversion",
    //   //   functionName: "unixToLocaleDate",
    //   // },
    //   {
    //     id: "createdAt",
    //     accessor: "createdAt",
    //     dataType: "date",
    //     sortable: true,
    //     disablePadding: false,
    //     visibility: true,
    //     width: 200,
    //     label: "sessions.createdAt",
    //     className: "DateConversion",
    //     functionName: "formatDate",
    //   },
    //   {
    //     id: "updatedAt",
    //     accessor: "updatedAt",
    //     dataType: "date",
    //     sortable: true,
    //     disablePadding: false,
    //     visibility: true,
    //     width: 200,
    //     label: "advertisements.updatedAt",
    //     className: "DateConversion",
    //     functionName: "formatDate",
    //   },
    // ];

    [
      {
        id: "patient",
        accessor: "fname",
        dataType: "string",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Patient",
        className: "",
        functionName: "",
      },
      {
        id: "createdBY",
        accessor: "consultation.createdBy.fname",
        dataType: "string",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Created By",
        className: "DateConversion",
        functionName: "renderDescriptionCell", // Assigning the function to functionName
      },
      {
        id: "status",
        accessor: "consultation.status",
        dataType: "string",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Status",
        className: "",
        functionName: "",
      },
      {
        id: "updatedAt",
        accessor: "consultation.startDate",
        dataType: "date",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Start Date/ Time",
        className: "DateConversion",
        functionName: "dateAndTime",
      },
      {
        id: "meetlink",
        accessor: "consultation.consultationId.meetLink",
        dataType: "string",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Meetlink",
        className: "",
        functionName: "",
      },

      {
        id: "title",
        accessor: "consultation.consultationId.title",
        dataType: "string",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Title",
        className: "DateConversion",
        functionName: "renderDescriptionCell", // Assigning the function to functionName
      },
      {
        id: "description",
        accessor: "consultation.consultationId.description",
        dataType: "string",
        sortable: true,
        disablePadding: false,
        visibility: true,
        width: 200,
        label: "Description",
        className: "",
        functionName: "",
      },
    ];
}
export default TableHeader;
