import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Popover,
} from "@material-ui/core";
import { connect } from "react-redux";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DashboardQuery from "./DashboardQuery";
import WebAdminDashboard from "./webAdmin";

import SnackbarUtils from "../../../../utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  typographyQueryTitle: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    alignSelf: "center",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

const today = new Date();
const oneMonthAgo = new Date(today);
oneMonthAgo.setMonth(today.getMonth() - 1);

const queryParams = {
  startDate: oneMonthAgo,
  // startDate: new Date(new Date().setDate(1)),
  endDate: new Date(),
};

function Dashboard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [webAdminData, setWebAdminData] = useState([]);
  const [webAdminGraph, setWebAdminGraph] = useState([]);

  const [query, setQuery] = useState(queryParams);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const resetQuery = () => {
    setQuery(queryParams);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    getWebStats();
  }, []);

  useEffect(() => {
    getCaseStats(query);
  }, [query]);

  const getWebStats = () => {
    APIRequest.request("GET", ConfigAPIURL.getWebStats, "").then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode !== undefined) {
          if (response.data.responseCode === 109) {
            setWebAdminData(response.data.result);
          }
        }
      }
    });
  };
  const getCaseStats = (queryDates) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getCaseStats,
      JSON.stringify({
        ...query,
        startDate: Math.floor(
          new Date(query.startDate.setHours(0, 0, 0, 0)).getTime() / 1000
        ),
        endDate: Math.floor(
          new Date(query.endDate.setHours(23, 59, 0, 0)).getTime() / 1000
        ),
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode !== undefined) {
          if (response.data.responseCode === 109) {
            let serverResponse = [];
            let data;
            response.data.case.map((results, index) => {
              data = { _id: results._id };
              results.item.map((result) => {
                data[result.status] = result.count;
              });
              if (data.processed === undefined) {
                data["processed"] = 0;
              }
              if (data.new === undefined) {
                data["new"] = 0;
              }
              if (data.underReview === undefined) {
                data["underReview"] = 0;
              }
              serverResponse.push(data);
            });

            setWebAdminGraph(serverResponse);
          }
        }
      }
    });
  };

  const handleQueryStartDates = (date) => {
    if (date > query.endDate) {
      return SnackbarUtils.error(
        props.t("toaster.startDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }

    setQuery({
      ...query,
      startDate: date,
    });
  };
  const handleQueryEndDates = (date) => {
    if (date < query.startDate) {
      return SnackbarUtils.error(
        props.t("toaster.endDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
    setQuery({
      ...query,
      endDate: date,
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardContent>
              <Grid container style={{ justifyContent: "flex-end" }}>
                {/* <Grid
                  item
                  xl={2}
                  lg={2}
                  md={4}
                  sm={6}
                  xs={12}
                  style={{ marginTop: 10 }}
                >
                  <Typography component='p' variant='p' gutterBottom>
                    {props.t('dashboard.customDateRange')}
                  </Typography>
                </Grid>

                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                  <TextField
                    id='startDate'
                    label={props.t('query.startDate')}
                    InputProps={{
                      readOnly: true,
                    }}
                    autoComplete='something-unsupported'
                    required
                    value={DateConversion.unixToLocaleDate(query.startDate)}
                    fullWidth
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                  <TextField
                    id='endDate'
                    label={props.t('query.endDate')}
                    InputProps={{
                      readOnly: true,
                    }}
                    autoComplete='something-unsupported'
                    required
                    value={DateConversion.unixToLocaleDate(query.endDate)}
                    fullWidth
                  />
                </Grid> */}

                <Grid item xl={1} lg={1} md={2} sm={6} xs={12}>
                  <Tooltip title={props.t("dataTable.filterList")}>
                    <IconButton
                      aria-label="filter list"
                      onClick={handleFilterClick}
                    >
                      <FilterListIcon
                        aria-describedby={"query_Menu"}
                        variant="contained"
                      />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    style={{ width: "min-content" }}
                    id={"query_Menu"}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Grid container>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        align="left"
                        style={{ alignSelf: "center" }}
                      >
                        <Typography style={{ margin: 5 }}>
                          {props.t("dataTable.filter")}
                        </Typography>
                      </Grid>
                      <Grid xl={6} lg={6} md={6} sm={6} xs={6} align="right">
                        <Tooltip ml={2} title={props.t("dataTable.clearAll")}>
                          <IconButton onClick={() => resetQuery()}>
                            <ClearAllIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <DashboardQuery
                      query={query}
                      setQuery={setQuery}
                      getCaseStats={getCaseStats}
                      handleQueryStartDates={handleQueryStartDates}
                      handleQueryEndDates={handleQueryEndDates}
                    />
                  </Popover>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <WebAdminDashboard
            webAdminData={webAdminData}
            webAdminGraph={webAdminGraph}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(Dashboard)
);
