import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  CardActions,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import NotificationMacroDisplay from "../../../../templates/NotificationMacroDisplay";
import SnackbarUtils from "../../../../utils/SnackbarUtils";

const CreateReminderTemplates = (props) => {
  const [openMacroForm, setOpenMacroForm] = useState(false);
  const [userMacros, setUserMacros] = useState([]); //List of all Macros

  const handleMacroForm = () => {
    setOpenMacroForm(false);
  };
  const handleOpenMacroForm = () => {
    getAllmacros();
    setOpenMacroForm(true);
  };

  const getAllmacros = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.getAllmacros,
      JSON.stringify({ userId: props?.rowDetails?._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            // Transform API response into desired format
            const transformedMacros = response?.data?.macros?.map((macro) => ({
              label: `macros.${macro}`,
              value: `<%${macro}%>`,
            }));

            // Setting state with transformed data
            setUserMacros(transformedMacros);
          }
        }
      }
    });
  };

  // Update form data for a specific field
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the changed field is title, then update both title and subject
    if (name === "title") {
      props.setFormData((prevState) => ({
        ...prevState,
        title: value,
        subject: value, // Update subject with the same value as title
      }));
    } else {
      props.setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const createRemindertemplates = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.createReminderTemplate,
      JSON.stringify({ ...props.formData })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            // Transform API response into desired format

            props.setSlideInDialog();
            props.getNotifications();
          }
        }
      }
    });
  };
  const updateRemindertemplates = () => {
    APIRequest.request(
      "PATCH",
      ConfigAPIURL.updateReminders,
      JSON.stringify({ ...props.formData })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            // Transform API response into desired format
            props.setSlideInDialog();
            props.getNotifications();
            // alert(`Successfully created reminder template!`);
          }
        }
      }
    });
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          alignItems: "flex-end",
          width: "100%",
          justifyContent: "flex-end",
          margin: "0px",
        }}
      >
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Button fullWidth onClick={handleOpenMacroForm}>
            {props.t("patientAccess.viewMacros")}
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          // className={classes.formGrid}
        >
          <TextField
            name="title"
            label={props.t("patientAccess.title")}
            autoComplete="something-unsupported"
            value={props.formData.title}
            fullWidth
            required
            onChange={handleInputChange} // Update title state
          />
        </Grid>
        <Grid
          item
          xl={8}
          lg={8}
          md={6}
          sm={12}
          xs={12}
          // className={classes.formGrid}
        >
          <TextField
            name="content"
            label={props.t("patientAccess.content")}
            autoComplete="something-unsupported"
            value={props.formData.content}
            fullWidth
            required
            onChange={handleInputChange} // Update title state
          />
        </Grid>
      </Grid>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={props.setSlideInDialog}>
          {props.t("buttons.close")}
        </Button>
        {props.isEditing === true ? (
          <Button onClick={updateRemindertemplates}>
            {props.t("buttons.update")}
          </Button>
        ) : (
          <Button onClick={createRemindertemplates}>
            {props.t("buttons.create")}
          </Button>
        )}
      </Grid>

      <SlideInDialog
        title={props.t("patientAccess.openMacrosReminder")}
        contentComponent={
          <NotificationMacroDisplay
            setSlideInDialog={handleMacroForm}
            macro={userMacros}
          />
        }
        fullWidth={true}
        maxWidth={"md"}
        dialogActionsStatus={false}
        slideInDialog={openMacroForm}
        setSlideInDialog={handleMacroForm}
        // handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
};

export default withTranslation("translations")(CreateReminderTemplates);
