import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  makeStyles,
} from '@material-ui/core';
import APIRequest from '../../../../utils/APIRequest';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import { connect } from 'react-redux';
import FormValidation from '../../../../utils/FormValidation';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textAlign: 'end',
  },
}));
function AddRole(props) {
  const classes = useStyles();
  const [addRoleObject, setAddRoleObject] = React.useState({
    name: '',
    active: true,
  });
  const resetRoleObject = () => {
    setAddRoleObject({ name: '', active: true });
    props.setSlideInDialog(false);
  };
  const addRole = () => {
    let fieldValidation = ['name'];
    FormValidation.validation(fieldValidation, addRoleObject).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(
            'POST',
            ConfigAPIURL.roleCreate,
            JSON.stringify(addRoleObject)
          ).then((response) => {
            if (response.code === 100) {
              if (response.data !== undefined && response.data !== null) {
                if (response.data.responseCode === 109) {
                  resetRoleObject();
                  SnackbarUtils.sucess(
                    props.t('toaster.transaction_completed'),
                    'bottomCenter',
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                } else {
                  SnackbarUtils.error(
                    props.t('toaster.' + response.data.responseCode),
                    'bottomCenter',
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              } else {
                SnackbarUtils.error(
                  props.t('toaster.serverError'),
                  'bottomCenter',
                  3000
                ).then((notification) => {
                  props.publishNotification(notification);
                });
              }
            } else {
              SnackbarUtils.error(
                props.t('toaster.serverError'),
                'bottomCenter',
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
            }
          });
        }
      }
    );
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <TextField
            autoFocus
            margin='dense'
            required
            id='name'
            autoComplete='off'
            label={props.t('role.roleName')}
            type='text'
            fullWidth
            value={addRoleObject.name}
            onChange={(event) => {
              setAddRoleObject({ ...addRoleObject, name: event.target.value });
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} ml={2}>
          <TextField
            label={props.t('role.status')}
            select
            defaultValue={addRoleObject.active}
            value={addRoleObject.active}
            onChange={(event) =>
              setAddRoleObject({ ...addRoleObject, active: event.target.value })
            }
            fullWidth
            style={{ marginTop: 4 }}
          >
            <MenuItem key={0} value={true}>
              {props.t('role.active')}
            </MenuItem>
            <MenuItem key={1} value={false}>
              {props.t('role.inactive')}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.actionButton}
        >
          <Button
            onClick={() => {
              resetRoleObject();
            }}
            color='primary'
            style={{ margin: 2 }}
          >
            {props.t('buttons.cancel')}
          </Button>
          <Button
            onClick={() => addRole()}
            color='primary'
            style={{ margin: 2 }}
          >
            {props.t('buttons.save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(AddRole)
);
