import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { withTranslation } from "react-i18next";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import APIRequest from "../../../../../utils/APIRequest";
import CreatePatient from "./CreatePatient";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SlideInDialog from "../../../../../templates/dialog/SlideInDialog";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function Patient(props) {
  const classes = useStyles();

  const [openAddForm, setOpenAddForm] = useState(false);

  const [patient, setpatient] = useState("");

  // const [userList, setUserList] = useState([]);

  const handleAddPatients = () => {
    setOpenAddForm(true);
  };
  const handleAddPatientsDialog = () => {
    setOpenAddForm(false);
  };

  // useEffect(() => {
  //   getUserList();
  // }, []);

  // const getUserList = () => {
  //   APIRequest.request(
  //     'POST',
  //     ConfigAPIURL.searchPatientOrCaregiver,
  //     JSON.stringify({ userId: props.rowDetails._id, keyword: '' })
  //   ).then((response) => {
  //     if (response !== undefined && response !== null) {
  //       if (response.code === 100 && response.data.responseCode === 109) {
  //         setUserList(response.data.result);
  //       }
  //     }
  //   });
  // };

  // const handleAssignDialog = (response) => {
  //   setOpenAddForm(false);
  //   if (response === 'submit') {
  //     if (patient === '') {
  //       return SnackbarUtils.error(
  //         props.t('snackbar.addPatient'),
  //         'bottomCenter',
  //         3000
  //       ).then((notification) => {
  //         props.publishNotification(notification);
  //       });
  //     }
  //     APIRequest.request(
  //       'POST',
  //       ConfigAPIURL.addPatient,
  //       JSON.stringify({
  //         userId: props.rowDetails._id,
  //         patientId: patient._id,
  //       })
  //     ).then((response) => {
  //       if (response !== undefined && response !== null) {
  //         if (response.code === 100 && response.data.responseCode === 100) {
  //           props.handleRefreshData();
  //           setpatient('');
  //           // props.getUserList();
  //           SnackbarUtils.sucess(
  //             props.t('snackbar.transComplete'),
  //             'bottomCenter',
  //             3000
  //           ).then((notification) => {
  //             props.publishNotification(notification);
  //           });
  //         }
  //       }
  //     });
  //   }
  // };

  // const handleUserList = (event, value) => {
  //   setpatient(value);
  // };

  const handledelete = (selected) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.removePatient,
      JSON.stringify({
        userId: props.rowDetails._id,
        patientId: selected._id,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 100) {
          props.handleRefreshData();
          // setpatient('');
          // props.getUserList();
          SnackbarUtils.sucess(
            props.t("snackbar.transComplete"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };
  return (
    <div>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.root}>
            <CardHeader
              action={
                <Tooltip title={props.t("dataTable.add")}>
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      handleAddPatients();
                    }}
                  >
                    <AddCircleIcon variant="contained" />
                  </IconButton>
                </Tooltip>
              }
              title={
                <Typography
                  variant="p"
                  component="p"
                  style={{ fontWeight: "bold" }}
                >
                  {props.t("patientAccess.assignedPatients")}
                </Typography>
              }
            />
            <CardContent>
              <Grid container>
                {/* <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: '20px' }}
                >
                  <TextField
                    variant='outlined'
                    id='fname'
                    label={props.t('patientAccess.searchPatient')}
                    autoComplete='something-unsupported'
                    autoComplete='off'
                    //   value={props.data.keyword}
                    fullWidth
                    onChange={(event) =>
                      props.setData({
                        ...props.data,
                        keyword: event.target.value,
                        searchType: 'patient',
                      })
                    }
                  />
                </Grid> */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {props.patientsData.length === 0 ? (
                    <Card
                      style={{
                        marginTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              variant="p"
                              component="p"
                            >
                              No Records Found
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    props.patientsData.map((patients, index) => (
                      <Card key={index}>
                        <CardContent>
                          <Tooltip
                            title={props.t("dataTable.delete")}
                            style={{ float: "right" }}
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => handledelete(patients)}
                            >
                              <DeleteIcon variant="contained" />
                            </IconButton>
                          </Tooltip>

                          <Typography
                            variant="p"
                            component="p"
                            style={{ fontWeight: "bold" }}
                          >
                            {props.t("patientAccess.name")}
                          </Typography>
                          <Typography variant="p" component="p">
                            {patients.fname + " " + patients.lname}
                          </Typography>
                          {/* <Grid container> */}
                          <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
                            <Typography
                              variant="p"
                              component="p"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.t("patientAccess.mobileNo")}
                            </Typography>
                            <Typography variant="p" component="p">
                              {patients.mobileNo}
                            </Typography>
                          </Grid>
                          <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
                            <Typography
                              variant="p"
                              component="p"
                              style={{ fontWeight: "bold" }}
                            >
                              {props.t("patientAccess.email")}
                            </Typography>
                            <Typography variant="p" component="p">
                              {patients.email}
                            </Typography>
                          </Grid>
                          {/* </Grid> */}
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SlideInDialog
        title={props.t("patientAccess.addPatients")}
        contentComponent={
          <CreatePatient
            page={"patient"}
            // getUserLists={props.getUserList}
            label={props.t("patientAccess.selectPatient")}
            // userList={props.userList}
            handleRefreshData={props.handleRefreshData}
            // patient={patient}
            handleAddPatientsDialog={handleAddPatientsDialog}
            rowDetails={props.rowDetails}
            // handleUserList={handleUserList}
            // handleAssignDialog={handleAssignDialog}
          ></CreatePatient>
        }
        fullWidth={true}
        maxWidth={"sm"}
        slideInDialog={openAddForm}
        dialogActionsStatus={false}
      />
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(Patient)
);
