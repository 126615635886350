class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
      
          id:- Use for uniquely identify each column in data table,
          accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                      Example:- 
                                  1> name, is from parent, ( {name:'name'} )
                                  2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
          dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                      1> string & date - left (handle in DataTable.js)
                      2> boolean- center (handle in DataTable.js)
                      3> number-  right (handle in DataTable.js)
          sortable:- Use to enable & disable sorting for perticuler column.
          disablePadding:- Use to enable padding.
          width:- Use to set individual column min-width in px. It's should be number format.
          label:- Refer the values from translations file, (./src/translations/**.js).
          className:- It's basically the js file name & all the file should be available in utils folder.
          functionName:-  It's basically the function name, which should be available inside above className 
          
      ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/
  static tableHeader = [
    {
      id: 'webAdmin',
      accessor: 'label',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: 'dashboard.webAdmin',
      className: '',
      functionName: '',
    },
    {
      id: 'today',
      accessor: 'today',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'dashboard.today',
      className: '',
      functionName: '',
    },

    {
      id: 'last7Days',
      accessor: 'sevendays',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'dashboard.last7Days',
      className: '',
      functionName: '',
    },

    {
      id: 'last30Days',
      accessor: 'thirtydays',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'dashboard.last30Days',
      className: '',
      functionName: '',
    },

    // {
    //   id: 'notes',
    //   accessor: '_id',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: 'case.notes',
    //   className: 'DataTableUtil',
    //   functionName: 'processTableId',
    // },
    // {
    //   id: 'userName',
    //   accessor: 'userName',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: 'case.userName',
    //   className: '',
    //   functionName: '',
    // },

    // {
    //   id: 'doctor',
    //   accessor: 'doctor.name',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: 'case.doctorName',
    //   className: '',
    //   functionName: '',
    // },
  ];
}
export default TableHeader;
