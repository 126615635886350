import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
  Paper,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Alert } from '@material-ui/lab';
import imageCompression from 'browser-image-compression';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import ChipInput from 'material-ui-chip-input';
import APIRequest from '../../../../utils/APIRequest';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import LocalStorage from '../../../../config/LocalStorage';
import { MdDeleteSweep, MdSend, MdCloudUpload } from 'react-icons/md';
import FileUploadTableCheckBox from '../../../../templates/FileUploadTableCheckBox';
import FileUpload from '../../../../templates/FileUpload';
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
  },
}));
const enquiryFormDetails = {
  active: true,
  attachment: [],
  userId: {},
  name: '',
  mobileNo: '',
  email: '',
  ticketNo: '',
  content: '',
  enquiryType: '', //API
  status: '', //opened, closed,
  resolved: {
    // resolver: '',
    remark: '',
    attachment: [],
    resolver: {
      fname: '',
    },
  },
};
function EVForm(props) {
  const classes = useStyles();

  const [selectedImages, setSelectedImages] = useState([]);

  const [userForm, setUserForm] = useState(enquiryFormDetails);

  const [aws, setAws] = useState(LocalStorage.aws);
  const [galleryFileBase64, setGalleryFileBase64] = useState([]);
  const [file, setFile] = useState('');
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const notificationClose = (event) => {
    setSnakbarValues({ status: false, severity: '', message: '' });
  };

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: '',
    message: '',
  });

  const galleryFileUpload = useRef();

  useEffect(() => {
    // setUserForm(enquiryFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'submit'
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'reset'
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== '' &&
      props.formDialog.divType !== 'new' &&
      props.formDialog.functionName === ''
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setUserForm(enquiryFormDetails);
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== 'new'
    ) {
      APIRequest.request(
        'POST',
        ConfigAPIURL.enquiryDetails,
        JSON.stringify({ eId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setUserForm(response.data.enquiry);
          }
        }
      });
    }
  };

  const handleGalleryImage = (event) => {
    if (aws !== '') {
      [...event.target.files].map(async (data) => {
        let reader = new FileReader();
        let url = '';
        if (data.type.startsWith('image') && data.size > 1000000) {
          setSnakbarValues({
            status: true,
            severity: 'sucess',
            message: 'Compressing image. Please wait for a while.',
          });

          var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(data, options);
        } else if (data.size < 3000000) {
          url = reader.readAsDataURL(data);
        } else {
          setSnakbarValues({
            status: true,
            severity: 'error',
            message: 'File size is too large, Max. file size is 3mb.',
          });

          return;
        }
        setSnakbarValues({
          status: true,
          severity: 'sucess',
          message: 'Ready to upload image into storage',
        });

        reader.onloadend = function () {
          setSnakbarValues({
            status: true,
            severity: 'sucess',
            message: 'Error uploading file',
          });
          setGalleryFileBase64(reader.result.split('base64,')[1]);
          setFile(data);
          setFileUploadStatus(true);
          galleryFileUpload.current._s3Upload();
        }.bind(this);
      });
    } else {
      setFileUploadStatus(false);
      setGalleryFileBase64('');
      setFile('');
      setSnakbarValues({
        status: true,
        severity: 'sucess',
        message: 'Error uploading file',
      });
    }
  };

  const imageTableRowDetails = (selected) => {
    if (selected !== undefined && selected !== null) {
      setSelectedImages(selected);
    }
  };

  const _s3GalleryImageUrl = (url) => {
    let user = userForm;
    user.resolved.attachment.push(url);
    setUserForm(user);
    setFileUploadStatus(false);
    setGalleryFileBase64('');
    setFile('');
  };

  const _deleteS3Files = (event) => {
    let form = userForm;
    selectedImages.map((selectedimages, idx) => {
      let index = form.resolved.attachment.indexOf(selectedimages);
      if (index !== -1) {
        form.resolved.attachment.splice(index, 1);
      }
    });
    setUserForm({ ...userForm, form });
  };

  const sendToServer = () => {
    let url = '';
    let method = 'POST';

    if (props.formDialog.divType === 'edit') {
      url = ConfigAPIURL.enquiryUpdate;
      userForm['eId'] = props.rowDetails._id;
      method = 'PUT';
    }
    APIRequest.request(method, url, JSON.stringify(userForm)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            props.handleFormDialog();
            props.tableQuery({
              keyword: '',
              page: 0,
              pageSize: 10,
              sortField: '',
              userType: 'all',
              sortOrder: 'false',
              active: true,
            });
            setUserForm(enquiryFormDetails);
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            alert('Duplicate Records');
          }
        }
      }
    );
  };
  return (
    <div className={classes.root}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <p>Name : {userForm.userId.fname}</p>
                <p>Mobile Number : {userForm.userId.mobileNo}</p>
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <p>
                  {props.t('enquiries.enquiryType')} : {userForm.enquiryType}
                </p>
                <p> Ticket No : {userForm.ticketNo}</p>
                <p> Status : {userForm.status}</p>
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <p>Details : {userForm.content}</p>
              </Grid>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ textAlign: 'center' }}
              >
                <p>{props.t('enquiries.resolvingDetails')}</p>
              </Grid>
              {userForm.resolved.resolver !== undefined &&
              userForm.resolved.resolver !== null ? (
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: 'center' }}
                >
                  <p>
                    {props.t('enquiries.resolver')} :{' '}
                    {userForm.resolved.resolver.fname}
                  </p>
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                xl={3}
                lg={3}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id='active'
                  label={props.t('enquiries.active')}
                  select
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={userForm.active}
                  value={userForm.active}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      active: event.target.value,
                    })
                  }
                  style={{ marginTop: '-4px' }}
                >
                  <MenuItem key={0} value={true}>
                    {props.t('enquiries.yes')}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t('enquiries.no')}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={3}
                lg={3}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  id='status'
                  label={props.t('enquiries.status')}
                  select
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={userForm.status}
                  value={userForm.status}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      status: event.target.value,
                    })
                  }
                  style={{ marginTop: '-4px' }}
                >
                  <MenuItem key={0} value={'opened'}>
                    {props.t('enquiries.opened')}
                  </MenuItem>
                  <MenuItem key={1} value={'closed'}>
                    {props.t('enquiries.closed')}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
              >
                <TextField
                  label={props.t('enquiries.remarks')}
                  id='remarks'
                  fullWidth
                  value={userForm.resolved.remark}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      resolved: {
                        ...userForm.resolved,
                        remark: event.target.value,
                      },
                    })
                  }
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  autoComplete='off'
                />
              </Grid>
              {props.formDialog.divType === 'new' ||
              props.formDialog.divType === 'edit' ? (
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formGrid}
                >
                  <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id='galleryImageFileUploader'
                    // multiple
                    disabled={
                      props.formDialog.divType === 'new' ||
                      props.formDialog.divType === 'edit'
                        ? false
                        : true
                    }
                    onChange={handleGalleryImage}
                    type='file'
                  />
                  <label htmlFor='galleryImageFileUploader'>
                    <Button
                      style={{ marginTop: '10px' }}
                      variant='contained'
                      color='primary'
                      component='span'
                      className={'mr-2'}
                    >
                      <MdCloudUpload
                        style={{
                          fontSize: 20,
                          marginRight: '20px',
                        }}
                      />
                      {props.t('enquiries.uploadImages')}
                    </Button>
                  </label>
                  <Button
                    style={{ marginTop: '10px', float: 'right' }}
                    variant='contained'
                    color='primary'
                    component='span'
                    className={'mr-2'}
                    onClick={_deleteS3Files}
                  >
                    <MdDeleteSweep
                      style={{
                        fontSize: 20,
                        marginRight: '20px',
                      }}
                    />
                    {props.t('enquiries.deleteFile')}
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <FileUploadTableCheckBox
                  imageTableCallBack={imageTableRowDetails}
                  data={userForm.resolved.attachment}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <FileUpload
        ref={galleryFileUpload}
        aws={aws}
        file={file}
        base64={galleryFileBase64}
        bucket={aws.enquiryBucket}
        callback={_s3GalleryImageUrl}
      />
      <Snackbar
        open={snakbarValues.status}
        onClose={notificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
      >
        <Alert severity={snakbarValues.severity}>{snakbarValues.message}</Alert>
      </Snackbar>
    </div>
  );
}
export default withTranslation('translations')(EVForm);
