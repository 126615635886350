import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ConfigAPIURL from '../../../../../config/ConfigAPIURL';
import SnackbarUtils from '../../../../../utils/SnackbarUtils';
import ImageUploadHistroy from '../../../../../templates/imageuploadhistroy/ImageUploadHistroy';
const UploadImageHeader = ['title', 'fileType'];
function UploadStock(props) {
	const [bucketName] = React.useState('inventoryImage');
	const [uploadTableHeader] = React.useState(UploadImageHeader);
	const [uploadUrl] = React.useState(ConfigAPIURL.uploadInventoryImages);
	const [uploadHistoryUrl] = React.useState('');
	const notification = (message, type) => {
		SnackbarUtils[type](message, 'bottomCenter', 3000).then((notification) => {
			props.publishNotification(notification);
		});
	};
	return (
		<ImageUploadHistroy
			uploadTableHeader={uploadTableHeader}
			uploadUrl={uploadUrl}
			uploadHistoryUrl={uploadHistoryUrl}
			bucketName={bucketName}
			notification={notification}
			menuName={'inventory'}
			operationType={'uploadInventoryImage'}
		/>
	);
}

const mapDispachToProps = (dispatch) => {
	return {
		publishNotification: (notification) => dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
	};
};
export default withTranslation('translations')(connect(null, mapDispachToProps)(UploadStock));
