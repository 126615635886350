import PropTypes from 'prop-types';
const Article = {
	seoFormatter: function (details) {
		return {
			'@context': 'https://schema.org/',
			'@type': 'Article',
			headline: details.headline,
			image: {
				'@type': 'ImageObject',
				url: details.imageUrl,
				width: details.imageWidth,
				height: details.imageHeight,
			},
			author: {
				'@type': 'Person',
				name: details.author,
			},
			publisher: {
				'@type': 'Organization',
				name: details.publisher,
				logo: {
					'@type': 'ImageObject',
					url: details.publisherLogoUrl,
					width: details.publisherLogoWidth,
					height: details.publisherLogoHeight,
				},
			},
			datePublished: details.datePublished,
		};
	},
};
Article.propTypes = {
	headline: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired,
	imageWidth: PropTypes.string.isRequired,
	imageHeight: PropTypes.string.isRequired,
	author: PropTypes.string.isRequired,
	publisher: PropTypes.string.isRequired,
	publisherLogoUrl: PropTypes.string.isRequired,
	publisherLogoWidth: PropTypes.string.isRequired,
	publisherLogoHeight: PropTypes.string.isRequired,
	datePublished: PropTypes.string.isRequired,
};
export default Article;
