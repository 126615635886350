import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  CardActions,
  CardContent,
  Card,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";

import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
// import APIRequest from '../../../../../utils/APIRequest';
// import FormValidation from '../../../../../utils/FormValidation';
// import ConfigAPIURL from '../../../../../config/ConfigAPIURL';
// import MedicationTableWithPagination from '../../../../../templates/tables/MedicationTableWithPagination';
// import Patient from './Patient';
// import Caregiver from './Caregiver';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

function NotificationMacroDisplay(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        {props?.macro?.map((macros, index) => (
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.formGrid}
          >
            <TextField
              key={index}
              id={index}
              label={props.t(macros.label)}
              autoComplete="something-unsupported"
              value={macros.value}
              fullWidth
            />
          </Grid>
        ))}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
          <Button onClick={props.setSlideInDialog}>
            {props.t("buttons.close")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation("translations")(NotificationMacroDisplay);
