class LocalStorage {
  //Base URL For Live & Staging
  // static aws = {
  // 	CognitoIdentityCredentials: {
  // 		IdentityPoolId: process.env.REACT_APP_AWS_IdentityPoolId || process.env.REACT_APP_Dev_AWS_IdentityPoolId,
  // 		region:
  // 			process.env.REACT_APP_AWS_CognitoIdentityCredentials_Region ||
  // 			process.env.REACT_APP_Dev_AWS_CognitoIdentityCredentials_Region,
  // 	},
  // 	region: process.env.REACT_APP_AWS_Region || process.env.REACT_APP_Dev_AWS_Region,
  // 	bucket: process.env.REACT_APP_AWS_Bucket || process.env.REACT_APP_Dev_AWS_Bucket,
  // 	link: process.env.REACT_APP_AWS_Link || process.env.REACT_APP_Dev_AWS_Link,
  // };

  static searchableStringLimit = 2;
  static aws = {
    CognitoIdentityCredentials: {
      IdentityPoolId: "ap-south-1:649af36f-e5a4-4138-8b30-1b2c67a5b4e6",
      region: "ap-south-1",
    },
    // accessKeyId: 'AKIAVZSKAPZUM3BMM6FZ',
    accessKeyId: "AKIA44Q7KKCMVUKGEWWP",
    secretAccessKey: "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",
    // secretAccessKey: 'aCHVX7DWauWyGxLoLPAPy7v5XjuCiv96FhKB5USl',
    smtpAccessKeyId: "AKIAVZSKAPZUMDOIUWQR",
    smtpSecretAccessKey: "BITjpfyduWf5AKisdIXJTsKVkvZ++f9lRLM5IfBwwqQD",
    region: "ap-south-1",
    bucket: "davaguide",
    prescriptionBucket: "davaguide/prescription",
    enquiryBucket: "davaguide/enquiry",
    advertisementBucket: "davaguide/advertisement",
    link: "https://s3.ap-south-1.amazonaws.com/",
  };
  static theme = "dark";
  static lng = {
    displayName: "English",
    code: "en_IN",
  };
  static userDetails = {
    email: "",
    mobileNo: "",
    name: "",
    permission: null,
    profileImage: "",
    userName: "",
    userType: "",
    _id: "",
  };
  static dataTablePageSize = [5, 10, 25, 50, 100, 250, 500, 1000];
  static adminKeyboardShortcut = [
    {
      action: ["command+a", "ctrl+a"],
      dispatchValue: "add",
      description: "keyboardShortcut.add",
    },
    {
      action: ["command+e", "ctrl+e"],
      dispatchValue: "edit",
      description: "keyboardShortcut.edit",
    },
    {
      action: ["command+d", "ctrl+d"],
      dispatchValue: "delete",
      description: "keyboardShortcut.delete",
    },
    {
      action: ["command+m", "ctrl+m"],
      dispatchValue: "view",
      description: "keyboardShortcut.view",
    },
    {
      action: ["command+s", "ctrl+s"],
      dispatchValue: "save",
      description: "keyboardShortcut.save",
    },
    {
      action: ["command+r", "ctrl+r"],
      dispatchValue: "reset",
      description: "keyboardShortcut.reset",
    },
    {
      action: "esc",
      dispatchValue: "esc",
      description: "keyboardShortcut.esc",
    },
  ];
  static token =
    sessionStorage.getItem("payhub.session") !== undefined &&
    sessionStorage.getItem("payhub.session") !== null
      ? sessionStorage.getItem("payhub.session")
      : "";
  static timeSlotsList = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  static roleList = {
    account: "MACCONT",
    account_code: "MACCCOD",
    ack_nack: "TACKCTR",
    alternate_id: "MALTEID",
    banks: "MBNKLST",
    beneficiary_template: "MBENTEM",
    ccy_country_route: "MCCYRUT",
    core: "TCORCTR",
    core_error: "TCORERR",
    core_repair: "TCORREP",
    core_screening: "TCORSCR",
    country: "MCUNTRY",
    country_currency: "MCONCCY",
    country_holidays: "MCUNTHD",
    currency: "MCURNCY",
    currency_country_holidays: "MCCYCHD",
    currency_holidays: "MCURNHD",
    currency_pair: "MCURPIR",
    currency_rules: "MCURULE",
    customer: "MCUSTID",
    customer_family: "MCUSTFAM",
    dashboard: "TDASCTR",
    event_definition: "MEVNTDF",
    expected_statement: "MEXPSTM",
    fee_definition: "MFEESDF",
    function_list: "MFNLIST",
    inbound: "TINBCTR",
    localvostroaccounts: "MLCLVSR",
    master: "MVENMST", //ACT
    nostrobankaccounts: "MOURNSR",
    nostro_correspondent_bank_details: "MNOSTRO",
    outbound: "TOUTCTR",
    product_definition: "MPRODDF",
    product_derivation: "MPRDRIV",
    product_event_definition: "MPDEVDF",
    receiverdeliverymethod: "MRECDLY",
    report: "TREPCTR",
    role_function: "MROLEFN",
    rule_gen_ccy_rule: "MRGCCYC",
    setting: "TSETCTR",
    statement_cash_position: "TSTMBAL",
    statement_detail: "TSTMCTR",
    statement_inbound: "TSTMINB",
    task: "TTASCTR",
    task_reject: "TTASREJ",
    task_repair: "TTASREP",
    tenant: "MBANKID",
    tenor_definition: "MTENRDF",
    user: "MUSRCTR",
    vendor_account: "MVENACT",
    xlscsvmetadata: "MXLSCSV",
    xmldefinition: "MXMLDEF",
  };
  static permission = {}; //For Button Permission Check And Other Usage in Content Side
  static maintenanceOptions = []; //For Side Menu
  //side menu with url with role id
  static adminSideMenuWithRoleID = {
    Dashboard: "/admin/dashboard",
    Users: "/admin/users",
    Cases: "/admin/cases",
    Products: "/admin/products",
    Advertisement: "/admin/advertisement",
    Sessions: "/admin/sessions", // added new page
    Enquiry: "/admin/enquiry",
    faq: "/admin/faq",
    Role: "/admin/security/role",
    setting: "/admin/setting",
    "": "/admin/profile",
    Doctor:"/admin/doctor",
    support:"/admin/customer/support",
  };
  static adminButtonPermission = [];
  static buttonPermissionDefault = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  static dateFormatList = [
    "d.M.yyyy",
    "d.M.yyyy.",
    "d.MM.yyyy",
    "dd.MM.yyyy",
    "dd.MM.yyyy.",
    "d/M/yyyy",
    "d/MM/yyyy",
    "dd/MM/yyyy",
    "dd/MMMM/yy",
    "dd/MM/yy",
    "d-M-yyyy",
    "dd-MM-yyyy",
    "dd-MMMM-yy",
    "dd-MM-yy",
    "M/d/yyyy",
    "MM-dd-yy",
    "MM-dd-yyyy",
    "MM/dd/yy",
    "MM/dd/yyyy",
    "MMMM-dd-yy",
    "MMMM-dd-yyyy",
    "MMMM/dd/yy",
    "MMMM/dd/yyyy",
    "yyyy. M. d",
    "yyyy.M.d",
    "yyyy.MM.dd.",
    "yyyy.d.M",
    "yy-MM-dd",
    "yy-MMMM-dd",
    "yyyy-M-d",
    "yyyy-MM-dd",
    "yyyy-MMMM-dd",
    "yy/MM/dd",
    "yy/MMMM/dd",
    "yyyy/M/d",
    "yyyy/MM/dd",
    "yyyy/MMMM/dd",
    "yyMMdd",
    "yyyyMMdd",
  ];
}

export default LocalStorage;
