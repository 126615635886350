import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import TableQuery from "./TableQuery";
import LocalStorage from "../../../../config/LocalStorage";
import TableHeader from "./TableHeader";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
import APIRequest from "../../../../utils/APIRequest";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import SessionTableData from "../../../../templates/tables/SessionDataTable";
import APIRequestDataTableQuery from "../../../../utils/APIRequestDataTableQuery";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import TableButtons from "../../../../templates/buttons/TableButtons";
import AssignSession from "./AssignSession";
import { useLocation } from "react-router-dom";

import FormDialog from "../../../../templates/dialog/FormDialog";
import AEVForm from "./AEVForm";
import EditForm from "./EditForm";
import DataTable from "../../../../templates/tables/DataTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

function Sessions(props) {
  const classes = useStyles();

  const [slideInDialog, setSlideInDialog] = useState(false);
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  console.log(LocalStorage?.userDetails?.permission,"from here")
  const [query, setQuery] = useState({
    keyword: "",
    page: 0,
    pageSize: 10,
    sortField: "",
    sortOrder: "false",
    active: "true",
    status: "",
    isCareTeam: LocalStorage?.userDetails?.permission === "Care Team" ? true : false,
  });
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });
  // user form Values
  const [userForm, setUserForm] = useState({
    userType: "",
    active: false,
    fname: "",
    lname: "",
    mobileNo: "",
    email: "",
    age: "",
    dob: new Date(),
    gender: "",
    weight: 0,
    height: 0,
    isSmoker: false,
    alcoholIntake: "none",
    allergies: "",
    chronicConditions: "",
    familyHistory: "",
    surgeries: "",
    password: "",
  });
  const location = useLocation();
  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    tableQuery(query);
  };
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);
  const [urlId, setURLID] = useState(null);

  // useful of on page load
  useEffect(() => {
    tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery({
      keyword: "",
      page: 0,
      pageSize: 10,
      sortField: "",
      sortOrder: "false",
      active: "true",
      isCareTeam: LocalStorage?.userDetails?.permission === "Care Team" ? true : false,
    });
    tableQuery({
      keyword: "",
      page: 0,
      pageSize: 10,
      sortField: "",
      sortOrder: "false",
      active: "true",
      isCareTeam: LocalStorage?.userDetails?.permission === "Care Team" ? true : false,
    });
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === "query" ? true : false;
      })
    ) {
      const url = `${ConfigAPIURL.listSession}`;
      APIRequestDataTableQuery.request(
        "POST",
        url,
        JSON.stringify({ ...query })
      ).then((tableData) => {
        setTableData(tableData);
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const openSessionEditPage = queryParams.get("sessionId");

  useEffect(() => {
    if (openSessionEditPage !== null) {
      setURLID(openSessionEditPage);
      setOpenForm({
        status: true,
        title: props.t("sessions.editSession"),
        divType: "edit",
        functionName: "",
      });
    }
  }, [openSessionEditPage]);

  useEffect(() => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.dataTableDBClick === "dataTableDBClick") {
        // eslint-disable-next-line no-lone-blocks
        {
          LocalStorage.adminButtonPermission.find(function (element) {
            return element.button === "edit" ? true : false;
          })
            ? tableEdit()
            : SnackbarUtils.error(
                props.t("toaster.permissionNotAvailable"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
        }
      }
    }
  }, [rowDetails]);

  const tableCallBack = (rowDetails) => {
    if (rowDetails !== null) {
      setRowDetails(rowDetails);
    }
  };

  //table button call back function
  const tableAdd = () => {
    setURLID("");
    let url = window.location.href;
    const sessionIdIndex = url.indexOf("sessionId");
    if (sessionIdIndex !== -1) {
      const ampersandIndex = url.indexOf("&", sessionIdIndex);
      if (ampersandIndex !== -1) {
        url =
          url.substring(0, sessionIdIndex) + url.substring(ampersandIndex + 1);
      } else {
        url = url.substring(0, sessionIdIndex - 1);
      }
      window.history.replaceState({}, document.title, url);
    }

    setOpenForm({
      status: true,
      title: props.t("Add Template"),
      divType: "new",
      functionName: "",
    });
  };
  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails !== null) {
        setURLID("");
        let url = window.location.href;
        const sessionIdIndex = url.indexOf("sessionId");
        if (sessionIdIndex !== -1) {
          const ampersandIndex = url.indexOf("&", sessionIdIndex);
          if (ampersandIndex !== -1) {
            url =
              url.substring(0, sessionIdIndex) +
              url.substring(ampersandIndex + 1);
          } else {
            url = url.substring(0, sessionIdIndex - 1);
          }
          window.history.replaceState({}, document.title, url);
        }
      }

      setOpenForm({
        status: true,
        title: props.t("sessions.editSession"),
        divType: "edit",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableAssign = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: true,
        title: "Assign Session",
        divType: "assign",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableView = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: true,
        title: props.t("sessions.viewSession"),
        divType: "view",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setSlideInDialog(true);
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  const handleDeleteRequest = (response) => {
    console.log(rowDetails);
    if (response === "agree") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.editSessionbyid,
        JSON.stringify({
          userConsultationId: rowDetails?.consultation?._id,
          active: "false",
        })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.deleteSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            tableQuery(query);
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
    }
  };

  const tableDBLCallBack = (dbClickrowDetails) => {
    setRowDetails({
      ...dbClickrowDetails,
      dataTableDBClick: "dataTableDBClick",
    });
  };


  useEffect(() => {
    const handleFocus = () => {
      tableQuery(query);
    };
    window.addEventListener("focus", handleFocus);
  
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [openForm]);
  

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <SessionTableData
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            searchLabel={"Search by Name,Title"}
            tableDBLCallBack={tableDBLCallBack}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              <TableButtons
                tableAdd={tableAdd}
                tooltip={"Add Template"}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableCheckStatus={tableCheckStatus}
                buttonList={buttonList?.filter(
                  (button) =>
                    button.button !== "assign" && button.button !== "delete"
                )}
                tableAssign={tableAssign}
              />
            }
          />
        </Grid>
      </Grid>
      {openForm?.divType === "new" && (
        <FormDialog
          showLabel={true}
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <AEVForm
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails}
              tableQuery={tableQuery}
            />
          }
        />
      )}
      {openForm?.divType === "edit" && (
        <FormDialog
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <EditForm
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails}
              tableQuery={tableQuery}
              urlId={urlId}
            />
          }
        />
      )}
      {openForm?.divType === "assign" && (
        <FormDialog
          sideDrawerData={props.sideDrawerData}
          formDialog={openForm}
          handleFormDialog={handleFormDialog}
          showUpdateLabel={false}
          submit={submit}
          resetForm={resetForm}
          formComponent={
            <AssignSession
              formDialog={openForm}
              handleFormDialog={handleFormDialog}
              rowDetails={rowDetails}
              tableQuery={tableQuery}
            />
          }
        />
      )}

      <SlideInDialog
        title={props.t("sessions.deleteSession")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Sessions)
);
