import { Stack } from "@mui/material";
import React from "react";
import { allImages } from "../../../../../../images";

const Search = ({ handleSearchList }) => {
  return (
    <>
      <Stack
        style={{
          width: "100%",
          height: "40px",
          borderRadius: "70px",
          flexDirection: "row",
          alignItems: "center",
          padding: "8px 1rem ",
          background: "#F2F3F5",
          justifyContent: "center",
          margin: ".5rem 0",
        }}
      >
        <img
          src={allImages.searchIcon}
          alt="search"
          style={{ width: "16px", marginRight: ".5rem" }}
        />
        <input
          onChange={(e) => handleSearchList(e.target.value)}
          autoFocus
          style={{
            resize: "none",
            outline: "none",
            border: "none",
            flexGrow: 2,
            backgroundColor: "#F2F3F5",
            fontFamily: "Poppins",
          }}
          type="text"
          placeholder="Search or start new chat"
        />
      </Stack>
    </>
  );
};

export default Search;
