import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: '15px' }}
          >
            <TextField
              label={props.t('query.activeStatus')}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t('query.active')}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t('query.inactive')}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: '15px' }}
          >
            <TextField
              label={props.t('query.status')}
              select
              defaultValue={props.query.status}
              value={props.query.status}
              onChange={(event) =>
                props.setQuery({ ...props.query, status: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={'all'}>
                {props.t('query.all')}
              </MenuItem>
              <MenuItem key={1} value={'opened'}>
                {props.t('query.opened')}
              </MenuItem>
              <MenuItem key={2} value={'closed'}>
                {props.t('query.closed')}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
                margin='normal'
                id='startAt'
                label={props.t('query.startDate')}
                format='dd/MM/yyyy'
                autoOk={true}
                value={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : null
                }
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    startDate: Math.floor(new Date(date).getTime() / 1000.0),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin='normal'
                id='endAt'
                style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
                label={props.t('query.endDate')}
                format='dd/MM/yyyy'
                autoOk={true}
                value={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : null
                }
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    endDate: Math.floor(new Date(date).getTime() / 1000.0),
                  })
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: '15px' }}
          >
            <TextField
              label={props.t('query.enquiryType')}
              select
              defaultValue={props.query.type}
              value={props.query.type}
              onChange={(event) =>
                props.setQuery({ ...props.query, type: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={'all'}>
                {props.t('query.all')}
              </MenuItem>
              {props.enquiryType.options.map((enquiryOptions, index) => (
                <MenuItem key={index + 1} value={enquiryOptions.value}>
                  {enquiryOptions.display}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation('translations')(TableQuery);
