import PropTypes from 'prop-types';
const Event = {
	seoFormatter: function (details) {
		return {
			'@context': 'https://schema.org',
			'@type': 'Event',
			name: details.name,
			startDate: details.startDate,
			location: {
				'@type': 'Place',
				name: details.locationName,
				address: {
					'@type': 'PostalAddress',
					streetAddress: details.locationStreetAddress,
					addressLocality: details.locationAddressLocality,
					postalCode: details.locationPostalCode,
					addressCountry: details.locationAddressCountry,
				},
			},
		};
	},
};
Event.propTypes = {
	name: PropTypes.string.isRequired,
	startDate: PropTypes.string.isRequired,
	locationName: PropTypes.string.isRequired,
	locationStreetAddress: PropTypes.string.isRequired,
	locationAddressLocality: PropTypes.string.isRequired,
	locationPostalCode: PropTypes.string.isRequired,
	locationAddressCountry: PropTypes.string.isRequired,
};
export default Event;
