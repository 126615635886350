import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: "15px" }}
          >
            <TextField
              label={props.t("query.activeStatus")}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={"all"}>
                {props.t("query.all")}
              </MenuItem>
              <MenuItem key={1} value={true}>
                {props.t("query.active")}
              </MenuItem>
              <MenuItem key={2} value={false}>
                {props.t("query.retire")}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: "15px" }}
          >
            <TextField
              label={props.t("query.caseStatus")}
              select
              defaultValue={props.query.status}
              value={props.query.status}
              onChange={(event) =>
                props.setQuery({
                  ...props.query,
                  status: event.target.value,
                })
              }
              style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
            >
              <MenuItem key={0} value={"all"}>
                {props.t("query.all")}
              </MenuItem>
              <MenuItem key={1} value={"new"}>
                {props.t("query.new")}
              </MenuItem>
              <MenuItem key={2} value={"underReview"}>
                {props.t("query.underReview")}
              </MenuItem>
              <MenuItem key={3} value={"processed"}>
                {props.t("query.processed")}
              </MenuItem>
            </TextField>
          </Grid>

          {props.pageName !== undefined &&
          props.pageName !== null &&
          props.pageName !== "" ? (
            <></>
          ) : (
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "15px" }}
            >
              <TextField
                label={props.t("query.caseType")}
                select
                defaultValue={props.query.caseType}
                value={props.query.caseType}
                onChange={(event) =>
                  props.setQuery({
                    ...props.query,
                    caseType: event.target.value,
                  })
                }
                style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
              >
                <MenuItem key={0} value={"all"}>
                  {props.t("query.all")}
                </MenuItem>
                <MenuItem key={1} value={"Prescription"}>
                  {props.t("case.prescription")}
                  {/* {props.t('query.active')} */}
                </MenuItem>
                <MenuItem key={2} value={"counselling"}>
                  {props.t("Counseling")}
                </MenuItem>
                <MenuItem key={2} value={"Lab Result"}>
                  {props.t("case.labResult")}
                </MenuItem>
              </TextField>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
