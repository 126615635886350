import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Container,
  Typography,
  ListItemText,
} from "@material-ui/core";
function Copyright() {
  return (
    <div>
      <Typography variant="button" display="block" gutterBottom align="center">
        Please contact us at{" "}
        <Link
          class="mailto"
          href="mailto:support@wiserx.co"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
          color="primary"
        >
          support@wiserx.co
        </Link>{" "}
        with any questions regarding this Agreement.
      </Typography>
      <Typography variant="button" display="block" gutterBottom align="center">
        {"Copyright © "}
        WiseRx {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  root: {
    backgroundRepeat: "no-repeat",
    backgroundColor: "#709fe3",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  list: { backgroundColor: theme.palette.background.paper },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function TermsOfService() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="lg" className="dummyPage">
            <List className={classes.list}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Terms Of Service
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Welcome to WiseRx, the website and online and/or mobile
                        service, operated by ThinkDrive Inc. (“Company,” “we,”
                        or “us”). This page explains the terms by which you may
                        use our online and/or mobile services, our website
                        (“Site”), and software provided on or in connection with
                        the service, including as a tool for interaction with
                        Company personal and outside partners such as
                        pharmacists/chemists, physicians, ePharmacies and other
                        healthcare providers (the “Platform”), including our
                        artificial intelligence-enabled services such as
                        automated chat bots (the “Machine Services”) as well as
                        our Virtual Visit Service, as defined below
                        (collectively, the “Service”). By accessing or using the
                        Service, or by clicking a button or checking a box
                        marked “I Agree” (or something similar), you signify
                        that you have read, understood, and agree to be bound by
                        these Terms of Service (this “Agreement”), and to the
                        collection and use of your information as set forth in
                        our Privacy Policy , whether or not you are a registered
                        user of our Service. Company reserves the right to
                        modify these terms and will provide notice of these
                        changes as described below. This Agreement applies to
                        all visitors, users, and others who access the Service
                        (“Users”).
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Please read this Agreement carefully to ensure that you
                        understand each provision.{" "}
                      </Typography>
                      <Divider
                        variant="inset"
                        component="li"
                        style={{ margin: "25px 0px" }}
                      />

                      <Typography variant="subtitle2" gutterBottom align="left">
                        <Typography style={{ fontWeight: "bold" }}>
                          ARBITRATION NOTICE:
                        </Typography>{" "}
                        This agreement contains a mandatory individual
                        arbitration and class action/jury trial waiver provision
                        that requires the use of arbitration on an individual
                        basis to resolve disputes, rather than jury trials or
                        class actions. agreeing to arbitration is an important
                        decision which you should carefully consider.
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        align="left"
                        style={{ fontWeight: "bold", margin: "25px 0px" }}
                      >
                        YOU SHOULD BE AWARE THAT COMPANY IS NOT A PROVIDER OF
                        MEDICAL ADVICE. BEFORE YOU TAKE ANY ACTION THAT MAY
                        AFFECT YOUR HEALTH OR SAFETY OR THE HEALTH OR SAFETY OF
                        YOUR FAMILY, PLEASE CONSULT WITH A PROFESSIONAL. IF YOU
                        THINK YOU MAY HAVE A MEDICAL EMERGENCY, CALL YOUR LOCAL
                        EMERGENCY PHONE NUMBER OR YOUR HEALTHCARE PROVIDER
                        IMMEDIATELY.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>1</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Our Service
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Company provides a Service that empowers people to make
                        informed health decisions.
                      </Typography>
                      <Container maxWidth="lg" style={{ paddingLeft: 0 }}>
                        <List className={classes.list}>
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.1</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  Eligibility
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    This is a contract between you and the
                                    Company. You must read and agree to these
                                    terms before using or accessing any of the
                                    Company Services. If you do not agree to any
                                    of these terms, you may not use the Service.
                                    You may use the Service only if you can form
                                    a binding contract with the Company, and
                                    only in compliance with this Agreement and
                                    all applicable local, state, national, and
                                    international laws, rules and regulations.
                                    Any use or access to the Service by anyone
                                    under the age of 18 is strictly prohibited
                                    and in violation of this Agreement. You may
                                    only use the Service if Company has made the
                                    Service available in your country; any other
                                    use is prohibited.The Service is not
                                    available to any Users previously removed
                                    from the Service by Company. Any use of the
                                    Service by a User that is not eligible under
                                    and according to these Terms of Service, is
                                    prohibited and is at the own risk of such
                                    User.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.2</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  No Medical Advice through the Platform
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    ALL OF THE MATERIAL PROVIDED THROUGH THE
                                    PLATFORM, SUCH AS TEXT, GRAPHICS,
                                    PHOTOGRAPHS, IMAGES, MESSAGES, FORUM
                                    POSTINGS, AND ANY OTHER MATERIALS, ARE FOR
                                    INFORMATIONAL PURPOSES ONLY AND ARE NOT A
                                    SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE
                                    OR TREATMENT. ALWAYS SEEK THE ADVICE OF YOUR
                                    PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER
                                    WITH ANY QUESTIONS YOU MAY HAVE REGARDING
                                    YOUR HEALTH. NEVER DISREGARD PROFESSIONAL
                                    MEDICAL ADVICE OR DELAY IN SEEKING IT
                                    BECAUSE OF ANY INFORMATION PROVIDED THROUGH
                                    THE PLATFORM. ADDITIONALLY, COMPANY DOES NOT
                                    PROMISE ANY PARTICULAR RESULTS WITH REGARDS
                                    TO YOUR HEALTH IN CONNECTION WITH YOUR USE
                                    OF THE PLATFORM. IF YOU THINK YOU HAVE A
                                    MEDICAL EMERGENCY, CALL YOUR DOCTOR OR YOUR
                                    LOCAL EMERGENCY PHONE NUMBER IMMEDIATELY.
                                    The Company does not undertake any
                                    responsibility in relation to the quality of
                                    the medical advice or the manner in which it
                                    is provided by doctors or medical
                                    organizations whose information you have
                                    uploaded in the Platform. Further any
                                    information provided by Company personal or
                                    its partners through our Platform is merely
                                    to facilitate you with your health and NOT
                                    medical advice provided to you. We recommend
                                    that you use reasonable discretion and if
                                    you do not feel well or in the event you do
                                    not receive feedback within a reasonable
                                    time, we recommended that you contact your
                                    doctor directly.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.3</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  Medical Advice through the Virtual Visit
                                  Service
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Do not use the Virtual Visit Service for
                                    emergency medical needs. If you experience a
                                    medical emergency, call your local emergency
                                    phone number immediately. Company does not
                                    provide any physicians’ or other healthcare
                                    providers’ (collectively, “Providers”)
                                    services itself. All of the Providers are
                                    independent of the Company and using the
                                    Virtual Visit Service to communicate with
                                    you. Any information or advice received from
                                    a Provider comes from the Provider, and not
                                    from Company. Your interactions with the
                                    Providers via the Virtual Visit Service are
                                    not intended to take the place of your
                                    relationship(s) with your regular health
                                    care practitioner(s).
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Company will not be liable for any
                                    professional advice you obtain from a
                                    Provider via the Virtual Visit Service. You
                                    acknowledge your reliance on any Providers
                                    or information provided by the Virtual Visit
                                    Services is solely at your own risk and you
                                    assume full responsibility for all risk
                                    associated therewith.
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    You hereby certify that you are physically
                                    located in the State you have entered as
                                    your current location for Virtual Visit
                                    Service. You acknowledge that your ability
                                    to access and use the Virtual Visit Service
                                    is conditioned upon the truthfulness of this
                                    certification and that the Providers you
                                    access through the Virtual Visit Service are
                                    relying upon this certification in order to
                                    interact with you. In the event that your
                                    certification is inaccurate, you agree to
                                    indemnify the Company and the Providers you
                                    interact with from any resulting damages,
                                    costs, or claims.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.4</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  Limited License
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Subject to the terms and conditions of this
                                    Agreement, you are hereby granted a
                                    non-exclusive, limited, non-transferable,
                                    freely revocable license to use the Service
                                    for your personal, noncommercial use only
                                    and as permitted by the features of the
                                    Service. Organizations, companies, and/or
                                    businesses may not become members and should
                                    not use the Site or the Service without
                                    express consent from the Company. Users with
                                    individual commercial interests may not
                                    solicit or overtly promote their products or
                                    services within the Service. Representatives
                                    from life sciences and insurance companies
                                    are prohibited from creating accounts or
                                    registering for the Service on behalf of
                                    their employers, but may use the Service in
                                    their personal capacity. All content shared
                                    within the Site is not for external use.
                                    Company reserves all rights not expressly
                                    granted herein in the Service and the
                                    Company Content (as defined below). Company
                                    may terminate this license at any time for
                                    any reason or no reason.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.5</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  Users
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Whether you register an account on the
                                    Service or use the Service without
                                    registering an account, you may access the
                                    Service and functionality that we may
                                    establish and maintain from time to time and
                                    in our sole discretion subject to this
                                    Agreement. We may maintain different types
                                    of accounts (“User Accounts”) for different
                                    types of Users. To access certain features
                                    or areas of the Service, you may be required
                                    to provide personal and/or demographic
                                    information as part of a registration or
                                    log-in process. By connecting to Company
                                    with a third-party service (such as a social
                                    media platform), you give us permission to
                                    access and use your information from that
                                    service as permitted by that service, and to
                                    store your log-in credentials for that
                                    service.
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    You may never use another Users’ User
                                    Account without permission. When creating
                                    your User Account, you must provide accurate
                                    and complete information, and you must keep
                                    this information up to date (this includes
                                    your contact information, so that we can
                                    reliably contact you). You are solely
                                    responsible for the activity that occurs on
                                    your User Account, and if you are required
                                    to provide a password for your User Account,
                                    you must keep it secure. You must notify the
                                    Company immediately of any breach of
                                    security or unauthorized use of your User
                                    Account. Company will not be liable for any
                                    losses caused by any unauthorized use of
                                    your User Account.
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    You may control your User Account. By
                                    providing Company your registered mobile
                                    number and other contact information, you
                                    consent to our using such contact
                                    information to send you Service-related
                                    notices, including any notices required by
                                    law, in lieu of communication by postal
                                    mail. Such notices may be provided to you by
                                    email, or any other contact form you may
                                    provide us with (including your phone number
                                    for calls or text messages) and you agree to
                                    receive such notices. We may also use your
                                    email address or phone number to send you
                                    other messages, such as changes to features
                                    of the Service and special offers, such as
                                    the opportunity to participate in clinical
                                    trials. If you do not want to receive such
                                    notifications, you may opt-out or change
                                    your preferences in your settings page or by
                                    contacting us at{" "}
                                    <Link
                                      class="mailto"
                                      href="mailto:support@wiserx.co"
                                      variant="subtitle2"
                                      style={{ fontWeight: "bold" }}
                                      color="primary"
                                    >
                                      support@wiserx.co
                                    </Link>{" "}
                                    . Opting out may prevent you from receiving
                                    notification including notices regarding
                                    updates, improvements, or offers. You will
                                    not be able to opt-out from receiving
                                    service and payment connected notifications
                                    from the Company.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.6</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  Changes to the Service
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    We may, without prior notice, change the
                                    Service; stop providing the Service or
                                    certain features of the Service, to you or
                                    to Users generally; or create usage limits
                                    for the Service. We may permanently or
                                    temporarily terminate or suspend your access
                                    to the Service without notice and liability
                                    for any reason, including if, in our sole
                                    determination, you violate any provision of
                                    this Agreement, or for no reason. Upon
                                    termination of your access to the Service
                                    for any reason, you shall continue to be
                                    bound by the terms of this Agreement as
                                    applicable.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>1.7</ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="h6" gutterBottom>
                                  Interactions with Other Users
                                </Typography>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    If the Service permits interaction with
                                    other Users, you are solely responsible for
                                    such interactions. We reserve the right, but
                                    have no obligation, to monitor disputes
                                    between you and other Users. Company shall
                                    have no liability for your interactions with
                                    other Users, or for any User’s action or
                                    inaction.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>2</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      User Content
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Some areas of the Service allow Users to submit, post,
                        display, provide, or otherwise make available content
                        such as profile information, comments, questions, and
                        other content or information (any such materials a User
                        submits, posts, displays, provides, or otherwise makes
                        available on the Service is referred to as “User
                        Content”).
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        WE CLAIM NO OWNERSHIP RIGHTS OVER USER CONTENT CREATED
                        BY YOU.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        THE USER CONTENT YOU CREAT REMAINS YOURS. However, you
                        understand that if portions of the Service allow other
                        Users to view, edit, share, and/or otherwise interact
                        with your User Content, by providing or sharing User
                        Content through the Service, you agree to allow others
                        to view, edit, share, and/or interact with your User
                        Content in accordance with your settings and this
                        Agreement. Company has the right (but not the
                        obligation) in its sole discretion and without an
                        obligation to provide reasoning, to remove any User
                        Content that is shared via the Service.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        By submitting, posting, displaying, providing, or
                        otherwise making available any User Content on or
                        through the Service, you expressly grant, and you
                        represent and warrant that you have all rights necessary
                        to grant, to Company a royalty-free, sublicensable,
                        transferable, perpetual, irrevocable, non-exclusive,
                        worldwide license to use, reproduce, modify, publish,
                        list information regarding, edit, translate, distribute,
                        syndicate, publicly perform, publicly display, and make
                        derivative works of all such User Content and your name,
                        voice, and/or likeness as contained in your User
                        Content, in whole or in part, and in any form, media or
                        technology, whether now known or hereafter developed,
                        for use in connection with the Service and Company’s
                        (and its successors’ and affiliates’) business,
                        including without limitation for promoting and
                        redistributing part or all of the Service (and
                        derivative works thereof) in any media formats and
                        through any media channels. You also hereby grant each
                        User of the Service a non-exclusive license to access
                        your User Content through the Service, and to use,
                        reproduce, distribute, display and perform such User
                        Content as permitted through the functionality of the
                        Service and under this Agreement.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        For the purposes of this Agreement, “Intellectual
                        Property Rights” means all patent rights, copyright
                        rights, mask work rights, moral rights, rights of
                        publicity, trademark, trade dress and service mark
                        rights, goodwill, trade secret rights and other
                        intellectual property rights as may now exist or
                        hereafter come into existence, and all applications
                        therefore and registrations, renewals and extensions
                        thereof, under the laws of any state, country, territory
                        or other jurisdiction.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        In connection with your User Content, you affirm,
                        represent and warrant the following:
                      </Typography>
                      <Container maxWidth="lg" style={{ paddingLeft: 0 }}>
                        <ul
                          style={{
                            listStyleType: "disc",
                            fontSize: "10px",
                          }}
                        >
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            You have the written consent of each and every
                            identifiable natural person in the User Content, if
                            any, to use such person’s name or likeness in the
                            manner contemplated by the Service and this
                            Agreement, and each such person has released you
                            from any liability that may arise in relation to
                            such use.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            You have obtained and are solely responsible for
                            obtaining all consents as may be required by law to
                            post any User Content relating to third parties.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Your User Content and Company’s use thereof as
                            contemplated by this Agreement and the Service will
                            not violate any law or infringe any rights of any
                            third party, including but not limited to any
                            Intellectual Property Rights and privacy rights.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            Company may exercise the rights to your User Content
                            granted under this Agreement without liability for
                            payment of any guild fees, residuals, payments,
                            fees, or royalties payable under any collective
                            bargaining agreement or otherwise.
                          </Typography>
                          <Typography
                            component="li"
                            variant="subtitle2"
                            gutterBottom
                            align="left"
                          >
                            To the best of your knowledge, all your User Content
                            and other information that you provide to us is
                            truthful and accurate.
                          </Typography>
                        </ul>
                      </Container>

                      <Typography variant="subtitle2" gutterBottom align="left">
                        Company takes no responsibility and assumes no liability
                        for any User Content that you or any other User or third
                        party posts, sends, or otherwise makes available over
                        the Service. You shall be solely responsible for your
                        User Content and the consequences of posting, publishing
                        it, sharing it, or otherwise making it available on the
                        Service, and you agree that we are only acting as a
                        passive conduit for your online distribution and
                        publication of your User Content. You understand and
                        agree that you may be exposed to User Content that is
                        inaccurate, objectionable, inappropriate for children,
                        or otherwise unsuited to your purpose, and you agree
                        that Company shall not be liable for any damages you
                        allege to incur as a result of or relating to any User
                        Content.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>3</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Acceptable Use
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        You agree not to engage in any of the following
                        prohibited activities: (i) copying, distributing, or
                        disclosing any part of the Service in any medium,
                        including without limitation by any automated or
                        non-automated “scraping”; (ii) using any automated
                        system, including without limitation “robots,”
                        “spiders,” “offline readers,” etc., to access the
                        Service in a manner that sends more request messages to
                        the Company servers than a human can reasonably produce
                        in the same period of time by using a conventional
                        on-line web browser (except that Company grants the
                        operators of public search engines revocable permission
                        to use spiders to copy publically available materials
                        from Company’s Site for the sole purpose of and solely
                        to the extent necessary for creating publicly available
                        searchable indices of the materials, but not caches or
                        archives of such materials); (iii) transmitting spam,
                        chain letters, or other unsolicited email; (iv)
                        attempting to interfere with, compromise the system
                        integrity or security or decipher any transmissions to
                        or from the servers running the Service; (v) taking any
                        action that imposes, or may impose at our sole
                        discretion an unreasonable or disproportionately large
                        load on our infrastructure; (vi) uploading invalid data,
                        viruses, worms, or other software agents through the
                        Service; (vii) collecting or harvesting any personally
                        identifiable information, including account names, from
                        the Service; (viii) using the Service for any commercial
                        solicitation purposes; (ix) impersonating another person
                        or otherwise misrepresenting your affiliation with a
                        person or entity, conducting fraud, hiding or attempting
                        to hide your identity; (x) interfering with the proper
                        working of the Service; (xi) accessing any content on
                        the Service through any technology or means other than
                        those provided or authorized by the Service; or (xii)
                        bypassing the measures we may use to prevent or restrict
                        access to the Service, including without limitation
                        features that prevent or restrict use or copying of any
                        content or enforce limitations on use of the Service or
                        the content therein.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        You agree not to post User Content that: (i) may create
                        a risk of harm, loss, physical or mental injury,
                        emotional distress, death, disability, disfigurement, or
                        physical or mental illness to you, to any other person,
                        or to any animal; (ii) may create a risk of any other
                        loss or damage to any person or property; (iii) seeks to
                        harm or exploit children by exposing them to
                        inappropriate content, asking for personally
                        identifiable details or otherwise; (iv) may constitute
                        or contribute to a crime or tort; (v) contains any
                        information or content that we deem to be unlawful,
                        harmful, abusive, racially or ethnically offensive,
                        defamatory, infringing, invasive of personal privacy or
                        publicity rights, harassing, humiliating to other people
                        (publicly or otherwise), libelous, threatening, profane,
                        obscene, pornographic, or otherwise objectionable; (vi)
                        contains any information or content that is illegal
                        (including, without limitation, the disclosure of
                        insider information under securities law or of another
                        party’s trade secrets); (vii) contains any information
                        or content that you do not have a right to make
                        available under any law or under contractual or
                        fiduciary relationships; or (viii) contains any
                        information or content that you know is not correct and
                        current or (ix) violates any school or other applicable
                        policy, including those related to cheating or ethics.
                        You agree that any User Content that you post does not
                        and will not violate third-party rights of any kind,
                        including without limitation any Intellectual Property
                        Rights (as defined below) or rights of privacy. Company
                        reserves the right, but is not obligated, to reject
                        and/or remove any User Content that Company believes, in
                        its sole discretion, violates any of these provisions.
                        You understand that publishing your User Content on the
                        Service is not a substitute for registering it with the
                        U.S. Copyright Office, the Writer’s Guild of America, or
                        any other rights organization.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>4</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Our Proprietary Rights
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Except for your User Content, the Service and all
                        materials therein or transferred thereby, including,
                        without limitation, software, images, text, graphics,
                        illustrations, logos, patents, trademarks, service
                        marks, copyrights, photographs, audio, videos, music,
                        and User Content belonging to other Users (the “Company
                        Content”), and all Intellectual Property Rights related
                        thereto, are the exclusive property of Company and its
                        licensors (including other Users who post User Content
                        to the Service). Except as explicitly provided herein,
                        nothing in this Agreement shall be deemed to create a
                        license in or under any such Intellectual Property
                        Rights, and you agree not to sell, license, rent,
                        modify, distribute, copy, reproduce, transmit, publicly
                        display, publicly perform, publish, adapt, edit or
                        create derivative works from any Company Content. Use of
                        the Company Content for any purpose not expressly
                        permitted by this Agreement is strictly prohibited.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        You may choose to or we may invite you to submit
                        comments or ideas about the Service, including without
                        limitation about how to improve the Service or our
                        products (“Ideas”). By submitting any Idea, you agree
                        that your disclosure is gratuitous, unsolicited and
                        without restriction and will not place Company under any
                        fiduciary or other obligation, and that we are free to
                        use the Idea without any additional compensation to you,
                        and/or to disclose the Idea on a non-confidential basis
                        or otherwise to anyone. You further acknowledge that, by
                        acceptance of your submitted Ideas, Company does not
                        waive any rights to use similar or related ideas
                        previously known to Company, or developed by its
                        employees, or obtained from sources other than you.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemIcon>5</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Text Messaging
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        By agreeing to use the Service, you agree to receive
                        certain Company notification or information via push
                        notification or SMS text message. You may incur
                        additional charges from your wireless provider for these
                        services and that you are solely responsible for any
                        such charges. These notifications or text messages may
                        contain information about the symptoms and other health
                        information you have provided to the Company and to
                        Providers who participate with the Company. You can
                        opt-out of receiving such notifications.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemIcon>6</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Privacy
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        We care about the privacy of our Users. You understand
                        that by using the Service you consent to the collection,
                        use and disclosure of your information, including (i)
                        personally identifiable information and aggregate and/or
                        anonymized data, and (ii) personal health information
                        which you choose to share with us. You further consent
                        to have your personally identifiable information
                        collected, used, transferred to and processed in the
                        United States of America, India or any other country in
                        which Company or its affiliates or service providers
                        maintain facilities and/or will be shared with medical
                        physicians according to the actions you take in using
                        the Service. We encourage you to read our Privacy Policy
                        on a regular basis, for a description of such data
                        collection and use practices.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>7</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Security
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Company uses commercially reasonable physical,
                        managerial, and technical safeguards to preserve the
                        integrity and security of your personal information and
                        implement your privacy settings. However, we cannot
                        guarantee that unauthorized third parties will never be
                        able to defeat our security measures or use your
                        personal information for improper purposes. You
                        acknowledge that you provide your personal information
                        at your own risk. In the event that any information
                        under our control is compromised as a result of a breach
                        of security, or lost due to a technical failure, we will
                        take reasonable steps to investigate the situation and,
                        where appropriate, notify those individuals whose
                        information may have been compromised and take other
                        steps, in accordance with any applicable laws and
                        regulations.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemIcon>8</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Third-Party Links and Information
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        The Service may contain links to third-party materials
                        that are not owned or controlled by the Company. Company
                        does not endorse or assume any responsibility for any
                        such third-party sites, information, materials,
                        products, or services. If you access a third-party
                        website or service from the Service or share your User
                        Content on or through any third-party website or
                        service, you do so at your own risk, and you understand
                        that this Agreement and Company’s Privacy Policy do not
                        apply to your use of such third-party sites, products or
                        services. You expressly relieve Company from any and all
                        liability arising from your use of any third-party
                        website, service, or content, including without
                        limitation User Content submitted by other Users.
                        Additionally, your dealings with or participation in
                        promotions of advertisers found on the Service,
                        including payment and delivery of goods, and any other
                        terms (such as warranties) are solely between you and
                        such advertisers. You agree that Company shall not be
                        responsible for any loss or damage of any sort relating
                        to your dealings with such advertisers.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>9</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Paid Services
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Container maxWidth="lg" style={{ paddingLeft: 0 }}>
                        <List className={classes.list}>
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.1
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    The use of certain Services may be subject
                                    to payment of particular fees, as determined
                                    by the Company in its sole discretion (“Paid
                                    Services” and “Fee(s)”, respectively). We
                                    will provide notice of such Fees then in
                                    effect in relation to such Paid Services. If
                                    you wish to receive or use such Paid
                                    Services, you are required to pay all
                                    applicable Fees in advance.
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Company reserves the right to change its
                                    Fees at any time, upon notice to you if such
                                    change may affect your existing
                                    subscriptions.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.2
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    All Fees shall be deemed to be in Users
                                    local currency, except as specifically
                                    stated otherwise in writing by the Company.
                                    To the extent permitted by law (and unless
                                    specified otherwise by Company in writing),
                                    all Fees are exclusive of all taxes
                                    (including value added tax, sales tax, goods
                                    and services tax, etc.), levies or duties
                                    imposed by taxing authorities (“Taxes”), and
                                    you shall be responsible for payment of all
                                    applicable Taxes relating to your use of the
                                    Services, or to any payments or purchases
                                    made by you. If Company is obligated to
                                    collect or pay Taxes for the Fees payable by
                                    you, and whether or not such Taxes were
                                    added and collected from you for previous
                                    transactions, such Taxes may be added to the
                                    payment of any outstanding Fees and will be
                                    reflected in the Invoice for such
                                    transaction. We recommend that you verify
                                    the existence of any additional fees you may
                                    be charged by third parties in connection
                                    with the purchase of Paid Services or in
                                    connection with the renewal thereof (such as
                                    international transaction fees, currency
                                    exchange fees or fees due to banks or credit
                                    card companies). Company is not responsible
                                    for any such additional fees or costs.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.3
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Third Party Services. In connection to the
                                    use of certain Services such as a Virtual
                                    Visit, you may elect to receive other
                                    services by third parties, such as lab tests
                                    and specialists’ visits. We recommend that
                                    you verify the fees of such services and
                                    whether they are covered by your insurance
                                    plan. Further, in some instances, your
                                    health insurance plan may not pay for
                                    referrals from a doctor outside your
                                    insurance network or through a telemedicine
                                    visit. You can verify the coverage of your
                                    plan by contacting your insurance provider
                                    directly. The Company is not responsible for
                                    any fees or costs incurred for any services
                                    provided to you by third parties, or for the
                                    quality of such external services
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.4
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    As part of registering or submitting
                                    information to receive Paid Services, you
                                    also authorize Company (either directly or
                                    through its affiliates, subsidiaries or
                                    other third parties) to request and collect
                                    payment (or otherwise charge, refund or take
                                    any other billing actions) from our payment
                                    provider or your designated banking account,
                                    and to make any inquiries Company or its
                                    affiliates may consider necessary to
                                    validate your designated payment account or
                                    financial information, in order to ensure
                                    prompt payment, including for the purpose of
                                    receiving updated payment details from your
                                    payment, credit card or banking account
                                    provider (e.g., updated expiry date or card
                                    number as may be provided to us by your
                                    credit card company). YOU ACKNOWLEDGE AND
                                    AGREE THAT WE MAY SUBMIT CHARGES WITHOUT
                                    FURTHER AUTHORIZATION FROM YOU UNTIL YOU
                                    PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS
                                    CONFIRMED BY US) THAT YOU HAVE TERMINATED
                                    THIS AUTHORIZATION OR WISH TO CHANGE YOUR
                                    PAYMENT METHOD.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.5
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    If the credit card or other payment method
                                    you supplied to us for the Paid Services is
                                    declined, we will not provide you the
                                    service, and you may be required to supply a
                                    new form of payment to receive our services.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.6
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Invoices. Company and/or its affiliated
                                    companies will issue an invoice or credit
                                    memo for any payment of Fees or refund made
                                    to or by Company (“Invoice”). Each Invoice
                                    will be issued in electronic form and based
                                    on the country stated in your billing
                                    address and will be made available to you
                                    via your User Account. For the purpose of
                                    issuing the Invoice, you may be required to
                                    furnish certain Personal Information (as
                                    such term is defined in the Privacy Policy)
                                    in order to comply with local laws.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.7
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Payments are nonrefundable, and there are no
                                    refunds for partially used Services. Please
                                    make sure you have read and understood the
                                    list of requirements in order to be treated
                                    through the Virtual Visit Service to avoid
                                    payment for a service that cannot be
                                    provided to you. The company may provide
                                    promos, discounts of partial refunds at its
                                    sole and absolute discretion and on a
                                    case-by-case basis.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.8
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    We may offer several membership plans with
                                    various membership fees and billing cycles.
                                    Your membership fee and length of billing
                                    cycle will depend on our offerings and your
                                    choice when you register as a member.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              9.9
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Free Trial. We may offer a free trial period
                                    for Platform and/or Services and you will be
                                    charged at the end of the free trial period,
                                    unless you cancel your free trial prior to
                                    the end of such free trial period. The
                                    duration of the free trial period may differ
                                    amongst users and will be specified during
                                    your registration process.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>10</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Indemnify
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        You agree to defend, indemnify and hold harmless Company
                        and its subsidiaries, agents, licensors, managers, and
                        other affiliated companies, and their employees,
                        contractors, agents, officers and directors, from and
                        against any and all claims, damages, obligations,
                        losses, liabilities, costs or debt, and expenses
                        (including but not limited to attorney’s fees) arising
                        from: (i) your use of and access to the Service,
                        including any data or content transmitted or received by
                        you; (ii) your violation of any term of this Agreement,
                        including without limitation your breach of any of the
                        representations and warranties above; (iii) your
                        violation of any third-party right, including without
                        limitation any right of privacy or Intellectual Property
                        Rights; (iv) your violation of any applicable law, rule
                        or regulation; (v) User Content or any content that is
                        submitted via your User Account including without
                        limitation misleading, false, or inaccurate information;
                        (vi) your willful misconduct; or (vii) any other party’s
                        access and use of the Service with your unique username,
                        password or other appropriate security code.{" "}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>11</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      No Warranty
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        The Service is provided on an “as is” and “as available”
                        basis. Use of the Service is at your own risk. To the
                        maximum extent permitted by applicable law, the Service
                        is provided without warranties of any kind, whether
                        express or implied, including, but not limited to,
                        implied warranties of merchantability, fitness for a
                        particular purpose, or non-infringement. No advice or
                        information, whether oral or written, obtained by you
                        from Company or through the Service will create any
                        warranty not expressly stated herein. Without limiting
                        the foregoing, Company, its subsidiaries, its
                        affiliates, and its licensors do not warrant that the
                        content is accurate, reliable or correct; that the
                        Service will meet your requirements; that the Service
                        will be available at any particular time or location,
                        uninterrupted or secure; that any defects or errors will
                        be corrected; or that the Service is free of viruses or
                        other harmful components. Any content downloaded or
                        otherwise obtained through the use of the Service is
                        downloaded at your own risk and you will be solely
                        responsible for any damage to your computer system or
                        mobile device or loss of data that results from such
                        download or your use of the Service.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Further, Company does not warrant, endorse, guarantee,
                        or assume responsibility for any product or service
                        advertised or offered by a third party through the
                        Service or any hyperlinked website or service, and
                        Company will not be a party to or in any way monitor any
                        transaction between you and third-party providers of
                        products or services.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Federal law, some states, provinces and other
                        jurisdictions do not allow the exclusion and limitations
                        of certain implied warranties, so the above exclusions
                        may not apply to you. This Agreement gives you specific
                        legal rights, and you may also have other rights which
                        vary from state to state. The disclaimers and exclusions
                        under this Agreement will not apply to the extent
                        prohibited by applicable law.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>12</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Limitation of Liability
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        To the maximum extent permitted by applicable law, in no
                        event shall Company, its affiliates, agents, directors,
                        employees, suppliers or licensors be liable for any
                        indirect, punitive, incidental, special, consequential
                        or exemplary damages, including without limitation
                        damages for loss of profits, goodwill, use, data or
                        other intangible losses, arising out of or relating to
                        the use of, or inability to use, the Service. Under no
                        circumstances will Company be responsible for any
                        damage, loss or injury resulting from hacking, tampering
                        or other unauthorized access or use of the Service or
                        your account or the information contained therein.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        To the maximum extent permitted by applicable law,
                        Company assumes no liability or responsibility for any
                        (i) errors, mistakes, or inaccuracies of content or
                        information; (ii) personal injury or property damage, of
                        any nature whatsoever, resulting from your access to or
                        use of our service; (iii) any unauthorized access to or
                        use of our secure servers and/or any and all personal
                        information stored therein; (iv) any interruption or
                        cessation of transmission to or from the Service; (v)
                        any bugs, viruses, trojan horses, or the like that may
                        be transmitted to or through our service by any third
                        party; (vi) any errors or omissions in any content or
                        for any loss or damage incurred as a result of the use
                        of any content posted, emailed, transmitted, or
                        otherwise made available through the Service; and/or
                        (vii) User Content or the defamatory, offensive, or
                        illegal conduct of any third party. In no event shall
                        Company, its affiliates, agents, directors, employees,
                        suppliers, or licensors be liable to you for any claims,
                        proceedings, liabilities, obligations, damages, losses
                        or costs in an amount exceeding the amount you paid to
                        Company hereunder or Rs 1000.00, whichever is greater.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        This limitation of liability section applies whether the
                        alleged liability is based on contract, tort,
                        negligence, strict liability, or any other basis, even
                        if the Company has been advised of the possibility of
                        such damage.
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom align="left">
                        Some states do not allow the exclusion or limitation of
                        incidental or consequential damages, so the above
                        limitations or exclusions may not apply to you. This
                        Agreement gives you specific legal rights, and you may
                        also have other rights which vary from state to state.
                        The disclaimers, exclusions, and limitations of
                        liability under this Agreement will not apply to the
                        extent prohibited by applicable law
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>13</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Governing Law, Arbitration, and Class Action/Jury Trial
                      Waiver
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Container maxWidth="lg" style={{ paddingLeft: 0 }}>
                        <List className={classes.list}>
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              13.1
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Governing Law. This Terms of Service and
                                    Privacy Policy shall be governed by and
                                    construed in accordance with the laws of the
                                    United States of America only without
                                    reference to conflict of laws principles and
                                    disputes arising in relation hereto and
                                    shall be subject to the exclusive
                                    jurisdiction of the competent courts of the
                                    United States of America.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              13.2
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Arbitration. Read this section carefully
                                    because it requires each of us to arbitrate
                                    our disputes and limits the manner in which
                                    you can seek relief from the Company. For
                                    any dispute with the Company, you agree to
                                    first contact us at{" "}
                                    <Link
                                      class="mailto"
                                      href="mailto:support@wiserx.co"
                                      variant="subtitle2"
                                      style={{ fontWeight: "bold" }}
                                      color="primary"
                                    >
                                      support@wiserx.co
                                    </Link>{" "}
                                    and attempt to resolve the dispute with us
                                    informally. In the unlikely event that
                                    Company has not been able to resolve a
                                    dispute it has with you after sixty (60)
                                    days, we each agree to resolve any claim,
                                    dispute, or controversy (excluding any
                                    claims for injunctive or other equitable
                                    relief as provided below) arising out of or
                                    in connection with or relating to this
                                    Agreement, or the breach or alleged breach
                                    thereof (collectively, “Claims”), by binding
                                    arbitration.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              13.3
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Class Action/Jury Trial Waiver. With respect
                                    to all persons and entities, regardless of
                                    whether they have obtained or used the
                                    Service for personal, commercial or other
                                    purposes, all Claims must be brought in the
                                    parties’ individual capacity, and not as a
                                    plaintiff or class member in any purported
                                    class action, collective action, private
                                    attorney general action or other
                                    representative proceeding. This waiver
                                    applies to class arbitration, and, unless we
                                    agree otherwise, the arbitrator may not
                                    consolidate more than one person’s Claims.
                                    You agree that, by entering into this
                                    Agreement, you and Company are each waiving
                                    the right to a trial by jury or to
                                    participate in a class action, collective
                                    action, private attorney general action, or
                                    other representative proceedings of any
                                    kind.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>14</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      Additional Terms for Mobile Applications
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Container maxWidth="lg" style={{ paddingLeft: 0 }}>
                        <List className={classes.list}>
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              14.1
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Mobile Applications. We may make available
                                    software to access the Service via a mobile
                                    device (“Mobile Applications”). To use any
                                    Mobile Applications, you must have a mobile
                                    device that is compatible with the Mobile
                                    Applications. Company does not warrant that
                                    the Mobile Applications will be compatible
                                    with your mobile device. You may use mobile
                                    data in connection with the Mobile
                                    Applications and may incur additional
                                    charges from your wireless provider for
                                    these services. You agree that you are
                                    solely responsible for any such charges.
                                    Company hereby grants you a non-exclusive,
                                    non-transferable, revocable license to use a
                                    compiled code copy of the Mobile
                                    Applications for one Company User Account on
                                    one mobile device owned or leased solely by
                                    you, for your personal use. You may not: (i)
                                    modify, disassemble, decompile or reverse
                                    engineer the Mobile Applications, except to
                                    the extent that such restriction is
                                    expressly prohibited by law; (ii) rent,
                                    lease, loan, resell, sublicense, distribute
                                    or otherwise transfer the Mobile
                                    Applications to any third party or use the
                                    Mobile Applications to provide time sharing
                                    or similar services for any third party;
                                    (iii) make any copies of the Mobile
                                    Applications; (iv) remove, circumvent,
                                    disable, damage or otherwise interfere with
                                    security-related features of the Mobile
                                    Applications, features that prevent or
                                    restrict use or copying of any content
                                    accessible through the Mobile Applications,
                                    or features that enforce limitations on use
                                    of the Mobile Applications; or (v) delete
                                    the copyright and other proprietary rights
                                    notices on the Mobile Applications. You
                                    acknowledge that the Company may from time
                                    to time issue upgraded versions of the
                                    Mobile Applications, and may automatically
                                    electronically upgrade the version of the
                                    Mobile Applications that you are using on
                                    your mobile device. You consent to such
                                    automatic upgrading on your mobile device,
                                    and agree that the terms and conditions of
                                    this Agreement will apply to all such
                                    upgrades. Any third-party code that may be
                                    incorporated in the Mobile Applications is
                                    covered by the applicable open source or
                                    third-party license EULA, if any,
                                    authorizing use of such code. The foregoing
                                    license grant is not a sale of the Mobile
                                    Applications or any copy thereof, and
                                    Company or its third-party partners or
                                    suppliers retain all right, title, and
                                    interest in the Mobile Applications (and any
                                    copy thereof). Any attempt by you to
                                    transfer any of the rights, duties or
                                    obligations hereunder, except as expressly
                                    provided for in this Agreement, is void.
                                    Company reserves all rights not expressly
                                    granted under this Agreement.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              14.2
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Mobile Applications from Apple App Store.
                                    The following applies to any Mobile
                                    Applications you acquire from the Apple App
                                    Store (“Apple-Sourced Software”): You
                                    acknowledge and agree that this Agreement is
                                    solely between you and Company, not Apple,
                                    Inc. (“Apple”) and that Apple has no
                                    responsibility for the Apple-Sourced
                                    Software or content thereof. Your use of the
                                    Apple-Sourced Software must comply with the
                                    App Store Terms of Service. You acknowledge
                                    that Apple has no obligation whatsoever to
                                    furnish any maintenance and support services
                                    with respect to the Apple-Sourced Software.
                                    In the event of any failure of the
                                    Apple-Sourced Software to conform to any
                                    applicable warranty, you may notify Apple,
                                    and Apple will refund the purchase price for
                                    the Apple-Sourced Software to you; to the
                                    maximum extent permitted by applicable law,
                                    Apple will have no other warranty obligation
                                    whatsoever with respect to the Apple-Sourced
                                    Software, and any other claims, losses,
                                    liabilities, damages, costs or expenses
                                    attributable to any failure to conform to
                                    any warranty will be solely governed by this
                                    Agreement and any law applicable to Company
                                    as provider of the software. You acknowledge
                                    that Apple is not responsible for addressing
                                    any claims of you or any third party
                                    relating to the Apple-Sourced Software or
                                    your possession and/or use of the
                                    Apple-Sourced Software, including, but not
                                    limited to: (i) product liability claims;
                                    (ii) any claim that the Apple-Sourced
                                    Software fails to conform to any applicable
                                    legal or regulatory requirement; and (iii)
                                    claims arising under consumer protection or
                                    similar legislation; and all such claims are
                                    governed solely by this Agreement and any
                                    law applicable to Company as provider of the
                                    software. You acknowledge that, in the event
                                    of any third-party claim that the
                                    Apple-Sourced Software or your possession
                                    and use of that Apple-Sourced Software
                                    infringes that third party’s intellectual
                                    property rights, Company, not Apple, will be
                                    solely responsible for the investigation,
                                    defense, settlement and discharge of any
                                    such intellectual property infringement
                                    claim to the extent required by this
                                    Agreement. You and Company acknowledge and
                                    agree that Apple, and Apple’s subsidiaries,
                                    are third-party beneficiaries of this
                                    Agreement as relates to your license of the
                                    Apple-Sourced Software, and that, upon your
                                    acceptance of the terms and conditions of
                                    this Agreement, Apple will have the right
                                    (and will be deemed to have accepted the
                                    right) to enforce this Agreement as relates
                                    to your license of the Apple-Sourced
                                    Software against you as a third-party
                                    beneficiary thereof.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              14.3
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Mobile Applications from Google Play Store.
                                    The following applies to any Mobile
                                    Applications you acquire from the Google
                                    Play Store (“Google-Sourced Software”): (i)
                                    you acknowledge that the Agreement is
                                    between you and Company only, and not with
                                    Google, Inc. (“Google”); (ii) your use of
                                    Google-Sourced Software must comply with
                                    Google’s then-current Google Play Store
                                    Terms of Service; (iii) Google is only a
                                    provider of the Google Play Store where you
                                    obtained the Google-Sourced Software; (iv)
                                    Company, and not Google, is solely
                                    responsible for its Google-Sourced Software;
                                    (v) Google has no obligation or liability to
                                    you with respect to Google-Sourced Software
                                    or the Agreement; and (vi) you acknowledge
                                    and agree that Google is a third-party
                                    beneficiary to the Agreement as it relates
                                    to Company’s Google-Sourced Software.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
            <List className={classes.list} style={{ marginTop: "50px" }}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>15</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" gutterBottom>
                      General
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Container maxWidth="lg" style={{ paddingLeft: 0 }}>
                        <List className={classes.list}>
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              15.1
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Assignment. This Agreement, and any rights
                                    and licenses granted hereunder, may not be
                                    transferred or assigned by you, but may be
                                    assigned by the Company without restriction.
                                    Any attempted transfer or assignment in
                                    violation hereof shall be null and void.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              15.2
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Notification Procedures and Changes to the
                                    Agreement. Company may provide
                                    notifications, whether such notifications
                                    are required by law or are for marketing or
                                    other business related purposes, to you via
                                    email notice, written or hard copy notice,
                                    or through posting of such notice on our
                                    website, as determined by the Company in our
                                    sole discretion. Company reserves the right
                                    to determine the form and means of providing
                                    notifications to our Users, provided that
                                    you may opt out of certain means of
                                    notification as described in this Agreement.
                                    Company is not responsible for any automatic
                                    filtering you or your network provider may
                                    apply to email notifications we send to the
                                    email address you provide us. Company may,
                                    in its sole discretion, modify or update
                                    this Agreement from time to time, and so you
                                    should review this page periodically. When
                                    we change the Agreement in a material
                                    manner, we will update the ‘last modified’
                                    date at the top of this page and notify you
                                    that material changes have been made to the
                                    Agreement. Your continued use of the Service
                                    after any such change constitutes your
                                    acceptance of the new Terms of Service. If
                                    you do not agree to any of these terms or
                                    any future Terms of Service, do not use or
                                    access (or continue to access) the Service.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "2px" }}>
                              15.3
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    Entire Agreement/Severability. This
                                    Agreement, together with any amendments and
                                    any additional agreements you may enter into
                                    with Company in connection with the Service,
                                    shall constitute the entire agreement
                                    between you and Company concerning the
                                    Service. If any provision of this Agreement
                                    is deemed invalid by a court of competent
                                    jurisdiction, the invalidity of such
                                    provision shall not affect the validity of
                                    the remaining provisions of this Agreement,
                                    which shall remain in full force and effect,
                                    except that in the event of unenforceability
                                    of the universal Class Action/Jury Trial
                                    Waiver, the entire arbitration agreement
                                    shall be unenforceable.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem
                            alignItems="flex-start"
                            style={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon style={{ marginTop: "3px" }}>
                              15.4
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    align="left"
                                  >
                                    No Waiver. No waiver of any term of this
                                    Agreement shall be deemed a further or
                                    continuing waiver of such term or any other
                                    term, and Company’s failure to assert any
                                    right or provision under this Agreement
                                    shall not constitute a waiver of such right
                                    or provision.
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Container>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          </Container>
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        ></Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  );
}
