import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles, TextField, Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import useAevForm from "../hooks/useAevForm";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));

function AEVForm(props) {
  const classes = useStyles();

  const { doctorDetails, setDoctorDetails, sendToServer } = useAevForm(props);
  console.log(doctorDetails.gender);

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="name"
                  label="Name"
                  required
                  value={doctorDetails.name}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "100px" },
                  }}
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      name: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  label="Gender"
                  select
                  defaultValue={doctorDetails?.gender || ""}
                  value={doctorDetails?.gender || ""}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      gender: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem key={1} value={"female"}>
                    Female
                  </MenuItem>
                  <MenuItem key={1} value={"other"}>
                    Others
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="speciality"
                  label="Specialization"
                  // autoComplete="off"
                  required
                  value={doctorDetails.specialization}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      specialization: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="licenseNo"
                  label="License No"
                  // autoComplete="off"
                  required
                  value={doctorDetails.licenseNo}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      licenseNo: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="contact"
                  label="Office Contact No."
                  // autoComplete="off"
                  required
                  value={doctorDetails?.contact?.officeNo}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contact: {
                        ...doctorDetails?.contact,
                        officeNo: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="extension"
                  label="Extension"
                  // autoComplete="off"
                  required
                  value={doctorDetails?.contact?.extension}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contact: {
                        ...doctorDetails?.contact,
                        extension: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="email"
                  label="Email"
                  // autoComplete="off"
                  required
                  value={doctorDetails?.contact?.email}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contact: {
                        ...doctorDetails?.contact,
                        email: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="fax"
                  label="Fax"
                  // autoComplete="off"
                  required
                  value={doctorDetails?.contact?.fax}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contact: {
                        ...doctorDetails?.contact,
                        fax: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
          
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="email"
                  label="Email"
                  // autoComplete="off"
                  required
                  value={doctorDetails?.contact?.email}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contact: {
                        ...doctorDetails?.contact,
                        email: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="hospital"
                  label="Hospital Name"
                  // autoComplete="off"
                  required
                  value={doctorDetails.hospital}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      hospital: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="designation"
                  label="Designation"
                  // autoComplete="off"
                  required
                  value={doctorDetails.designation}
                  InputLabelProps={{
                    shrink: true,
                    // style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      designation: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="address"
                  label="Address"
                  // autoComplete="off"
                  required
                  value={doctorDetails.address}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "100px" },
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      address: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="otherSpecizalation"
                  label="Other Specification"
                  // autoComplete="off"
                  required
                  value={doctorDetails.otherSpecialization}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      otherSpecialization: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="contactPersonName"
                  label="Contact Person Name"
                  // autoComplete="off"
                  required
                  value={doctorDetails.contactPersonName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contactPersonName: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <TextField
                  id="contactPersonName"
                  label="Contact Person Mobile No"
                  // autoComplete="off"
                  required
                  value={doctorDetails.contactPersonMobileNo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={(event) =>
                    setDoctorDetails({
                      ...doctorDetails,
                      contactPersonMobileNo: event.target.value,
                    })
                  }
                />
              </Grid>
              {/* {props.formDialog.divType === "view" ? ( */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                align="center"
                style={{ marginTop: "20px" }}
              >
                <Button color="primary" onClick={() => sendToServer()}>
                  Submit
                </Button>
              </Grid>
              {/* ) : (
                <div />
              )} */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
