import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ExcelCSVUploadHistroy from '../../../../../templates/excelcsvuploadhistroy/ExcelCSVUploadHistroy';
import ConfigAPIURL from '../../../../../config/ConfigAPIURL';
import SnackbarUtils from '../../../../../utils/SnackbarUtils';
const UploadItemHeader = [
  'productSku',
  'title',
  'genericName',
  'unitPackSize',
  'marketedBy',
  'distributedBy',
  'drcNo',
  'form',
  'dosageForm',
  'barCodeId',
  'overview',
  'description',
  'substitutes',
  'concerns',
  'composition',
  'brand',
  'images',
  'manufacturer',
  'deliveryDays',
  'drugClass',
  'offLabel',
  'preg',
  'alcohol',
  'rating',
  'popularity',
  'category',
  'subCategory',
  'itemType',
  'price',
  'discount',
  'tax',
  'available',
  'relatedTreatements',
  'relatedDrugs',
];
function UploadItem(props) {
  const [bucketName] = React.useState('inventory');
  const [uploadTableHeader] = React.useState(UploadItemHeader);
  const [fileName] = React.useState([
    'Inventory_Item_Template.xlsx',
    'Inventory_Item_Template.csv',
  ]);
  const [uploadUrl] = React.useState(ConfigAPIURL.inventoryUploadItems);
  const [uploadHistoryUrl] = React.useState('');
  const notification = (message, type) => {
    SnackbarUtils[type](message, 'bottomCenter', 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  return (
    <ExcelCSVUploadHistroy
      fileName={fileName}
      uploadTableHeader={uploadTableHeader}
      uploadUrl={uploadUrl}
      uploadHistoryUrl={uploadHistoryUrl}
      bucketName={bucketName}
      notification={notification}
      menuName={'inventory'}
      operationType={'uploadInventoryItems'}
    />
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(UploadItem)
);
