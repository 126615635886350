import { Container, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.css";
import Sidebar from "./components/sidebar/Index";
import useChat from "./hooks/useChat";
import ChatArea from "./components/chatArea/Index";
import { useLocation } from "react-router-dom";
import LocalStorage from "../../../../config/LocalStorage";
export const DataContext = React.createContext();

const Chats = () => {
  const userDetails = LocalStorage.userDetails;
  const userData = { ...userDetails }; // this is the logged in users details
  const location = useLocation();
  const {
    handleConnect,
    sideChatList,
    sendMessage,
    fetchMessages,
    conversation,
    calculateMinutes,
    selectedChat,
    setSelectedChat,
    hasMoreConverstion,
    handleSearchList,
  } = useChat(userData);

  useEffect(() => {
    // if any value found state location then that is the reviever
    if (!location?.state) return;
    setSelectedChat({
      ...location?.state,
    });
  }, []);

  return (
    <DataContext.Provider
      value={{
        handleConnect,
        sideChatList,
        selectedChat,
        userData,
        sendMessage,
        fetchMessages,
        conversation,
        calculateMinutes,
        hasMoreConverstion,
        handleSearchList,
      }}
    >
      <Container className="chat__container" style={{ padding: 0 }}>
        <Grid
          container
          className="chat__gridcontainer"
          style={{ height: "100%" }}
        >
          <Grid
            item
            sm={5}
            md={4}
            style={{
              borderRight: ".01px solid rgba(0, 0, 0, 0.12)",
              height: "100%",
            }}
          >
            <Sidebar setSelectedChat={setSelectedChat} />
          </Grid>
          <Grid item sm={7} md={8}>
            <ChatArea />
          </Grid>
        </Grid>
      </Container>
    </DataContext.Provider>
  );
};

export default Chats;
