import React, { Component } from "react";
import General from "./components/general";
import MobileWebViewPages from "./components/general/MobileWebViewPages";
import AdminIndex from "./components/privateRouting/admin";
import PrivateRouteValidation from "./PrivateRouteValidation"; // for restricted access route
import { HashRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default class App extends Component {
  render() {
    return (
      <div style={rootStyle}>
        <HashRouter>
          <Switch>
            <Route
              exact
              path={["/", "/login", "/user/password/generate"]}
              component={General}
            />
            <Route
              exact
              path={["/privacypolicy", "/contactus", "/termsofservice"]}
              component={MobileWebViewPages}
            />
            <PrivateRouteValidation path={["/admin"]} component={AdminIndex} />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </div>
    );
  }
}
const rootStyle = {
  overflowX: "hidden",
};
