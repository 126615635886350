import React from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";

const useUpload = (handleMedia) => {
  const mediaUploadPart = (media) => {
    // eslint-disable-next-line no-unused-expressions
    media?.forEach((value) => {
      const type = value?.split(".")?.pop();
      const name = value?.split("/")?.pop();
      const data = {
        type,
        name,
        url: value,
      };
      handleMedia(data);
    });
  };
  const fileUpload = async (e) => {
    const formdata = new FormData();
    const files = e.target.files;
    // eslint-disable-next-line no-unused-expressions
    files?.forEach((file) => {
      formdata.append("attachment", file);
      formdata.append("bucketName", "files");
    });
    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );
    if (response?.data?.responseCode === 109) {
      mediaUploadPart(response?.data?.attachmentUrl); //successfully uploaded as array
    } else return false; // an error occured
  };
  return { fileUpload };
};

export default useUpload;
