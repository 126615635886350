import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import UploadItem from './UploadItem';
import UploadStock from './UploadStock';
import UploadPrice from './UploadPrice';
import UploadImage from './UploadImage';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 50,
  },
}));

function Upload(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='on'
          aria-label='simple tabs example'
        >
          <Tab label={props.t('products.uploadItem')} {...a11yProps(0)} />
          <Tab label={props.t('products.uploadStock')} {...a11yProps(1)} />
          <Tab label={props.t('products.uploadPrice')} {...a11yProps(2)} />
          <Tab label={props.t('products.uploadImage')} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <UploadItem />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UploadStock />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UploadPrice />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <UploadImage />
      </TabPanel>
    </div>
  );
}

export default withTranslation('translations')(Upload);
