import React, { useState, useEffect } from "react";
import APIRequest from "../../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";

const SessionEdit = (props) => {
  const [sessionsList, setSessionsList] = useState([]);
  const getSessions = () => {
    console.log('update api called')
    const URL = `${ConfigAPIURL.getConsultationDetails}?userId=${props.rowDetails._id}`;
    APIRequest.request("GET", URL).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          const data = response?.data?.filteredConsultation?.reverse();
          setSessionsList(data);
        }
      }
    });
  };

  return {
    sessionsList,
    getSessions,
  };
};
export default SessionEdit;
