class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/
  static tableHeader = [
    {
      id: 'caseId',
      accessor: 'caseId',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.caseId',
      className: '',
      functionName: '',
    },

    {
      id: 'createdAt',
      accessor: 'createdAt',
      dataType: 'date',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'case.createdAt',
      className: 'DateConversion',
      functionName: 'unixToLocaleDateTime',
    },

    {
      id: 'timeLapsedOrigin',
      accessor: 'createdAt',
      dataType: 'date',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'case.timeLapsed',
      className: 'DateConversion',
      functionName: 'timeLapse',
    },

    {
      id: 'status',
      accessor: 'status',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.status',
      className: 'StringUtils',
      functionName: 'caseStatus',
    },
    {
      id: 'assignedUser',
      accessor: 'assignedUser.fname',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.assignedUser',
      className: '',
      functionName: '',
    },

    {
      id: 'updatedAt',
      accessor: 'updatedAt',
      dataType: 'date',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'case.updatedAt',
      className: 'DateConversion',
      functionName: 'unixToLocaleDateTime',
    },
    {
      id: 'updatedAt',
      accessor: 'updatedAt',
      dataType: 'date',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: 'case.timeLapsedUpdated',
      className: 'DateConversion',
      functionName: 'timeLapse',
    },

    {
      id: 'userName',
      accessor: 'userId.fname',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.userName',
      className: '',
      functionName: '',
    },
    {
      id: 'mobileNo',
      accessor: 'userId.mobileNo',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.mobileNo',
      className: '',
      functionName: '',
    },
    {
      id: 'patientId',
      accessor: 'userId.patientId',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: 'case.patientId',
      className: '',
      functionName: '',
    },
    {
      id: 'patientLocation',
      accessor: 'userId.city',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 120,
      label: 'case.patientLocation',
      className: '',
      functionName: '',
    },
    // {
    //   id: 'notes',
    //   accessor: '_id',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: 'case.notes',
    //   className: 'DataTableUtil',
    //   functionName: 'processTableId',
    // },
    // {
    //   id: 'userName',
    //   accessor: 'userName',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: 'case.userName',
    //   className: '',
    //   functionName: '',
    // },

    // {
    //   id: 'doctor',
    //   accessor: 'doctor.name',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 200,
    //   label: 'case.doctorName',
    //   className: '',
    //   functionName: '',
    // },
  ];
}
export default TableHeader;
