import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import CardHeader from '@material-ui/core/CardHeader';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function SlideInDialog(props) {
  return (
    <div>
      <Card
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.slideInDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        {props.doctorList.map((list, idx) => (
          <div>
            <CardContent
              onClick={() => props.handleSelectedDoctor(list)}
              style={{ cursor: 'pointer' }}
            >
              Dr. {list.name} ({list.hospital} - {list.contact.officeNo})
            </CardContent>
            <Divider />
          </div>
        ))}

        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={props.handleCancel} color='primary'>
            Cancel
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
export default withTranslation('translations')(SlideInDialog);
