import React, { useEffect, useRef, useState } from "react";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import LocalStorage from "../../../../../config/LocalStorage";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import FormValidation from "../../../../../utils/FormValidation";

const useAevForm = (props) => {
  const [query, setQuery] = useState({
    keyword: "",
    page: 0,
    pageSize: 10,
    sortField: "",
    sortOrder: "false",
    active: true,
  });
  const [tableData, setTableData] = React.useState([]);
  const [addForm, setAddForm] = useState({
    title: "",
    description: "",
    adPosition: "",
    siteLink: "",
    category: "",
    typeOfLink: "external",
    priority: "normal",
    active: true,
    images: [],
    searchKey: [],
    products: [],
    bannerList: [],
    startAt: new Date(),
    endAt: new Date(new Date().setDate(new Date().getDate() + 7)),
  });
  const [appointmentCreateData, setAppointmentCreateData] = useState({
    description: "",
    title: "",
    meetLink: "",
    active: true,
  });

  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setAppointmentCreateData({
      title: "",
      description: "",
      active: true,
      meetLink: "",
      // startAt: new Date(),
      // endAt: new Date(new Date().setDate(new Date().getDate() + 7)),
    });
  };

  console.log(props,"from edit template")

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getConsultationTemplateDetails,
        JSON.stringify({
          consultationId: props.rowDetails?._id,
        })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.result;

            setAppointmentCreateData({
              ...appointmentCreateData,
              title: userResult.title,
              description: userResult.description,
              meetLink: userResult.meetLink,
              active: userResult.active,
            });
          }
        }
      });
    }
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.createConsultationTemplate;
    let method = "POST";

    if (props.formDialog.divType === "edit") {
      url = ConfigAPIURL.updateConsultationTemplate;
      appointmentCreateData["_id"] = props.rowDetails[0];
      method = "PATCH";
    }
    // Remove fields based on selectedRole
    const payload = { ...appointmentCreateData };
    // Removed form validation here
    APIRequest.request(method, url, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            // if (props.formDialog.divType === "edit") {
              props.handleFormDialog();
            // }
            resetForm();
            getTableData(query);
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  const handleQuery = () => {
    setQuery(query);
    getTableData(query);
  };

  const resetQuery = () => {
    setQuery({
      page: 0,
      pageSize: 10,
      sortField: "",
      sortOrder: "false",
      active: true,
    });
  };

  //listing table after creating in session

  const getTableData = (query) => {
    const url = ConfigAPIURL.listSessionTemplate;
    APIRequest.request("POST", url, JSON.stringify(query)).then((response) => {
      if (response?.data?.responseCode === 109) {
        setTableData(response?.data);
      }
    });
  };

  

  return {
    addForm,
    setAddForm,
    appointmentCreateData,
    setAppointmentCreateData,
    tableData,
    setTableData,
    query,
    setQuery,
    handleQuery,
    resetQuery,
    getTableData,
    sendToServer,
    getEditable,
  };
};

export default useAevForm;
