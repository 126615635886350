import React from 'react';
import { withTranslation } from 'react-i18next';
import Admin from './Admin';
import ThemeProvider from './themeProvider/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import reducer from './hook/reducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
const store = createStore(reducer);
function AdminIndex() {
	return (
		<Provider store={store}>
			<ThemeProvider>
				<CssBaseline />
				<Admin />
			</ThemeProvider>
		</Provider>
	);
}
export default withTranslation('translations')(AdminIndex);
