import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import { withTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import AEVForm from "./AEVForm";
import { connect } from "react-redux";
import Cases from "./Cases/Index";
import Patients from "./Patients/Index";
import useAevForm from "../hooks/useAevForm";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* //{' '}
          <Box p={3} style={{ marginTop: '120px' }}>
            // <Typography>{children}</Typography>
            //{' '}
          </Box> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  if (index !== undefined && index !== null) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 60,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ViewUser(props) {
  const { sendToServer } = useAevForm(props);
  //console.log(props);

  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    }
  }, [props.formDialog]);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tabLabels, setTabLabels] = React.useState([
    "Edit Doctor",
    "Patients",
    "Cases",
  ]);
  const [tabs, setTabs] = React.useState([
    <AEVForm
      marginTop={0}
      roles={props.roles}
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
      handleFormDialog={props.handleFormDialog}
    />,
    <Patients
      marginTop={0}
      roles={props.roles}
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
      handleFormDialog={props.handleFormDialog}
    ></Patients>,

    <Cases
      marginTop={0}
      roles={props.roles}
      formDialog={props.formDialog}
      rowDetails={props.rowDetails}
      tableQuery={props.tableQuery}
      handleFormDialog={props.handleFormDialog}
    ></Cases>,
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTabLabels([...tabLabels]);
    setTabs([...tabs]);
  }, []);

  return (
    <div className={classes.root}>
      <Hidden only={["xl", "lg", "md"]}>
        <AppBar position="fixed">
          <Tabs
            value={value}
            className={!props.sideDrawerData ? "userTableViewTabs" : ""}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabLabels.map((labels, index) => (
              <Tab label={props.t(labels)} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <AppBar position="fixed" style={{ marginTop: "70px" }}>
          <Tabs
            value={value}
            className={!props.sideDrawerData ? "userTableViewTabs" : ""}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabLabels.map((labels, index) => (
              <Tab label={props.t(labels)} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </Hidden>

      {tabs.map((tab, idx) => (
        <TabPanel value={value} index={idx}>
          {tab}
        </TabPanel>
      ))}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(ViewUser)
);

// export default withTranslation('translations')(ViewUser);
