import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles, TextField, Button } from "@material-ui/core";

import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { connect } from "react-redux";
import APIRequest from "../../../../utils/APIRequest";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import LocalStorage from "../../../../config/LocalStorage";

import "react-dual-listbox/lib/react-dual-listbox.css";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
}));
function AssignCase(props) {
  const classes = useStyles();

  const [adminList, setAdminList] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  const getAdminList = (event, data) => {
    if (data.length > LocalStorage.searchableStringLimit) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.searchAdmin +
          "?keyword=" +
          data +
          "&roleId=" +
          rolename?._id,
        ""
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let serverResponse = response.data.result;
            serverResponse.map((result) => {
              result["label"] =
                result.fname + " " + result.lname + " " + result.mobileNo;
            });
            setAdminList(serverResponse);
          }
        }
      });
    } else {
      setAdminList([]);
    }
  };

  const getAllRoles = (event, data) => {
    if (data.length > LocalStorage.searchableStringLimit) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getAllRoles,
        JSON.stringify({ active: true, keyword: data })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response?.data?.responseCode === 109) {
            let serverResponse = response?.data?.rows;
            serverResponse.map((result) => {
              result["label"] = result.name;
            });
            setRolesList(serverResponse);
          }
        }
      });
    } else {
      setRolesList([]);
    }
  };

  const handleAssignDialog = (response) => {
    if (response === "submit") {
      if (
        assignee._id === undefined ||
        assignee._id === null ||
        assignee._id === ""
      ) {
        return SnackbarUtils.error(
          props.t("toaster.selectUser"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
      APIRequest.request(
        "PUT",
        ConfigAPIURL.assignCase,
        JSON.stringify({ recordId: props.rowDetails, assignee: assignee._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 111) {
            props.handleSlideInDialog();
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            setAssignee({ fname: "", lname: "" });
          }
        }
      });
    } else {
      props.handleSlideInDialog();
    }
  };

  const [assignee, setAssignee] = useState({ fname: "", lname: "" });
  const [rolename, setRolename] = useState({});

  const handleAdminList = (event, value) => {
    setAssignee(value);
  };
  const handleRolesList = (event, value) => {
    setRolename(value);
  };

  console.log("rolename", rolename);

  return (
    <div className={classes.root}>
      {/* <Autocomplete
        id="combo-box-demo"
        options={adminList}
        getOptionLabel={(option) => option.label}
        fullWidth
        onInputChange={getAdminList}
        onClose={() => setAdminList([])}
        onChange={handleAdminList}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t("user.assignAdmin")}
            variant="standard"
          />
        )}
      /> */}
      <Autocomplete
        id="combo-box-demo"
        options={rolesList}
        getOptionLabel={(option) => option.label}
        fullWidth
        onInputChange={getAllRoles}
        onClose={() => setRolesList([])}
        onChange={handleRolesList}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t("user.role")}
            variant="standard"
          />
        )}
      />
      <Autocomplete
        id="combo-box-demo"
        options={adminList}
        getOptionLabel={(option) => option.label}
        fullWidth
        onInputChange={getAdminList}
        onClose={() => setAdminList([])}
        disabled={rolename?.length === 0}
        onChange={handleAdminList}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.t("user.person")}
            variant="standard"
          />
        )}
      />

      <Grid container style={{ marginTop: "20px", textAlign: "right" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            onClick={() => handleAssignDialog("disagree")}
            color="primary"
          >
            cancel
          </Button>
          <Button onClick={() => handleAssignDialog("submit")} color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AssignCase)
);
