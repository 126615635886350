import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Grid,
  Divider,
  CardContent,
  Box,
  Slide,
  List,
  Checkbox,
  FormControlLabel,
  Radio,
  ListItem,
  FormControl,
  RadioGroup,
  ListItemText,
  FormGroup,
  TextField,
  MenuItem,
  Typography,
  CardHeader,
  Card,
  makeStyles,
  CardActions,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import SnackbarUtils from "../../utils/SnackbarUtils";
import DateConversion from "../../utils/DateConversion";
import APIRequest from "../../utils/APIRequest";
import Toaster from "../../utils/Toaster";
import ConfigAPIURL from "../../config/ConfigAPIURL";
import LocalStorage from "../../config/LocalStorage";
import SlideInDialog from "./SlideInDialog";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const unEditableTableData = {
  doctor: "",
  itemName: "",
  overview: "",
  prescriptionDate: "",
  caseId: "",
  form: "",
  unitPackSize: "",
};

const editableTableData = {
  _id: "", //mongoId
  retire: false, //medicineRetirement
  frequency: "", //Daily, Weekly 2 days

  whenToTake: {
    Morning: false,
    Afternoon: false,
    Evening: false,
    Night: false,
    OtherTime: false,
    sos: false,
  },

  intake: {
    Morning: "",
    Afternoon: "",
    Evening: "",
    Night: "",
    Other: "",
    OtherTime: "",
  },
  intakeQuantity: {
    Morning: "",
    Afternoon: "",
    Evening: "",
    Night: "",
    OtherTime: "",
    sosQuantity: "",
  },

  // whenToTake: [], //Morning, Night, Eveing, Afternoon

  intakeDetails: "", //asrequired before after with
  frequencyDates: [],
  forMedicalConditions: [], //db val
  startDate: new Date(),
  endDate: new Date(),
  itemCount: "",
  sos: false,

  // caseId: '', //noteditable
  //
  // itemName: '',
  // form: '',
  // unitPackSize: '',
  // itemCount: '',
  //
  //
  // timeOfDay: [],
  // frequency: '',
  // conditions: [],
  // startDate: new Date(),
  // endDate: new Date(),
  // doctor: { _id: '', name: '' },
};

const useStyles = makeStyles(() => ({
  headerLabel: {
    fontSize: 16,
    textAlign: "center",
    // color: '#666c73',
  },
  subHeader: {
    fontSize: 14,
    textAlign: "center",
    // color: '#666c73',
  },
  bodyData: {
    fontSize: 14,
  },
  formGrid: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const filter = createFilterOptions();
function UpdateMedicationDialog(props) {
  const classes = useStyles();

  const [medicationUnEditable, setMedicationData] =
    useState(unEditableTableData);
  const [formData, setFormData] = useState(editableTableData);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [medicalConditionsOptions, setMedicalConditionsOptions] = useState([]);
  const [openTempFreqDates, setOpenTempFreqDates] = useState(false);

  const [newMedCond, setNewMedCond] = useState("");

  useEffect(() => {
    // if (props.openForm === true) {
    handleMedEditOption();
    // // }
    getFrequency();
    // getMedicalConditions();
  }, []);

  const handleTempFreqDates = () => {
    setOpenTempFreqDates(false);
  };

  const handleButtonResponse = () => {
    setOpenTempFreqDates(false);
  };

  const _sendToServer = () => {
    if (
      formData.whenToTake.Morning === false &&
      formData.whenToTake.Afternoon === false &&
      formData.whenToTake.Evening === false &&
      formData.whenToTake.Night === false &&
      formData.whenToTake.OtherTime === false &&
      formData.whenToTake.sos === false
    ) {
      SnackbarUtils.error(
        props.t("case.selectTimeOfDay"),
        "topRight",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    if (formData.whenToTake.Morning === true) {
      if (formData.intake.Morning === "") {
        SnackbarUtils.error(
          props.t("case.selectMorningWhenToTake"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intakeQuantity.Morning === "") {
        SnackbarUtils.error(
          props.t("case.enterMorningQuantity"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    }

    if (formData.whenToTake.Afternoon === true) {
      if (formData.intake.Afternoon === "") {
        SnackbarUtils.error(
          props.t("case.selectAfternoonWhenToTake"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intakeQuantity.Afternoon === "") {
        SnackbarUtils.error(
          props.t("case.enterAfternoonQuantity"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    }

    if (formData.whenToTake.Evening === true) {
      if (formData.intake.Evening === "") {
        SnackbarUtils.error(
          props.t("case.selectEveningWhenToTake"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intakeQuantity.Evening === "") {
        SnackbarUtils.error(
          props.t("case.enterEveningQuantity"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    }

    if (formData.whenToTake.Night === true) {
      if (formData.intake.Night === "") {
        SnackbarUtils.error(
          props.t("case.selectNightWhenToTake"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intakeQuantity.Night === "") {
        SnackbarUtils.error(
          props.t("case.enterNightQuantity"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    }
    if (formData.whenToTake.Night === true) {
      if (formData.intake.Night === "") {
        SnackbarUtils.error(
          props.t("case.selectNightWhenToTake"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intakeQuantity.Night === "") {
        SnackbarUtils.error(
          props.t("case.enterNightQuantity"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    }
    if (formData.whenToTake.OtherTime === true) {
      if (formData.intake.Other === "") {
        // console.log("2");
        SnackbarUtils.error(
          props.t("Please select when to take for other"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intakeQuantity.OtherTime === "") {
        SnackbarUtils.error(
          props.t("Please enter the quantity for others"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
      if (formData.intake.OtherTime === "") {
        // console.log("1");
        SnackbarUtils.error(
          props.t("Please select the time for other"),
          "topRight",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
        return;
      }
    }
    if (
      formData.whenToTake.sos === true &&
      formData.intakeQuantity.sosQuantity === ""
    ) {
      SnackbarUtils.error(
        props.t("Please enter the quantity for sos"),
        "topRight",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    // let val = Object.keys(formData.whenToTake).filter(function (el) {
    //   return formData.whenToTake[el] === true;
    // });

    // let frequencyDatesVal = formData.frequencyDates.map((frequencyDates) => {
    //   return Math.floor(
    //     Number(new Date(frequencyDates.setHours(0, 0, 0, 0)).getTime() / 1000.0)
    //   );
    // });
    APIRequest.request(
      "POST",
      ConfigAPIURL.updateMedications,
      JSON.stringify({
        ...formData,
        recordId: formData._id,
        retire: !formData.retire,
        frequency: formData.frequency,
        frequencyDates: formData.frequencyDates,
        whenToTake: formData.whenToTake,
        intakeDetails: formData.intakeDetails,
        forMedicalConditions: formData.forMedicalConditions,
        startDate: formData.startDate,
        endDate: formData.endDate,
        itemCount: formData.itemCount,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          _cancel();
          props.handleTableData();
          SnackbarUtils.sucess(
            props.t("snackbar.transComplete"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  const handleTempMedicalConditions = (event, value) => {
    if (
      value[value.length - 1] !== undefined &&
      value[value.length - 1] !== null &&
      value[value.length - 1].inputValue !== undefined &&
      value[value.length - 1].inputValue !== null
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.addMedicationAdd,
        JSON.stringify({ value: value[value.length - 1].inputValue })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let medConditions = formData.forMedicalConditions;
            medConditions.push({
              display: value[value.length - 1].inputValue,
              value: value[value.length - 1].inputValue,
              active: true,
              logo: "",
            });
            setFormData({
              ...formData,
              forMedicalConditions: medConditions,
            });
          } else if (
            response.code === 100 &&
            response.data.responseCode === 114
          ) {
            SnackbarUtils.error(
              props.t("snackbar.medicationExits"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      });
    } else {
      setFormData({
        ...formData,
        forMedicalConditions: value,
      });
    }
  };

  const _cancel = () => {
    props.handleCancel();
    setMedicationData(editableTableData);
    setMedicationData(unEditableTableData);
  };

  const handleFrequencies = (event) => {
    let form = formData;
    form.frequency = event.target.value;

    if (
      event.target.value === "Custom Schedule" &&
      formData.frequencyDates.length === 0
    ) {
      let dates = [];
      const theStartDate = new Date(form.startDate);
      while (
        theStartDate <=
        new Date(
          new Date(form.endDate).setMonth(new Date(form.endDate).getMonth() + 3)
        )
      ) {
        dates = [...dates, new Date(theStartDate)];
        theStartDate.setDate(theStartDate.getDate() + 1);
      }
      form.frequencyDates = dates;
    }

    setFormData({
      ...formData,
      form,
    });
  };

  const addNewMedCondtions = () => {
    if (newMedCond !== "") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.addMedicationAdd,
        JSON.stringify({ value: newMedCond })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let medConditions = formData.forMedicalConditions;
            medConditions.push({
              display: newMedCond,
              value: newMedCond,
              logo: "",
            });
            setNewMedCond("");
            setFormData({
              ...formData,
              forMedicalConditions: medConditions,
            });
          } else if (
            response.code === 100 &&
            response.data.responseCode === 114
          ) {
            SnackbarUtils.error(
              props.t("snackbar.medicationExits"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      });
    }
  };

  const getMedicalConditions = (event, data) => {
    if (data !== undefined && data !== null) {
      if (data === "") {
        setMedicalConditionsOptions([]);
      } else if (data.length > LocalStorage.searchableStringLimit) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.medicalOptions,
          JSON.stringify({ name: "medicalConditions", keyword: data })
        ).then((response) => {
          if (response !== undefined && response !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (response.data.responseCode === 109) {
                if (response.data.options[0] !== undefined) {
                  setMedicalConditionsOptions(response.data.options[0].options);
                }
              }
            }
          }
        });
      } else {
        setMedicalConditionsOptions([]);
      }
    } else {
      setMedicalConditionsOptions([]);
    }
  };

  // const getMedicalConditions = () => {
  //   APIRequest.request(
  //     'POST',
  //     ConfigAPIURL.options,
  //     JSON.stringify({ name: 'medicalConditions' })
  //   ).then((response) => {
  //     if (response !== undefined && response !== null) {
  //       if (
  //         response.data.responseCode !== undefined &&
  //         response.data.responseCode !== null
  //       ) {
  //         if (response.data.responseCode === 109) {
  //           setMedicalConditionsOptions(response.data.options[0].options);
  //         }
  //       }
  //     }
  //   });
  // };
  const getFrequency = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "frequency" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setFrequencyOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const handleRadioChange = (event, type) => {
    setFormData({
      ...formData,
      intake: {
        ...formData.intake,
        [type]: event.target.value,
      },
    });
  };

  const handleTempMedicineCheckChange = (event) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        whenToTake: {
          ...formData.whenToTake,
          [event.target.name]: event.target.checked,
        },
      });
    } else {
      setFormData({
        ...formData,
        whenToTake: {
          ...formData.whenToTake,
          [event.target.name]: event.target.checked,
        },
        intake: {
          ...formData.intake,
          [event.target.name]: "",
        },
        intakeQuantity: {
          ...formData.intakeQuantity,
          [event.target.name]: "",
        },
        // sos: {
        //   ...formData.sos,
        //   [event.target.name]: !event.target.checked,
        // },
      });
    }
  };

  const handleMedEditOption = () => {
    // let rowVal = medicationUnEditable;

    let rowData = props.selectedRowData;
    // let childIndex = props.selectedRowDataIndex;
    setMedicationData({
      ...medicationUnEditable,
      doctor: rowData.doctor !== null ? rowData.doctor.name : "",
      caseId: rowData.caseId,
      prescriptionDate: rowData.prescriptionDate,
      itemName: rowData.itemName,
      form: rowData.itemId.form,
      overview: rowData.itemId.overview,
      dosageForm: rowData.itemId.dosageForm,
    });
    let medicineCondtn = [];
    rowData.forMedicalConditions.split(",").map((optionValue) => {
      if (optionValue !== "") {
        medicineCondtn.push({
          display: optionValue,
          value: optionValue,
        });
      }
    });

    let frequencyDatesVal = [];
    if (
      rowData.frequencyDates !== undefined &&
      rowData.frequencyDates !== null
    ) {
      frequencyDatesVal = rowData.frequencyDates.map((frequency) => {
        return new Date(
          new Date(new Date(frequency * 1000)).setHours(12, 0, 0, 0)
        );
      });
    }

    setFormData({
      ...formData,
      _id: rowData._id,
      retire: !rowData.retire,
      frequency: rowData.frequency,
      whenToTake: {
        Morning: rowData.whenToTake.includes("Morning"),
        Afternoon: rowData.whenToTake.includes("Afternoon"),
        Evening: rowData.whenToTake.includes("Evening"),
        Night: rowData.whenToTake.includes("Night"),
        OtherTime: rowData.whenToTake.includes("OtherTime"),
        sos: rowData.whenToTake.includes("sos"),
      },
      intake:
        rowData.intake !== undefined && rowData.intake !== null
          ? rowData.intake
          : "",
      intakeQuantity:
        rowData.intakeQuantity !== undefined && rowData.intakeQuantity !== null
          ? rowData.intakeQuantity
          : "",
      frequencyDates: frequencyDatesVal,
      forMedicalConditions: medicineCondtn,
      startDate: new Date(rowData.startDate * 1000),
      endDate: new Date(rowData.endDate * 1000),
      sos: rowData?.sos,
    });
  };

  const handleFrequencyDayClick = (day, selected) => {
    let form;
    form = formData;

    const selectedIndex = selected.findIndex((selectedDay) =>
      DateUtils.isSameDay(selectedDay, day)
    );

    if (selectedIndex !== -1) {
      form.frequencyDates.splice(selectedIndex, 1);
    } else {
      form.frequencyDates.push(day);
    }

    setFormData({ ...formData, form });
  };

  return (
    <div>
      <Card
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.slideInDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <CardHeader
          title={
            <Typography
              variant="p"
              className={classes.headerLabel}
              component="p"
            >
              {props.t("user.updateMedication")}
            </Typography>
          }
          subheader={
            <Box>
              <Typography
                variant="p"
                className={classes.subHeader}
                component="p"
              >
                {"Dr " +
                  medicationUnEditable.doctor +
                  " - " +
                  medicationUnEditable.caseId +
                  " ( " +
                  DateConversion.unixToLocaleDate(
                    medicationUnEditable.prescriptionDate
                  ) +
                  " ) "}
              </Typography>
              <Typography
                variant="p"
                className={classes.subHeader}
                component="p"
              >
                {medicationUnEditable.itemName +
                  " ( " +
                  medicationUnEditable.form +
                  " - " +
                  medicationUnEditable.dosageForm +
                  " )"}
              </Typography>
              <Typography
                variant="p"
                className={classes.subHeader}
                component="p"
              >
                {medicationUnEditable.overview}
              </Typography>
            </Box>
          }
        />
        <CardContent>
          <Grid container>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ marginTop: "24px" }}
            >
              <TextField
                id="active"
                select
                label={props.t("doctor.caseActiveStatus")}
                fullWidth
                // InputLabelProps={{
                //   shrink: addForm.acti? true : false,
                // }}
                defaultValue={formData.retire}
                value={formData.retire}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    retire: event.target.value,
                  });
                  // setStatusCheck(true);
                }}
              >
                <MenuItem key={0} value={true}>
                  {props.t("doctor.active")}
                </MenuItem>
                <MenuItem key={1} value={false}>
                  {props.t("doctor.retire")}
                </MenuItem>
              </TextField>
            </Grid>

            <Grid
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={classes.formGrid}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <KeyboardDatePicker
                    animateYearScrolling={false}
                    // disableFuture={true}
                    variant="dialog"
                    required
                    fullWidth
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="startDate"
                    label={props.t("case.medStartDate")}
                    value={formData.startDate}
                    autoOk={true}
                    onChange={(date) => {
                      if (date > formData.endDate) {
                        return Toaster.error(
                          props.t("toaster.startDateLesser"),
                          "topRight"
                        );
                      }
                      setFormData({
                        ...formData,
                        startDate: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={classes.formGrid}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <KeyboardDatePicker
                    animateYearScrolling={false}
                    // disableFuture={true}
                    variant="dialog"
                    required
                    fullWidth
                    // disabled={
                    //   props.formDialog.divType === 'new' ||
                    //   props.formDialog.divType === 'edit'
                    //     ? false
                    //     : true
                    // }
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="endDate"
                    label={props.t("case.medEndDate")}
                    value={formData.endDate}
                    autoOk={true}
                    onChange={(date) => {
                      if (date < formData.startDate) {
                        return Toaster.error(
                          props.t("toaster.endDateLesser"),
                          "topRight"
                        );
                      }

                      setFormData({
                        ...formData,
                        endDate: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={classes.formGrid}
              style={{ marginTop: "26px" }}
            >
              <TextField
                id="frequency"
                // className={classes.userTypeMenuItem}
                select
                label={props.t("case.frequency")}
                required
                fullWidth
                value={formData.frequency}
                defaultValue={formData.frequency}
                onChange={handleFrequencies}
              >
                {frequencyOptions.map((options, key) => (
                  <MenuItem key={key} value={options.display}>
                    {options.display}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formData.frequency === "Custom Schedule" ? (
              <Grid
                xl={8}
                lg={8}
                md={8}
                sm={6}
                xs={12}
                className={classes.formGrid}
                style={{ marginTop: "26px" }}
              >
                <Button onClick={() => setOpenTempFreqDates(true)}>
                  {props.t("case.setViewCustomSchedule")}
                </Button>
              </Grid>
            ) : (
              <></>
            )}

            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid
                  xl={4}
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  className={classes.formGrid}
                  style={{ textAlign: "left", maxWidth: "52%" }}
                >
                  {props.t("case.timeOfDay")}
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={5}
                  xs={5}
                  className={classes.formGrid}
                  style={{ textAlign: "left", maxWidth: "48%" }}
                >
                  {props.t("case.whenToTake")}
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className={classes.formGrid}
                  style={{ textAlign: "left" }}
                >
                  {props.t("case.itemQuantity")}
                  <span style={{ color: "red" }}>*</span>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "52%" }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.whenToTake.Morning}
                            onChange={handleTempMedicineCheckChange}
                            name="Morning"
                          />
                        }
                        label="Morning"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "48%" }}
                >
                  <FormControlLabel
                    value="Before"
                    onChange={(event) => handleRadioChange(event, "Morning")}
                    control={
                      <Radio checked={formData.intake.Morning === "Before"} />
                    }
                    disabled={!formData.whenToTake.Morning}
                    label="Before"
                  />
                  <FormControlLabel
                    value="After"
                    onChange={(event) => handleRadioChange(event, "Morning")}
                    control={
                      <Radio checked={formData.intake.Morning === "After"} />
                    }
                    disabled={!formData.whenToTake.Morning}
                    label="After"
                  />
                  <FormControlLabel
                    onChange={(event) => handleRadioChange(event, "Morning")}
                    value="With"
                    control={
                      <Radio checked={formData.intake.Morning === "With"} />
                    }
                    disabled={!formData.whenToTake.Morning}
                    label="With"
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <TextField
                    id="morningQuantity"
                    // label={props.t('case.provider')}
                    autoComplete="something-unsupported"
                    autoComplete="off"
                    disabled={!formData.whenToTake.Morning}
                    value={formData.intakeQuantity.Morning}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        intakeQuantity: {
                          ...formData.intakeQuantity,
                          Morning: event.target.value,
                        },
                      });
                    }}
                    fullWidth
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        event.charCode === 32 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "52%" }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.whenToTake.Afternoon}
                            onChange={handleTempMedicineCheckChange}
                            name="Afternoon"
                          />
                        }
                        label="Afternoon"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "48%" }}
                >
                  <FormControlLabel
                    value="Before"
                    disabled={!formData.whenToTake.Afternoon}
                    onChange={(event) => handleRadioChange(event, "Afternoon")}
                    control={
                      <Radio checked={formData.intake.Afternoon === "Before"} />
                    }
                    label="Before"
                  />
                  <FormControlLabel
                    value="After"
                    disabled={!formData.whenToTake.Afternoon}
                    onChange={(event) => handleRadioChange(event, "Afternoon")}
                    control={
                      <Radio checked={formData.intake.Afternoon === "After"} />
                    }
                    label="After"
                  />
                  <FormControlLabel
                    onChange={(event) => handleRadioChange(event, "Afternoon")}
                    value="With"
                    disabled={!formData.whenToTake.Afternoon}
                    control={
                      <Radio checked={formData.intake.Afternoon === "With"} />
                    }
                    label="With"
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <TextField
                    id="afternoonQuantity"
                    disabled={!formData.whenToTake.Afternoon}
                    // label={props.t('case.provider')}
                    autoComplete="something-unsupported"
                    autoComplete="off"
                    value={formData.intakeQuantity.Afternoon}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        intakeQuantity: {
                          ...formData.intakeQuantity,
                          Afternoon: event.target.value,
                        },
                      });
                    }}
                    fullWidth
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        event.charCode === 32 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "52%" }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.whenToTake.Evening}
                            onChange={handleTempMedicineCheckChange}
                            name="Evening"
                          />
                        }
                        label="Evening"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "48%" }}
                >
                  <FormControlLabel
                    value="Before"
                    onChange={(event) => handleRadioChange(event, "Evening")}
                    control={
                      <Radio checked={formData.intake.Evening === "Before"} />
                    }
                    label="Before"
                    disabled={!formData.whenToTake.Evening}
                  />
                  <FormControlLabel
                    value="After"
                    onChange={(event) => handleRadioChange(event, "Evening")}
                    control={
                      <Radio checked={formData.intake.Evening === "After"} />
                    }
                    disabled={!formData.whenToTake.Evening}
                    label="After"
                  />
                  <FormControlLabel
                    onChange={(event) => handleRadioChange(event, "Evening")}
                    value="With"
                    control={
                      <Radio checked={formData.intake.Evening === "With"} />
                    }
                    disabled={!formData.whenToTake.Evening}
                    label="With"
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <TextField
                    id="eveningQuantity"
                    // label={props.t('case.provider')}
                    autoComplete="something-unsupported"
                    autoComplete="off"
                    disabled={!formData.whenToTake.Evening}
                    value={formData.intakeQuantity.Evening}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        intakeQuantity: {
                          ...formData.intakeQuantity,
                          Evening: event.target.value,
                        },
                      });
                    }}
                    fullWidth
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        event.charCode === 32 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "52%" }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.whenToTake.Night}
                            onChange={handleTempMedicineCheckChange}
                            name="Night"
                          />
                        }
                        label="Night"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={5}
                  xs={5}
                  style={{ maxWidth: "48%" }}
                >
                  <FormControlLabel
                    value="Before"
                    onChange={(event) => handleRadioChange(event, "Night")}
                    control={
                      <Radio checked={formData.intake.Night === "Before"} />
                    }
                    disabled={!formData.whenToTake.Night}
                    label="Before"
                  />
                  <FormControlLabel
                    value="After"
                    onChange={(event) => handleRadioChange(event, "Night")}
                    control={
                      <Radio checked={formData.intake.Night === "After"} />
                    }
                    disabled={!formData.whenToTake.Night}
                    label="After"
                  />
                  <FormControlLabel
                    onChange={(event) => handleRadioChange(event, "Night")}
                    value="With"
                    control={
                      <Radio checked={formData.intake.Night === "With"} />
                    }
                    disabled={!formData.whenToTake.Night}
                    label="With"
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <TextField
                    id="nightQuantity"
                    // label={props.t('case.provider')}
                    autoComplete="something-unsupported"
                    autoComplete="off"
                    value={formData.intakeQuantity.Night}
                    disabled={!formData.whenToTake.Night}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        intakeQuantity: {
                          ...formData.intakeQuantity,
                          Night: event.target.value,
                        },
                      });
                    }}
                    fullWidth
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        event.charCode === 32 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                  />
                </Grid>

                {/* below we are going to add fields for Others and SOS */}

                <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.whenToTake.OtherTime}
                            onChange={handleTempMedicineCheckChange}
                            name="OtherTime"
                          />
                        }
                        label="Other"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                  <FormControlLabel
                    value="Before"
                    onChange={(event) => handleRadioChange(event, "Other")}
                    disabled={!formData.whenToTake.OtherTime}
                    control={
                      <Radio checked={formData.intake.Other === "Before"} />
                    }
                    label="Before"
                  />
                  <FormControlLabel
                    onChange={(event) => handleRadioChange(event, "Other")}
                    disabled={!formData.whenToTake.OtherTime}
                    value="With"
                    control={
                      <Radio checked={formData.intake.Other === "With"} />
                    }
                    label="With"
                  />
                  <FormControlLabel
                    value="After"
                    onChange={(event) => handleRadioChange(event, "Other")}
                    disabled={!formData.whenToTake.OtherTime}
                    control={
                      <Radio checked={formData.intake.Other === "After"} />
                    }
                    label="After"
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <TextField
                    id="nightQuantity"
                    // label={props.t('case.provider')}
                    autoComplete="something-unsupported"
                    disabled={!formData.whenToTake.OtherTime}
                    value={formData.intakeQuantity.OtherTime}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        intakeQuantity: {
                          ...formData.intakeQuantity,
                          OtherTime: event.target.value,
                        },
                      });
                    }}
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        event.charCode === 32 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-20px",
                    }}
                  >
                    <p style={{ marginLeft: "32px" }}>Time</p>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={2}
                      xs={2}
                      style={{ marginTop: "10px" }}
                    >
                      <TextField
                        id="nightQuantity"
                        type="time"
                        disabled={!formData.whenToTake.OtherTime}
                        value={formData.intake.OtherTime}
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            intake: {
                              ...formData.intake,
                              OtherTime: event.target.value,
                            },
                          });
                        }}
                        onKeyPress={(event) => {
                          return (event.charCode >= 48 &&
                            event.charCode <= 57) ||
                            (event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) ||
                            event.charCode === 32 ||
                            event.charCode === 46
                            ? event
                            : event.preventDefault();
                        }}
                        fullWidth
                        style={{
                          padding: "0px",
                          margin: "0px",
                        }}
                      />
                    </Grid>
                  </div>
                </Grid>

                {/* ------------------------SOS-------------------- */}
                <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.whenToTake.sos}
                            // onChange={handleTempMedicineCheckChange}
                            onChange={(event) =>
                              setFormData({
                                ...formData,
                                whenToTake: {
                                  ...formData.whenToTake,
                                  sos: event.target.checked,
                                },
                              })
                            }
                            name="sos"
                          />
                        }
                        label="SOS"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={5} xs={5}></Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <TextField
                    id="sosQuantity"
                    value={formData.intakeQuantity.sosQuantity}
                    disabled={!formData.whenToTake.sos}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        intakeQuantity: {
                          ...formData.intakeQuantity,
                          sosQuantity: event.target.value,
                        },
                      });
                    }}
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) ||
                        (event.charCode >= 65 && event.charCode <= 90) ||
                        (event.charCode >= 97 && event.charCode <= 122) ||
                        event.charCode === 32 ||
                        event.charCode === 46
                        ? event
                        : event.preventDefault();
                    }}
                    fullWidth
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // style={{ marginTop: '20px' }}
            >
              <Autocomplete
                value={formData.forMedicalConditions}
                onInputChange={getMedicalConditions}
                multiple
                onClose={() => setMedicalConditionsOptions([])}
                // onChange={(event, newValue) =>
                //   handleTempMedicalConditions(event, newValue)
                // }
                noOptionsText={props.t(
                  "case.noOptionsTextforMedicalConditions"
                )}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (
                    params.inputValue !== undefined &&
                    params.inputValue !== null
                  ) {
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        display: `"${params.inputValue}" ${props.t(
                          "case.testMedicalConditionsNotFound"
                        )}`,
                      });
                    }
                    return filtered;
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={(event, newValue) =>
                  handleTempMedicalConditions(event, newValue)
                }
                id="tempForMedicalConditions"
                options={medicalConditionsOptions}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.display;
                }}
                renderOption={(option) => option.display}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={props.t("case.medicalConditions")}
                    variant="standard"
                  />
                )}
              />

              {/* <Autocomplete
                id='forMedicalConditions'
                options={medicalConditionsOptions}
                multiple
                fullWidth
                value={formData.forMedicalConditions}
                onChange={(event, data) =>
                  setFormData({
                    ...formData,
                    forMedicalConditions: data,
                  })
                }
                getOptionLabel={(option) => option.display}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={props.t('case.medicalConditions')}
                    variant='standard'
                  />
                )}
              /> */}
            </Grid>

            {/* <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: '20px' }}
            >
              <Grid container>
                <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                  <TextField
                    id='newMedCond'
                    value={newMedCond}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete='something-unsupported'
                    label={props.t('case.newMedCond')}
                    onChange={(event) => {
                      setNewMedCond(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                  <Button
                    onClick={() => {
                      addNewMedCondtions();
                    }}
                  >
                    {props.t('buttons.add')}
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Button variant="primary" onClick={_cancel}>
            {props.t("buttons.cancel")}
          </Button>
          <Button variant="primary" onClick={_sendToServer}>
            {props.t("buttons.update")}
          </Button>
        </CardActions>
      </Card>

      <SlideInDialog
        title={props.t("case.frequencyDates")}
        contentComponent={
          <DayPicker
            numberOfMonths={2}
            pagedNavigation
            // numberOfMonths={3}
            // pagedNavigation
            fixedWeeks
            disabledDays={{
              before: formData.startDate,
              after: formData.endDate,
            }}
            selectedDays={formData.frequencyDates}
            onDayClick={(day) =>
              handleFrequencyDayClick(day, formData.frequencyDates)
            }
          />
        }
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={"ok"}
        slideInDialog={openTempFreqDates}
        setSlideInDialog={handleTempFreqDates}
        handleButtonResponse={handleButtonResponse}
      />
    </div>
  );
}

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(UpdateMedicationDialog)
);
