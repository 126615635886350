import PropTypes from 'prop-types';
const Event = {
	seoFormatter: function (details) {
		let mainEntity = [];
		details.mainEntity.map((data) => {
			return mainEntity.push({
				'@type': 'Question',
				name: details.question,
				acceptedAnswer: {
					'@type': 'Answer',
					text: details.acceptedAnswer,
				},
			});
		});
		return {
			'@context': 'https://schema.org/',
			'@type': 'FAQPage',
			mainEntity,
		};
	},
};
Event.propTypes = {
	mainEntity: PropTypes.array.isRequired,
};
export default Event;
