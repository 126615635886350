import avatar from "../src/assets/avatar.png";
import chatBg from "../src/assets/chatBackground.png";
import searchIcon from "../src/assets/Search.png";

const allImages = {
  avatar,
  chatBg,
  searchIcon,
};
export { allImages };
