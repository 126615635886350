import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from '@material-ui/core';
import { connect } from 'react-redux';
import TableWithoutSelection from '../../../../../templates/tables/TableWithoutSelection';
import Chart from './Chart';
import TableHeader from './TableHeader';

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  headerDivider: {
    height: 6,
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    borderRadius: '10px',
    margin: 'auto',
    backgroundColor: theme.palette.error.main,
    fontWeight: 800,
    marginTop: '1%',
    marginBottom: '15px',
    width: '20%',
  },
  headerDivider1: {
    height: 6,
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    borderRadius: '10px',
    margin: 'auto',
    backgroundColor: theme.palette.error.main,
    fontWeight: 800,
    marginTop: '1%',
    marginBottom: '15px',
    width: '10%',
  },
}));
function WebAdminDashboard(props) {
  const classes = useStyles();
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant='h6'
                    component='h6'
                    className={classes.headerText}
                  >
                    {props.t('dashboard.dgstats')}
                  </Typography>
                  <Divider className={classes.headerDivider} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TableWithoutSelection
                    tableHeader={TableHeader.tableHeader}
                    tableData={props.webAdminData}
                  ></TableWithoutSelection>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant='h6'
                    component='h6'
                    className={classes.headerText}
                  >
                    {props.t('dashboard.caseStats')}
                  </Typography>
                  <Divider className={classes.headerDivider1} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Chart webAdminGraph={props.webAdminGraph} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(null, mapDispachToProps)(WebAdminDashboard)
);
