import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  Avatar,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Paper,
  FormControl,
  AppBar,
  Tabs,
  FormGroup,
  Tab,
  Radio,
  FormControlLabel,
  Hidden,
  CircularProgress,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Toaster from "../../../../utils/Toaster";
import FileUpload from "../../../../templates/FileUpload";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SelectSendNotification from "../../../../templates/SelectSendNotification";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DateConversion from "../../../../utils/DateConversion";
import FormValidation from "../../../../utils/FormValidation";
import SelectableDoctorList from "./SelectableDoctorList";
import SlideInDialog from "../../../../templates/dialog/SlideInDialog";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import PrismaZoom from "react-prismazoom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import imageCompression from "browser-image-compression";
import LocalStorage from "../../../../config/LocalStorage";
import Notification from "../../../../templates/patientConnect/Notification";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TimePicker from "material-ui/TimePicker";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },

  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    display: "flex",
    marginBottom: 10,
    marginTop: 10,
  },
  doctorForm: {
    marginBottom: 10,
    marginTop: 10,
  },
  customFontSize: {
    fontSize: 16,
    color: "#666c73",
    marginTop: "10px",
    marginBottom: "10px",
  },
  customHeadingFontSize: {
    fontSize: "18px",
  },
}));
const caseFormDetails = {
  userId: "",
  active: true,
  caseType: "Prescription",
  userName: "",
  weight: "",
  allergies: [],
  chronicConditions: [],
  surgeries: [],
  familyHistory: [],
  bloodPressure: { high: "", low: "" },
  mobileNo: "",
  doctor: "",
  diagnosis: "",
  patientId: "",
  prescriptionDate: new Date(),
  // active: true,
  prescription: [],
  medicineList: [],
  doctorInstructions: "",
  pharmaInstructions: "",
  remark: "",
  height: "",
};

const tempDataForDisplay = {
  userId: {},
  caseDynamicUrl: "",
};

//funtion for diaplying list of doctors

//funtion for showing date of birth
function formatDate(epochDateString) {
  if (!epochDateString || isNaN(epochDateString)) return "-";

  let epoch = parseInt(epochDateString);

  epoch *= 1000;

  const date = new Date(epoch);

  const day = date.getDate().toString().padStart(2, "0");
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthNames[monthIndex];

  return `${day} ${monthName} ${year}`;
}

const doctorFormData = {
  // _id: '',
  name: "",
  qualification: "",
  gender: "",
  specialization: "",
  otherSpecialization: "",
  contactPersonMobileNo: "",
  contactPersonName: "",
  hospital: "",
  address: "",
  contact: {
    email: "",
    fax: "",
    mobileNo: "",
    extension: "",
    officeNo: "",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const filter = createFilterOptions();
function AEVForm(props) {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(caseFormDetails);

  const [statusCheck, setStatusCheck] = useState(false);
  const [doctorForm, setDoctorForm] = useState(doctorFormData);
  const [doctorAlert, setDoctorAlert] = useState(false);
  const [tempStoreDoctorData, setTempStoreDoctorData] = useState([]);

  const [medDeleteDialog, setMedDeleteDialog] = useState(false);
  const [medIdToDelete, setMedIdToDelete] = useState("");

  const [editableDoctorFields, setEditableDoctorFields] = useState(false);

  const [openTempFreqDates, setOpenTempFreqDates] = useState(false);
  const [openMedFreqDates, setOpenMedFreqDates] = useState(false);

  const [tempMedLabel, setTempMedLabel] = useState("");
  const [tabLabel, setTabLabel] = useState([
    { label: props.t("case.patientInfo"), value: "patientInfo" },
    // { label: props.t("case.prescriptionStart"), value: "prescriptionStart" },
    // { label: props.t("case.doctorDetails"), value: "doctorDetails" },
    // { label: props.t("case.dgNotes"), value: "dgNotes" },
    { label: props.t("case.selectMedicineList"), value: "selectMedicineList" },
  ]);

  const [isSearching, setIsSearching] = useState(false);

  const handleStatusSlider = () => {
    setStatusCheck(false);
  };

  const handleButtonResponse = () => {
    setOpenTempFreqDates(false);
    setOpenMedFreqDates(false);
  };

  const handleFormChange = (response) => {
    setStatusCheck(false);

    let prevStatus = addForm.active;
    if (response === "agree") {
      setAddForm({ ...addForm, active: prevStatus });
    } else {
      setAddForm({ ...addForm, active: !prevStatus });
    }
  };

  const [whenToTakeOption, setwhenToTakeOptions] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);

  const [tempMedicineList, setTempMedicineList] = useState({
    itemId: "",
    itemName: "",
    forMedicalConditions: [],
    itemCount: "",
    form: "",
    duration: "",
    startDate: new Date(),
    endDate: new Date(),
    frequencyDates: [],
    frequency: "Daily",
    sos: false,
    whenToTake: {
      Morning: false,
      Afternoon: false,
      Evening: false,
      Night: false,
      OtherTime: false,
      sos: false,
    },
    strength: "",
    overview: "",
    intakeDetails: "",
    priceList: [],
    intake: {
      Morning: "",
      Afternoon: "",
      Evening: "",
      Night: "",
      Other: "",
      OtherTime: "",
    },
    intakeQuantity: {
      Morning: "",
      Afternoon: "",
      Evening: "",
      Night: "",
      OtherTime: "",
      sosQuantity: "",
    },
  });

  // console.log("tempMedicineListtempMedicineList", tempMedicineList);

  // const [tempData, setTempData] = useState(tempFormData);
  const [tempData, setTempData] = useState(tempDataForDisplay);

  const [tabValue, setTabValue] = React.useState(0);

  // console.log("this is tabvalue", tabValue);
  // const [openDoctorForm, setOpenDoctorForm] = useState(false);
  const [expandDoctorName, setExpandDoctorName] = useState(false);
  const [userListOptions, setUserOptionList] = useState([]);
  const [inventoryListOptions, setInventoryOptionList] = useState([]);
  const [doctorListOption, setDoctorsOptions] = useState([]);

  const [imageIndex, setImageIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    if (doctorForm.name === "") {
      Toaster.error(props.t("case.selectDoctor"), "topRight");
      return;
    }
    if (doctorForm.hospital === "") {
      Toaster.error(props.t("case.addHospital"), "topRight");
      return;
    }
    if (doctorForm.contact.officeNo === "") {
      Toaster.error(props.t("case.addOfficeNo"), "topRight");
      return;
    }
    if (doctorForm.address === "") {
      Toaster.error(props.t("case.addAddress"), "topRight");
      return;
    }
    if (
      doctorForm.specialization === "Other" &&
      doctorForm.otherSpecialization === ""
    ) {
      Toaster.error(props.t("case.enterDoctorSpecialization"), "topRight");
      return;
    }

    let fieldsValidation = false;

    addForm.medicineList.map((medicines) => {
      if (
        medicines.whenToTake.Morning === false &&
        medicines.whenToTake.Afternoon === false &&
        medicines.whenToTake.Evening === false &&
        medicines.whenToTake.Night === false &&
        medicines.whenToTake.OtherTime === false &&
        medicines.whenToTake.sos === false
      ) {
        Toaster.error(
          "Please select time of day for " + medicines.itemName,
          "topRight"
        );
        fieldsValidation = true;
        return;
      }

      if (medicines.whenToTake.Morning === true) {
        if (medicines.intake.Morning === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Morning",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Morning === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Morning",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }

      if (medicines.whenToTake.Afternoon === true) {
        if (medicines.intake.Afternoon === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Afternoon",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Afternoon === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Afternoon",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }

      if (medicines.whenToTake.Evening === true) {
        if (medicines.intake.Evening === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Evening",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Evening === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Evening",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }

      if (medicines.whenToTake.Night === true) {
        if (medicines.intake.Night === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Night",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Night === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Night",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }
    });

    // console.log('tabChange', addForm);
    // return;
    if (fieldsValidation === false) {
      update("tabChange");
    }
  };
  const handleSlideInDialog = () => {
    setDoctorAlert(false);
    setMedDeleteDialog(false);
    setSlideAssignDialog({
      status: false,
      title: "",
      divType: "",
    });
  };

  const handleSelectedDoctor = (selectedData) => {
    setDoctorAlert(false);
    // setEditableDoctorFields(true);
    setAddForm({ ...addForm, doctor: selectedData._id });
    setDoctorForm({
      ...doctorForm,
      // _id: selectedData._id,
      name: selectedData.name,
      qualification: selectedData.qualification,
      gender: selectedData.gender,
      specialization: selectedData.specialization,
      contactPersonMobileNo: selectedData.contactPersonMobileNo,
      contactPersonName: selectedData.contactPersonName,
      hospital: selectedData.hospital,
      address: selectedData.address,
      contact: {
        email: selectedData.contact.email,
        fax: selectedData.contact.fax,
        mobileNo: selectedData.contact.mobileNo,
        extension: selectedData.contact.extension,
        officeNo: selectedData.contact.officeNo,
      },
    });
    // if (response === 'agree') {

    // } else {
    //   let resetData = doctorFormData;
  };

  const handleCancel = () => {
    setDoctorAlert(false);
    setEditableDoctorFields(false);
    setAddForm({ ...addForm, doctor: "" });

    setDoctorForm({
      name: doctorForm.name,
      hospital: "",
      qualification: "",
      gender: "",
      specialization: "",
      otherSpecialization: "",
      contactPersonMobileNo: "",
      contactPersonName: "",
      address: "",
      contact: {
        email: "",
        fax: "",
        mobileNo: "",
        extension: "",
        officeNo: "",
      },
    });
  };

  const handleImageNextViewer = (event, newValue) => {
    setImageIndex(newValue);
  };

  const [qualificationsOptions, setQualificationsOptions] = useState([]);
  const [medicalConditionsOptions, setMedicalConditionsOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [medicineFormOptions, setMedicineFormOptions] = useState([]);

  const [notificationsList, setNotificationsList] = useState([]);
  const [aws, setAws] = useState(LocalStorage.aws);

  const [productOptions, setProductOptions] = useState([]);
  useEffect(() => {
    // setAddForm(caseFormDetails);
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      resetForm();
      // getUser();
      // getDoctors();
      // getMedicalConditions();
      // getQualifications();
      // getWhenToTake();
      getFrequency();
      getSpecialization();
      getMedicineFormOptions();
      getCaseBasedNotifications();
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setAddForm(caseFormDetails);
    setTempMedicineList({
      itemId: "",
      itemName: "",
      forMedicalConditions: [],
      // startDate: new Date(),
      // endDate: new Date(),
      itemCount: "",
      form: "",
      duration: "",
      // frequencyDates: [],
      frequency: "Daily",
      whenToTake: {
        Morning: false,
        Afternoon: false,
        Evening: false,
        Night: false,
        OtherTime: false,
        sos: false,
      },
      strength: "",
      overview: "",
      intakeDetails: "",
      priceList: [],
      intake: {
        Morning: "",
        Afternoon: "",
        Evening: "",
        Night: "",
        Other: "",
        OtherTime: "",
      },
      intakeQuantity: {
        Morning: "",
        Afternoon: "",
        Evening: "",
        Night: "",
        OtherTime: "",
        sosQuantity: "",
      },
    });
  };

  const getQualifications = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "qualifications" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setQualificationsOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const [doctorList, setDoctorList] = useState([]);
  useEffect(() => {
    getTableData();
  }, []);

  console.log(doctorForm, "from here");

  const getTableData = (keyword) => {
    const url = ConfigAPIURL.doctorTableURL;
    APIRequest.request(
      "POST",
      url,
      JSON.stringify({
        page: 0,
        pageSize: 5,
        sortField: "",
        sortOrder: "false",
        active: true,
        keyword: keyword,
      })
    ).then((response) => {
      if (response?.data?.responseCode === 109) {
        setDoctorList(response?.data?.rows);
      }
    });
  };
  const getMedicalConditions = (event, data) => {
    if (data !== undefined && data !== null) {
      if (data === "") {
        setMedicalConditionsOptions([]);
      } else if (data.length > LocalStorage.searchableStringLimit) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.medicalOptions,
          JSON.stringify({ name: "medicalConditions", keyword: data })
        ).then((response) => {
          if (response !== undefined && response !== null) {
            if (
              response.data.responseCode !== undefined &&
              response.data.responseCode !== null
            ) {
              if (response.data.responseCode === 109) {
                if (response.data.options[0] !== undefined) {
                  setMedicalConditionsOptions(response.data.options[0].options);
                }
              }
            }
          }
        });
      } else {
        setMedicalConditionsOptions([]);
      }
    } else {
      setMedicalConditionsOptions([]);
    }
  };
  const getWhenToTake = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "timeOfDay" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setwhenToTakeOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const getFrequency = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "frequency" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setFrequencyOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const getSpecialization = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "specialization" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            let sortedResult = response.data.options[0].options.sort((a, b) =>
              a.display.localeCompare(b.display, "es", {
                sensitivity: "base",
              })
            );

            setSpecializationOptions(sortedResult);
          }
        }
      }
    });
  };

  const getMedicineFormOptions = () => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.options,
      JSON.stringify({ name: "form" })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setMedicineFormOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const getDoctors = (event, val) => {
    if (val.length > 3) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.searchDoctors,
        JSON.stringify({ keyword: val })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let result = [];
            response.data.result.map((doctorList) => {
              result.push({
                label:
                  doctorList.name +
                  " ( " +
                  doctorList.qualification +
                  " - " +
                  doctorList.specialization +
                  " - " +
                  doctorList.hospital +
                  " )",
                value: doctorList._id,
                name: doctorList.name,
                contact: doctorList.contact,
                qualification: doctorList.qualification,
                specialization: doctorList.specialization,
                contactPersonMobileNo: doctorList.contactPersonMobileNo,
                hospital: doctorList.hospital,
                contactPersonName: doctorList.contactPersonName,
                address: doctorList.address,
                gender: doctorList.gender,
              });
            });
            setDoctorsOptions(result);
          }
        }
      });
    }
  };
  const getInventorList = (event, value) => {
    if (value !== undefined && value.length !== undefined) {
      if (value === "") {
        setInventoryOptionList([]);
      } else if (value.length > LocalStorage.searchableStringLimit) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.inventorySearch,
          JSON.stringify({
            keyword: value,
            recordId: props.rowDetails[0],
          })
        ).then((response) => {
          if (response !== undefined && response !== null) {
            if (response.code === 100 && response.data.responseCode === 109) {
              let serverResponse = response.data.result;

              serverResponse.map((results) => {
                results["label"] =
                  results.title +
                  ", " +
                  results.form +
                  ", " +
                  results.dosageForm;
              });

              setInventoryOptionList(serverResponse);
            }
          }
        });
      } else {
        setInventoryOptionList([]);
      }
    } else {
      setInventoryOptionList([]);
    }
  };

  const getUser = (value) => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.searchUser + "?keyword=" + (value !== undefined ? value : "")
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setUserOptionList(response.data.result);
        }
      }
    });
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.caseDetails,
        JSON.stringify({ recordId: props.rowDetails[0] })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let processResult = response?.data?.result;
            processResult.prescriptionDate = new Date(
              processResult?.prescriptionDate * 1000
            );

            let dates = [];
            const theDate = new Date(response?.data?.result?.prescriptionDate);
            while (
              theDate <=
              new Date(
                new Date(response.data.result.prescriptionDate).setMonth(
                  new Date(response.data.result.prescriptionDate).getMonth() + 3
                )
              )
            ) {
              dates = [...dates, new Date(theDate)];
              theDate.setDate(theDate.getDate() + 1);
            }

            setTempMedicineList({
              ...tempMedicineList,
              frequencyDates: dates,
              startDate: processResult.prescriptionDate,
              endDate: new Date(
                new Date(processResult.prescriptionDate).setMonth(
                  new Date(processResult.prescriptionDate).getMonth() + 3
                )
              ),
            });

            let meds = processResult.medicineList;

            meds.map((med, idx) => {
              let medicineCondtn = [];
              if (
                med.forMedicalConditions !== undefined &&
                med.forMedicalConditions !== ""
              ) {
                med.forMedicalConditions.split(",").map((optionValue) => {
                  medicineCondtn.push({
                    display: optionValue,
                    value: optionValue,
                  });
                });
              }

              let frequencyDatesVal;

              frequencyDatesVal = med.frequencyDates.map((frequency) => {
                return new Date(
                  new Date(new Date(frequency * 1000)).setHours(12, 0, 0, 0)
                );
              });

              meds[idx].frequencyDates = frequencyDatesVal;

              meds[idx].startDate = new Date(med.startDate * 1000);
              meds[idx].endDate = new Date(med.endDate * 1000);
              meds[idx].forMedicalConditions = medicineCondtn;
              meds[idx].itemName =
                med.itemId.title !== undefined &&
                med.itemId.title !== null &&
                med.itemId.title !== ""
                  ? med.itemId.title
                  : "";
              meds[idx].strength =
                med.itemId.dosageForm !== undefined &&
                med.itemId.dosageForm !== null &&
                med.itemId.dosageForm !== ""
                  ? med.itemId.dosageForm
                  : "";
              meds[idx].overview =
                med.itemId.overview !== undefined &&
                med.itemId.overview !== null &&
                med.itemId.overview !== ""
                  ? med.itemId.overview
                  : "";
              meds[idx].form =
                med.itemId.form !== undefined &&
                med.itemId.form !== null &&
                med.itemId.form !== ""
                  ? med.itemId.form.charAt(0).toUpperCase() +
                    med.itemId.form.slice(1)
                  : "";

              // meds[idx].frequencyDates = medicineCondtn;

              // meds[idx].whenToTake = {
              //   display: med.whenToTake,
              //   value: med.whenToTake,
              // };
              meds[idx].whenToTake = {
                Morning: med.whenToTake.includes("Morning"),
                Afternoon: med.whenToTake.includes("Afternoon"),
                Evening: med.whenToTake.includes("Evening"),
                Night: med.whenToTake.includes("Night"),
                OtherTime: med.whenToTake.includes("OtherTime"),
                sos: med.whenToTake.includes("sos"),
              };
            });

            setAddForm({
              ...addForm,
              userId: processResult.userId._id,
              patientId: processResult.userId.patientId,

              bloodPressure: {
                high: processResult.userId.bloodPressure.high,
                low: processResult.userId.bloodPressure.low,
              },
              height: processResult.userId.height,
              heightUnit: processResult.userId.heightUnit,
              weightUnit: processResult.userId.weightUnit,

              allergies:
                processResult.userId.allergies !== null &&
                processResult.userId.allergies !== ""
                  ? processResult.userId.allergies.split(",")
                  : [],
              chronicConditions:
                processResult.userId.chronicConditions !== null &&
                processResult.userId.chronicConditions !== ""
                  ? processResult.userId.chronicConditions.split(",")
                  : [],
              surgeries:
                processResult.userId.surgeries !== null &&
                processResult.userId.surgeries !== ""
                  ? processResult.userId.surgeries.split(",")
                  : [],
              familyHistory:
                processResult.userId.familyHistory !== null &&
                processResult.userId.familyHistory !== ""
                  ? processResult.userId.familyHistory.split(",")
                  : [],

              weight:
                processResult.weight !== "" && processResult.weight !== 0
                  ? processResult.weight
                  : processResult.userId.weight,
              caseId: processResult.caseId,
              active: processResult.active,
              caseType: processResult.caseType,
              userName: processResult.userName,
              mobileNo: processResult.mobileNo,
              doctor:
                processResult.doctor !== undefined &&
                processResult.doctor._id !== null
                  ? processResult.doctor._id
                  : "",

              diagnosis: processResult.diagnosis,
              prescriptionDate: processResult.prescriptionDate,
              prescription: processResult.prescription,
              doctorInstructions: processResult.doctorInstructions,
              pharmaInstructions: processResult.pharmaInstructions,
              remark: processResult.remark,
              medicineList: meds,
            });

            let tabLabelValue = processResult.medicineList;

            tabLabelValue.map((value) => {
              tabLabel.push({
                label: value.itemId.title,
                value: value.itemId.title,
              });
            });
            setTabLabel([...tabLabel], tabLabelValue);

            if (
              props.medicineName !== undefined &&
              props.medicineName !== null
            ) {
              let findMedicineIndex = tabLabel.findIndex(
                (obj) => obj.label === props.medicineName
              );

              if (findMedicineIndex !== -1) {
                setTabValue(findMedicineIndex);
              }
            }
            setTempData({
              ...tempData,
              userId: processResult.userId,
              caseDynamicUrl:
                processResult.caseDynamicUrl !== undefined &&
                processResult.caseDynamicUrl !== null
                  ? processResult.caseDynamicUrl
                  : "",
            });
            if (
              processResult.doctor !== undefined &&
              processResult.doctor !== null
            ) {
              setDoctorForm({
                ...doctorForm,
                // _id: selectedData._id,
                name: processResult.doctor.name,
                qualification: processResult.doctor.qualification,
                gender: processResult.doctor.gender,
                specialization: processResult.doctor.specialization,
                otherSpecialization: processResult.doctor.otherSpecialization,
                contactPersonMobileNo:
                  processResult.doctor.contactPersonMobileNo,
                contactPersonName: processResult.doctor.contactPersonName,
                hospital: processResult.doctor.hospital,
                address: processResult.doctor.address,
                contact: {
                  email: processResult.doctor.contact.email,
                  fax: processResult.doctor.contact.fax,
                  mobileNo: processResult.doctor.contact.mobileNo,
                  extension: processResult.doctor.contact.extension,
                  officeNo: processResult.doctor.contact.officeNo,
                },
              });
            }
          }
        }
      });
    }
  };

  const getCaseBasedNotifications = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.getCaseBasedNotifications,
        JSON.stringify({ actionId: props.rowDetails[0] })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let serverResponse = response.data.result;

            serverResponse.map((parent, index) => {
              if (parent !== undefined && parent.child !== undefined) {
                parent.notificationHistory.sort((a, b) =>
                  a.updatedAt > b.updatedAt ? 1 : -1
                );
              }
            });

            setNotificationsList(serverResponse);
          }
        }
      });
    }
  };

  const sendToServer = () => {
    if (doctorForm.name === "") {
      Toaster.error(props.t("case.selectDoctor"), "topRight");
      return;
    }
    if (doctorForm.hospital === "") {
      Toaster.error(props.t("case.addHospital"), "topRight");
      return;
    }
    if (doctorForm.contact.officeNo === "") {
      Toaster.error(props.t("case.addOfficeNo"), "topRight");
      return;
    }
    if (doctorForm.address === "") {
      Toaster.error(props.t("case.addAddress"), "topRight");
      return;
    }
    if (
      doctorForm.specialization === "Other" &&
      doctorForm.otherSpecialization === ""
    ) {
      Toaster.error(props.t("case.enterDoctorSpecialization"), "topRight");
      return;
    }

    let fieldsValidation = false;

    addForm.medicineList.map((medicines) => {
      if (
        medicines.whenToTake.Morning === false &&
        medicines.whenToTake.Afternoon === false &&
        medicines.whenToTake.Evening === false &&
        medicines.whenToTake.Night === false &&
        medicines.whenToTake.OtherTime === false &&
        medicines.whenToTake.sos === false
      ) {
        Toaster.error(
          "Please select time of day for " + medicines.itemName,
          "topRight"
        );
        fieldsValidation = true;
        return;
      }

      if (medicines.whenToTake.Morning === true) {
        if (medicines.intake.Morning === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Morning",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Morning === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Morning",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }

      if (medicines.whenToTake.Afternoon === true) {
        if (medicines.intake.Afternoon === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Afternoon",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Afternoon === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Afternoon",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }

      if (medicines.whenToTake.Evening === true) {
        if (medicines.intake.Evening === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Evening",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Evening === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Evening",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }

      if (medicines.whenToTake.Night === true) {
        if (medicines.intake.Night === "") {
          Toaster.error(
            "Please select when to take for " +
              medicines.itemName +
              " in the Night",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
        if (medicines.intakeQuantity.Night === "") {
          Toaster.error(
            "Please select the quantity for " +
              medicines.itemName +
              " in the Night",
            "topRight"
          );
          fieldsValidation = true;
          return;
        }
      }
      if (medicines.whenToTake.OtherTime === true) {
        if (medicines.intake.Other === "") {
          setLoading(false);
          Toaster.error(
            "Please select the when to take for " +
              medicines.itemName +
              " for Other Time",
            "topRight"
          );
          return;
        }
        if (medicines.intakeQuantity.OtherTime === "") {
          setLoading(false);
          Toaster.error(
            "Please enter the quantity " +
              medicines.itemName +
              " for Other Time",
            "topRight"
          );
          return;
        }
        if (medicines.intake.OtherTime === "") {
          setLoading(false);
          Toaster.error(
            "Please select the time for " +
              medicines.itemName +
              " for Other Time",
            "topRight"
          );
          return;
        }
      }
      if (medicines.whenToTake.sos === true) {
        if (medicines.intakeQuantity.sosQuantity === "") {
          setLoading(false);
          Toaster.error(
            "Please select quantity for " + medicines.itemName + " for SOS",
            "topRight"
          );
          return;
        }
      }
    });

    // console.log('sendtoserver', addForm);
    // return;
    if (fieldsValidation === false) {
      update("sendtoserver");
    }
  };

  const update = (from) => {
    let body = {
      recordId: props.rowDetails[0],
      ...addForm,
      doctorData: { ...doctorForm },
    };

    // console.log("bodybodybody", body);
    // console.log("stringify", JSON.stringify(body));
    // console.log('parse', JSON.parse(body));
    // console.log('stringifyparse', JSON.stringify(JSON.parse(body)));
    // console.log('parsestringify', JSON.parse(JSON.stringify(body)));
    // return;

    APIRequest.request(
      "PUT",
      ConfigAPIURL.caseUpdate,
      JSON.stringify(body)
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          if (from === "sendtoserver") {
            SnackbarUtils.sucess(
              props.t("snackbar.transComplete"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            props.handleFormDialog();

            setAddForm(caseFormDetails);
          } else {
            SnackbarUtils.sucess(
              props.t("snackbar.caseUpdated"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    });
  };

  const handleInventoryListOption = (event, value) => {
    if (value !== undefined && value !== null) {
      setTempMedLabel("");
      if (value.inputValue !== undefined) {
        setTempMedicineList({
          ...tempMedicineList,
          itemId: "",
          itemName: value.inputValue,
          itemCount: "",
          form: "",
          forMedicalConditions: [],
          duration: "",
          frequency: "Daily",
          // frequencyDates: [],
          whenToTake: {
            Morning: false,
            Afternoon: false,
            Evening: false,
            Night: false,
            OtherTime: false,
            sos: false,
          },
          intake: {
            Morning: "",
            Afternoon: "",
            Evening: "",
            Night: "",
            OtherTime: "",
            Other: "",
          },
          intakeQuantity: {
            Morning: "",
            Afternoon: "",
            Evening: "",
            Night: "",
            OtherTime: "",
            sosQuantity: "",
          },
          strength: "",
          overview: "",
          intakeDetails: "",
          priceList: [],
        });
      } else {
        setTempMedicineList({
          ...tempMedicineList,
          itemId: value._id,
          itemName: value.title,
          itemCount: "",
          form:
            value.form !== undefined && value.form !== null
              ? value.form.charAt(0).toUpperCase() + value.form.slice(1)
              : "",
          forMedicalConditions: [],

          duration: "",
          frequency: "Daily",
          // frequencyDates: [],
          whenToTake: {
            Morning: false,
            Afternoon: false,
            Evening: false,
            Night: false,
            OtherTime: false,
            sos: false,
          },
          intake: {
            Morning: "",
            Afternoon: "",
            Evening: "",
            Night: "",
            OtherTime: "",
            Other: "",
          },
          intakeQuantity: {
            Morning: "",
            Afternoon: "",
            Evening: "",
            Night: "",
            OtherTime: "",
            sosQuantity: "",
          },
          strength: value.dosageForm,
          overview: value.overview,
          intakeDetails: "",
          priceList: value.pricingList,
        });
      }
      setTempData(tempDataForDisplay);
    }
  };
  const [loading, setLoading] = useState(false);

  const handleMedicineListPush = () => {
    let fieldValidation = ["itemName", "form", "overview"];

    FormValidation.validation(fieldValidation, tempMedicineList).then(
      (ValidationResult) => {
        if (ValidationResult) {
          if (
            tempMedicineList.whenToTake.Morning === false &&
            tempMedicineList.whenToTake.Afternoon === false &&
            tempMedicineList.whenToTake.Evening === false &&
            tempMedicineList.whenToTake.Night === false &&
            tempMedicineList.whenToTake.OtherTime === false &&
            tempMedicineList.whenToTake.sos === false
          ) {
            setLoading(false);
            SnackbarUtils.error(
              props.t("case.selectTimeOfDay"),
              "topRight",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            return;
          }

          if (tempMedicineList.whenToTake.Morning === true) {
            if (tempMedicineList.intake.Morning === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.selectMorningWhenToTake"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            if (tempMedicineList.intakeQuantity.Morning === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.enterMorningQuantity"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
          }

          if (tempMedicineList.whenToTake.Afternoon === true) {
            if (tempMedicineList.intake.Afternoon === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.selectAfternoonWhenToTake"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            if (tempMedicineList.intakeQuantity.Afternoon === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.enterAfternoonQuantity"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
          }
          if (tempMedicineList.whenToTake.Evening === true) {
            if (tempMedicineList.intake.Evening === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.selectEveningWhenToTake"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            if (tempMedicineList.intakeQuantity.Evening === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.enterEveningQuantity"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
          }
          if (tempMedicineList.whenToTake.Night === true) {
            if (tempMedicineList.intake.Night === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.selectNightWhenToTake"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            if (tempMedicineList.intakeQuantity.Night === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("case.enterNightQuantity"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
          }
          console.log(tempMedicineList, "from here in the validation");
          if (tempMedicineList.whenToTake.OtherTime === true) {
            if (tempMedicineList.intake.Other === "") {
              setLoading(false);
              console.log("2");
              SnackbarUtils.error(
                props.t("Please select when to take for other"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            if (tempMedicineList.intakeQuantity.OtherTime === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("Please enter the quantity for others"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            if (tempMedicineList.intake.OtherTime === "") {
              setLoading(false);
              console.log("1");
              SnackbarUtils.error(
                props.t("Please select the time for other"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
          }
          if (tempMedicineList.whenToTake.sos === true) {
            if (tempMedicineList.intakeQuantity.sosQuantity === "") {
              setLoading(false);
              SnackbarUtils.error(
                props.t("Please enter the quantity for sos"),
                "topRight",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
          }

          const found = addForm.medicineList.some(
            (el) =>
              el.itemName === tempMedicineList.itemName &&
              el.form === tempMedicineList.form &&
              el.strength == tempMedicineList.strength
          );

          if (!found) {
            if (tempMedicineList.frequency !== "Custom Schedule") {
              tempMedicineList.frequencyDates = [];
              setLoading(false);
            }
            setLoading(true);
            APIRequest.request(
              "POST",
              ConfigAPIURL.createInventory,
              JSON.stringify({
                recordId: props.rowDetails[0],
                medicineList: tempMedicineList,
              })
            ).then((response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  setLoading(false);

                  let serverResponse = response.data.createdMedication;
                  let medicineCondtn = [];
                  if (
                    serverResponse.forMedicalConditions !== undefined &&
                    serverResponse.forMedicalConditions !== null &&
                    serverResponse.forMedicalConditions !== ""
                  ) {
                    serverResponse.forMedicalConditions
                      .split(",")
                      .map((optionValue) => {
                        medicineCondtn.push({
                          display: optionValue,
                          value: optionValue,
                        });
                      });
                  }

                  let frequencyDatesVal;

                  frequencyDatesVal = serverResponse.frequencyDates.map(
                    (frequency) => {
                      return new Date(
                        new Date(new Date(frequency * 1000)).setHours(
                          12,
                          0,
                          0,
                          0
                        )
                      );
                    }
                  );

                  serverResponse.form = tempMedicineList.form;
                  serverResponse.overview = tempMedicineList.overview;
                  serverResponse.frequencyDates = frequencyDatesVal;

                  serverResponse.forMedicalConditions = medicineCondtn;

                  serverResponse.startDate = new Date(
                    serverResponse.startDate * 1000
                  );
                  serverResponse.endDate = new Date(
                    serverResponse.endDate * 1000
                  );

                  serverResponse.whenToTake = {
                    Morning: serverResponse.whenToTake.includes("Morning"),
                    Afternoon: serverResponse.whenToTake.includes("Afternoon"),
                    Evening: serverResponse.whenToTake.includes("Evening"),
                    Night: serverResponse.whenToTake.includes("Night"),
                    OtherTime: serverResponse.whenToTake.includes("OtherTime"),
                    sos: serverResponse.whenToTake.includes("sos"),
                  };
                  let form = addForm.medicineList;

                  form.push(serverResponse);
                  let tabLabels = tabLabel;
                  tabLabels.push({
                    label: serverResponse.itemName,
                    value: serverResponse.itemName,
                  });
                  setTabLabel([...tabLabels]);
                  setAddForm({ ...addForm, medicineList: form });

                  let dates = [];
                  const theDate = new Date(addForm.prescriptionDate);
                  while (
                    theDate <=
                    new Date(
                      new Date(addForm.prescriptionDate).setMonth(
                        new Date(addForm.prescriptionDate).getMonth() + 3
                      )
                    )
                  ) {
                    dates = [...dates, new Date(theDate)];
                    theDate.setDate(theDate.getDate() + 1);
                  }

                  setTempMedicineList({
                    itemId: "",
                    itemName: "",
                    forMedicalConditions: [],
                    startDate: addForm.prescriptionDate,
                    endDate: new Date(
                      new Date(addForm.prescriptionDate).setMonth(
                        new Date(addForm.prescriptionDate).getMonth() + 3
                      )
                    ),
                    itemCount: "",
                    form: "",
                    duration: "",
                    frequency: "Daily",
                    frequencyDates: dates,
                    // frequencyDates: [],
                    whenToTake: {
                      Morning: false,
                      Afternoon: false,
                      Evening: false,
                      Night: false,
                      OtherTime: false,
                      sos: false,
                    },
                    intake: {
                      Morning: "",
                      Afternoon: "",
                      Evening: "",
                      Night: "",
                      OtherTime: "",
                      Other: "",
                    },
                    intakeQuantity: {
                      Morning: "",
                      Afternoon: "",
                      Evening: "",
                      Night: "",
                      OtherTime: "",
                      sosQuantity: "",
                    },
                    strength: "",
                    overview: "",
                    intakeDetails: "",
                    priceList: [],
                    sos: false,
                  });
                }
              }
            });
          } else {
            SnackbarUtils.error(
              props.t("snackbar.dupliRecords"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  // const handleAddDialog = (dialogType) => {
  //   if (dialogType === 'doctor') {
  //     // setOpenDoctorForm(true);
  //   }
  // };

  // const handleCloseForm = () => {
  //   // setOpenDoctorForm(false);
  // };

  const handleMedicineListChange = (event, index, type) => {
    let form = addForm;
    form.medicineList[index][type] = event.target.value;

    setAddForm({ ...addForm, form });
  };

  const handleMedicineListFrequencyChange = (event, index, type) => {
    let form = addForm;

    if (
      event.target.value === "Custom Schedule" &&
      form.medicineList[index].frequencyDates.length === 0
    ) {
      let dates = [];
      const theStartDate = new Date(form.medicineList[index].startDate);
      while (
        theStartDate <=
        new Date(
          new Date(form.medicineList[index].endDate).setMonth(
            new Date(form.medicineList[index].endDate).getMonth() + 3
          )
        )
      ) {
        dates = [...dates, new Date(theStartDate)];
        theStartDate.setDate(theStartDate.getDate() + 1);
      }
      form.medicineList[index].frequencyDates = dates;
    }

    form.medicineList[index][type] = event.target.value;

    setAddForm({ ...addForm, form });
  };

  const handleMedicineListStartDateChange = (date, index) => {
    let form = addForm;

    if (date > form.medicineList[index].endDate) {
      return Toaster.error(props.t("toaster.startDateLesser"), "topRight");
    }
    form.medicineList[index].startDate = date;

    setAddForm({ ...addForm, form });
  };
  const handleMedicineListEndDateChange = (date, index) => {
    let form = addForm;

    if (date < form.medicineList[index].startDate) {
      return Toaster.error(props.t("toaster.endDateLesser"), "topRight");
    }

    form.medicineList[index].endDate = date;

    setAddForm({ ...addForm, form });
  };

  const handleMedicineAutoCompleteListChange = (event, val, index) => {
    const currentIndex = addForm.medicineList[index].whenToTake.indexOf(val);

    const newChecked = [...addForm.medicineList[index].whenToTake];

    if (currentIndex === -1) {
      newChecked.push(val);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    let form = addForm;

    form.medicineList[index].whenToTake = newChecked;

    setAddForm({ ...addForm, form });

    // form.medicineList[index].whenToTake = val;
    // setAddForm({ ...addForm, form });
  };
  const handleMedicineMultipleAutoCompleteListChange = (
    event,
    val,
    index,
    type
  ) => {
    let form = addForm;
    if (
      val[val.length - 1] !== undefined &&
      val[val.length - 1] !== null &&
      val[val.length - 1].inputValue !== undefined &&
      val[val.length - 1].inputValue !== null
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.addMedicationAdd,
        JSON.stringify({ value: val[val.length - 1].inputValue })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            form.medicineList[index].forMedicalConditions.push({
              display: val[val.length - 1].inputValue,
              value: val[val.length - 1].inputValue,
              active: true,
              logo: "",
            });
            setAddForm({ ...addForm, form });
          } else if (
            response.code === 100 &&
            response.data.responseCode === 114
          ) {
            SnackbarUtils.error(
              props.t("snackbar.medicationExits"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      });
    } else {
      form.medicineList[index].forMedicalConditions = val;
      setAddForm({ ...addForm, form });
    }
  };

  const handleMedicineProductListChange = (event, index, priceIndex, type) => {
    let form = addForm;
    form.medicineList[index].priceList[priceIndex][type] = event.target.value;
    setAddForm({ ...addForm, form });
  };

  const handleTempMedicineProductListChange = (event, priceIndex, type) => {
    let tempList = tempMedicineList;
    tempList.priceList[priceIndex][type] = event.target.value;

    setTempMedicineList({ ...tempMedicineList, tempList });
  };
  const handlePushPriceList = (event, index) => {
    let form = addForm;
    form.medicineList[index].priceList.push({
      provider: "",
      price: "",
      discount: "",
    });
    setAddForm({ ...addForm, form });
  };

  const handleOfficeNumber = () => {
    if (doctorForm.name === "") {
      SnackbarUtils.error(
        props.t("snackbar.enterDoctorName"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    if (doctorForm.hospital === "") {
      SnackbarUtils.error(
        props.t("snackbar.hospitalName"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    if (doctorForm.contact.officeNo === "") {
      SnackbarUtils.error(
        props.t("snackbar.enterOfficeNumber"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    setIsSearching(true);
    APIRequest.request(
      "POST",
      ConfigAPIURL.getDoctorData,
      JSON.stringify({
        name: doctorForm.name,
        hospital: doctorForm.hospital,
        officeNo: doctorForm.contact.officeNo,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setIsSearching(false);

          if (response.data.result.length > 0) {
            setDoctorAlert(true);
            setDoctorAlert(true);
            setTempStoreDoctorData(response.data.result);
          }
        }
      }
    });
  };

  const handleMedDeleteRequest = (response) => {
    if (response === "agree") {
      if (medIdToDelete._id !== undefined) {
        APIRequest.request(
          "POST",
          ConfigAPIURL.deleteMedications,
          JSON.stringify({
            medicationId: medIdToDelete._id,
            recordId: props.rowDetails[0],
          })
        ).then((response) => {
          if (response !== undefined && response !== null) {
            if (response.code === 100 && response.data.responseCode === 109) {
              var array = [];
              tabLabel.map((labels) => {
                array.push(labels.label);
              });

              var index = array.indexOf(medIdToDelete.itemName);

              if (index !== -1) {
                array.splice(index, 1);
              } else {
                array.pop();
              }

              var result = [];

              array.map((values) => {
                result.push({ label: values, value: values });
              });

              setTabLabel([...result]);

              let addform = addForm;

              addform.medicineList = addform.medicineList.filter(
                (e) => e._id !== medIdToDelete._id
              );

              setAddForm({ ...addForm, addform });
            }
          }
        });
      } else {
        var array = [];
        tabLabel.map((labels) => {
          array.push(labels.label);
        });

        var index = array.indexOf(medIdToDelete.itemName);

        if (index !== -1) {
          array.splice(index, 1);
        } else {
          array.pop();
        }

        var result = [];

        array.map((values) => {
          result.push({ label: values, value: values });
        });

        setTabLabel([...result]);

        let addform = addForm;

        addform.medicineList = addform.medicineList.filter(
          (e) => e.itemId !== medIdToDelete.itemId
        );

        setAddForm({ ...addForm, addform });
      }
      handleSlideInDialog();
      SnackbarUtils.sucess(
        props.t("case.deletedSuccessfully"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      setTabValue(tabValue - 1);
    } else {
      handleSlideInDialog();
    }
  };

  const handleDeleteMedicine = (selectedRow) => {
    setMedDeleteDialog(true);
    setMedIdToDelete(selectedRow);
  };

  const handleTempMedicineCheckChange = (event) => {
    if (event.target.checked) {
      setTempMedicineList({
        ...tempMedicineList,
        whenToTake: {
          ...tempMedicineList.whenToTake,
          [event.target.name]: event.target.checked,
        },
      });
    } else {
      setTempMedicineList({
        ...tempMedicineList,
        whenToTake: {
          ...tempMedicineList.whenToTake,
          [event.target.name]: event.target.checked,
        },
        intake: {
          ...tempMedicineList.intake,
          [event.target.name]: "",
        },
        intakeQuantity: {
          ...tempMedicineList.intakeQuantity,
          [event.target.name]: "",
        },
      });
    }
  };

  const handleMedicineCheckChange = (event, index) => {
    console.log(event, "from checing event");
    if (event.target.checked) {
      let form = addForm;
      form.medicineList[index].sos[event.target.checked] =
        !event.target.checked;

      form.medicineList[index].whenToTake[event.target.name] =
        event.target.checked;

      setAddForm({ ...addForm, form });
    } else {
      let form = addForm;
      console.log(
        "I am getting called for checkboxed changes",
        form.medicineList[index],
        index
      );
      form.medicineList[index].whenToTake[event.target.name] =
        event.target.checked;
      form.medicineList[index].intake[event.target.name] = "";
      form.medicineList[index].intakeQuantity[event.target.name] = "";
      setAddForm({ ...addForm, form });
    }
  };

  const handleMedicineChange = (event, index, type) => {
    let form = addForm;
    form.medicineList[index].intake[type] = event.target.value;
    setAddForm({ ...addForm, form });
  };
  const handleMedicineIntakeQuantityChange = (event, index, type) => {
    let form = addForm;
    form.medicineList[index].intakeQuantity[type] = event.target.value;
    setAddForm({ ...addForm, form });
  };

  const handleRadioChange = (event, type) => {
    setTempMedicineList({
      ...tempMedicineList,
      intake: {
        ...tempMedicineList.intake,
        [type]: event.target.value,
      },
    });
  };

  const handleTempMedicineDayClick = (day, { selected }) => {
    if (selected) {
      const selectedIndex = tempMedicineList.frequencyDates.findIndex(
        (selectedDay) => DateUtils.isSameDay(selectedDay, day)
      );
      tempMedicineList.frequencyDates.splice(selectedIndex, 1);
    } else {
      tempMedicineList.frequencyDates.push(day);
    }
    setTempMedicineList({
      ...tempMedicineList,
      frequencyDates: tempMedicineList.frequencyDates,
    });
  };

  console.log(tempMedicineList, "from date checlkinmg");

  const handleMedicineDayClick = (day, selected, index) => {
    const selectedIndex = selected.findIndex((selectedDay) =>
      DateUtils.isSameDay(selectedDay, day)
    );

    if (selectedIndex !== -1) {
      addForm.medicineList[index].frequencyDates.splice(selectedIndex, 1);
    } else {
      addForm.medicineList[index].frequencyDates.push(day);
    }

    setAddForm({ ...addForm });
  };
  const [slideAssignDialog, setSlideAssignDialog] = useState({
    status: false,
    title: "",
    divType: "",
  });

  const handleNotification = () => {
    setSlideAssignDialog({
      status: true,
      title: props.t("case.sendNotification"),
      divType: "notification",
    });
  };

  const [postAttachmentFileBase64, setPostAttachmentFileBase64] = useState("");
  const [postAttachmentFile, setPostAttachmentFile] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [s3FileURLPostAttachment, setS3FileURLPostAttachment] = useState([]);
  const postAttachmentFileUpload = useRef();
  const handlePrescriptions = async (event) => {
    let file = event.target.files[0];

    if (file !== undefined && file !== null) {
      if (file.type.endsWith("png") || file.type.endsWith("jpeg")) {
        if (file !== undefined && file !== null) {
          let reader = new FileReader();
          let url = "";
          if (
            event.target.files[0].type.startsWith("image") &&
            event.target.files[0].size > 1000000
          ) {
            SnackbarUtils.info(
              props.t("snackbar.compressFile"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const compressedFile = await imageCompression(
              event.target.files[0],
              options
            );
            url = reader.readAsDataURL(compressedFile);
          } else if (
            event.target.files[0].type.startsWith("image") &&
            event.target.files[0].size < 3000000
          ) {
            url = reader.readAsDataURL(event.target.files[0]);
          } else if (event.target.files[0].type.startsWith("image")) {
            SnackbarUtils.error(
              props.t("snackbar.fileLarge"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            return;
          }
          SnackbarUtils.sucess(
            props.t("snackbar.uploadReady"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          reader.onloadend = function (err) {
            setPostAttachmentFileBase64(reader.result.split("base64,")[1]);
            setPostAttachmentFile(file);
            setFileUploadStatus(true);
            setS3FileURLPostAttachment("");
            postAttachmentFileUpload.current._s3Upload();
          }.bind(this);
        }
      } else {
        SnackbarUtils.error(
          props.t("snackbar.onlyImage"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    }
  };

  const _s3ImageUrlPostAttachment = (url) => {
    if (addForm.prescription.length === 0) {
      setImageIndex(0);
    }
    addForm.prescription.push(url);
    setAddForm({ ...addForm });
    setFileUploadStatus(false);
    setPostAttachmentFileBase64("");
    setS3FileURLPostAttachment(url);
    setPostAttachmentFile("");

    APIRequest.request(
      "PUT",
      ConfigAPIURL.updatePrescriptionCase,
      JSON.stringify({
        recordId: props.rowDetails[0],
        prescription: addForm.prescription,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          SnackbarUtils.sucess(
            props.t("snackbar.uploadPrescriptionSuccess"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  const handleDelete = () => {
    addForm.prescription.splice(imageIndex, 1);

    if (addForm.prescription.length === imageIndex) {
      setImageIndex(imageIndex - 1);
    }
    setAddForm({
      ...addForm,
    });

    APIRequest.request(
      "PUT",
      ConfigAPIURL.updatePrescriptionCase,
      JSON.stringify({
        recordId: props.rowDetails[0],
        prescription: addForm.prescription,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          SnackbarUtils.sucess(
            props.t("snackbar.prescriptionDeleteSuccess"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    });
  };

  const handleTempMedicalConditions = (event, value) => {
    if (
      value[value.length - 1] !== undefined &&
      value[value.length - 1] !== null &&
      value[value.length - 1].inputValue !== undefined &&
      value[value.length - 1].inputValue !== null
    ) {
      APIRequest.request(
        "POST",
        ConfigAPIURL.addMedicationAdd,
        JSON.stringify({ value: value[value.length - 1].inputValue })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let medConditions = tempMedicineList.forMedicalConditions;
            medConditions.push({
              display: value[value.length - 1].inputValue,
              value: value[value.length - 1].inputValue,
              active: true,
              logo: "",
            });
            setTempMedicineList({
              ...tempMedicineList,
              forMedicalConditions: medConditions,
            });
          } else if (
            response.code === 100 &&
            response.data.responseCode === 114
          ) {
            SnackbarUtils.error(
              props.t("snackbar.medicationExits"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      });
    } else {
      setTempMedicineList({
        ...tempMedicineList,
        forMedicalConditions: value,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              {tabValue !== 0 ? (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  // style={{ padding: 40 }}
                >
                  {/* -----------------------------------Prescription image top buttons-------------------------- */}
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Typography variant="p" component="h4">
                          {props.t("case.prescriptionImage")}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        align="center"
                      >
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="uploadPrescription1"
                          onChange={(event) => handlePrescriptions(event)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          type="file"
                        />
                        <label htmlFor="uploadPrescription1">
                          <Tooltip title={props.t("buttons.upload")}>
                            <IconButton aria-label="upload" component="span">
                              <CloudUploadIcon
                                variant="contained"
                                // color='primary'
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </Grid>
                      {addForm.prescription[imageIndex] !== undefined &&
                      addForm.prescription[imageIndex] !== null &&
                      addForm.prescription[imageIndex] !== "" ? (
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          align="center"
                        >
                          <Tooltip title={props.t("buttons.delete")}>
                            <IconButton
                              aria-label="reset"
                              onClick={() => {
                                handleDelete();
                              }}
                            >
                              <DeleteIcon variant="contained" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid
                        item
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        align="center"
                      >
                        {addForm.prescription[imageIndex] !== undefined &&
                        addForm.prescription[imageIndex] !== null &&
                        addForm.prescription[imageIndex] !== "" ? (
                          <Tooltip title={props.t("buttons.downloadFile")}>
                            <IconButton
                              aria-label="reset"
                              onClick={() =>
                                window.open(
                                  ConfigAPIURL.fileDownloader +
                                    "?file=" +
                                    addForm.prescription[imageIndex] +
                                    "&fileName=" +
                                    tempData.userId.fname +
                                    " " +
                                    tempData.userId.lname +
                                    "_" +
                                    addForm.patientId +
                                    "_" +
                                    addForm.caseId +
                                    "_" +
                                    DateConversion.returnYearMonthDate(
                                      Math.floor(Date.now() / 1000)
                                    )
                                )
                              }
                            >
                              <CloudDownloadIcon variant="contained" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* -------------------------------Prescription Image------------------------------------------------------------ */}
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="imageViwer"
                      >
                        <Box
                          component="span"
                          m={1}
                          color="text.primary"
                          border={"1px dashed #000"}
                          clone
                        >
                          <TransformWrapper
                            style={{ height: "80vh" }}
                            defaultScale={1}
                            defaultPositionX={0}
                            defaultPositionY={0}
                          >
                            {({ resetTransform, ...rest }) => {
                              return (
                                <div>
                                  <Grid container>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      align="right"
                                    >
                                      <Hidden only={["xl", "lg", "md"]}>
                                        <div
                                          style={{
                                            right: "15%",
                                            marginTop: "-10px",
                                            position: "absolute",
                                            zIndex: 1,
                                          }}
                                        >
                                          <Tooltip
                                            title={props.t("buttons.reset")}
                                          >
                                            <IconButton
                                              aria-label="reset"
                                              onClick={() => resetTransform()}
                                            >
                                              <ZoomOutIcon
                                                color="primary"
                                                variant="contained"
                                                style={{ fontSize: 35 }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </Hidden>
                                      <Hidden only={["sm", "xs"]}>
                                        <div
                                          style={{
                                            right: "54%",
                                            marginTop: "-10px",
                                            position: "absolute",
                                            // width: '90%',
                                            zIndex: 1,
                                          }}
                                        >
                                          <Tooltip
                                            title={props.t("buttons.reset")}
                                          >
                                            <IconButton
                                              aria-label="reset"
                                              onClick={() => resetTransform()}
                                            >
                                              <ZoomOutIcon
                                                color="primary"
                                                variant="contained"
                                                style={{ fontSize: 35 }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </Hidden>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                    >
                                      <TransformComponent>
                                        <Hidden only={["sm", "xs"]}>
                                          <img
                                            style={{
                                              marginTop: "25px",
                                              width: "100%",
                                              height: "100%",
                                            }}
                                            src={
                                              addForm.prescription[imageIndex]
                                            }
                                            alt="test"
                                          />
                                        </Hidden>
                                        <Hidden only={["xl", "lg", "md"]}>
                                          <img
                                            style={{
                                              marginTop: "25px",
                                              width: "100%",
                                              height: "175px",
                                            }}
                                            src={
                                              addForm.prescription[imageIndex]
                                            }
                                            alt="test"
                                          />
                                        </Hidden>
                                      </TransformComponent>
                                    </Grid>
                                    <Hidden only={["sm", "xs"]}>
                                      <Grid
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="prescriptionImageStyle"
                                        style={{
                                          position: "absolute",
                                          bottom: "1%",
                                          width: "39%",
                                        }}
                                      >
                                        <Tabs
                                          value={imageIndex}
                                          onChange={handleImageNextViewer}
                                          variant="scrollable"
                                          scrollButtons="on"
                                          indicatorColor="primary"
                                          aria-label="scrollable force tabs example"
                                        >
                                          {addForm.prescription.map(
                                            (images, idxImages) => (
                                              <Tab
                                                style={{
                                                  height: "140px",
                                                  background: "unset",
                                                  width: "100px",
                                                }}
                                                key={idxImages}
                                                value={idxImages}
                                                icon={
                                                  <Avatar
                                                    style={{
                                                      height: "80px",
                                                      width: "98%",
                                                    }}
                                                    variant="square"
                                                    alt="test avatar"
                                                    src={images}
                                                  />
                                                }
                                              />
                                            )
                                          )}
                                        </Tabs>
                                      </Grid>
                                    </Hidden>
                                  </Grid>
                                </div>
                              );
                            }}
                          </TransformWrapper>
                        </Box>
                      </Grid>
                      <Hidden only={["xl", "lg", "md"]}>
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="prescriptionImageStyle"
                          style={{
                            position: "absolute",
                            top: "43%",
                            width: "85%",
                          }}
                        >
                          <Tabs
                            style={{ height: "110px" }}
                            value={imageIndex}
                            onChange={handleImageNextViewer}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            aria-label="scrollable force tabs example"
                          >
                            {addForm.prescription.map((images, idxImages) => (
                              <Tab
                                style={{
                                  height: "140px",
                                  background: "unset",
                                  width: "80px",
                                }}
                                key={idxImages}
                                value={idxImages}
                                icon={
                                  <Avatar
                                    style={{
                                      height: "60px",
                                      width: "50px",
                                    }}
                                    variant="square"
                                    alt="test avatar"
                                    src={images}
                                  />
                                }
                              />
                            ))}
                          </Tabs>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {/* --------------------------------------------------------------------------------------- */}
              {tabValue === 0 ? (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  // style={{ padding: 40 }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ marginTop: "10px" }}
                      >
                        <Grid container>
                          <Grid xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.caseId")} : {addForm.caseId}
                            </Typography>
                          </Grid>

                          <Grid xl={7} lg={7} md={7} sm={12} xs={12}>
                            <TextField
                              id="dynamicUrl"
                              label={props.t("case.dynamicUrl")}
                              value={tempData.caseDynamicUrl}
                              fullWidth
                              className={classes.customFontSize}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(event) => {
                                        let copyText =
                                          document.getElementById("dynamicUrl");

                                        copyText.select();
                                        copyText.setSelectionRange(0, 99999);

                                        document.execCommand("copy");
                                      }}
                                    >
                                      <FileCopyIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid
                            xl={4}
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            className={classes.formGrid}
                          >
                            <TextField
                              id="active"
                              select
                              label={props.t("doctor.caseActiveStatus")}
                              fullWidth
                              // InputLabelProps={{
                              //   shrink: addForm.acti? true : false,
                              // }}
                              defaultValue={addForm.active}
                              value={addForm.active}
                              onChange={(event) => {
                                setAddForm({
                                  ...addForm,
                                  active: event.target.value,
                                });
                                setStatusCheck(true);
                              }}
                            >
                              <MenuItem key={0} value={true}>
                                {props.t("doctor.active")}
                              </MenuItem>
                              <MenuItem key={1} value={false}>
                                {props.t("doctor.retire")}
                              </MenuItem>
                            </TextField>
                          </Grid>

                          <Grid
                            xl={4}
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            className={classes.formGrid}
                          >
                            <TextField
                              id="caseType"
                              select
                              label={props.t("case.caseType")}
                              fullWidth
                              // InputLabelProps={{
                              //   shrink: addForm.acti? true : false,
                              // }}
                              defaultValue={addForm.caseType}
                              value={addForm.caseType}
                              onChange={(event) => {
                                setAddForm({
                                  ...addForm,
                                  caseType: event.target.value,
                                });
                              }}
                            >
                              <MenuItem key={0} value={"Prescription"}>
                                {props.t("case.prescription")}
                              </MenuItem>
                              <MenuItem key={1} value={"Lab Result"}>
                                {props.t("case.labResult")}
                              </MenuItem>
                              <MenuItem key={1} value={"counselling"}>
                                {props.t("Counseling")}
                              </MenuItem>
                            </TextField>
                          </Grid>

                          <Grid
                            xl={4}
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            className={classes.formGrid}
                            style={{ marginTop: "-6px" }}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container>
                                <KeyboardDatePicker
                                  animateYearScrolling={false}
                                  // disableFuture={true}
                                  variant="dialog"
                                  required
                                  fullWidth
                                  disabled={
                                    props.formDialog.divType === "new" ||
                                    props.formDialog.divType === "edit"
                                      ? false
                                      : true
                                  }
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="prescriptionDate"
                                  label={props.t("case.dateOfPrescription")}
                                  value={addForm.prescriptionDate}
                                  autoOk={true}
                                  onChange={(date) => {
                                    addForm.medicineList.map((medicines) => {
                                      if (date > medicines.startDate) {
                                        SnackbarUtils.sucess(
                                          props.t("case.medicineDatesCheck"),
                                          "bottomCenter",
                                          3000
                                        ).then((notification) => {
                                          props.publishNotification(
                                            notification
                                          );
                                        });
                                      }
                                    });
                                    setAddForm({
                                      ...addForm,
                                      prescriptionDate: date,
                                    });
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* -----------------Medical Information------------------------------------ */}

                      <Grid
                        style={{ border: "1px solid #f1f1f1", padding: "10px" }}
                      >
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                        >
                          <Grid container>
                            <h2 style={{ alignSelf: "center" }}>
                              MEDICAL INFORMATION
                            </h2>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ textAlign: "left" }}
                            >
                              {props.t("case.bloodPressure")}
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="bloodPressure"
                                label={props.t("case.high")}
                                value={
                                  addForm.bloodPressure !== undefined &&
                                  addForm.bloodPressure !== null
                                    ? addForm.bloodPressure.high
                                    : ""
                                }
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    bloodPressure: {
                                      ...addForm.bloodPressure,
                                      high: event.target.value,
                                    },
                                  })
                                }
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="bloodPressurelow"
                                label={props.t("case.low")}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                value={
                                  addForm.bloodPressure !== undefined &&
                                  addForm.bloodPressure !== null
                                    ? addForm.bloodPressure.low
                                    : ""
                                }
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    bloodPressure: {
                                      ...addForm.bloodPressure,
                                      low: event.target.value,
                                    },
                                  })
                                }
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>

                            <Grid
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="weight"
                                label={props.t("case.weight")}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                value={addForm.weight}
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    weight: event.target.value,
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {addForm.weightUnit}
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="weight"
                                label={props.t("case.height")}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                value={addForm.height}
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    height: event.target.value,
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {addForm.heightUnit}
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                              <Typography
                                gutterBottom
                                variant="p"
                                className={classes.customFontSize}
                                component="p"
                              >
                                {props.t("case.bloodGroup")} :{" "}
                                {tempData.userId.bloodGroup}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <ChipInput
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                fullWidth
                                defaultValue={
                                  addForm.allergies !== undefined &&
                                  addForm.allergies !== null
                                    ? addForm.allergies
                                    : ""
                                }
                                onChange={(chip) =>
                                  setAddForm({
                                    ...addForm,
                                    allergies: chip,
                                  })
                                }
                                allowDuplicates={false}
                                placeholder={props.t("user.enterAllergies")}
                                label={props.t("user.allergies")}
                              />
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <ChipInput
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                fullWidth
                                defaultValue={
                                  addForm.chronicConditions !== undefined &&
                                  addForm.chronicConditions !== null
                                    ? addForm.chronicConditions
                                    : ""
                                }
                                onChange={(chip) =>
                                  setAddForm({
                                    ...addForm,
                                    chronicConditions: chip,
                                  })
                                }
                                allowDuplicates={false}
                                placeholder={props.t(
                                  "user.enterChronicConditions"
                                )}
                                label={props.t("user.chronicConditions")}
                              />
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <ChipInput
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                fullWidth
                                defaultValue={
                                  addForm.familyHistory !== undefined &&
                                  addForm.familyHistory !== null
                                    ? addForm.familyHistory
                                    : ""
                                }
                                onChange={(chip) =>
                                  setAddForm({
                                    ...addForm,
                                    familyHistory: chip,
                                  })
                                }
                                allowDuplicates={false}
                                placeholder={props.t("user.familyHistory")}
                                label={props.t("user.familyHistory")}
                              />
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <ChipInput
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                fullWidth
                                defaultValue={
                                  addForm.surgeries !== undefined &&
                                  addForm.surgeries !== null
                                    ? addForm.surgeries
                                    : ""
                                }
                                onChange={(chip) =>
                                  setAddForm({
                                    ...addForm,
                                    surgeries: chip,
                                  })
                                }
                                allowDuplicates={false}
                                placeholder={props.t("user.surgeries")}
                                label={props.t("user.surgeries")}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* ------------- */}
                    </Grid>
                  </Grid>
                  {/* ---------------------------------hehe------------------ */}
                  <Grid container>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id="pharmaInstructions"
                        label={props.t("case.pharmaInstructions")}
                        autoComplete="something-unsupported"
                        multiline
                        value={addForm.pharmaInstructions}
                        fullWidth
                        onChange={(event) =>
                          setAddForm({
                            ...addForm,
                            pharmaInstructions: event.target.value,
                          })
                        }
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id="remark"
                        label={props.t("case.remark")}
                        autoComplete="something-unsupported"
                        multiline
                        value={addForm.remark}
                        fullWidth
                        onChange={(event) =>
                          setAddForm({
                            ...addForm,
                            remark: event.target.value,
                          })
                        }
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      align="right"
                    >
                      <Button
                        onClick={() => {
                          handleNotification();
                        }}
                      >
                        {props.t("case.sendNotification")}
                      </Button>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Notification
                        getNotifications={getCaseBasedNotifications}
                        notificationLists={notificationsList}
                      ></Notification>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {/* --------------------------------------------------------------------------------------------- */}
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="formViwer"
                // style={{ padding: '55px 40px 40px 40px' }}
              >
                <AppBar position="sticky" className="processCaseEditTab">
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable force tabs example"
                  >
                    {tabLabel.map((label, index) => (
                      <Tab
                        label={label.label}
                        disabled={
                          addForm.caseType === "Lab Result" &&
                          label.value === "selectMedicineList"
                            ? true
                            : doctorForm.name === "" &&
                              label.value === "selectMedicineList"
                            ? true
                            : false
                        }
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                  {/* patientInfo */}
                  <Grid
                    style={{ border: "1px solid #f1f1f1", padding: "10px" }}
                  >
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <Grid container>
                        <h2 style={{ alignSelf: "center" }}>
                          PERSONAL INFORMATION
                        </h2>
                        <Grid container>
                          <Grid
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            // style={{ width: '80%' }}
                          >
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.patientId")} : {addForm.patientId}
                            </Typography>
                          </Grid>

                          <Grid
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            // style={{ width: '80%' }}
                          >
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.patientName")} :{" "}
                              {tempData.userId.fname} {tempData.userId.lname}
                            </Typography>
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            {console.log(tempData.userId.dob, "priting dobbbb")}
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.age")} :{" "}
                              {/* Displaying text "Age:" */}
                              {
                                tempData.userId.dob !== undefined &&
                                tempData.userId.dob !== null
                                  ? Math.floor(
                                      (new Date() -
                                        new Date(tempData.userId.dob * 1000)) /
                                        31536000000
                                    ) /* Calculating age based on date of birth */
                                  : "-" /* If date of birth is not available, display a dash */
                              }
                            </Typography>
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.gender")} :{" "}
                              {tempData.userId.gender !== undefined &&
                              tempData.userId.gender[0] !== undefined
                                ? tempData.userId.gender[0].toUpperCase() +
                                  tempData.userId.gender.slice(1)
                                : tempData.userId.gender}
                            </Typography>
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.dob")} :{" "}
                              {formatDate(tempData?.userId?.dob)}
                            </Typography>
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.mobNo")} :{" "}
                              {tempData.userId.mobileNo}{" "}
                            </Typography>
                          </Grid>
                          {/* ------------------------- */}
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {props.t("case.email")} : {tempData.userId.email}{" "}
                            </Typography>
                          </Grid>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.address1")} :{" "}
                              {tempData.userId.address1}{" "}
                            </Typography>
                          </Grid>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.address2")} :{" "}
                              {tempData.userId.address2}{" "}
                            </Typography>
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.city")} : {tempData.userId.city}{" "}
                            </Typography>
                          </Grid>
                          <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                            <Typography
                              gutterBottom
                              variant="p"
                              className={classes.customFontSize}
                              component="p"
                            >
                              {props.t("case.state")} : {tempData.userId.state}{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    {/* commenting below to implement autocomplete */}
                    {/* <TextField
                      id="name"
                      value={doctorForm.name}
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      label={props.t("doctor.name")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Dr. </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          name: event.target.value,
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    /> */}
                    <Autocomplete
                      id="userId"
                      options={doctorList}
                      getOptionLabel={(option) => option.name}
                      // onClose={() => setListTemplate([])}
                      onInput={(event) => {
                        getTableData(event.target.value);
                      }}
                      value={
                        (doctorForm?.name &&
                          doctorList?.find(
                            (item) =>
                              item?.name.trim() === doctorForm?.name.trim()
                          )) ||
                        doctorForm?.name ||
                        ""
                      }
                      onChange={(event, newValue) => {
                        setDoctorForm({
                          ...doctorForm,
                          name: newValue?.name,
                          hospital: newValue?.hospital,
                          contact: {
                            ...doctorForm?.contact,
                            officeNo: newValue?.contact?.officeNo,
                            mobileNo: newValue?.contact?.mobileNo,
                            email: newValue?.contact?.email,
                            fax: newValue?.contact?.fax,
                          },
                          contactPersonMobileNo: newValue?.contactPerson,
                          otherSpecialization: newValue?.otherSpecialization,
                          address: newValue?.address,
                          specialization: newValue?.specialization,
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Doctor Name"
                          variant="standard"
                          value={
                            (doctorForm?.name &&
                              doctorList?.find(
                                (item) =>
                                  item?.name.trim() === doctorForm?.name.trim()
                              )) ||
                            doctorForm?.name ||
                            ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="hospital"
                      value={doctorForm.hospital}
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: doctorForm.hospital !== "" ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      label={props.t("doctor.hospital")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          hospital: event.target.value,
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="contactOfficeNo"
                      value={doctorForm.contact.officeNo}
                      fullWidth
                      autoComplete="something-unsupported"
                      required
                      onBlur={handleOfficeNumber}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {isSearching ? <CircularProgress /> : <></>}
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(event) => {
                        return event.charCode >= 48 && event.charCode <= 57
                          ? event
                          : event.preventDefault();
                      }}
                      disabled={editableDoctorFields}
                      InputLabelProps={{
                        shrink:
                          doctorForm.contact.officeNo !== "" ? true : false,
                      }}
                      label={props.t("doctor.officeNo")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          contact: {
                            ...doctorForm.contact,
                            officeNo: event.target.value,
                          },
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>

                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="contactMobileNo"
                      value={doctorForm.contact.mobileNo}
                      fullWidth
                      InputLabelProps={{
                        shrink: doctorForm.contact.mobileNo ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      onKeyPress={(event) => {
                        return event.charCode >= 48 && event.charCode <= 57
                          ? event
                          : event.preventDefault();
                      }}
                      label={props.t("doctor.mobileNo")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          contact: {
                            ...doctorForm.contact,
                            mobileNo: event.target.value,
                          },
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="contactPersonName"
                      value={doctorForm.contactPersonName}
                      fullWidth
                      InputLabelProps={{
                        shrink: doctorForm.contactPersonName ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      label={props.t("doctor.contactPersonName")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          contactPersonName: event.target.value,
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>

                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="contactPersonMobileNo"
                      value={doctorForm.contactPersonMobileNo}
                      fullWidth
                      InputLabelProps={{
                        shrink: doctorForm.contactPersonMobileNo ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      label={props.t("doctor.contactPersonMobileNo")}
                      onKeyPress={(event) => {
                        return event.charCode >= 48 && event.charCode <= 57
                          ? event
                          : event.preventDefault();
                      }}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          contactPersonMobileNo: event.target.value,
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>

                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="contactEmail"
                      value={doctorForm.contact.email}
                      fullWidth
                      InputLabelProps={{
                        shrink: doctorForm.contact.email ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      label={props.t("doctor.email")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          contact: {
                            ...doctorForm.contact,
                            email: event.target.value,
                          },
                        });

                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="contactFax"
                      value={doctorForm.contact.fax}
                      fullWidth
                      InputLabelProps={{
                        shrink: doctorForm.contact.fax ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      autoComplete="something-unsupported"
                      label={props.t("doctor.fax")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          contact: {
                            ...doctorForm.contact,
                            fax: event.target.value,
                          },
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>

                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="specialization"
                      select
                      label={props.t("doctor.specialization")}
                      fullWidth
                      InputLabelProps={{
                        shrink: doctorForm.specialization ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      defaultValue={doctorForm.specialization}
                      value={doctorForm.specialization}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          specialization: event.target.value,
                          otherSpecialization: "",
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    >
                      {specializationOptions.map((options, index) => (
                        <MenuItem key={index} value={options.value}>
                          {options.display}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {doctorForm.specialization === "Other" ? (
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className={classes.doctorForm}
                    >
                      <TextField
                        id="otherSpecialization"
                        value={doctorForm.otherSpecialization}
                        fullWidth
                        InputLabelProps={{
                          shrink: doctorForm.otherSpecialization ? true : false,
                        }}
                        required
                        disabled={editableDoctorFields}
                        autoComplete="something-unsupported"
                        label={props.t("doctor.otherSpecialization")}
                        onChange={(event) => {
                          setDoctorForm({
                            ...doctorForm,
                            otherSpecialization: event.target.value,
                          });
                          let add = addForm;
                          delete add.doctor;
                          setAddForm(add);
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.doctorForm}
                  >
                    <TextField
                      id="address"
                      value={doctorForm.address}
                      fullWidth
                      autoComplete="something-unsupported"
                      required
                      InputLabelProps={{
                        shrink: doctorForm.address ? true : false,
                      }}
                      disabled={editableDoctorFields}
                      label={props.t("doctor.address")}
                      onChange={(event) => {
                        setDoctorForm({
                          ...doctorForm,
                          address: event.target.value,
                        });
                        let add = addForm;
                        delete add.doctor;
                        setAddForm(add);
                      }}
                    />
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      id="diagnosis"
                      label={props.t("case.diagnosis")}
                      autoComplete="something-unsupported"
                      autoComplete="off"
                      value={addForm.diagnosis}
                      fullWidth
                      multiline
                      onChange={(event) =>
                        setAddForm({
                          ...addForm,
                          diagnosis: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.formGrid}
                  >
                    <TextField
                      id="doctorInstructions"
                      label={props.t("case.doctorInstructions")}
                      autoComplete="something-unsupported"
                      multiline
                      autoComplete="off"
                      value={addForm.doctorInstructions}
                      fullWidth
                      onChange={(event) =>
                        setAddForm({
                          ...addForm,
                          doctorInstructions: event.target.value,
                        })
                      }
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                    />
                  </Grid>
                </TabPanel>
                {/* <TabPanel value={tabValue} index={1}>
                  <Grid container> */}
                {/* <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "10px" }}
                    >
                      <Grid container>
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            variant="p"
                            className={classes.customFontSize}
                            component="p"
                          >
                            {props.t("case.caseId")} : {addForm.caseId}
                          </Typography>
                        </Grid>

                        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            id="dynamicUrl"
                            label={props.t("case.dynamicUrl")}
                            value={tempData.caseDynamicUrl}
                            fullWidth
                            className={classes.customFontSize}
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={(event) => {
                                      let copyText =
                                        document.getElementById("dynamicUrl");

                                      copyText.select();
                                      copyText.setSelectionRange(0, 99999);

                                      document.execCommand("copy");
                                    }}
                                  >
                                    <FileCopyIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                        >
                          <TextField
                            id="active"
                            select
                            label={props.t("doctor.caseActiveStatus")}
                            fullWidth
                            // InputLabelProps={{
                            //   shrink: addForm.acti? true : false,
                            // }}
                            defaultValue={addForm.active}
                            value={addForm.active}
                            onChange={(event) => {
                              setAddForm({
                                ...addForm,
                                active: event.target.value,
                              });
                              setStatusCheck(true);
                            }}
                          >
                            <MenuItem key={0} value={true}>
                              {props.t("doctor.active")}
                            </MenuItem>
                            <MenuItem key={1} value={false}>
                              {props.t("doctor.retire")}
                            </MenuItem>
                          </TextField>
                        </Grid>

                        <Hidden only={["md", "sm", "xs"]}>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                          ></Grid>
                        </Hidden>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                        >
                          <TextField
                            id="caseType"
                            select
                            label={props.t("case.caseType")}
                            fullWidth
                            // InputLabelProps={{
                            //   shrink: addForm.acti? true : false,
                            // }}
                            defaultValue={addForm.caseType}
                            value={addForm.caseType}
                            onChange={(event) => {
                              setAddForm({
                                ...addForm,
                                caseType: event.target.value,
                              });
                            }}
                          >
                            <MenuItem key={0} value={"Prescription"}>
                              {props.t("case.prescription")}
                            </MenuItem>
                            <MenuItem key={1} value={"Lab Result"}>
                              {props.t("case.labResult")}
                            </MenuItem>
                          </TextField>
                        </Grid>

                        <Hidden only={["md", "sm", "xs"]}>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                          ></Grid>
                        </Hidden>

                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                          style={{ marginTop: "-6px" }}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                              <KeyboardDatePicker
                                animateYearScrolling={false}
                                // disableFuture={true}
                                variant="dialog"
                                required
                                fullWidth
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="prescriptionDate"
                                label={props.t("case.dateOfPrescription")}
                                value={addForm.prescriptionDate}
                                autoOk={true}
                                onChange={(date) => {
                                  addForm.medicineList.map((medicines) => {
                                    if (date > medicines.startDate) {
                                      SnackbarUtils.sucess(
                                        props.t("case.medicineDatesCheck"),
                                        "bottomCenter",
                                        3000
                                      ).then((notification) => {
                                        props.publishNotification(notification);
                                      });
                                    }
                                  });
                                  setAddForm({
                                    ...addForm,
                                    prescriptionDate: date,
                                  });
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                        >
                          <Grid container>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ textAlign: "left" }}
                            >
                              {props.t("case.bloodPressure")}
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="bloodPressure"
                                label={props.t("case.high")}
                                value={
                                  addForm.bloodPressure !== undefined &&
                                  addForm.bloodPressure !== null
                                    ? addForm.bloodPressure.high
                                    : ""
                                }
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    bloodPressure: {
                                      ...addForm.bloodPressure,
                                      high: event.target.value,
                                    },
                                  })
                                }
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="bloodPressurelow"
                                label={props.t("case.low")}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                value={
                                  addForm.bloodPressure !== undefined &&
                                  addForm.bloodPressure !== null
                                    ? addForm.bloodPressure.low
                                    : ""
                                }
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    bloodPressure: {
                                      ...addForm.bloodPressure,
                                      low: event.target.value,
                                    },
                                  })
                                }
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>

                            <Grid
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id="weight"
                                label={props.t("case.weight")}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                onKeyPress={(event) => {
                                  return event.charCode >= 48 &&
                                    event.charCode <= 57
                                    ? event
                                    : event.preventDefault();
                                }}
                                value={addForm.weight}
                                fullWidth
                                onChange={(event) =>
                                  setAddForm({
                                    ...addForm,
                                    weight: event.target.value,
                                  })
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {props.t("user.Kg")}
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> */}
                {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        gutterBottom
                        variant='p'
                        className={classes.customFontSize}
                        component='p'
                      >
                        {props.t('case.patientCondition')} :{addForm.diagnosis}
                      </Typography>
                    </Grid> */}
                {/* </Grid>
                </TabPanel> */}

                {/* <TabPanel value={tabValue} index={2}>
                  <Grid container>
                    <Grid xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
                    <Grid container> */}
                {/* -------------------- Personal------------------------- */}
                {/* <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.doctorForm}
                      >
                        <TextField
                          id="name"
                          value={doctorForm.name}
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={editableDoctorFields}
                          autoComplete="something-unsupported"
                          label={props.t("doctor.name")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Dr.{" "}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            setDoctorForm({
                              ...doctorForm,
                              name: event.target.value,
                            });
                            let add = addForm;
                            delete add.doctor;
                            setAddForm(add);
                          }}
                        />
                      </Grid>
                      <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.doctorForm}
                      >
                        <TextField
                          id="hospital"
                          value={doctorForm.hospital}
                          fullWidth
                          required
                          InputLabelProps={{
                            shrink: doctorForm.hospital !== "" ? true : false,
                          }}
                          disabled={editableDoctorFields}
                          autoComplete="something-unsupported"
                          label={props.t("doctor.hospital")}
                          onChange={(event) => {
                            setDoctorForm({
                              ...doctorForm,
                              hospital: event.target.value,
                            });
                            let add = addForm;
                            delete add.doctor;
                            setAddForm(add);
                          }}
                        />
                      </Grid>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className={classes.doctorForm}
                      >
                        <TextField
                          id="contactOfficeNo"
                          value={doctorForm.contact.officeNo}
                          fullWidth
                          autoComplete="something-unsupported"
                          required
                          onBlur={handleOfficeNumber}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {isSearching ? <CircularProgress /> : <></>}
                              </InputAdornment>
                            ),
                          }}
                          onKeyPress={(event) => {
                            return event.charCode >= 48 && event.charCode <= 57
                              ? event
                              : event.preventDefault();
                          }}
                          disabled={editableDoctorFields}
                          InputLabelProps={{
                            shrink:
                              doctorForm.contact.officeNo !== "" ? true : false,
                          }}
                          label={props.t("doctor.officeNo")}
                          onChange={(event) => {
                            setDoctorForm({
                              ...doctorForm,
                              contact: {
                                ...doctorForm.contact,
                                officeNo: event.target.value,
                              },
                            });
                            let add = addForm;
                            delete add.doctor;
                            setAddForm(add);
                          }}
                        />
                      </Grid>

                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className={classes.doctorForm}
                      >
                        <TextField
                          id="contactMobileNo"
                          value={doctorForm.contact.mobileNo}
                          fullWidth
                          InputLabelProps={{
                            shrink: doctorForm.contact.mobileNo ? true : false,
                          }}
                          disabled={editableDoctorFields}
                          autoComplete="something-unsupported"
                          onKeyPress={(event) => {
                            return event.charCode >= 48 && event.charCode <= 57
                              ? event
                              : event.preventDefault();
                          }}
                          label={props.t("doctor.mobileNo")}
                          onChange={(event) => {
                            setDoctorForm({
                              ...doctorForm,
                              contact: {
                                ...doctorForm.contact,
                                mobileNo: event.target.value,
                              },
                            });
                            let add = addForm;
                            delete add.doctor;
                            setAddForm(add);
                          }}
                        />
                      </Grid> */}
                {/* </Grid>
                  </Grid>
                </TabPanel> */}

                {/* <TabPanel value={tabValue} index={1}>
                  <Grid container>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id="pharmaInstructions"
                        label={props.t("case.pharmaInstructions")}
                        autoComplete="something-unsupported"
                        multiline
                        value={addForm.pharmaInstructions}
                        fullWidth
                        onChange={(event) =>
                          setAddForm({
                            ...addForm,
                            pharmaInstructions: event.target.value,
                          })
                        }
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.formGrid}
                    >
                      <TextField
                        id="remark"
                        label={props.t("case.remark")}
                        autoComplete="something-unsupported"
                        multiline
                        value={addForm.remark}
                        fullWidth
                        onChange={(event) =>
                          setAddForm({
                            ...addForm,
                            remark: event.target.value,
                          })
                        }
                        disabled={
                          props.formDialog.divType === "new" ||
                          props.formDialog.divType === "edit"
                            ? false
                            : true
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      align="right"
                    >
                      <Button
                        onClick={() => {
                          handleNotification();
                        }}
                      >
                        {props.t("case.sendNotification")}
                      </Button>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Notification
                        getNotifications={getCaseBasedNotifications}
                        notificationLists={notificationsList}
                      ></Notification>
                    </Grid>
                  </Grid>
                </TabPanel> */}

                <TabPanel value={tabValue} index={1}>
                  <Grid container>
                    <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Autocomplete
                        value={tempMedLabel}
                        onClose={() => setInventoryOptionList([])}
                        onChange={handleInventoryListOption}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (
                            params.inputValue !== "" &&
                            params.inputValue.length >
                              LocalStorage.searchableStringLimit
                          ) {
                            filtered.push({
                              inputValue: params.inputValue,
                              label: `"${params.inputValue}" ${props.t(
                                "case.testMedicineSearchNotFound"
                              )}`,
                            });
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        noOptionsText={props.t(
                          "case.noOptionsTextforMedicineList"
                        )}
                        clearOnBlur
                        handleHomeEndKeys
                        onInputChange={getInventorList}
                        id="medicineList"
                        options={inventoryListOptions}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.label;
                        }}
                        renderOption={(option) => option.label}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={props.t("case.medicineList")}
                            variant="standard"
                          />
                        )}
                      />

                      {/* <Autocomplete
                        id='medicineList'
                        options={inventoryListOptions}
                        onClose={() => setInventoryOptionList([])}
                        value={tempMedLabel}
                        getOptionLabel={(option) => option.label}
                        onInputChange={getInventorList}
                        onChange={handleInventoryListOption}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={props.t('case.medicineList')}
                            variant='standard'
                          />
                        )}
                      /> */}
                    </Grid>

                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "20px" }}
                    >
                      <Grid container>
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                        >
                          <TextField
                            id="itemName"
                            label={props.t("case.itemName")}
                            autoComplete="something-unsupported"
                            required
                            value={tempMedicineList.itemName}
                            fullWidth
                            onChange={(event) => {
                              setTempMedicineList({
                                ...tempMedicineList,
                                itemName: event.target.value,
                              });
                              if (tempMedicineList.itemId !== "") {
                                setTempMedicineList({
                                  ...tempMedicineList,
                                  itemId: "",
                                });
                              }
                            }}
                            disabled={
                              props.formDialog.divType === "new" ||
                              props.formDialog.divType === "edit"
                                ? false
                                : true
                            }
                            // disabled={true}
                          />
                        </Grid>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}

                          // className={classes.formGrid}
                        >
                          <TextField
                            id="form"
                            className={classes.userTypeMenuItem}
                            select
                            label={props.t("case.form")}
                            required
                            disabled={
                              props.formDialog.divType === "new" ||
                              props.formDialog.divType === "edit"
                                ? false
                                : true
                            }
                            fullWidth
                            value={tempMedicineList.form}
                            defaultValue={tempMedicineList.form}
                            onChange={(event) =>
                              setTempMedicineList({
                                ...tempMedicineList,
                                form: event.target.value,
                              })
                            }
                          >
                            {medicineFormOptions.map((options, index) => (
                              <MenuItem key={index} value={options.value}>
                                {props.t(
                                  `case.${options.display.toLowerCase()}`
                                )}
                              </MenuItem>
                            ))}
                            {/* <MenuItem key={0} value={'Tablet'}>
                              {props.t('case.tablet')}
                            </MenuItem>
                            <MenuItem key={1} value={'Capsule'}>
                              {props.t('case.capsule')}
                            </MenuItem>
                            <MenuItem key={2} value={'Syrup'}>
                              {props.t('case.syrup')}
                            </MenuItem>
                            <MenuItem key={3} value={'Injection'}>
                              {props.t('case.injection')}
                            </MenuItem> */}
                          </TextField>
                        </Grid>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          className={classes.formGrid}
                        >
                          <TextField
                            id="strength"
                            label={props.t("case.strength")}
                            autoComplete="something-unsupported"
                            autoComplete="off"
                            // required
                            value={tempMedicineList.strength}
                            fullWidth
                            onKeyPress={(event) => {
                              return (event.charCode >= 48 &&
                                event.charCode <= 57) ||
                                (event.charCode >= 65 &&
                                  event.charCode <= 90) ||
                                (event.charCode >= 97 &&
                                  event.charCode <= 122) ||
                                event.charCode === 32 ||
                                event.charCode === 46
                                ? event
                                : event.preventDefault();
                            }}
                            onChange={(event) =>
                              setTempMedicineList({
                                ...tempMedicineList,
                                strength: event.target.value,
                              })
                            }
                            disabled={
                              props.formDialog.divType === "new" ||
                              props.formDialog.divType === "edit"
                                ? false
                                : true
                            }
                            // disabled={true}
                          />
                        </Grid>
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                        >
                          <TextField
                            id="overview"
                            label={props.t("case.overview")}
                            autoComplete="something-unsupported"
                            autoComplete="off"
                            required
                            value={tempMedicineList.overview}
                            fullWidth
                            onKeyPress={(event) => {
                              return (event.charCode >= 48 &&
                                event.charCode <= 57) ||
                                (event.charCode >= 65 &&
                                  event.charCode <= 90) ||
                                (event.charCode >= 97 &&
                                  event.charCode <= 122) ||
                                event.charCode === 32 ||
                                event.charCode === 46
                                ? event
                                : event.preventDefault();
                            }}
                            onChange={(event) =>
                              setTempMedicineList({
                                ...tempMedicineList,
                                overview: event.target.value,
                              })
                            }
                            disabled={
                              props.formDialog.divType === "new" ||
                              props.formDialog.divType === "edit"
                                ? false
                                : true
                            }
                            // disabled={true}
                          />
                        </Grid>
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "20px" }}
                        >
                          <Autocomplete
                            value={tempMedicineList.forMedicalConditions}
                            onInputChange={getMedicalConditions}
                            multiple
                            onClose={() => setMedicalConditionsOptions([])}
                            onChange={(event, newValue) =>
                              handleTempMedicalConditions(event, newValue)
                            }
                            noOptionsText={props.t(
                              "case.noOptionsTextforMedicalConditions"
                            )}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (
                                params.inputValue !== undefined &&
                                params.inputValue !== null
                              ) {
                                if (params.inputValue !== "") {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    display: `"${params.inputValue}" ${props.t(
                                      "case.testMedicalConditionsNotFound"
                                    )}`,
                                  });
                                }
                                return filtered;
                              }
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="tempForMedicalConditions"
                            options={medicalConditionsOptions}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.display;
                            }}
                            renderOption={(option) => option.display}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label={props.t("case.medicalConditions")}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          lg={4}
                          md={4}
                          sm={6}
                          xs={6}
                          className={classes.formGrid}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                              <KeyboardDatePicker
                                animateYearScrolling={false}
                                // disableFuture={true}
                                variant="dialog"
                                required
                                fullWidth
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="startDate"
                                label={props.t("case.medStartDate")}
                                value={tempMedicineList.startDate}
                                autoOk={true}
                                onChange={(date) => {
                                  if (date > tempMedicineList.endDate) {
                                    return Toaster.error(
                                      props.t("toaster.startDateLesser"),
                                      "topRight"
                                    );
                                  }
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    startDate: date,
                                    // frequencyDates: [],
                                  });
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          xl={4}
                          lg={4}
                          md={4}
                          sm={6}
                          xs={6}
                          className={classes.formGrid}
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container>
                              <KeyboardDatePicker
                                animateYearScrolling={false}
                                // disableFuture={true}
                                variant="dialog"
                                required
                                fullWidth
                                disabled={
                                  props.formDialog.divType === "new" ||
                                  props.formDialog.divType === "edit"
                                    ? false
                                    : true
                                }
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="endDate"
                                label={props.t("case.medEndDate")}
                                value={tempMedicineList.endDate}
                                autoOk={true}
                                onChange={(date) => {
                                  if (date < tempMedicineList.startDate) {
                                    return Toaster.error(
                                      props.t("toaster.endDateLesser"),
                                      "topRight"
                                    );
                                  }
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    endDate: date,
                                    // frequencyDates: [],
                                  });
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className={classes.formGrid}
                          style={{ marginTop: "18px" }}
                        >
                          <TextField
                            id="frequency"
                            className={classes.userTypeMenuItem}
                            select
                            label={props.t("case.frequency")}
                            required
                            disabled={
                              props.formDialog.divType === "new" ||
                              props.formDialog.divType === "edit"
                                ? false
                                : true
                            }
                            fullWidth
                            value={tempMedicineList.frequency}
                            defaultValue={tempMedicineList.frequency}
                            onChange={(event) => {
                              if (event.target.value === "Custom Schedule") {
                                setOpenTempFreqDates(true);
                              }
                              setTempMedicineList({
                                ...tempMedicineList,
                                frequency: event.target.value,
                              });
                            }}
                          >
                            {frequencyOptions.map((options, key) => (
                              <MenuItem key={key} value={options.display}>
                                {options.display}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        {tempMedicineList.frequency === "Custom Schedule" ? (
                          <>
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                              {/* <Grid xl={6} lg={6} md={8} sm={6} xs={6}>
                                  {props.t('case.frequencyDates')}
                                </Grid> */}

                              <Button
                                onClick={() => setOpenTempFreqDates(true)}
                              >
                                {props.t("case.setViewCustomSchedule")}
                              </Button>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                        {/*    <Grid
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <DayPicker
                                fixedWeeks
                                disabledDays={{
                                  before: tempMedicineList.startDate,
                                  after: tempMedicineList.endDate,
                                }}
                                selectedDays={tempMedicineList.frequencyDates}
                                // selectedDays={[
                                //   ...tempMedicineList.frequencyDates,
                                //   {
                                //     after: tempMedicineList.startDate,
                                //     before: tempMedicineList.endDate,
                                //   },
                                // ]}
                                onDayClick={handleTempMedicineDayClick}
                              />
                            </Grid>
                          </>
                        */}
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid container>
                            <Grid
                              xl={4}
                              lg={4}
                              md={4}
                              sm={5}
                              xs={5}
                              className={classes.formGrid}
                              style={{ textAlign: "left" }}
                            >
                              {props.t("case.timeOfDay")}

                              <span style={{ color: "red" }}>*</span>
                            </Grid>
                            <Grid
                              xl={6}
                              lg={6}
                              md={6}
                              sm={5}
                              xs={5}
                              className={classes.formGrid}
                              style={{ textAlign: "left" }}
                            >
                              {props.t("case.whenToTake")}
                              <span style={{ color: "red" }}>*</span>
                            </Grid>
                            <Grid
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              className={classes.formGrid}
                              style={{ textAlign: "left" }}
                            >
                              {props.t("case.itemQuantity")}

                              <span style={{ color: "red" }}>*</span>
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          tempMedicineList.whenToTake.Morning
                                        }
                                        onChange={handleTempMedicineCheckChange}
                                        name="Morning"
                                      />
                                    }
                                    label="Morning"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                              <FormControlLabel
                                value="Before"
                                onChange={(event) =>
                                  handleRadioChange(event, "Morning")
                                }
                                disabled={!tempMedicineList.whenToTake.Morning}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Morning ===
                                      "Before"
                                    }
                                  />
                                }
                                label="Before"
                              />
                              <FormControlLabel
                                onChange={(event) =>
                                  handleRadioChange(event, "Morning")
                                }
                                disabled={!tempMedicineList.whenToTake.Morning}
                                value="With"
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Morning === "With"
                                    }
                                  />
                                }
                                label="With"
                              />
                              <FormControlLabel
                                value="After"
                                onChange={(event) =>
                                  handleRadioChange(event, "Morning")
                                }
                                disabled={!tempMedicineList.whenToTake.Morning}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Morning ===
                                      "After"
                                    }
                                  />
                                }
                                label="After"
                              />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="morningQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                disabled={!tempMedicineList.whenToTake.Morning}
                                value={tempMedicineList.intakeQuantity.Morning}
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      Morning: event.target.value,
                                    },
                                  });
                                }}
                                fullWidth
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          tempMedicineList.whenToTake.Afternoon
                                        }
                                        onChange={handleTempMedicineCheckChange}
                                        name="Afternoon"
                                      />
                                    }
                                    label="Afternoon"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                              <FormControlLabel
                                value="Before"
                                onChange={(event) =>
                                  handleRadioChange(event, "Afternoon")
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.Afternoon
                                }
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Afternoon ===
                                      "Before"
                                    }
                                  />
                                }
                                label="Before"
                              />
                              <FormControlLabel
                                onChange={(event) =>
                                  handleRadioChange(event, "Afternoon")
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.Afternoon
                                }
                                value="With"
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Afternoon ===
                                      "With"
                                    }
                                  />
                                }
                                label="With"
                              />
                              <FormControlLabel
                                value="After"
                                onChange={(event) =>
                                  handleRadioChange(event, "Afternoon")
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.Afternoon
                                }
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Afternoon ===
                                      "After"
                                    }
                                  />
                                }
                                label="After"
                              />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="afternoonQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                value={
                                  tempMedicineList.intakeQuantity.Afternoon
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.Afternoon
                                }
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      Afternoon: event.target.value,
                                    },
                                  });
                                }}
                                fullWidth
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                              />
                            </Grid>

                            <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          tempMedicineList.whenToTake.Evening
                                        }
                                        onChange={handleTempMedicineCheckChange}
                                        name="Evening"
                                      />
                                    }
                                    label="Evening"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                              <FormControlLabel
                                value="Before"
                                onChange={(event) =>
                                  handleRadioChange(event, "Evening")
                                }
                                disabled={!tempMedicineList.whenToTake.Evening}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Evening ===
                                      "Before"
                                    }
                                  />
                                }
                                label="Before"
                              />
                              <FormControlLabel
                                onChange={(event) =>
                                  handleRadioChange(event, "Evening")
                                }
                                disabled={!tempMedicineList.whenToTake.Evening}
                                value="With"
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Evening === "With"
                                    }
                                  />
                                }
                                label="With"
                              />
                              <FormControlLabel
                                value="After"
                                onChange={(event) =>
                                  handleRadioChange(event, "Evening")
                                }
                                disabled={!tempMedicineList.whenToTake.Evening}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Evening ===
                                      "After"
                                    }
                                  />
                                }
                                label="After"
                              />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="eveningQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                value={tempMedicineList.intakeQuantity.Evening}
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      Evening: event.target.value,
                                    },
                                  });
                                }}
                                disabled={!tempMedicineList.whenToTake.Evening}
                                fullWidth
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                              />
                            </Grid>

                            <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          tempMedicineList.whenToTake.Night
                                        }
                                        onChange={handleTempMedicineCheckChange}
                                        name="Night"
                                      />
                                    }
                                    label="Night"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                              <FormControlLabel
                                value="Before"
                                onChange={(event) =>
                                  handleRadioChange(event, "Night")
                                }
                                disabled={!tempMedicineList.whenToTake.Night}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Night === "Before"
                                    }
                                  />
                                }
                                label="Before"
                              />
                              <FormControlLabel
                                onChange={(event) =>
                                  handleRadioChange(event, "Night")
                                }
                                disabled={!tempMedicineList.whenToTake.Night}
                                value="With"
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Night === "With"
                                    }
                                  />
                                }
                                label="With"
                              />
                              <FormControlLabel
                                value="After"
                                onChange={(event) =>
                                  handleRadioChange(event, "Night")
                                }
                                disabled={!tempMedicineList.whenToTake.Night}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Night === "After"
                                    }
                                  />
                                }
                                label="After"
                              />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="nightQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                disabled={!tempMedicineList.whenToTake.Night}
                                value={tempMedicineList.intakeQuantity.Night}
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      Night: event.target.value,
                                    },
                                  });
                                }}
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                              />
                            </Grid>

                            {/* ------------------------other medicine-------------------- */}
                            <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          tempMedicineList.whenToTake.OtherTime
                                        }
                                        onChange={handleTempMedicineCheckChange}
                                        name="OtherTime"
                                      />
                                    }
                                    label="Other"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                              <FormControlLabel
                                value="Before"
                                onChange={(event) =>
                                  handleRadioChange(event, "Other")
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.OtherTime
                                }
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Other === "Before"
                                    }
                                  />
                                }
                                label="Before"
                              />
                              <FormControlLabel
                                onChange={(event) =>
                                  handleRadioChange(event, "Other")
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.OtherTime
                                }
                                value="With"
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Other === "With"
                                    }
                                  />
                                }
                                label="With"
                              />
                              <FormControlLabel
                                value="After"
                                onChange={(event) =>
                                  handleRadioChange(event, "Other")
                                }
                                disabled={
                                  !tempMedicineList.whenToTake.OtherTime
                                }
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Other === "After"
                                    }
                                  />
                                }
                                label="After"
                              />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="nightQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                disabled={
                                  !tempMedicineList.whenToTake.OtherTime
                                }
                                value={
                                  tempMedicineList.intakeQuantity.OtherTime
                                }
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      OtherTime: event.target.value,
                                    },
                                  });
                                }}
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "-20px",
                                }}
                              >
                                <p style={{ marginLeft: "32px" }}>Time</p>
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={2}
                                  xs={2}
                                  style={{ marginTop: "10px" }}
                                >
                                  <TextField
                                    id="nightQuantity"
                                    type="time"
                                    disabled={
                                      !tempMedicineList.whenToTake.OtherTime
                                    }
                                    value={tempMedicineList.intake.OtherTime}
                                    onChange={(event) => {
                                      setTempMedicineList({
                                        ...tempMedicineList,
                                        intake: {
                                          ...tempMedicineList.intake,
                                          OtherTime: event.target.value,
                                        },
                                      });
                                    }}
                                    onKeyPress={(event) => {
                                      return (event.charCode >= 48 &&
                                        event.charCode <= 57) ||
                                        (event.charCode >= 65 &&
                                          event.charCode <= 90) ||
                                        (event.charCode >= 97 &&
                                          event.charCode <= 122) ||
                                        event.charCode === 32 ||
                                        event.charCode === 46
                                        ? event
                                        : event.preventDefault();
                                    }}
                                    fullWidth
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  />
                                </Grid>
                              </div>
                              {/* <TextField
                                id="nightQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                disabled={
                                  !tempMedicineList.whenToTake.OtherTime
                                }
                                value={
                                  tempMedicineList.intakeQuantity.OtherTime
                                }
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      OtherTime: event.target.value,
                                    },
                                  });
                                }}
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                              /> */}
                            </Grid>

                            {/* ------------------------SOS-------------------- */}
                            <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          tempMedicineList.whenToTake.sos
                                        }
                                        // onChange={handleTempMedicineCheckChange}
                                        onChange={(event) =>
                                          setTempMedicineList({
                                            ...tempMedicineList,
                                            whenToTake: {
                                              ...tempMedicineList.whenToTake,
                                              sos: event.target.checked,
                                            },
                                          })
                                        }
                                        name="sos"
                                      />
                                    }
                                    label="SOS"
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={5}
                              xs={5}
                            ></Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="sosQuantity"
                                value={
                                  tempMedicineList.intakeQuantity.sosQuantity
                                }
                                disabled={!tempMedicineList.whenToTake.sos}
                                onChange={(event) =>
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      sosQuantity: event.target.value,
                                    },
                                  })
                                }
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                                style={{
                                  padding: "0px",
                                  margin: "0px",
                                }}
                              />
                            </Grid>
                            {/* <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                              <FormControlLabel
                                value="Before"
                                onChange={(event) =>
                                  handleRadioChange(event, "Night")
                                }
                                disabled={!tempMedicineList.whenToTake.Night}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Night === "Before"
                                    }
                                  />
                                }
                                label="Before"
                              />
                              <FormControlLabel
                                onChange={(event) =>
                                  handleRadioChange(event, "Night")
                                }
                                disabled={!tempMedicineList.whenToTake.Night}
                                value="With"
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Night === "With"
                                    }
                                  />
                                }
                                label="With"
                              />
                              <FormControlLabel
                                value="After"
                                onChange={(event) =>
                                  handleRadioChange(event, "Night")
                                }
                                disabled={!tempMedicineList.whenToTake.Night}
                                control={
                                  <Radio
                                    checked={
                                      tempMedicineList.intake.Night === "After"
                                    }
                                  />
                                }
                                label="After"
                              />
                            </Grid> */}
                            {/* <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <TextField
                                id="nightQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                disabled={!tempMedicineList.whenToTake.Night}
                                value={tempMedicineList.intakeQuantity.Night}
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      Night: event.target.value,
                                    },
                                  });
                                }}
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                              />
                            </Grid> */}
                          </Grid>
                        </Grid>

                        {/* <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "20px" }}
                        >
                          <Autocomplete
                            value={tempMedicineList.forMedicalConditions}
                            onInputChange={getMedicalConditions}
                            multiple
                            onClose={() => setMedicalConditionsOptions([])}
                            onChange={(event, newValue) =>
                              handleTempMedicalConditions(event, newValue)
                            }
                            noOptionsText={props.t(
                              "case.noOptionsTextforMedicalConditions"
                            )}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (
                                params.inputValue !== undefined &&
                                params.inputValue !== null
                              ) {
                                if (params.inputValue !== "") {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    display: `"${params.inputValue}" ${props.t(
                                      "case.testMedicalConditionsNotFound"
                                    )}`,
                                  });
                                }
                                return filtered;
                              }
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="tempForMedicalConditions"
                            options={medicalConditionsOptions}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.display;
                            }}
                            renderOption={(option) => option.display}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label={props.t("case.medicalConditions")}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid> */}
                        {/* <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: '20px' }}
                        >
                          <Grid container>
                            <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                              <TextField
                                id='newMedCond'
                                value={newMedCond}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                autoComplete='something-unsupported'
                                label={props.t('case.newMedCond')}
                                onChange={(event) => {
                                  setNewMedCond(event.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                              <Button
                                onClick={() => {
                                  addNewMedCondtions();
                                }}
                              >
                                {props.t('buttons.add')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid> */}

                        {/* Commenting price list */}
                        {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                          {LocalStorage.adminButtonPermission.find(function (
                            element
                          ) {
                            return element.button === 'price' ? true : false;
                          }) ? (
                            tempMedicineList.priceList !== undefined ? (
                              tempMedicineList.priceList.map(
                                (pricelists, idx) => (
                                  <Grid
                                    container
                                    key={idx}
                                    style={{ marginTop: '10px' }}
                                  >
                                    <Grid
                                      xl={4}
                                      lg={4}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className={classes.formGrid}
                                    >
                                      <TextField
                                        id='provider'
                                        label={props.t('case.provider')}
                                        autoComplete='something-unsupported'
                                        required
                                        value={pricelists.provider}
                                        fullWidth
                                        // onChange={(event) =>
                                        //   handleMedicineProductListChange(
                                        //     event,
                                        //     index,
                                        //     idx,
                                        //     'provider'
                                        //   )
                                        // }
                                        disabled={true}
                                        // disabled={true}
                                      />
                                    </Grid>
                                    <Grid
                                      xl={4}
                                      lg={4}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className={classes.formGrid}
                                    >
                                      <TextField
                                        id='price'
                                        label={props.t('case.price')}
                                        autoComplete='something-unsupported'
                                        required
                                        value={pricelists.price}
                                        fullWidth
                                        onChange={(event) =>
                                          handleTempMedicineProductListChange(
                                            event,
                                            idx,
                                            'price'
                                          )
                                        }
                                        disabled={
                                          props.formDialog.divType === 'new' ||
                                          props.formDialog.divType === 'edit'
                                            ? false
                                            : true
                                        }
                                        // disabled={true}
                                      />
                                    </Grid>
                                    <Grid
                                      xl={4}
                                      lg={4}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className={classes.formGrid}
                                    >
                                      <TextField
                                        id='discount'
                                        label={props.t('case.discount')}
                                        autoComplete='something-unsupported'
                                        required
                                        value={pricelists.discount}
                                        fullWidth
                                        onChange={(event) =>
                                          handleTempMedicineProductListChange(
                                            event,
                                            idx,
                                            'discount'
                                          )
                                        }
                                        disabled={
                                          props.formDialog.divType === 'new' ||
                                          props.formDialog.divType === 'edit'
                                            ? false
                                            : true
                                        }
                                        // disabled={true}
                                      />
                                    </Grid>
                                  </Grid>
                                )
                              )
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </Grid> */}
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ textAlign: "center", marginTop: "10px" }}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              onClick={handleMedicineListPush}
                              disabled={loading}
                            >
                              {props.t("case.addMedicine")}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                {/* ------------------------This is map for medicine list---------------- */}
                {addForm.medicineList.map((medicineLists, index) => (
                  <TabPanel value={tabValue} index={index + 2}>
                    {console.log(medicineLists, "from hetre")}
                    <Grid container>
                      <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          id="itemName"
                          label={props.t("case.itemName")}
                          autoComplete="off"
                          autoComplete="something-unsupported"
                          required
                          onChange={(event) =>
                            handleMedicineListChange(event, index, "itemName")
                          }
                          value={medicineLists.itemName}
                          fullWidth
                          disabled={true}
                        />
                      </Grid>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        // className={classes.formGrid}
                      >
                        <TextField
                          id="form"
                          className={classes.userTypeMenuItem}
                          select
                          label={props.t("case.form")}
                          required
                          disabled={true}
                          fullWidth
                          value={medicineLists.form}
                          defaultValue={medicineLists.form}
                          onChange={(event) =>
                            handleMedicineListChange(event, index, "form")
                          }
                        >
                          {medicineFormOptions.map((options, index) => (
                            <MenuItem key={index} value={options.value}>
                              {/* {options.display} */}

                              {props.t(`case.${options.display.toLowerCase()}`)}
                            </MenuItem>
                          ))}
                          {/* <MenuItem key={0} value={'Tablet'}>
                            {props.t('case.tablet')}
                          </MenuItem>
                          <MenuItem key={1} value={'Capsule'}>
                            {props.t('case.capsule')}
                          </MenuItem>
                          <MenuItem key={1} value={'Syrup'}>
                            {props.t('case.syrup')}
                          </MenuItem>
                          <MenuItem key={1} value={'Injection'}>
                            {props.t('case.injection')}
                          </MenuItem> */}
                        </TextField>
                      </Grid>

                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className={classes.formGrid}
                      >
                        <TextField
                          id="strength"
                          disabled={true}
                          label={props.t("case.strength")}
                          autoComplete="something-unsupported"
                          autoComplete="off"
                          // required
                          value={medicineLists.strength}
                          onKeyPress={(event) => {
                            return (event.charCode >= 48 &&
                              event.charCode <= 57) ||
                              (event.charCode >= 65 && event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              event.charCode === 32 ||
                              event.charCode === 46
                              ? event
                              : event.preventDefault();
                          }}
                          fullWidth
                          onChange={(event) =>
                            handleMedicineListChange(event, index, "strength")
                          }

                          // disabled={true}
                        />
                      </Grid>
                      <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.formGrid}
                      >
                        <TextField
                          id={index + "overview"}
                          disabled={true}
                          label={props.t("case.overview")}
                          autoComplete="something-unsupported"
                          autoComplete="off"
                          // required
                          value={medicineLists.overview}
                          onKeyPress={(event) => {
                            return (event.charCode >= 48 &&
                              event.charCode <= 57) ||
                              (event.charCode >= 65 && event.charCode <= 90) ||
                              (event.charCode >= 97 && event.charCode <= 122) ||
                              event.charCode === 32 ||
                              event.charCode === 46
                              ? event
                              : event.preventDefault();
                          }}
                          fullWidth
                          onChange={(event) =>
                            handleMedicineListChange(event, index, "overview")
                          }

                          // disabled={true}
                        />
                      </Grid>

                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className={classes.formGrid}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container>
                            <KeyboardDatePicker
                              animateYearScrolling={false}
                              // disableFuture={true}
                              variant="dialog"
                              required
                              fullWidth
                              disabled={
                                props.formDialog.divType === "new" ||
                                props.formDialog.divType === "edit"
                                  ? false
                                  : true
                              }
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="startDate"
                              label={props.t("case.medStartDate")}
                              value={medicineLists.startDate}
                              autoOk={true}
                              onChange={(date) =>
                                handleMedicineListStartDateChange(date, index)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className={classes.formGrid}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container>
                            <KeyboardDatePicker
                              animateYearScrolling={false}
                              // disableFuture={true}
                              variant="dialog"
                              required
                              fullWidth
                              disabled={
                                props.formDialog.divType === "new" ||
                                props.formDialog.divType === "edit"
                                  ? false
                                  : true
                              }
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="endDate"
                              label={props.t("case.medEndDate")}
                              value={medicineLists.endDate}
                              autoOk={true}
                              onChange={(date) =>
                                handleMedicineListEndDateChange(date, index)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        className={classes.formGrid}
                        style={{ marginTop: "18px" }}
                      >
                        <TextField
                          id="frequency"
                          className={classes.userTypeMenuItem}
                          select
                          label={props.t("case.frequency")}
                          required
                          disabled={
                            props.formDialog.divType === "new" ||
                            props.formDialog.divType === "edit"
                              ? false
                              : true
                          }
                          fullWidth
                          defaultValue={medicineLists.frequency}
                          value={medicineLists.frequency}
                          onChange={(event) =>
                            handleMedicineListFrequencyChange(
                              event,
                              index,
                              "frequency"
                            )
                          }
                        >
                          {frequencyOptions.map((options, key) => (
                            <MenuItem key={key} value={options.display}>
                              {options.display}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Autocomplete
                          value={medicineLists.forMedicalConditions}
                          onInputChange={getMedicalConditions}
                          multiple
                          onChange={(event, newValue) =>
                            handleMedicineMultipleAutoCompleteListChange(
                              event,
                              newValue,
                              index
                            )
                          }
                          noOptionsText={props.t(
                            "case.noOptionsTextforMedicalConditions"
                          )}
                          onClose={() => setMedicalConditionsOptions([])}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            if (
                              params.inputValue !== undefined &&
                              params.inputValue !== null
                            ) {
                              if (params.inputValue !== "") {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  display: `"${params.inputValue}" ${props.t(
                                    "case.testMedicalConditionsNotFound"
                                  )}`,
                                });
                              }
                              return filtered;
                            }
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="medicineConditions"
                          options={medicalConditionsOptions}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.display;
                          }}
                          renderOption={(option) => option.display}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={props.t("case.medicalConditions")}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>

                      {addForm.medicineList[index].frequency ===
                      "Custom Schedule" ? (
                        <>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            {/* <Grid xl={6} lg={6} md={8} sm={6} xs={6}>
                                  {props.t('case.frequencyDates')}
                                </Grid> */}

                            <Button onClick={() => setOpenMedFreqDates(true)}>
                              {props.t("case.setViewCustomSchedule")}
                            </Button>
                          </Grid>
                          <Grid
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.formGrid}
                          >
                            <SlideInDialog
                              title={props.t("case.frequencyDates")}
                              contentComponent={
                                <DayPicker
                                  fixedWeeks
                                  // numberOfMonths={2}
                                  numberOfMonths={2}
                                  pagedNavigation
                                  disabledDays={{
                                    before:
                                      addForm.medicineList[index].startDate,
                                    after: addForm.medicineList[index].endDate,
                                  }}
                                  selectedDays={medicineLists.frequencyDates}
                                  onDayClick={(day) => {
                                    handleMedicineDayClick(
                                      day,
                                      medicineLists.frequencyDates,
                                      index
                                    );
                                  }}
                                />
                              }
                              fullWidth={true}
                              maxWidth={"sm"}
                              dialogActionsStatus={"ok"}
                              slideInDialog={openMedFreqDates}
                              setSlideInDialog={handleButtonResponse}
                              handleButtonResponse={handleButtonResponse}
                            />

                            {/* <DayPicker
                              style={{ width: 'max-content' }}
                              // numberOfMonths={2}
                              // numberOfMonths={2}
                              // pagedNavigation
                              fixedWeeks
                              disabledDays={{
                                before: addForm.medicineList[index].startDate,
                                after: addForm.medicineList[index].endDate,
                              }}
                              selectedDays={medicineLists.frequencyDates}
                              onDayClick={(day) =>
                                handleMedicineDayClick(
                                  day,
                                  medicineLists.frequencyDates,
                                  index
                                )
                              }
                            /> */}
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container>
                              <Grid
                                xl={4}
                                lg={4}
                                md={4}
                                sm={5}
                                xs={5}
                                className={classes.formGrid}
                                style={{ textAlign: "left" }}
                              >
                                {props.t("case.timeOfDay")}

                                <span style={{ color: "red" }}>*</span>
                              </Grid>
                              <Grid
                                xl={6}
                                lg={6}
                                md={6}
                                sm={5}
                                xs={5}
                                className={classes.formGrid}
                                style={{ textAlign: "left" }}
                              >
                                {props.t("case.whenToTake")}

                                <span style={{ color: "red" }}>*</span>
                              </Grid>

                              <Grid
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                                className={classes.formGrid}
                                style={{ textAlign: "left" }}
                              >
                                {props.t("case.itemQuantity")}

                                <span style={{ color: "red" }}>*</span>
                              </Grid>
                              <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <FormControl
                                  component="fieldset"
                                  className={classes.formControl}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            medicineLists.whenToTake.Morning
                                          }
                                          onChange={(event) =>
                                            handleMedicineCheckChange(
                                              event,
                                              index
                                            )
                                          }
                                          name="Morning"
                                        />
                                      }
                                      label="Morning"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                                <FormControlLabel
                                  value="Before"
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Morning"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Morning
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Morning ===
                                        "Before"
                                      }
                                    />
                                  }
                                  label="Before"
                                />
                                <FormControlLabel
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Morning"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Morning
                                  }
                                  value="With"
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Morning === "With"
                                      }
                                    />
                                  }
                                  label="With"
                                />
                                <FormControlLabel
                                  value="After"
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Morning"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Morning
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Morning === "After"
                                      }
                                    />
                                  }
                                  label="After"
                                />
                              </Grid>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="morningQuantity"
                                  // label={props.t('case.provider')}
                                  autoComplete="something-unsupported"
                                  autoComplete="off"
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Morning
                                  }
                                  value={medicineLists.intakeQuantity.Morning}
                                  onChange={(event) =>
                                    handleMedicineIntakeQuantityChange(
                                      event,
                                      index,
                                      "Morning"
                                    )
                                  }
                                  fullWidth
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                />
                              </Grid>

                              <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <FormControl
                                  component="fieldset"
                                  className={classes.formControl}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            medicineLists.whenToTake.Afternoon
                                          }
                                          onChange={(event) =>
                                            handleMedicineCheckChange(
                                              event,
                                              index
                                            )
                                          }
                                          name="Afternoon"
                                        />
                                      }
                                      label="Afternoon"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                                <FormControlLabel
                                  value="Before"
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Afternoon"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Afternoon
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Afternoon ===
                                        "Before"
                                      }
                                    />
                                  }
                                  label="Before"
                                />
                                <FormControlLabel
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Afternoon"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Afternoon
                                  }
                                  value="With"
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Afternoon ===
                                        "With"
                                      }
                                    />
                                  }
                                  label="With"
                                />
                                <FormControlLabel
                                  value="After"
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Afternoon"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Afternoon
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Afternoon ===
                                        "After"
                                      }
                                    />
                                  }
                                  label="After"
                                />
                              </Grid>

                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="afternoonQuantity"
                                  // label={props.t('case.provider')}
                                  autoComplete="something-unsupported"
                                  autoComplete="off"
                                  value={medicineLists.intakeQuantity.Afternoon}
                                  onChange={(event) =>
                                    handleMedicineIntakeQuantityChange(
                                      event,
                                      index,
                                      "Afternoon"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Afternoon
                                  }
                                  fullWidth
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                />
                              </Grid>
                              <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <FormControl
                                  component="fieldset"
                                  className={classes.formControl}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            medicineLists.whenToTake.Evening
                                          }
                                          onChange={(event) =>
                                            handleMedicineCheckChange(
                                              event,
                                              index
                                            )
                                          }
                                          name="Evening"
                                        />
                                      }
                                      label="Evening"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                                <FormControlLabel
                                  value="Before"
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Evening"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Evening
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Evening ===
                                        "Before"
                                      }
                                    />
                                  }
                                  label="Before"
                                />
                                <FormControlLabel
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Evening"
                                    )
                                  }
                                  value="With"
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Evening === "With"
                                      }
                                    />
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Evening
                                  }
                                  label="With"
                                />
                                <FormControlLabel
                                  value="After"
                                  onChange={(event) =>
                                    handleMedicineChange(
                                      event,
                                      index,
                                      "Evening"
                                    )
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Evening === "After"
                                      }
                                    />
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Evening
                                  }
                                  label="After"
                                />
                              </Grid>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="eveningQuantity"
                                  // label={props.t('case.provider')}
                                  autoComplete="something-unsupported"
                                  autoComplete="off"
                                  value={medicineLists.intakeQuantity.Evening}
                                  onChange={(event) =>
                                    handleMedicineIntakeQuantityChange(
                                      event,
                                      index,
                                      "Evening"
                                    )
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Evening
                                  }
                                  fullWidth
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                />
                              </Grid>
                              {/* {console.log("when added new", medicineLists)} */}
                              <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <FormControl
                                  component="fieldset"
                                  className={classes.formControl}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            medicineLists.whenToTake.Night
                                          }
                                          onChange={(event) =>
                                            handleMedicineCheckChange(
                                              event,
                                              index
                                            )
                                          }
                                          name="Night"
                                        />
                                      }
                                      label="Night"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                                <FormControlLabel
                                  value="Before"
                                  onChange={(event) =>
                                    handleMedicineChange(event, index, "Night")
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Night
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Night === "Before"
                                      }
                                    />
                                  }
                                  label="Before"
                                />
                                <FormControlLabel
                                  onChange={(event) =>
                                    handleMedicineChange(event, index, "Night")
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Night
                                  }
                                  value="With"
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Night === "With"
                                      }
                                    />
                                  }
                                  label="With"
                                />
                                <FormControlLabel
                                  value="After"
                                  onChange={(event) =>
                                    handleMedicineChange(event, index, "Night")
                                  }
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Night
                                  }
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Night === "After"
                                      }
                                    />
                                  }
                                  label="After"
                                />
                              </Grid>

                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="nightQuantity"
                                  disabled={
                                    !addForm.medicineList[index].whenToTake
                                      .Night
                                  }
                                  // label={props.t('case.provider')}
                                  autoComplete="something-unsupported"
                                  autoComplete="off"
                                  value={medicineLists.intakeQuantity.Night}
                                  onChange={(event) =>
                                    handleMedicineIntakeQuantityChange(
                                      event,
                                      index,
                                      "Night"
                                    )
                                  }
                                  fullWidth
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                />
                              </Grid>

                              {/* ---------------------------------added new map---------------------------------------- */}

                              {/* below code I am commenting out for adding new code for Other Time */}

                              {/* <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                  >
                                  
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              medicineLists.whenToTake.OtherTime
                                            }
                                          
                                            onChange={(event) =>
                                              handleMedicineCheckChange(
                                                event,
                                                index
                                              )
                                            }
                                            name="OtherTime"
                                          />
                                        }
                                        label="Other"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                  <Grid item xl={6} lg={6} md={6} sm={2} xs={2}>
                                    <TextField
                                      id="nightQuantity"
                                      // label={props.t('case.provider')}
                                      autoComplete="something-unsupported"
                                      autoComplete="off"
                                      disabled={
                                        !medicineLists.whenToTake.OtherTime
                                      }
                                      value={medicineLists.intake.Other}
                                      onChange={(event) =>
                                        handleMedicineChange(
                                          event,
                                          index,
                                          "Other"
                                        )
                                      }
                                      onKeyPress={(event) => {
                                        return (event.charCode >= 48 &&
                                          event.charCode <= 57) ||
                                          (event.charCode >= 65 &&
                                            event.charCode <= 90) ||
                                          (event.charCode >= 97 &&
                                            event.charCode <= 122) ||
                                          event.charCode === 32 ||
                                          event.charCode === 46
                                          ? event
                                          : event.preventDefault();
                                      }}
                                      fullWidth
                                    />
                                  </Grid>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "-20px",
                                  }}
                                >
                                  <p style={{ marginLeft: "32px" }}>Time</p>
                                  <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={2}
                                    xs={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <TextField
                                      id="nightQuantity"
                                      // label={props.t('case.provider')}
                                      autoComplete="something-unsupported"
                                      autoComplete="off"
                                      disabled={
                                        !medicineLists.whenToTake.OtherTime
                                      }
                                      value={medicineLists.intake.OtherTime}
                                   
                                      onChange={(event) =>
                                        handleMedicineChange(
                                          event,
                                          index,
                                          "OtherTime"
                                        )
                                      }
                                      onKeyPress={(event) => {
                                        return (event.charCode >= 48 &&
                                          event.charCode <= 57) ||
                                          (event.charCode >= 65 &&
                                            event.charCode <= 90) ||
                                          (event.charCode >= 97 &&
                                            event.charCode <= 122) ||
                                          event.charCode === 32 ||
                                          event.charCode === 46
                                          ? event
                                          : event.preventDefault();
                                      }}
                                      fullWidth
                                      style={{
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                  </Grid>
                                </div>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                               
                              </Grid>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="nightQuantity"
                                  // label={props.t('case.provider')}
                                  autoComplete="something-unsupported"
                                  autoComplete="off"
                                  disabled={!medicineLists.whenToTake.OtherTime}
                                  value={medicineLists.intakeQuantity.OtherTime}
                              
                                  onChange={(event) =>
                                    handleMedicineIntakeQuantityChange(
                                      event,
                                      index,
                                      "OtherTime"
                                    )
                                  }
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                  fullWidth
                                />
                              </Grid> */}

                              <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <FormControl
                                  component="fieldset"
                                  className={classes.formControl}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            medicineLists.whenToTake.OtherTime
                                          }
                                          onChange={(event) =>
                                            setAddForm({
                                              ...addForm,
                                              medicineList:
                                                addForm.medicineList.map(
                                                  (item, i) =>
                                                    i === index
                                                      ? {
                                                          ...item,
                                                          whenToTake: {
                                                            ...item.whenToTake,
                                                            OtherTime:
                                                              event.target
                                                                .checked,
                                                          },
                                                        }
                                                      : item
                                                ),
                                            })
                                          }
                                          name="OtherTime"
                                        />
                                      }
                                      label="Other"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xl={6} lg={6} md={6} sm={5} xs={5}>
                                <FormControlLabel
                                  value="Before"
                                  onChange={(event) =>
                                    setAddForm({
                                      ...addForm,
                                      medicineList: addForm.medicineList.map(
                                        (item, i) =>
                                          i === index
                                            ? {
                                                ...item,
                                                intake: {
                                                  ...item.intake,
                                                  Other: "Before",
                                                },
                                              }
                                            : item
                                      ),
                                    })
                                  }
                                  disabled={!medicineLists.whenToTake.OtherTime}
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Other === "Before"
                                      }
                                    />
                                  }
                                  label="Before"
                                />
                                <FormControlLabel
                                  onChange={(event) =>
                                    setAddForm({
                                      ...addForm,
                                      medicineList: addForm.medicineList.map(
                                        (item, i) =>
                                          i === index
                                            ? {
                                                ...item,
                                                intake: {
                                                  ...item.intake,
                                                  Other: "With",
                                                },
                                              }
                                            : item
                                      ),
                                    })
                                  }
                                  disabled={!medicineLists.whenToTake.OtherTime}
                                  value="With"
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Other === "With"
                                      }
                                    />
                                  }
                                  label="With"
                                />
                                <FormControlLabel
                                  value="After"
                                  onChange={(event) =>
                                    setAddForm({
                                      ...addForm,
                                      medicineList: addForm.medicineList.map(
                                        (item, i) =>
                                          i === index
                                            ? {
                                                ...item,
                                                intake: {
                                                  ...item.intake,
                                                  Other: "After",
                                                },
                                              }
                                            : item
                                      ),
                                    })
                                  }
                                  disabled={!medicineLists.whenToTake.OtherTime}
                                  control={
                                    <Radio
                                      checked={
                                        medicineLists.intake.Other === "After"
                                      }
                                    />
                                  }
                                  label="After"
                                />
                              </Grid>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="nightQuantity"
                                  // label={props.t('case.provider')}
                                  autoComplete="something-unsupported"
                                  disabled={!medicineLists.whenToTake.OtherTime}
                                  value={medicineLists.intakeQuantity.OtherTime}
                                  onChange={(event) =>
                                    handleMedicineIntakeQuantityChange(
                                      event,
                                      index,
                                      "OtherTime"
                                    )
                                  }
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "-20px",
                                  }}
                                >
                                  <p style={{ marginLeft: "32px" }}>Time</p>
                                  <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={2}
                                    xs={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <TextField
                                      id="OtherTime"
                                      type="time"
                                      value={medicineLists.intake.OtherTime}
                                      disabled={
                                        !medicineLists.whenToTake.OtherTime
                                      }
                                      onChange={(event) =>
                                        handleMedicineChange(
                                          event,
                                          index,
                                          "OtherTime"
                                        )
                                      }
                                      onKeyPress={(event) => {
                                        return (event.charCode >= 48 &&
                                          event.charCode <= 57) ||
                                          (event.charCode >= 65 &&
                                            event.charCode <= 90) ||
                                          (event.charCode >= 97 &&
                                            event.charCode <= 122) ||
                                          event.charCode === 32 ||
                                          event.charCode === 46
                                          ? event
                                          : event.preventDefault();
                                      }}
                                      fullWidth
                                      style={{
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                  </Grid>
                                </div>
                                {/* <TextField
                                id="nightQuantity"
                                // label={props.t('case.provider')}
                                autoComplete="something-unsupported"
                                autoComplete="off"
                                disabled={
                                  !tempMedicineList.whenToTake.OtherTime
                                }
                                value={
                                  tempMedicineList.intakeQuantity.OtherTime
                                }
                                onChange={(event) => {
                                  setTempMedicineList({
                                    ...tempMedicineList,
                                    intakeQuantity: {
                                      ...tempMedicineList.intakeQuantity,
                                      OtherTime: event.target.value,
                                    },
                                  });
                                }}
                                onKeyPress={(event) => {
                                  return (event.charCode >= 48 &&
                                    event.charCode <= 57) ||
                                    (event.charCode >= 65 &&
                                      event.charCode <= 90) ||
                                    (event.charCode >= 97 &&
                                      event.charCode <= 122) ||
                                    event.charCode === 32 ||
                                    event.charCode === 46
                                    ? event
                                    : event.preventDefault();
                                }}
                                fullWidth
                              /> */}
                              </Grid>

                              {/* ------------------------SOS-------------------- */}
                              <Grid item xl={4} lg={4} md={4} sm={5} xs={5}>
                                <FormControl
                                  component="fieldset"
                                  className={classes.formControl}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={medicineLists.whenToTake.sos}
                                          // onChange={handleTempMedicineCheckChange}
                                          onChange={(event) => {
                                            setAddForm({
                                              ...addForm,
                                              medicineList:
                                                addForm.medicineList.map(
                                                  (item, i) =>
                                                    i === index
                                                      ? {
                                                          ...item,
                                                          whenToTake: {
                                                            ...item.whenToTake,
                                                            sos: event.target
                                                              .checked,
                                                          },
                                                        }
                                                      : item
                                                ),
                                            });
                                          }}
                                          // onChange={(event) =>
                                          //   handleMedicineCheckChange(
                                          //     event,
                                          //     index
                                          //   )
                                          // }
                                          name="sos"
                                        />
                                      }
                                      label="SOS"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={6}
                                sm={5}
                                xs={5}
                              ></Grid>
                              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <TextField
                                  id="sosQuantity"
                                  value={
                                    medicineLists.intakeQuantity.sosQuantity
                                  }
                                  disabled={!medicineLists.whenToTake.sos}
                                  onChange={(event) =>
                                    setAddForm({
                                      ...addForm,
                                      medicineList: addForm.medicineList.map(
                                        (item, i) =>
                                          i === index
                                            ? {
                                                ...item,
                                                intakeQuantity: {
                                                  ...item.intakeQuantity,
                                                  sosQuantity:
                                                    event.target.value,
                                                },
                                              }
                                            : item
                                      ),
                                    })
                                  }
                                  onKeyPress={(event) => {
                                    return (event.charCode >= 48 &&
                                      event.charCode <= 57) ||
                                      (event.charCode >= 65 &&
                                        event.charCode <= 90) ||
                                      (event.charCode >= 97 &&
                                        event.charCode <= 122) ||
                                      event.charCode === 32 ||
                                      event.charCode === 46
                                      ? event
                                      : event.preventDefault();
                                  }}
                                  fullWidth
                                  style={{
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                />
                              </Grid>
                              {/* ---------------------------------added new---------------------------------------- */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        xs={12}
                        sm={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() => handleDeleteMedicine(medicineLists)}
                        >
                          {props.t("buttons.delete")}
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <SlideInDialog
        title={
          tempStoreDoctorData.length === 1
            ? props.t("case.foundADoctor")
            : props.t("case.foundSomeDoctors")
        }
        contentComponent={
          <SelectableDoctorList
            doctorList={tempStoreDoctorData}
            handleSelectedDoctor={handleSelectedDoctor}
            handleCancel={handleCancel}
          />
        }
        fullWidth={true}
        maxWidth={"md"}
        dialogActionsStatus={false}
        slideInDialog={doctorAlert}
        setSlideInDialog={handleSlideInDialog}
        // handleDeleteRequest={handleDeleteRequest}
      />

      <SlideInDialog
        title={slideAssignDialog.title}
        contentComponent={
          <SelectSendNotification
            page={"caseMedicine"}
            getCaseBasedNotifications={getCaseBasedNotifications}
            notificationList={props.notificationList}
            setNotificationList={props.setNotificationList}
            rowDetails={props.rowDetails}
            handleSlideInDialog={handleSlideInDialog}
            getNotificationTemplate={props.getNotificationTemplate}
          ></SelectSendNotification>
        }
        fullWidth={true}
        maxWidth={slideAssignDialog.divType === "assign" ? "sm" : "md"}
        dialogActionsStatus={false}
        slideInDialog={slideAssignDialog.status}
        setSlideInDialog={handleSlideInDialog}
      />

      <SlideInDialog
        title={props.t("case.retriement")}
        contentComponent={
          addForm.active === false
            ? props.t("case.confirmRetireCase")
            : props.t("case.confirmActivateCase")
        }
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={statusCheck}
        setSlideInDialog={handleStatusSlider}
        handleDeleteRequest={handleFormChange}
      />

      <SlideInDialog
        title={props.t("user.deleteRecord")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={medDeleteDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleMedDeleteRequest}
      />

      <SlideInDialog
        title={props.t("case.frequencyDates")}
        contentComponent={
          <DayPicker
            fixedWeeks
            // numberOfMonths={2}
            numberOfMonths={2}
            pagedNavigation
            disabledDays={{
              before: tempMedicineList.startDate,
              after: tempMedicineList.endDate,
            }}
            selectedDays={tempMedicineList.frequencyDates}
            onDayClick={handleTempMedicineDayClick}
          />
        }
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={"ok"}
        slideInDialog={openTempFreqDates}
        setSlideInDialog={handleButtonResponse}
        handleButtonResponse={handleButtonResponse}
      />
      <FileUpload
        ref={postAttachmentFileUpload}
        aws={aws}
        file={postAttachmentFile}
        base64={postAttachmentFileBase64}
        bucket={aws.prescriptionBucket}
        callback={_s3ImageUrlPostAttachment}
      />
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
