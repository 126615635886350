import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import StringUtils from '../../utils/StringUtils';

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.tableHeader.map((headCell) => (
          <TableCell style={{ minWidth: headCell.length * 15 }} key={headCell}>
            {StringUtils.JSONKeyToUI(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 400,
    minHeight: 400,
    overflowX: 'auto',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
    tableLayout: 'auto',
  },
}));

export default function NormalMuiTable(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-label='sticky table'
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              tableHeader={props.tableHeader}
            />
            <TableBody>
              {props.tableRecord.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                    {row.map((cellDetails, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        component='th'
                        id={labelId}
                        scope='row'
                        padding='16'
                        style={{
                          minWidth: 'auto',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '400px',
                        }}
                      >
                        {cellDetails !== undefined &&
                        cellDetails !== null &&
                        cellDetails !== ''
                          ? cellDetails
                          : '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
