import React, { Component } from 'react';
import Content from './Content';
import ThemeProvider from '../privateRouting/admin/themeProvider/ThemeProvider';
import reducer from '../privateRouting/admin/hook/reducer';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import { createStore } from 'redux';

const store = createStore(reducer);
export default class MobileWebViewPages extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider>
          <CssBaseline />
          <Content />
        </ThemeProvider>
      </Provider>
    );
  }
}
