import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles, fade } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Switch from "@material-ui/core/Switch";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Link from "@material-ui/core/Link";
import {
  Grid,
  Toolbar,
  Typography,
  Hidden,
  Popover,
  MenuItem,
  Button,
} from "@material-ui/core";
import XLSX from "xlsx";
import $ from "jquery";

import { connect } from "react-redux";
import SnackbarUtils from "../../utils/SnackbarUtils";
import APIRequest from "../../utils/APIRequest";
import ConfigAPIURL from "../../config/ConfigAPIURL";

import Checkbox from "@material-ui/core/Checkbox";
import StringUtils from "../../utils/StringUtils";
import SlideInDialog from "../dialog/SlideInDialog";
import UpdateMedicationDialog from "../dialog/UpdateMedicationDialog";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";
import ObjectUtils from "../../utils/ObjectUtils";
import { Route } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import LocalStorage from "../../config/LocalStorage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FilterListIcon from "@material-ui/icons/FilterList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import FormDialog from "../dialog/FormDialog";
import AEVForm from "../../components/privateRouting/admin/processCase/AEVForm";
import ClearAllIcon from "@material-ui/icons/ClearAll";
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectAll,
    tableHeader,
    translator,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeader.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.dataType === "string" || headCell.dataType === "date"
                ? "left"
                : headCell.dataType === "boolean"
                ? "center"
                : headCell.dataType === "number"
                ? "right"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              minWidth: headCell.width,
              display:
                headCell.visibility !== undefined &&
                headCell.visibility !== null &&
                headCell.visibility
                  ? ""
                  : "none",
            }}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell)}
              >
                {translator(headCell.label)}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{translator(headCell.label)}</span>
            )}
          </TableCell>
        ))}
        <TableCell style={{ whiteSpace: "pre" }}>
          {translator("case.caseId")}
        </TableCell>
        <TableCell style={{ whiteSpace: "pre" }}>
          {translator("user.quickEdit")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    overflow: "auto",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },

  typographyQueryTitle: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    alignSelf: "center",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "22.5ch",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorColumnsEl, setAnchorColumnsEl] = React.useState(null);
  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [tableMenuDropdown, setTableMenuDropdown] = React.useState(null);
  // useEffect(() => {
  // 	console.log(props.tableHeader);
  // }, [props.tableHeader]);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleViewColumnsClick = (event) => {
    setAnchorColumnsEl(event.currentTarget);
  };
  const handleTableMenuClick = (event) => {
    setTableMenuDropdown(event.currentTarget);
  };
  const handleTableMenuClose = () => {
    setTableMenuDropdown(null);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleViewColumnsClose = () => {
    setAnchorColumnsEl(null);
  };
  useEffect(() => {
    if (dataToDownload.length > 0) {
      var filename =
        StringUtils.JSONKeyToUI(window.location.href.split("/").pop()) +
        "_" +
        new Date().toDateString() +
        ".xlsx";
      var ws_name =
        StringUtils.JSONKeyToUI(window.location.href.split("/").pop()) +
        "_" +
        new Date().toDateString();
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(dataToDownload);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);
    }
  }, [dataToDownload]);

  const dynamicFunctionHandler = async (headerData, value) => {
    return import("../../utils/" + headerData.className)
      .then(async (file) => {
        return file.default[headerData.functionName](value);
      })
      .then((result, error) => {
        if (error === undefined) {
          return result;
        } else {
          return "";
        }
      });
  };
  const updateHeader = (index) => {
    let tableHeader = props.tableHeader;
    tableHeader[index]["visibility"] = !tableHeader[index]["visibility"];
    props.updateTableHeader(tableHeader);
  };
  // const downloadCSV = () => {
  //   let tableHeader = [];
  //   let tableBody = [];
  //   props.tableHeader.map((headerData) => {
  //     if (headerData.visibility)
  //       return tableHeader.push(props.translator(headerData.label));
  //   });
  //   props.tableData.rows.map(async (bodyDetails) => {
  //     let rowDetails = {};
  //     rowDetails = await Promise.all(
  //       props.tableHeader.map(async (headerData, headerIndex) => {
  //         if (headerData.visibility) {
  //           let value = ObjectUtils.dataTableBodyGenarater(
  //             bodyDetails,
  //             headerData
  //           );
  //           if (value !== undefined && value !== null && value !== '') {
  //             if (
  //               headerData !== 'boolean' &&
  //               headerData.className !== undefined &&
  //               headerData.className !== null &&
  //               headerData.className !== '' &&
  //               headerData.functionName !== undefined &&
  //               headerData.functionName !== null &&
  //               headerData.functionName !== ''
  //             ) {
  //               value = await dynamicFunctionHandler(headerData, value);
  //             }
  //           } else {
  //             value = '-';
  //           }
  //           return (rowDetails = {
  //             ...rowDetails,
  //             [props.translator(headerData.label)]: value,
  //           });
  //         }
  //       })
  //     );
  //     if (rowDetails.length >= tableHeader.length) {
  //       rowDetails.map((resultRowDetails) => {
  //         if (resultRowDetails !== undefined && resultRowDetails !== null) {
  //           if (Object.keys(resultRowDetails).length >= tableHeader.length) {
  //             return tableBody.push(resultRowDetails);
  //           }
  //           return null;
  //         } else {
  //           return null;
  //         }
  //       });
  //     }
  //   });
  //   setDataToDownload(tableBody);
  // };

  const downloadCSV = () => {
    let tableHeader = [];
    let tableBody = [];
    props.tableHeader.map((headerData) => {
      if (headerData.visibility)
        return tableHeader.push(props.translator(headerData.label));
    });
    props.tableData.rows.map(async (bodyDetails) => {
      let rowDetails = {};
      rowDetails = await Promise.all(
        props.tableHeader.map(async (headerData, headerIndex) => {
          if (headerData.visibility) {
            let value = ObjectUtils.dataTableBodyGenarater(
              bodyDetails,
              headerData
            );

            if (value !== undefined && value !== null && value !== "") {
              if (
                headerData !== "boolean" &&
                headerData.className !== undefined &&
                headerData.className !== null &&
                headerData.className !== "" &&
                headerData.functionName !== undefined &&
                headerData.functionName !== null &&
                headerData.functionName !== ""
              ) {
                value = await dynamicFunctionHandler(headerData, value);
              }
            } else {
              value = "-";
            }
            return (rowDetails = {
              ...rowDetails,
              [props.translator(headerData.label)]: value,
            });
          }
        })
      );
      if (rowDetails.length >= tableHeader.length) {
        rowDetails.map((resultRowDetails) => {
          if (resultRowDetails !== undefined && resultRowDetails !== null) {
            if (Object.keys(resultRowDetails).length >= tableHeader.length) {
              return tableBody.push(resultRowDetails);
            }
            return null;
          } else {
            return null;
          }
        });
      }
    });

    setDataToDownload(tableBody);
  };
  const open = Boolean(anchorEl);
  const openHeader = Boolean(anchorColumnsEl);
  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Toolbar className={classes.root}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          ></Typography>

          <Hidden only={["sm", "xs"]}>
            <Button
              onClick={props.handleOpenCustomerViewForm}
              style={{ width: "30%" }}
            >
              Customer View
            </Button>

            {props.buttonComponent}
            {LocalStorage.adminButtonPermission.find(function (element) {
              return element.button === "export" ? true : false;
            }) ? (
              <Tooltip title={props.translator("dataTable.downloadCSV")}>
                <IconButton
                  aria-label="download csv"
                  onClick={downloadCSV}
                  disabled={
                    props.tableData.rows === null ||
                    props.tableData.rows.length === 0
                      ? true
                      : false
                  }
                >
                  <CloudDownloadIcon variant="contained" />
                </IconButton>
              </Tooltip>
            ) : null}

            <Tooltip title={props.translator("dataTable.viewColumns")}>
              <IconButton
                aria-label="view columns"
                onClick={handleViewColumnsClick}
              >
                <ViewColumnIcon
                  aria-describedby={"view_header"}
                  variant="contained"
                />
              </IconButton>
            </Tooltip>
            {LocalStorage.adminButtonPermission.find(function (element) {
              return element.button === "export" ? true : false;
            }) ? (
              <Tooltip title={props.translator("dataTable.filterList")}>
                <IconButton
                  aria-label="filter list"
                  onClick={handleFilterClick}
                >
                  <FilterListIcon
                    aria-describedby={"query_Menu"}
                    variant="contained"
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </Hidden>

          <Popover
            style={{ width: "min-content" }}
            id={"query_Menu"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            className={classes.popover}
          >
            <Grid container>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                align="left"
                style={{ alignSelf: "center" }}
              >
                <Typography className={classes.typographyQueryTitle}>
                  {props.translator("dataTable.filter")}
                </Typography>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={6} align="right">
                <Tooltip ml={2} title={props.translator("dataTable.clearAll")}>
                  <IconButton onClick={() => props.resetQuery()}>
                    <ClearAllIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {props.queryComponent}
          </Popover>
          <Popover
            style={{ width: "min-content" }}
            id={"view_header"}
            open={openHeader}
            anchorEl={anchorColumnsEl}
            onClose={handleViewColumnsClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            className={classes.popover}
          >
            {props.tableHeader.map((headerData, headerIndex) => (
              <MenuItem
                onClick={() => updateHeader(headerIndex)}
                key={headerIndex}
              >
                <Checkbox color="secondry" checked={headerData.visibility} />
                <Typography variant="inherit" noWrap>
                  {props.translator(headerData.label)}
                </Typography>
              </MenuItem>
            ))}
          </Popover>
        </Toolbar>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Hidden only={["xl", "lg", "md"]}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {props.buttonComponent}
            {LocalStorage.adminButtonPermission.find(function (element) {
              return element.button === "export" ? true : false;
            }) ? (
              <Tooltip title={props.translator("dataTable.downloadCSV")}>
                <IconButton
                  aria-label="download csv"
                  onClick={downloadCSV}
                  disabled={
                    props.tableData.rows === null ||
                    props.tableData.rows.length === 0
                      ? true
                      : false
                  }
                >
                  <CloudDownloadIcon variant="contained" />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title={props.translator("dataTable.viewColumns")}>
              <IconButton
                aria-label="view columns"
                onClick={handleViewColumnsClick}
              >
                <ViewColumnIcon
                  aria-describedby={"view_header"}
                  variant="contained"
                />
              </IconButton>
            </Tooltip>
            {LocalStorage.adminButtonPermission.find(function (element) {
              return element.button === "export" ? true : false;
            }) ? (
              <Tooltip title={props.translator("dataTable.filterList")}>
                <IconButton
                  aria-label="filter list"
                  onClick={handleFilterClick}
                >
                  <FilterListIcon
                    aria-describedby={"query_Menu"}
                    variant="contained"
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        </Hidden>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: { minHeight: "calc(69vh)", maxHeight: "calc(69vh)" },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
    overflow: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  typography: {
    padding: theme.spacing(2),
  },

  tableRow:
    theme.palette.type === "light"
      ? {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: theme.palette.info.light,
          },
        }
      : {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: theme.palette.info.dark,
          },
        },
}));
export const TableBodyContext = React.createContext({});
function TableBodyContextHandler({ children }) {
  const [value, setValue] = React.useState("-");
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    import("../../utils/" + children.props.cellDetails.className).then(
      (file) => {
        let value = file.default[children.props.cellDetails.functionName](
          children.props.value
        );
        setValue(value);
        setIsLoading(false);
      }
    );
  }, [children]);

  return (
    <TableBodyContext.Provider value={{ value, isLoading }}>
      {children}
    </TableBodyContext.Provider>
  );
}
const TableBodyFunctionCall = () => {
  const { value, isLoading } = useContext(TableBodyContext);
  return <Route render={() => (!isLoading ? value : "-")} />;
};
const TableBodyGenarater = (props) => {
  let value = ObjectUtils.dataTableBodyGenarater(props.row, props.cellDetails);
  if (value !== undefined && value !== null && value !== "") {
    if (props.cellDetails.dataType === "boolean") {
      return (
        <Switch
          checked={!value}
          name="checkedA"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      );
    } else if (
      props.cellDetails.dataType !== "boolean" &&
      props.cellDetails.className !== undefined &&
      props.cellDetails.className !== null &&
      props.cellDetails.className !== "" &&
      props.cellDetails.functionName !== undefined &&
      props.cellDetails.functionName !== null &&
      props.cellDetails.functionName !== ""
    ) {
      return (
        <TableBodyContextHandler>
          <TableBodyFunctionCall
            cellDetails={props.cellDetails}
            value={value}
          />
        </TableBodyContextHandler>
      );
    }
  } else {
    value = "-";
  }

  return value;
};
function MedicationTableWithPagination(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [tableHeader, setTableHeader] = React.useState(props.tableHeader);
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const [selectedRowData, setSelectedData] = useState({});
  const [selectedRowDataIndex, setSelectedDataIndex] = useState(0);

  const [openMedicineEdit, setOpenMedicineEdit] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const [notificationList, setNotificationList] = useState([]);

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });
  const [slideAssignDialog, setSlideAssignDialog] = useState({
    status: false,
    title: "",
    divType: "",
  });

  // const tableEdit = (rowDetails) => {
  //   if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
  //     setOpenForm({
  //       status: true,
  //       title: props.t('case.editProcessCase'),
  //       divType: 'edit',
  //       functionName: '',
  //     });
  //   } else {
  //     SnackbarUtils.error(
  //       props.t('snackbar.selectRow'),
  //       'bottomCenter',
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //   }
  // };
  const getNotificationTemplate = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.getNotificationTemplate + "?notificationType=notice",
      ""
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          let serverResponse = response.data.result;

          serverResponse.map((results) => {
            results["visibleOn"] = {
              app: false,
              home: false,
              sms: false,
              whatsApp: false,
              email: false,
            };
          });

          setNotificationList(serverResponse);

          // let array = new Array(response.data.result.length).fill(false);

          // setNotificationRadio(array);
        }
      }
    });
  };

  const handleFormDialog = () => {
    setRowDetails(null);
    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
  };

  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

  var lastTouch = -1;
  const handleMedicationEditOption = (rowData) => {
    setOpenMedicineEdit(true);
    setSelectedData(rowData);

    // setSelectedDataIndex(childIndex);
  };

  useEffect(() => {
    if (props.openForm !== undefined && props.openForm !== null) {
      if (!props.openForm.status) {
        setSelected([]);
      }
    }
  }, [props.openForm]);
  useEffect(() => {
    setTableHeader([...props.tableHeader]);
  }, [props.tableHeader]);

  $("table").bind("touchend", function (event) {
    var now = new Date().getTime();
    var lastTouchCal =
      lastTouch ||
      now + 1; /** the first time this will make delta a negative number */
    var delta = now - lastTouchCal;
    if (delta < 500 && delta > 0) {
      props.tableDBLCallBack(JSON.parse(event.target.id));
    } else {
      lastTouch = now;
    }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property.id && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property.id);
    props.setQuery({
      ...props.query,
      sortField: property.accessor,
      sortOrder: isAsc ? "true" : "false",
    });
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.tableData.rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  let timer = 0;
  let delay = 200;
  let prevent = false;
  const handleClick = (event, name, rowDetails) => {
    timer = setTimeout(function () {
      if (!prevent) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (props.selectAll) {
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1)
            );
          }
        } else {
          if (selected[0] !== name) {
            newSelected[0] = name;
          }
          if (newSelected.length > 0) {
            props.tableCallBack(rowDetails);
          } else {
            props.tableCallBack(null);
          }
        }

        setSelected(newSelected);
      }
      prevent = false;
    }, delay);
  };

  const handleDBLClick = (event, name, rowDetails) => {
    props.tableDBLCallBack(rowDetails);
  };

  const handleChangePage = (event, newPage) => {
    props.setQuery({ ...props.query, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    props.setQuery({
      ...props.query,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    });
  };
  const _cancel = () => {
    setOpenMedicineEdit(false);
    // props.getMedicalInfo();
    // setMedicationData(unEditableTableData);
    // setFormData(editableTableData);
  };
  const [medicineName, setMedicineName] = React.useState("");
  const getMedicationData = (caseId, medicationId) => {
    APIRequest.request(
      "POST",
      ConfigAPIURL.adminCaseMongoId,
      JSON.stringify({
        caseId: caseId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setRowDetails([response?.data?.result?._id]);
          setMedicineName(medicationId);
          setOpenForm({
            status: true,
            title: props.t("case.editProcessCase"),
            divType: "edit",
            functionName: "",
          });
        } else {
          SnackbarUtils.error(
            props.t("snackbar.recordNotFound"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      } else {
        SnackbarUtils.error(
          props.t("snackbar.recordNotFound"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    });
  };

  const handleSlideInDialog = () => {
    setOpenMedicineEdit(false);
    setSlideAssignDialog({ status: false, title: "", divType: "" });

    // tableQuery(query);
  };

  const isSelected = (name) =>
    selected !== undefined &&
    selected !== null &&
    selected.length > 0 &&
    selected.indexOf(name) !== -1;

  const emptyRows = props.tableData.rows;
  const updateTableHeader = (headerDetails) => {
    setTableHeader([...headerDetails]);
  };
  return (
    <Paper className={classes.paper}>
      <EnhancedTableToolbar
        translator={props.t}
        numSelected={selected.length}
        queryComponent={props.queryComponent}
        buttonComponent={props.buttonComponent}
        query={props.query}
        resetQuery={props.resetQuery}
        searchLabel={
          props.searchLabel !== undefined ? props.searchLabel : "Search"
        }
        setQuery={props.setQuery}
        tableData={props.tableData}
        tableHeader={tableHeader}
        updateTableHeader={updateTableHeader}
        handleOpenCustomerViewForm={props.handleOpenCustomerViewForm}
      />
      <TableContainer className={classes.container}>
        <Table
          size={dense ? "small" : "medium"}
          aria-label="sticky table"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={
              props.tableData.rows !== null ? props.tableData.rows.length : 0
            }
            selectAll={props.selectAll}
            tableHeader={tableHeader}
            translator={props.t}
          />
          <TableBody>
            {emptyRows === null ? (
              <TableRow>
                <TableCell colSpan={tableHeader.length + 1} align={"center"}>
                  <CircularProgress></CircularProgress>
                </TableCell>
              </TableRow>
            ) : emptyRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={tableHeader.length + 1} align={"center"}>
                  No Record Found
                </TableCell>
              </TableRow>
            ) : (
              props.tableData.rows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={() => alert('click')}
                    // onClick={(event) => handleClick(event, row._id, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    {/* <TableCell padding='checkbox'>
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell> */}
                    {tableHeader.map((cellDetails, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        align={
                          cellDetails.dataType === "string" ||
                          cellDetails.dataType === "date"
                            ? "left"
                            : cellDetails.dataType === "boolean"
                            ? "center"
                            : cellDetails.dataType === "number"
                            ? "right"
                            : "left"
                        }
                        style={{
                          minWidth: cellDetails.width,
                          display:
                            cellDetails.visibility !== undefined &&
                            cellDetails.visibility !== null &&
                            cellDetails.visibility
                              ? ""
                              : "none",
                        }}
                      >
                        <TableBodyGenarater
                          row={row}
                          cellDetails={cellDetails}
                        />
                      </TableCell>
                    ))}

                    <TableCell style={{ whiteSpace: "pre" }}>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          getMedicationData(row.caseId, row.itemName);
                        }}
                      >
                        {row.caseId}
                      </Link>

                      {/* <Link
                        target='_blank'
                        href={
                          '#/admin/cases?caseId=' +
                          row.caseId +
                          '&medicine=' +
                          row.itemName
                        }
                      >
                        {row.caseId}
                      </Link> */}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "pre" }}>
                      <Tooltip title={props.t("dataTable.edit")}>
                        <IconButton
                          aria-label="reject"
                          onClick={() => handleMedicationEditOption(row)}
                        >
                          <EditIcon variant="contained" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={LocalStorage.dataTablePageSize}
        component="div"
        count={props.tableData.filterRecords}
        rowsPerPage={props.query.pageSize}
        page={props.query.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {openMedicineEdit ? (
        <SlideInDialog
          title={null}
          contentComponent={
            <UpdateMedicationDialog
              openForm={openMedicineEdit}
              handleTableData={props.handleTableData}
              selectedRowData={selectedRowData}
              selectedRowDataIndex={selectedRowDataIndex}
              handleCancel={_cancel}
            />
          }
          fullWidth={true}
          maxWidth={"md"}
          dialogActionsStatus={false}
          slideInDialog={openMedicineEdit}
          setSlideInDialog={handleSlideInDialog}
          // handleDeleteRequest={handleDeleteRequest}
        />
      ) : (
        <></>
      )}

      <FormDialog
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          <AEVForm
            medicineName={medicineName}
            notificationList={notificationList}
            slideAssignDialog={slideAssignDialog}
            setNotificationList={setNotificationList}
            handleSlideInDialog={handleSlideInDialog}
            getNotificationTemplate={getNotificationTemplate}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={props.handleTableData}
          />
        }
      />
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(MedicationTableWithPagination)
);
