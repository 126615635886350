import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  Box,
  Typography,
  CardContent,
  Button,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditSession from "./EditSessionComponent/EditSession";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import SmsIcon from "@material-ui/icons/Sms";
import EmailIcon from "@material-ui/icons/Email";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import APIRequest from "../../../../../utils/APIRequest";
import DateConversion from "../../../../../utils/DateConversion";
import { Link } from "react-router-dom";
import FormDialog from "../../../../../templates/dialog/FormDialog";
import SessionDialog from "../../../../../templates/dialog/SessionDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  chatReceiverMessages: {
    width: "80%",
    margin: "15px 0px",
    backgroundColor: theme.palette.action.disabledBackground,
  },
  chatSenderMessages: {
    width: "80%",
    margin: "15px 0px",
    backgroundColor: theme.palette.primary.light,
  },
}));

function ScheduleSession(props) {
  const classes = useStyles();

  const [notificationMessages, setNotificationMessages] = useState([]);

  const [openNotifications, setOpenNotifications] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [submit, setSubmit] = useState({
    status: "",
  });

  const [loading, setLoading] = useState(false);
  const handleMessageTitles = (selected) => {
    setOpenNotifications(true);
    setNotificationMessages(selected);
  };

  const handleNotificationStatus = (read, notificationId) => {
    setLoading(true);
    APIRequest.request(
      "POST",
      ConfigAPIURL.retireCase,
      JSON.stringify({ recordId: notificationId, read: read })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setOpenNotifications(false);
          setLoading(false);
          props.getNotifications();
        }
      }
    });
  };

  // function getNotificationVisible(data) {
  //   const actionTo = data?.actionTo || [];
  //   console.log(actionTo, "actionTo");

  //   if (actionTo.includes("everyone")) {
  //     return "careteam, patient, and caregiver";
  //   } else if (actionTo.includes("careteam and patient")) {
  //     return "both careteam and patient";
  //   } else if (actionTo==="careteam") {
  //     return "Careteam";
  //   } 
  //    else if (actionTo==="Careteam") {
  //     return "Careteam";
  //   } 
  //   else if (actionTo.includes("caregiver and patient")) {
  //     return "both caregiver and patient";
  //   } else if (actionTo.includes("careteam and patient")) {
  //     return "both careteam and patient";
  //   } else if (actionTo.includes("both careteam and caregiver")) {
  //     return "careteam and caregiver";
  //   } else if (actionTo ==="both careteam and caregiver") {
  //     return "careteam and caregiver";
  //   } else if (actionTo ==="patient") {
  //     return "Patient";
  //   } else if (actionTo.includes("caregiver")) {
  //     return "Caregiver";
  //   } else if (actionTo.includes("both")) {
  //     return "caregiver and patient";
  //   } else {
  //     return "";
  //   }
  // }

  function getNotificationVisible(data) {
    const actionTo = data?.actionTo || [];

    if (actionTo === "everyone") {
      return "careteam, patient, and caregiver";
    } else if (actionTo === "both careteam and patient") {
      return "both careteam and patient";
    } else if (actionTo === "careteam") {
      return "Careteam";
    } else if (actionTo === "both caregiver and patient") {
      return "both caregiver and patient";
    } else if (actionTo === "both careteam and patient") {
      return "both careteam and patient";
    } else if (actionTo === "both careteam and caregiver") {
      return "careteam and caregiver";
    } else if (actionTo === "patient") {
      return "patient";
    } else if (actionTo === "patient") {
      return "caregiver";
    } else if (actionTo === "both") {
      return "caregiver and patient";
    } else {
      return "both careteam and caregiver";
    }
  }

  const handleSubmit = () => {
    setSubmit({
      status: "submit",
    });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div style={{ position: "absolute", bottom: "20%", right: "25%" }}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <></>
      )}
      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardHeader
              avatar={
                openNotifications ? (
                  <Tooltip title={props.t("buttons.back")}>
                    <IconButton
                      aria-label="settings"
                      onClick={() => setOpenNotifications(false)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )
              }
              title={
                <Typography
                  align={"center"}
                  variant="p"
                  style={{ fontWeight: "bold" }}
                  component="p"
                >
                  {props.t("sessions.sessions")}
                </Typography>
              }
            />
            <Divider />
            <div>
              {openNotifications === false ? (
                <CardContent style={{ height: "450px", overflow: "auto" }}>
                  {props?.sessionsList?.length === 0 ? (
                    <Card
                      style={{
                        marginTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              variant="p"
                              component="p"
                            >
                              No Record Found
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ) : (
                    props?.sessionsList?.map((sessions, index) => (
                      <div style={{ marginTop: "15px" }} key={index}>
                        {console.log(sessions, "from main page")}
                        <Card
                          key={index}
                          style={{
                            marginTop: "15px",
                            // paddingTop: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleMessageTitles(sessions)}
                        >
                          <CardContent>
                            <Grid container>
                              <Grid xl={10} lg={10} md={10} sm={12} xs={12}>
                                <Typography
                                  style={{
                                    margin: "0",
                                    padding: "0",
                                    fontWeight: "600",
                                  }}
                                  align="left"
                                  variant="p"
                                  component="p"
                                >
                                  Title :
                                  <span style={{ fontWeight: "400" }}>
                                    {" "}
                                    {sessions?.consultationId?.title}
                                  </span>
                                </Typography>
                                <Typography
                                  style={{
                                    margin: "0",
                                    padding: "0",
                                    fontWeight: "600",
                                  }}
                                  align="left"
                                  variant="p"
                                  component="p"
                                >
                                  When :
                                  <span style={{ fontWeight: "400" }}>
                                    {DateConversion.returnDate(
                                      sessions?.startDate
                                    )}
                                  </span>
                                </Typography>
                                <Typography
                                  style={{
                                    margin: "0",
                                    padding: "0",
                                    fontWeight: "600",
                                  }}
                                  align="left"
                                  variant="p"
                                  component="p"
                                >
                                  Sent On :
                                  <span style={{ fontWeight: "400" }}>
                                    {DateConversion.returnDate(
                                      sessions?.createdAt
                                    )}
                                  </span>
                                </Typography>
                                <Typography
                                  style={{
                                    margin: "0",
                                    padding: "0",
                                    fontWeight: "600",
                                  }}
                                  align="left"
                                  variant="p"
                                  component="p"
                                >
                                  Sent By :
                                  <span style={{ fontWeight: "400" }}>
                                    {`${sessions?.createdBy?.fname} ${sessions?.createdBy?.lname}`}
                                  </span>
                                </Typography>
                                <Typography
                                  style={{
                                    margin: "0",
                                    padding: "0",
                                    fontWeight: "600",
                                  }}
                                  align="left"
                                  variant="p"
                                  component="p"
                                >
                                  Sent To:
                                  <span style={{ fontWeight: "400" }}>
                                    {getNotificationVisible(sessions)}
                                  </span>
                                </Typography>
                                <Typography
                                  style={{
                                    margin: "0",
                                    padding: "0",
                                    fontWeight: "600",
                                  }}
                                  align="left"
                                  variant="p"
                                  component="p"
                                >
                                  Meetlink :
                                  <span style={{ fontWeight: "400" }}>
                                    {sessions.consultationId?.meetLink}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid
                                xl={2}
                                lg={2}
                                md={2}
                                sm={12}
                                xs={12}
                                style={{ marginTop: "-12px" }}
                              >
                                <Tooltip title={props.t("buttons.open")}>
                                  <IconButton aria-label="view">
                                    <ArrowForwardIosIcon variant="contained" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </div>
                    ))
                  )}
                </CardContent>
              ) : (
                <CardContent style={{ overflow: "auto" }}>
                  {/* <Link
                    to={`/admin/sessions?sessionId=${notificationMessages?._id}`}
                  > */}
                  <Button
                    onClick={() => {
                      setOpenForm(true);
                    }}
                  >
                    Edit
                  </Button>
                  {/* </Link> */}
                  <Typography
                    variant="p"
                    align="left"
                    component="p"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Status :
                    <span style={{ fontWeight: "400" }}>
                      {(notificationMessages?.status).charAt(0).toUpperCase() +
                        notificationMessages?.status.slice(1)}
                    </span>
                  </Typography>

                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="p"
                    align="left"
                    component="p"
                  >
                    When :
                    <span style={{ fontWeight: "400" }}>
                      {DateConversion.returnDate(
                        notificationMessages?.startDate
                      )}
                    </span>
                  </Typography>

                  <Typography
                    variant="p"
                    align="left"
                    component="p"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Sent On :
                    <span style={{ fontWeight: "400" }}>
                      {DateConversion.returnDate(
                        notificationMessages?.createdAt
                      )}
                    </span>
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Sent By :
                    <span style={{ fontWeight: "400" }}>
                      {`${notificationMessages?.createdBy?.fname} ${notificationMessages?.createdBy?.lname}`}
                    </span>
                  </Typography>

                  <Box align="left">
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        gap: 7,
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                        }}
                        variant="p"
                        component="p"
                      >
                        Sent To:
                        <span style={{ fontWeight: "400" }}>
                          {getNotificationVisible(notificationMessages)}
                        </span>
                      </Typography>
                    </div>
                  </Box>
                  <Typography
                    align="left"
                    style={{ fontWeight: "bold", marginTop: "15px" }}
                    variant="p"
                    component="p"
                  >
                    Title :
                    <span style={{ fontWeight: "400" }}>
                      {notificationMessages?.consultationId?.title}
                    </span>
                  </Typography>

                  <Typography
                    align="left"
                    style={{ fontWeight: "bold", marginTop: "15px" }}
                    variant="p"
                    component="p"
                  >
                    Message :
                    <span style={{ fontWeight: "400" }}>
                      {notificationMessages?.consultationId?.description}
                    </span>
                  </Typography>
                </CardContent>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
      <SessionDialog
        showLables={true}
        label={"Edit Session"}
        showUpdateLabel={true}
        formDialog={openForm}
        // setOpenForm={setOpenForm}
        handleFormDialog={() => {
          setOpenForm(false);
        }}
        submit={handleSubmit}
        // resetForm={resetForm}
        formComponent={
          <EditSession
            openForm={openForm}
            setOpenForm={setOpenForm}
            data={notificationMessages}
            submit={submit}
            getSessions={props.getSessions}
            closeNotification={setOpenNotifications}
          />
        }
      />
    </div>
  );
}
export default withTranslation("translations")(ScheduleSession);
