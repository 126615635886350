class TableHeader {
  static tableHeader = [
    {
      id: "title",
      accessor: "title",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 80,
      label: "sessions.title",
      className: "",
      functionName: "",
    },
    {
      id: "description",
      accessor: "description",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "sessions.description",
      className: "",
      functionName: "",
    },
    {
      id: "meetLink",
      accessor: "meetLink",
      dataType: "string",
      sortable: false,
      disablePadding: false,
      visibility: true,
      width: 100,
      label: "sessions.meetLink",
      className: "",
      functionName: "",
    },
    // {
    //   id: "rating",
    //   accessor: "rating",
    //   dataType: "string",
    //   sortable: false,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 80,
    //   label: "sessions.rating",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "feedback",
    //   accessor: "feedback",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: "sessions.feedback",
    //   className: "",
    //   functionName: "",
    // },
    // {
    //   id: "consultationType",
    //   accessor: "consultationType",
    //   dataType: "string",
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: "sessions.consultationType",
    //   className: "",
    //   functionName: "",
    // },
  ];
}
export default TableHeader;
