import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";

import TableHeader from "./PatientsHeader";
import TableQuery from "./PatientsQuery";

import APIRequest from "../../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";
import ProcessCaseMultiSelectTable from "../../../../../../templates/tables/ProcessCaseMultiSelectTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    marginTop: "80px",
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

// const tableHeader = [
//   {
//     id: 'itemName',
//     accessor: 'itemName',
//     dataType: 'string',
//     sortable: true,
//     disablePadding: false,
//     visibility: true,
//     width: 200,
//     label: 'user.itemName',
//     className: '',
//     functionName: '',
//   },
// ];

function Patients(props) {
  const classes = useStyles();

  const [query, setQuery] = useState({
    page: 0,
    pageSize: 100,
    sortField: "",
    sortOrder: "false",
    active: "all",
    keyword: "",
  });

  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });
  //console.log("query", query);

  useEffect(() => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog !== undefined &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getMedicalInfo(query);
    }
    if (
      props.formDialog !== undefined &&
      props.formDialog !== null &&
      props.formDialog.functionName === "submit"
    ) {
      props.handleFormDialog();
    }

  }, [props.formDialog]);

  //   console.log("userID", props);

  const getMedicalInfo = (query) => {
    const url = ConfigAPIURL.doctorPatientListURL;
    APIRequest.request(
      "POST",
      url,
      JSON.stringify({
        doctorId: props.rowDetails._id,
      })
    ).then((response) => {
      let serverResponse = response?.data?.patients;
      setTableData({
        ...tableData,
        rows: serverResponse, // Save result data in rows property
      });
      //   setTableData(serverResponse);
    });
  };
  const tableCallBack = (rowDetails) => {};

  const handleQuery = (query) => {
    setQuery(query);
    getMedicalInfo(query);
  };

  const resetQuery = () => {
    setQuery({
      page: 0,
      pageSize: 100,
      sortField: "",
      sortOrder: "false",
      active: "all",
    });
    getMedicalInfo({
      page: 0,
      pageSize: 100,
      sortField: "",
      sortOrder: "false",
      active: "all",
    });
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "10px" }}
        >
          {tableData.rows && (
            <ProcessCaseMultiSelectTable
              tableHeader={TableHeader.tableHeader} // table column header
              tableData={tableData} // pass table record
              query={query} // pass query condition
              setQuery={handleQuery} // update query fields
              resetQuery={resetQuery} // reset filter menu values
              selectAll={false} //select all check box props
              tableCallBack={tableCallBack}
              // tableDBLCallBack={tableDBLCallBack}
              searchLabel={props.t("case.searchLabel")}
              //   page={"processCase"}
              queryComponent={
                <TableQuery
                  query={query}
                  setQuery={handleQuery}
                  pageName={
                    props.pageName !== undefined && props.pageName !== null
                      ? props.pageName
                      : null
                  }
                />
              } //filter menu option
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation("translations")(Patients);
