import React from "react";
import { Grid, makeStyles, TextField, Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },

  notesLabel: {
    fontSize: 16,
    color: "#666c73",
  },
  notesData: {
    fontSize: 14,
  },
}));

const MacrosDisplay = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "100%" }}>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.formGrid}
          >
            <TextField
              label='first name'
              autoComplete="something-unsupported"
              value={'<%fname%>'}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.formGrid}
          >
            <TextField
               label='Last name'
              autoComplete="something-unsupported"
              value={'<%lname%>'}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.formGrid}
          >
            <TextField
               label='Meetlink'
              autoComplete="something-unsupported"
              value={'<%meetLink%>'}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.formGrid}
          >
            <TextField
               label='Date'
              autoComplete="something-unsupported"
              value={'<%date%>'}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.formGrid}
          >
            <TextField
               label='Time'
              autoComplete="something-unsupported"
              value={'<%time%>'}
              fullWidth
            />
          </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
          <Button onClick={props.setSlideInDialog}>Close</Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MacrosDisplay;
