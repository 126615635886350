import React, { useEffect, useState } from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";

const EditForm = (props) => {
  console.log(props, "from heres");
  useEffect(() => {
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      updateSessionEditDetails();
    }
  }, [props.formDialog]);

  const [listTemplates, setListTemplate] = useState([]);
  const [interviewSetToOptions, setInterviewSetToOptions] = useState([]);
  const [dateAndTime, setDateAndTime] = useState({
    time: "",
    date: "",
  });
  const [appointmentCreateData, setAppointmentCreateData] = React.useState({
    userId: "",
    description: "",
    title: "",
    meetLink: "",
    startDate: Math.floor(new Date().getTime() / 1000),
    endDate: "",
    status: "upcoming",
    consultToCareTeam: [],
    consultationId: "",
    visibleOn: [],
    actionType: "consultation",
    actionTo: ["patient"],
    active: true,
    rating: null,
    createdAt: null,
    createdBy: null,
    fname: "",
    lname: "",
    email: "",
    mobileNo: "",
  });

  useEffect(() => {
    listTemplatesFunc();
    getsessionEditPage();
  }, []);

  function formatEpochTime(epochTime) {
    const date = new Date(epochTime * 1000);
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const options = { day: "numeric", month: "short", year: "numeric" };
    const dateFormat = new Intl.DateTimeFormat("en-US", options);
    const dateString = dateFormat.format(date);
    return {
      time,
      date: dateString,
    };
  }

  const listTemplatesFunc = async (keyword) => {
    const response = await APIRequest.request(
      "POST",
      ConfigAPIURL.getAllAppointments,
      JSON.stringify({
        keyword: keyword,
        sortOrder: "true",
        active: true,
        page: 0,
        pageSize: 20,
      })
    );
    if (response?.data?.responseCode === 109) {
      setListTemplate(response?.data?.rows);
    }
  };

  const getInterviewersList = (event, data) => {
    if (!appointmentCreateData?.userId) {
      return;
    }

    const URL = ConfigAPIURL.getCareTeamLists;
    APIRequest.request(
      "POST",
      URL,
      JSON.stringify({
        userId: appointmentCreateData?.userId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setInterviewSetToOptions(response?.data?.result?.careTeam);
        }
      }
    });
  };

  const handleStartAtDateChange = (date) => {
    const unixTimestamp = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
    setAppointmentCreateData({
      ...appointmentCreateData,
      startDate: unixTimestamp,
    });
  };

  useEffect(() => {
    getInterviewersList();
  }, [appointmentCreateData?.userId]);

  const getsessionEditPage = () => {
    console.log(props.urlId, "props.urlId");
    const URL = ConfigAPIURL.getSessionbyid;
    APIRequest.request(
      "POST",
      URL,
      JSON.stringify({
        userConsultationId: props?.urlId
          ? props.urlId
          : props?.rowDetails?.consultation?._id,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          const responseData = response?.data?.result?.[0];
          console.log(responseData, "responseData");
          setAppointmentCreateData({
            ...appointmentCreateData,
            title: responseData?.consultation?.consultationId?.[0]?.title,
            description:
              responseData?.consultation?.consultationId?.[0]?.description,
            startDate: responseData?.consultation?.startDate,
            endDate: responseData?.consultation?.endDate,
            meetLink: responseData?.consultation?.consultationId?.[0]?.meetLink,
            consultationId:
              responseData?.consultation?.consultationId?.[0]?._id,
            consultToCareTeam: responseData?.consultation?.consultToCareTeam,
            visibleOn: responseData?.consultation?.visibleOn,
            actionTo: responseData?.consultation?.actionTo,
            userConsultationId: responseData?.consultation?._id,
            status: responseData?.consultation?.status,
            rating: responseData?.consultation?.rating,
            createdAt: responseData?.consultation?.createdAt,
            createdBy: responseData?.consultation?.createdBy,
            fname: responseData?.fname,
            lname: responseData?.lname,
            userId: responseData?._id,
            email: responseData?.email,
            mobileNo: responseData?.mobileNo,
          });
          const epochTime = responseData?.consultation?.startDate;
          const formattedDateTime = formatEpochTime(epochTime);
          setDateAndTime({
            ...dateAndTime,
            time: formattedDateTime.time,
            date: formattedDateTime.date,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (appointmentCreateData.actionTo.includes("everyone")) {
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient", "caregiver", "careteam"],
      });
    }
    if(appointmentCreateData.actionTo.includes("patient") && appointmentCreateData.actionTo.includes("caregiver") && appointmentCreateData.actionTo.includes("careteam")){
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient","careteam","caregiver"],
      });
    }
    if(appointmentCreateData.actionTo.includes("patient") && appointmentCreateData.actionTo.includes("caregiver")){
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient","caregiver"],
      });
    }
    if(appointmentCreateData.actionTo.includes("patient") && appointmentCreateData.actionTo.includes("careteam")){
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient","careteam"],
      });
    }
    if(appointmentCreateData.actionTo.includes("caregiver") && appointmentCreateData.actionTo.includes("careteam")){
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["caregiver","careteam"],
      });
    }
    if (appointmentCreateData.actionTo === "both") {
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["patient","caregiver"],
      });
    }
    if(appointmentCreateData.actionTo.includes("Careteam")){
      setAppointmentCreateData({
        ...appointmentCreateData,
        actionTo: ["careteam"],
      });
    }

  
  },[appointmentCreateData?.userId]);

  const updateSessionEditDetails = () => {
    if (
      !appointmentCreateData?.consultToCareTeam ||
      appointmentCreateData.consultToCareTeam.length === 0
    ) {
      SnackbarUtils.error(
        "Please select at least one care team!",
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }

    const consultToCareTeamIds = appointmentCreateData.consultToCareTeam.map(
      (option) => option._id
    );
    appointmentCreateData.consultToCareTeam = consultToCareTeamIds;

    // Deleting unwanted properties
    delete appointmentCreateData.rating;
    delete appointmentCreateData.createdAt;
    delete appointmentCreateData.createdBy;
    delete appointmentCreateData.fname;
    delete appointmentCreateData.lname;
    delete appointmentCreateData.email;
    delete appointmentCreateData.mobileNo;

    const URL = ConfigAPIURL.editSessionbyid;

    const payload = {
      ...appointmentCreateData,
      ...dateAndTime,
    };
    APIRequest.request("POST", URL, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              "Session Updated Successfully",
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            props.handleFormDialog();
            props.tableQuery({
              keyword: "",
              page: 0,
              pageSize: 10,
              sortField: "",
              sortOrder: "false",
              active: "true",
            });
          } else {
            SnackbarUtils.error(
              "Something went wrong!",
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
          }
        }
      }
    );
  };

  return {
    appointmentCreateData,
    setAppointmentCreateData,
    listTemplates,
    interviewSetToOptions,
    handleStartAtDateChange,
    setListTemplate,
    getInterviewersList,
    setInterviewSetToOptions,
    updateSessionEditDetails,
    listTemplatesFunc,
  };
};

export default EditForm;
