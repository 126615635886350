import React, { Component } from 'react';
import Header from './Header';
import Content from './Content';
export default class General extends Component {
	render() {
		return (
			<div>
				<Header {...this.props} />
				<div>
					<Content />
				</div>
			</div>
		);
	}
}
